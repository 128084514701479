/* eslint-disable */
import { OccupancyAtVa, occupancyAtVaFromJSON, occupancyAtVaToJSON } from "../../../web/data/offer";

export const protobufPackage = "od_protos.journey.data.categories";

export interface SellVACategory {
  event?:
    | { $case: "flowStart"; flowStart: SellVACategory_FlowStartEvent }
    | { $case: "start"; start: SellVACategory_StartEvent }
    | { $case: "complete"; complete: SellVACategory_CompleteEvent }
    | { $case: "occupancy"; occupancy: SellVACategory_OccupancyEvent }
    | undefined;
}

export enum SellVACategory_VAType {
  UNKNOWN_VA_TYPE = 0,
  ORVA = 1,
  SSVA = 2,
  UNRECOGNIZED = -1,
}

export function sellVACategory_VATypeFromJSON(object: any): SellVACategory_VAType {
  switch (object) {
    case 0:
    case "UNKNOWN_VA_TYPE":
      return SellVACategory_VAType.UNKNOWN_VA_TYPE;
    case 1:
    case "ORVA":
      return SellVACategory_VAType.ORVA;
    case 2:
    case "SSVA":
      return SellVACategory_VAType.SSVA;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellVACategory_VAType.UNRECOGNIZED;
  }
}

export function sellVACategory_VATypeToJSON(object: SellVACategory_VAType): string {
  switch (object) {
    case SellVACategory_VAType.UNKNOWN_VA_TYPE:
      return "UNKNOWN_VA_TYPE";
    case SellVACategory_VAType.ORVA:
      return "ORVA";
    case SellVACategory_VAType.SSVA:
      return "SSVA";
    case SellVACategory_VAType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SellVACategory_FlowStartEvent {
  leadId: string;
  /** This is populated with a UUID, not a numeric ID as the name suggests */
  offerId: string;
  offerUuid: string;
}

export interface SellVACategory_OccupancyEvent {
  leadId: string;
  offerUuid: string;
  occupancy: OccupancyAtVa;
}

export interface SellVACategory_StartEvent {
  leadId: string;
  /** This is populated with a UUID, not a numeric ID as the name suggests */
  offerId: string;
  vaType: SellVACategory_VAType;
  offerUuid: string;
}

export interface SellVACategory_CompleteEvent {
  leadId: string;
  offerId: string;
  vaType: SellVACategory_VAType;
  offerUuid: string;
}

function createBaseSellVACategory(): SellVACategory {
  return { event: undefined };
}

export const SellVACategory = {
  fromJSON(object: any): SellVACategory {
    return {
      event: isSet(object.flowStart)
        ? { $case: "flowStart", flowStart: SellVACategory_FlowStartEvent.fromJSON(object.flowStart) }
        : isSet(object.start)
        ? { $case: "start", start: SellVACategory_StartEvent.fromJSON(object.start) }
        : isSet(object.complete)
        ? { $case: "complete", complete: SellVACategory_CompleteEvent.fromJSON(object.complete) }
        : isSet(object.occupancy)
        ? { $case: "occupancy", occupancy: SellVACategory_OccupancyEvent.fromJSON(object.occupancy) }
        : undefined,
    };
  },

  toJSON(message: SellVACategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "flowStart") {
      obj.flowStart = SellVACategory_FlowStartEvent.toJSON(message.event.flowStart);
    }
    if (message.event?.$case === "start") {
      obj.start = SellVACategory_StartEvent.toJSON(message.event.start);
    }
    if (message.event?.$case === "complete") {
      obj.complete = SellVACategory_CompleteEvent.toJSON(message.event.complete);
    }
    if (message.event?.$case === "occupancy") {
      obj.occupancy = SellVACategory_OccupancyEvent.toJSON(message.event.occupancy);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellVACategory>, I>>(base?: I): SellVACategory {
    return SellVACategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellVACategory>, I>>(object: I): SellVACategory {
    const message = createBaseSellVACategory();
    if (
      object.event?.$case === "flowStart" && object.event?.flowStart !== undefined && object.event?.flowStart !== null
    ) {
      message.event = {
        $case: "flowStart",
        flowStart: SellVACategory_FlowStartEvent.fromPartial(object.event.flowStart),
      };
    }
    if (object.event?.$case === "start" && object.event?.start !== undefined && object.event?.start !== null) {
      message.event = { $case: "start", start: SellVACategory_StartEvent.fromPartial(object.event.start) };
    }
    if (object.event?.$case === "complete" && object.event?.complete !== undefined && object.event?.complete !== null) {
      message.event = { $case: "complete", complete: SellVACategory_CompleteEvent.fromPartial(object.event.complete) };
    }
    if (
      object.event?.$case === "occupancy" && object.event?.occupancy !== undefined && object.event?.occupancy !== null
    ) {
      message.event = {
        $case: "occupancy",
        occupancy: SellVACategory_OccupancyEvent.fromPartial(object.event.occupancy),
      };
    }
    return message;
  },
};

function createBaseSellVACategory_FlowStartEvent(): SellVACategory_FlowStartEvent {
  return { leadId: "", offerId: "", offerUuid: "" };
}

export const SellVACategory_FlowStartEvent = {
  fromJSON(object: any): SellVACategory_FlowStartEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
    };
  },

  toJSON(message: SellVACategory_FlowStartEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerId !== "") {
      obj.offerId = message.offerId;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellVACategory_FlowStartEvent>, I>>(base?: I): SellVACategory_FlowStartEvent {
    return SellVACategory_FlowStartEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellVACategory_FlowStartEvent>, I>>(
    object: I,
  ): SellVACategory_FlowStartEvent {
    const message = createBaseSellVACategory_FlowStartEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.offerUuid = object.offerUuid ?? "";
    return message;
  },
};

function createBaseSellVACategory_OccupancyEvent(): SellVACategory_OccupancyEvent {
  return { leadId: "", offerUuid: "", occupancy: 0 };
}

export const SellVACategory_OccupancyEvent = {
  fromJSON(object: any): SellVACategory_OccupancyEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
      occupancy: isSet(object.occupancy) ? occupancyAtVaFromJSON(object.occupancy) : 0,
    };
  },

  toJSON(message: SellVACategory_OccupancyEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    if (message.occupancy !== 0) {
      obj.occupancy = occupancyAtVaToJSON(message.occupancy);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellVACategory_OccupancyEvent>, I>>(base?: I): SellVACategory_OccupancyEvent {
    return SellVACategory_OccupancyEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellVACategory_OccupancyEvent>, I>>(
    object: I,
  ): SellVACategory_OccupancyEvent {
    const message = createBaseSellVACategory_OccupancyEvent();
    message.leadId = object.leadId ?? "";
    message.offerUuid = object.offerUuid ?? "";
    message.occupancy = object.occupancy ?? 0;
    return message;
  },
};

function createBaseSellVACategory_StartEvent(): SellVACategory_StartEvent {
  return { leadId: "", offerId: "", vaType: 0, offerUuid: "" };
}

export const SellVACategory_StartEvent = {
  fromJSON(object: any): SellVACategory_StartEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      vaType: isSet(object.vaType) ? sellVACategory_VATypeFromJSON(object.vaType) : 0,
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
    };
  },

  toJSON(message: SellVACategory_StartEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerId !== "") {
      obj.offerId = message.offerId;
    }
    if (message.vaType !== 0) {
      obj.vaType = sellVACategory_VATypeToJSON(message.vaType);
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellVACategory_StartEvent>, I>>(base?: I): SellVACategory_StartEvent {
    return SellVACategory_StartEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellVACategory_StartEvent>, I>>(object: I): SellVACategory_StartEvent {
    const message = createBaseSellVACategory_StartEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.vaType = object.vaType ?? 0;
    message.offerUuid = object.offerUuid ?? "";
    return message;
  },
};

function createBaseSellVACategory_CompleteEvent(): SellVACategory_CompleteEvent {
  return { leadId: "", offerId: "", vaType: 0, offerUuid: "" };
}

export const SellVACategory_CompleteEvent = {
  fromJSON(object: any): SellVACategory_CompleteEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      vaType: isSet(object.vaType) ? sellVACategory_VATypeFromJSON(object.vaType) : 0,
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
    };
  },

  toJSON(message: SellVACategory_CompleteEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerId !== "") {
      obj.offerId = message.offerId;
    }
    if (message.vaType !== 0) {
      obj.vaType = sellVACategory_VATypeToJSON(message.vaType);
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellVACategory_CompleteEvent>, I>>(base?: I): SellVACategory_CompleteEvent {
    return SellVACategory_CompleteEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellVACategory_CompleteEvent>, I>>(object: I): SellVACategory_CompleteEvent {
    const message = createBaseSellVACategory_CompleteEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.vaType = object.vaType ?? 0;
    message.offerUuid = object.offerUuid ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
