/* eslint-disable */

export const protobufPackage = "od_protos.journey.data.categories";

export interface PlatformCategory {
  event?:
    | { $case: "accordionClickEvent"; accordionClickEvent: PlatformCategory_AccordionClickEvent }
    | { $case: "accordionViewEvent"; accordionViewEvent: PlatformCategory_AccordionViewEvent }
    | { $case: "boxViewEvent"; boxViewEvent: PlatformCategory_BoxViewEvent }
    | { $case: "buttonClickEvent"; buttonClickEvent: PlatformCategory_ButtonClickEvent }
    | { $case: "buttonViewEvent"; buttonViewEvent: PlatformCategory_ButtonViewEvent }
    | { $case: "cardViewEvent"; cardViewEvent: PlatformCategory_CardViewEvent }
    | { $case: "checkboxClickEvent"; checkboxClickEvent: PlatformCategory_CheckboxClickEvent }
    | { $case: "checkboxViewEvent"; checkboxViewEvent: PlatformCategory_CheckboxViewEvent }
    | { $case: "imageViewEvent"; imageViewEvent: PlatformCategory_ImageViewEvent }
    | { $case: "inputViewEvent"; inputViewEvent: PlatformCategory_InputViewEvent }
    | { $case: "linkClickEvent"; linkClickEvent: PlatformCategory_LinkClickEvent }
    | { $case: "linkViewEvent"; linkViewEvent: PlatformCategory_LinkViewEvent }
    | { $case: "radioClickEvent"; radioClickEvent: PlatformCategory_RadioClickEvent }
    | { $case: "radioViewEvent"; radioViewEvent: PlatformCategory_RadioViewEvent }
    | { $case: "textViewEvent"; textViewEvent: PlatformCategory_TextViewEvent }
    | { $case: "pageViewEvent"; pageViewEvent: PlatformCategory_PageViewEvent }
    | { $case: "screenViewEvent"; screenViewEvent: PlatformCategory_ScreenViewEvent }
    | {
      $case: "customerOneTimeExperienceEvent";
      customerOneTimeExperienceEvent: PlatformCategory_CustomerOneTimeExperienceEvent;
    }
    | { $case: "leadOneTimeExperienceEvent"; leadOneTimeExperienceEvent: PlatformCategory_LeadOneTimeExperienceEvent }
    | { $case: "customerUnsubscribeEvent"; customerUnsubscribeEvent: PlatformCategory_CustomerUnsubscribeEvent }
    | undefined;
}

export interface PlatformCategory_AccordionClickEvent {
  /**
   * Canonical identifier of the accordion list
   * Convention: {app_name}-{accordion_list_name}
   * accordion_list_name should be unique within the app
   */
  targetName: string;
  /** Name of the individual accordion in the accordion list */
  accordionName: string;
}

export interface PlatformCategory_AccordionViewEvent {
  /**
   * Canonical identifier of the accordion list
   * Convention: {app_name}-{accordion_list_name}
   * accordion_list_name should be unique within the app
   */
  targetName: string;
}

export interface PlatformCategory_BoxViewEvent {
  /**
   * Canonical identifier of the box
   * Convention: {app_name}-{box_name}
   * box_name should be unique within the app
   */
  targetName: string;
}

export interface PlatformCategory_ButtonClickEvent {
  /**
   * Canonical identifier of the button
   * Convention: {app_name}-{button_name}
   * button_name should be unique within the app
   */
  targetName: string;
}

export interface PlatformCategory_ButtonViewEvent {
  /**
   * Canonical identifier of the button
   * Convention: {app_name}-{button_name}
   * button_name should be unique within the app
   */
  targetName: string;
}

export interface PlatformCategory_CardViewEvent {
  /**
   * Canonical identifier of the card
   * Convention: {app_name}-{card_name}
   * card_name should be unique within the app
   */
  targetName: string;
}

export interface PlatformCategory_CheckboxClickEvent {
  /**
   * Canonical identifier of the checkbox group
   * Convention: {app_name}-{checkbox_name}
   * checkbox_name should be unique within the app
   */
  targetName: string;
  /** Name of the individual checkbox in the checkbox group */
  optionName: string;
}

export interface PlatformCategory_CheckboxViewEvent {
  /**
   * Canonical identifier of the checkbox group
   * Convention: {app_name}-{checkbox_name}
   * checkbox_name should be unique within the app
   */
  targetName: string;
}

export interface PlatformCategory_ImageViewEvent {
  /**
   * Canonical identifier of the image
   * Convention: {app_name}-{image_name}
   * image_name should be unique within the app
   */
  targetName: string;
}

export interface PlatformCategory_InputViewEvent {
  /**
   * Canonical identifier of the input
   * Convention: {app_name}-{input_name}
   * input_name should be unique within the app
   */
  targetName: string;
}

export interface PlatformCategory_LinkClickEvent {
  /**
   * Canonical identifier of the link
   * Convention: {app_name}-{link_name}
   * link_name should be unique within the app
   */
  targetName: string;
}

export interface PlatformCategory_LinkViewEvent {
  /**
   * Canonical identifier of the link
   * Convention: {app_name}-{link_name}
   * link_name should be unique within the app
   */
  targetName: string;
}

export interface PlatformCategory_RadioClickEvent {
  /**
   * Canonical identifier of the radio group
   * Convention: {app_name}-{radio_name}
   * radio_name should be unique within the app
   */
  targetName: string;
  /** Name of the individual radio in the radio group */
  optionName: string;
}

export interface PlatformCategory_RadioViewEvent {
  /**
   * Canonical identifier of the radio group
   * Convention: {app_name}-{radio_name}
   * radio_name should be unique within the app
   */
  targetName: string;
}

export interface PlatformCategory_TextViewEvent {
  /**
   * Canonical identifier of the text
   * Convention: {app_name}-{text_name}
   * text_name should be unique within the app
   */
  targetName: string;
}

/**
 * website_source field contains the page's url, etc.
 * No other event-specific data needed for now.
 */
export interface PlatformCategory_PageViewEvent {
}

/**
 * mobile_source field contains the screen's focusRouteName, etc.
 * No other event-specfic data needed for now.
 */
export interface PlatformCategory_ScreenViewEvent {
}

export interface PlatformCategory_CustomerOneTimeExperienceEvent {
  experienceName: string;
}

export interface PlatformCategory_LeadOneTimeExperienceEvent {
  experienceName: string;
  leadId: string;
}

export interface PlatformCategory_CustomerUnsubscribeEvent {
  feedName: string;
}

function createBasePlatformCategory(): PlatformCategory {
  return { event: undefined };
}

export const PlatformCategory = {
  fromJSON(object: any): PlatformCategory {
    return {
      event: isSet(object.accordionClickEvent)
        ? {
          $case: "accordionClickEvent",
          accordionClickEvent: PlatformCategory_AccordionClickEvent.fromJSON(object.accordionClickEvent),
        }
        : isSet(object.accordionViewEvent)
        ? {
          $case: "accordionViewEvent",
          accordionViewEvent: PlatformCategory_AccordionViewEvent.fromJSON(object.accordionViewEvent),
        }
        : isSet(object.boxViewEvent)
        ? { $case: "boxViewEvent", boxViewEvent: PlatformCategory_BoxViewEvent.fromJSON(object.boxViewEvent) }
        : isSet(object.buttonClickEvent)
        ? {
          $case: "buttonClickEvent",
          buttonClickEvent: PlatformCategory_ButtonClickEvent.fromJSON(object.buttonClickEvent),
        }
        : isSet(object.buttonViewEvent)
        ? {
          $case: "buttonViewEvent",
          buttonViewEvent: PlatformCategory_ButtonViewEvent.fromJSON(object.buttonViewEvent),
        }
        : isSet(object.cardViewEvent)
        ? { $case: "cardViewEvent", cardViewEvent: PlatformCategory_CardViewEvent.fromJSON(object.cardViewEvent) }
        : isSet(object.checkboxClickEvent)
        ? {
          $case: "checkboxClickEvent",
          checkboxClickEvent: PlatformCategory_CheckboxClickEvent.fromJSON(object.checkboxClickEvent),
        }
        : isSet(object.checkboxViewEvent)
        ? {
          $case: "checkboxViewEvent",
          checkboxViewEvent: PlatformCategory_CheckboxViewEvent.fromJSON(object.checkboxViewEvent),
        }
        : isSet(object.imageViewEvent)
        ? { $case: "imageViewEvent", imageViewEvent: PlatformCategory_ImageViewEvent.fromJSON(object.imageViewEvent) }
        : isSet(object.inputViewEvent)
        ? { $case: "inputViewEvent", inputViewEvent: PlatformCategory_InputViewEvent.fromJSON(object.inputViewEvent) }
        : isSet(object.linkClickEvent)
        ? { $case: "linkClickEvent", linkClickEvent: PlatformCategory_LinkClickEvent.fromJSON(object.linkClickEvent) }
        : isSet(object.linkViewEvent)
        ? { $case: "linkViewEvent", linkViewEvent: PlatformCategory_LinkViewEvent.fromJSON(object.linkViewEvent) }
        : isSet(object.radioClickEvent)
        ? {
          $case: "radioClickEvent",
          radioClickEvent: PlatformCategory_RadioClickEvent.fromJSON(object.radioClickEvent),
        }
        : isSet(object.radioViewEvent)
        ? { $case: "radioViewEvent", radioViewEvent: PlatformCategory_RadioViewEvent.fromJSON(object.radioViewEvent) }
        : isSet(object.textViewEvent)
        ? { $case: "textViewEvent", textViewEvent: PlatformCategory_TextViewEvent.fromJSON(object.textViewEvent) }
        : isSet(object.pageViewEvent)
        ? { $case: "pageViewEvent", pageViewEvent: PlatformCategory_PageViewEvent.fromJSON(object.pageViewEvent) }
        : isSet(object.screenViewEvent)
        ? {
          $case: "screenViewEvent",
          screenViewEvent: PlatformCategory_ScreenViewEvent.fromJSON(object.screenViewEvent),
        }
        : isSet(object.customerOneTimeExperienceEvent)
        ? {
          $case: "customerOneTimeExperienceEvent",
          customerOneTimeExperienceEvent: PlatformCategory_CustomerOneTimeExperienceEvent.fromJSON(
            object.customerOneTimeExperienceEvent,
          ),
        }
        : isSet(object.leadOneTimeExperienceEvent)
        ? {
          $case: "leadOneTimeExperienceEvent",
          leadOneTimeExperienceEvent: PlatformCategory_LeadOneTimeExperienceEvent.fromJSON(
            object.leadOneTimeExperienceEvent,
          ),
        }
        : isSet(object.customerUnsubscribeEvent)
        ? {
          $case: "customerUnsubscribeEvent",
          customerUnsubscribeEvent: PlatformCategory_CustomerUnsubscribeEvent.fromJSON(object.customerUnsubscribeEvent),
        }
        : undefined,
    };
  },

  toJSON(message: PlatformCategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "accordionClickEvent") {
      obj.accordionClickEvent = PlatformCategory_AccordionClickEvent.toJSON(message.event.accordionClickEvent);
    }
    if (message.event?.$case === "accordionViewEvent") {
      obj.accordionViewEvent = PlatformCategory_AccordionViewEvent.toJSON(message.event.accordionViewEvent);
    }
    if (message.event?.$case === "boxViewEvent") {
      obj.boxViewEvent = PlatformCategory_BoxViewEvent.toJSON(message.event.boxViewEvent);
    }
    if (message.event?.$case === "buttonClickEvent") {
      obj.buttonClickEvent = PlatformCategory_ButtonClickEvent.toJSON(message.event.buttonClickEvent);
    }
    if (message.event?.$case === "buttonViewEvent") {
      obj.buttonViewEvent = PlatformCategory_ButtonViewEvent.toJSON(message.event.buttonViewEvent);
    }
    if (message.event?.$case === "cardViewEvent") {
      obj.cardViewEvent = PlatformCategory_CardViewEvent.toJSON(message.event.cardViewEvent);
    }
    if (message.event?.$case === "checkboxClickEvent") {
      obj.checkboxClickEvent = PlatformCategory_CheckboxClickEvent.toJSON(message.event.checkboxClickEvent);
    }
    if (message.event?.$case === "checkboxViewEvent") {
      obj.checkboxViewEvent = PlatformCategory_CheckboxViewEvent.toJSON(message.event.checkboxViewEvent);
    }
    if (message.event?.$case === "imageViewEvent") {
      obj.imageViewEvent = PlatformCategory_ImageViewEvent.toJSON(message.event.imageViewEvent);
    }
    if (message.event?.$case === "inputViewEvent") {
      obj.inputViewEvent = PlatformCategory_InputViewEvent.toJSON(message.event.inputViewEvent);
    }
    if (message.event?.$case === "linkClickEvent") {
      obj.linkClickEvent = PlatformCategory_LinkClickEvent.toJSON(message.event.linkClickEvent);
    }
    if (message.event?.$case === "linkViewEvent") {
      obj.linkViewEvent = PlatformCategory_LinkViewEvent.toJSON(message.event.linkViewEvent);
    }
    if (message.event?.$case === "radioClickEvent") {
      obj.radioClickEvent = PlatformCategory_RadioClickEvent.toJSON(message.event.radioClickEvent);
    }
    if (message.event?.$case === "radioViewEvent") {
      obj.radioViewEvent = PlatformCategory_RadioViewEvent.toJSON(message.event.radioViewEvent);
    }
    if (message.event?.$case === "textViewEvent") {
      obj.textViewEvent = PlatformCategory_TextViewEvent.toJSON(message.event.textViewEvent);
    }
    if (message.event?.$case === "pageViewEvent") {
      obj.pageViewEvent = PlatformCategory_PageViewEvent.toJSON(message.event.pageViewEvent);
    }
    if (message.event?.$case === "screenViewEvent") {
      obj.screenViewEvent = PlatformCategory_ScreenViewEvent.toJSON(message.event.screenViewEvent);
    }
    if (message.event?.$case === "customerOneTimeExperienceEvent") {
      obj.customerOneTimeExperienceEvent = PlatformCategory_CustomerOneTimeExperienceEvent.toJSON(
        message.event.customerOneTimeExperienceEvent,
      );
    }
    if (message.event?.$case === "leadOneTimeExperienceEvent") {
      obj.leadOneTimeExperienceEvent = PlatformCategory_LeadOneTimeExperienceEvent.toJSON(
        message.event.leadOneTimeExperienceEvent,
      );
    }
    if (message.event?.$case === "customerUnsubscribeEvent") {
      obj.customerUnsubscribeEvent = PlatformCategory_CustomerUnsubscribeEvent.toJSON(
        message.event.customerUnsubscribeEvent,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory>, I>>(base?: I): PlatformCategory {
    return PlatformCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory>, I>>(object: I): PlatformCategory {
    const message = createBasePlatformCategory();
    if (
      object.event?.$case === "accordionClickEvent" &&
      object.event?.accordionClickEvent !== undefined &&
      object.event?.accordionClickEvent !== null
    ) {
      message.event = {
        $case: "accordionClickEvent",
        accordionClickEvent: PlatformCategory_AccordionClickEvent.fromPartial(object.event.accordionClickEvent),
      };
    }
    if (
      object.event?.$case === "accordionViewEvent" &&
      object.event?.accordionViewEvent !== undefined &&
      object.event?.accordionViewEvent !== null
    ) {
      message.event = {
        $case: "accordionViewEvent",
        accordionViewEvent: PlatformCategory_AccordionViewEvent.fromPartial(object.event.accordionViewEvent),
      };
    }
    if (
      object.event?.$case === "boxViewEvent" &&
      object.event?.boxViewEvent !== undefined &&
      object.event?.boxViewEvent !== null
    ) {
      message.event = {
        $case: "boxViewEvent",
        boxViewEvent: PlatformCategory_BoxViewEvent.fromPartial(object.event.boxViewEvent),
      };
    }
    if (
      object.event?.$case === "buttonClickEvent" &&
      object.event?.buttonClickEvent !== undefined &&
      object.event?.buttonClickEvent !== null
    ) {
      message.event = {
        $case: "buttonClickEvent",
        buttonClickEvent: PlatformCategory_ButtonClickEvent.fromPartial(object.event.buttonClickEvent),
      };
    }
    if (
      object.event?.$case === "buttonViewEvent" &&
      object.event?.buttonViewEvent !== undefined &&
      object.event?.buttonViewEvent !== null
    ) {
      message.event = {
        $case: "buttonViewEvent",
        buttonViewEvent: PlatformCategory_ButtonViewEvent.fromPartial(object.event.buttonViewEvent),
      };
    }
    if (
      object.event?.$case === "cardViewEvent" &&
      object.event?.cardViewEvent !== undefined &&
      object.event?.cardViewEvent !== null
    ) {
      message.event = {
        $case: "cardViewEvent",
        cardViewEvent: PlatformCategory_CardViewEvent.fromPartial(object.event.cardViewEvent),
      };
    }
    if (
      object.event?.$case === "checkboxClickEvent" &&
      object.event?.checkboxClickEvent !== undefined &&
      object.event?.checkboxClickEvent !== null
    ) {
      message.event = {
        $case: "checkboxClickEvent",
        checkboxClickEvent: PlatformCategory_CheckboxClickEvent.fromPartial(object.event.checkboxClickEvent),
      };
    }
    if (
      object.event?.$case === "checkboxViewEvent" &&
      object.event?.checkboxViewEvent !== undefined &&
      object.event?.checkboxViewEvent !== null
    ) {
      message.event = {
        $case: "checkboxViewEvent",
        checkboxViewEvent: PlatformCategory_CheckboxViewEvent.fromPartial(object.event.checkboxViewEvent),
      };
    }
    if (
      object.event?.$case === "imageViewEvent" &&
      object.event?.imageViewEvent !== undefined &&
      object.event?.imageViewEvent !== null
    ) {
      message.event = {
        $case: "imageViewEvent",
        imageViewEvent: PlatformCategory_ImageViewEvent.fromPartial(object.event.imageViewEvent),
      };
    }
    if (
      object.event?.$case === "inputViewEvent" &&
      object.event?.inputViewEvent !== undefined &&
      object.event?.inputViewEvent !== null
    ) {
      message.event = {
        $case: "inputViewEvent",
        inputViewEvent: PlatformCategory_InputViewEvent.fromPartial(object.event.inputViewEvent),
      };
    }
    if (
      object.event?.$case === "linkClickEvent" &&
      object.event?.linkClickEvent !== undefined &&
      object.event?.linkClickEvent !== null
    ) {
      message.event = {
        $case: "linkClickEvent",
        linkClickEvent: PlatformCategory_LinkClickEvent.fromPartial(object.event.linkClickEvent),
      };
    }
    if (
      object.event?.$case === "linkViewEvent" &&
      object.event?.linkViewEvent !== undefined &&
      object.event?.linkViewEvent !== null
    ) {
      message.event = {
        $case: "linkViewEvent",
        linkViewEvent: PlatformCategory_LinkViewEvent.fromPartial(object.event.linkViewEvent),
      };
    }
    if (
      object.event?.$case === "radioClickEvent" &&
      object.event?.radioClickEvent !== undefined &&
      object.event?.radioClickEvent !== null
    ) {
      message.event = {
        $case: "radioClickEvent",
        radioClickEvent: PlatformCategory_RadioClickEvent.fromPartial(object.event.radioClickEvent),
      };
    }
    if (
      object.event?.$case === "radioViewEvent" &&
      object.event?.radioViewEvent !== undefined &&
      object.event?.radioViewEvent !== null
    ) {
      message.event = {
        $case: "radioViewEvent",
        radioViewEvent: PlatformCategory_RadioViewEvent.fromPartial(object.event.radioViewEvent),
      };
    }
    if (
      object.event?.$case === "textViewEvent" &&
      object.event?.textViewEvent !== undefined &&
      object.event?.textViewEvent !== null
    ) {
      message.event = {
        $case: "textViewEvent",
        textViewEvent: PlatformCategory_TextViewEvent.fromPartial(object.event.textViewEvent),
      };
    }
    if (
      object.event?.$case === "pageViewEvent" &&
      object.event?.pageViewEvent !== undefined &&
      object.event?.pageViewEvent !== null
    ) {
      message.event = {
        $case: "pageViewEvent",
        pageViewEvent: PlatformCategory_PageViewEvent.fromPartial(object.event.pageViewEvent),
      };
    }
    if (
      object.event?.$case === "screenViewEvent" &&
      object.event?.screenViewEvent !== undefined &&
      object.event?.screenViewEvent !== null
    ) {
      message.event = {
        $case: "screenViewEvent",
        screenViewEvent: PlatformCategory_ScreenViewEvent.fromPartial(object.event.screenViewEvent),
      };
    }
    if (
      object.event?.$case === "customerOneTimeExperienceEvent" &&
      object.event?.customerOneTimeExperienceEvent !== undefined &&
      object.event?.customerOneTimeExperienceEvent !== null
    ) {
      message.event = {
        $case: "customerOneTimeExperienceEvent",
        customerOneTimeExperienceEvent: PlatformCategory_CustomerOneTimeExperienceEvent.fromPartial(
          object.event.customerOneTimeExperienceEvent,
        ),
      };
    }
    if (
      object.event?.$case === "leadOneTimeExperienceEvent" &&
      object.event?.leadOneTimeExperienceEvent !== undefined &&
      object.event?.leadOneTimeExperienceEvent !== null
    ) {
      message.event = {
        $case: "leadOneTimeExperienceEvent",
        leadOneTimeExperienceEvent: PlatformCategory_LeadOneTimeExperienceEvent.fromPartial(
          object.event.leadOneTimeExperienceEvent,
        ),
      };
    }
    if (
      object.event?.$case === "customerUnsubscribeEvent" &&
      object.event?.customerUnsubscribeEvent !== undefined &&
      object.event?.customerUnsubscribeEvent !== null
    ) {
      message.event = {
        $case: "customerUnsubscribeEvent",
        customerUnsubscribeEvent: PlatformCategory_CustomerUnsubscribeEvent.fromPartial(
          object.event.customerUnsubscribeEvent,
        ),
      };
    }
    return message;
  },
};

function createBasePlatformCategory_AccordionClickEvent(): PlatformCategory_AccordionClickEvent {
  return { targetName: "", accordionName: "" };
}

export const PlatformCategory_AccordionClickEvent = {
  fromJSON(object: any): PlatformCategory_AccordionClickEvent {
    return {
      targetName: isSet(object.targetName) ? String(object.targetName) : "",
      accordionName: isSet(object.accordionName) ? String(object.accordionName) : "",
    };
  },

  toJSON(message: PlatformCategory_AccordionClickEvent): unknown {
    const obj: any = {};
    if (message.targetName !== "") {
      obj.targetName = message.targetName;
    }
    if (message.accordionName !== "") {
      obj.accordionName = message.accordionName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_AccordionClickEvent>, I>>(
    base?: I,
  ): PlatformCategory_AccordionClickEvent {
    return PlatformCategory_AccordionClickEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_AccordionClickEvent>, I>>(
    object: I,
  ): PlatformCategory_AccordionClickEvent {
    const message = createBasePlatformCategory_AccordionClickEvent();
    message.targetName = object.targetName ?? "";
    message.accordionName = object.accordionName ?? "";
    return message;
  },
};

function createBasePlatformCategory_AccordionViewEvent(): PlatformCategory_AccordionViewEvent {
  return { targetName: "" };
}

export const PlatformCategory_AccordionViewEvent = {
  fromJSON(object: any): PlatformCategory_AccordionViewEvent {
    return { targetName: isSet(object.targetName) ? String(object.targetName) : "" };
  },

  toJSON(message: PlatformCategory_AccordionViewEvent): unknown {
    const obj: any = {};
    if (message.targetName !== "") {
      obj.targetName = message.targetName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_AccordionViewEvent>, I>>(
    base?: I,
  ): PlatformCategory_AccordionViewEvent {
    return PlatformCategory_AccordionViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_AccordionViewEvent>, I>>(
    object: I,
  ): PlatformCategory_AccordionViewEvent {
    const message = createBasePlatformCategory_AccordionViewEvent();
    message.targetName = object.targetName ?? "";
    return message;
  },
};

function createBasePlatformCategory_BoxViewEvent(): PlatformCategory_BoxViewEvent {
  return { targetName: "" };
}

export const PlatformCategory_BoxViewEvent = {
  fromJSON(object: any): PlatformCategory_BoxViewEvent {
    return { targetName: isSet(object.targetName) ? String(object.targetName) : "" };
  },

  toJSON(message: PlatformCategory_BoxViewEvent): unknown {
    const obj: any = {};
    if (message.targetName !== "") {
      obj.targetName = message.targetName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_BoxViewEvent>, I>>(base?: I): PlatformCategory_BoxViewEvent {
    return PlatformCategory_BoxViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_BoxViewEvent>, I>>(
    object: I,
  ): PlatformCategory_BoxViewEvent {
    const message = createBasePlatformCategory_BoxViewEvent();
    message.targetName = object.targetName ?? "";
    return message;
  },
};

function createBasePlatformCategory_ButtonClickEvent(): PlatformCategory_ButtonClickEvent {
  return { targetName: "" };
}

export const PlatformCategory_ButtonClickEvent = {
  fromJSON(object: any): PlatformCategory_ButtonClickEvent {
    return { targetName: isSet(object.targetName) ? String(object.targetName) : "" };
  },

  toJSON(message: PlatformCategory_ButtonClickEvent): unknown {
    const obj: any = {};
    if (message.targetName !== "") {
      obj.targetName = message.targetName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_ButtonClickEvent>, I>>(
    base?: I,
  ): PlatformCategory_ButtonClickEvent {
    return PlatformCategory_ButtonClickEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_ButtonClickEvent>, I>>(
    object: I,
  ): PlatformCategory_ButtonClickEvent {
    const message = createBasePlatformCategory_ButtonClickEvent();
    message.targetName = object.targetName ?? "";
    return message;
  },
};

function createBasePlatformCategory_ButtonViewEvent(): PlatformCategory_ButtonViewEvent {
  return { targetName: "" };
}

export const PlatformCategory_ButtonViewEvent = {
  fromJSON(object: any): PlatformCategory_ButtonViewEvent {
    return { targetName: isSet(object.targetName) ? String(object.targetName) : "" };
  },

  toJSON(message: PlatformCategory_ButtonViewEvent): unknown {
    const obj: any = {};
    if (message.targetName !== "") {
      obj.targetName = message.targetName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_ButtonViewEvent>, I>>(
    base?: I,
  ): PlatformCategory_ButtonViewEvent {
    return PlatformCategory_ButtonViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_ButtonViewEvent>, I>>(
    object: I,
  ): PlatformCategory_ButtonViewEvent {
    const message = createBasePlatformCategory_ButtonViewEvent();
    message.targetName = object.targetName ?? "";
    return message;
  },
};

function createBasePlatformCategory_CardViewEvent(): PlatformCategory_CardViewEvent {
  return { targetName: "" };
}

export const PlatformCategory_CardViewEvent = {
  fromJSON(object: any): PlatformCategory_CardViewEvent {
    return { targetName: isSet(object.targetName) ? String(object.targetName) : "" };
  },

  toJSON(message: PlatformCategory_CardViewEvent): unknown {
    const obj: any = {};
    if (message.targetName !== "") {
      obj.targetName = message.targetName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_CardViewEvent>, I>>(base?: I): PlatformCategory_CardViewEvent {
    return PlatformCategory_CardViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_CardViewEvent>, I>>(
    object: I,
  ): PlatformCategory_CardViewEvent {
    const message = createBasePlatformCategory_CardViewEvent();
    message.targetName = object.targetName ?? "";
    return message;
  },
};

function createBasePlatformCategory_CheckboxClickEvent(): PlatformCategory_CheckboxClickEvent {
  return { targetName: "", optionName: "" };
}

export const PlatformCategory_CheckboxClickEvent = {
  fromJSON(object: any): PlatformCategory_CheckboxClickEvent {
    return {
      targetName: isSet(object.targetName) ? String(object.targetName) : "",
      optionName: isSet(object.optionName) ? String(object.optionName) : "",
    };
  },

  toJSON(message: PlatformCategory_CheckboxClickEvent): unknown {
    const obj: any = {};
    if (message.targetName !== "") {
      obj.targetName = message.targetName;
    }
    if (message.optionName !== "") {
      obj.optionName = message.optionName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_CheckboxClickEvent>, I>>(
    base?: I,
  ): PlatformCategory_CheckboxClickEvent {
    return PlatformCategory_CheckboxClickEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_CheckboxClickEvent>, I>>(
    object: I,
  ): PlatformCategory_CheckboxClickEvent {
    const message = createBasePlatformCategory_CheckboxClickEvent();
    message.targetName = object.targetName ?? "";
    message.optionName = object.optionName ?? "";
    return message;
  },
};

function createBasePlatformCategory_CheckboxViewEvent(): PlatformCategory_CheckboxViewEvent {
  return { targetName: "" };
}

export const PlatformCategory_CheckboxViewEvent = {
  fromJSON(object: any): PlatformCategory_CheckboxViewEvent {
    return { targetName: isSet(object.targetName) ? String(object.targetName) : "" };
  },

  toJSON(message: PlatformCategory_CheckboxViewEvent): unknown {
    const obj: any = {};
    if (message.targetName !== "") {
      obj.targetName = message.targetName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_CheckboxViewEvent>, I>>(
    base?: I,
  ): PlatformCategory_CheckboxViewEvent {
    return PlatformCategory_CheckboxViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_CheckboxViewEvent>, I>>(
    object: I,
  ): PlatformCategory_CheckboxViewEvent {
    const message = createBasePlatformCategory_CheckboxViewEvent();
    message.targetName = object.targetName ?? "";
    return message;
  },
};

function createBasePlatformCategory_ImageViewEvent(): PlatformCategory_ImageViewEvent {
  return { targetName: "" };
}

export const PlatformCategory_ImageViewEvent = {
  fromJSON(object: any): PlatformCategory_ImageViewEvent {
    return { targetName: isSet(object.targetName) ? String(object.targetName) : "" };
  },

  toJSON(message: PlatformCategory_ImageViewEvent): unknown {
    const obj: any = {};
    if (message.targetName !== "") {
      obj.targetName = message.targetName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_ImageViewEvent>, I>>(base?: I): PlatformCategory_ImageViewEvent {
    return PlatformCategory_ImageViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_ImageViewEvent>, I>>(
    object: I,
  ): PlatformCategory_ImageViewEvent {
    const message = createBasePlatformCategory_ImageViewEvent();
    message.targetName = object.targetName ?? "";
    return message;
  },
};

function createBasePlatformCategory_InputViewEvent(): PlatformCategory_InputViewEvent {
  return { targetName: "" };
}

export const PlatformCategory_InputViewEvent = {
  fromJSON(object: any): PlatformCategory_InputViewEvent {
    return { targetName: isSet(object.targetName) ? String(object.targetName) : "" };
  },

  toJSON(message: PlatformCategory_InputViewEvent): unknown {
    const obj: any = {};
    if (message.targetName !== "") {
      obj.targetName = message.targetName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_InputViewEvent>, I>>(base?: I): PlatformCategory_InputViewEvent {
    return PlatformCategory_InputViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_InputViewEvent>, I>>(
    object: I,
  ): PlatformCategory_InputViewEvent {
    const message = createBasePlatformCategory_InputViewEvent();
    message.targetName = object.targetName ?? "";
    return message;
  },
};

function createBasePlatformCategory_LinkClickEvent(): PlatformCategory_LinkClickEvent {
  return { targetName: "" };
}

export const PlatformCategory_LinkClickEvent = {
  fromJSON(object: any): PlatformCategory_LinkClickEvent {
    return { targetName: isSet(object.targetName) ? String(object.targetName) : "" };
  },

  toJSON(message: PlatformCategory_LinkClickEvent): unknown {
    const obj: any = {};
    if (message.targetName !== "") {
      obj.targetName = message.targetName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_LinkClickEvent>, I>>(base?: I): PlatformCategory_LinkClickEvent {
    return PlatformCategory_LinkClickEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_LinkClickEvent>, I>>(
    object: I,
  ): PlatformCategory_LinkClickEvent {
    const message = createBasePlatformCategory_LinkClickEvent();
    message.targetName = object.targetName ?? "";
    return message;
  },
};

function createBasePlatformCategory_LinkViewEvent(): PlatformCategory_LinkViewEvent {
  return { targetName: "" };
}

export const PlatformCategory_LinkViewEvent = {
  fromJSON(object: any): PlatformCategory_LinkViewEvent {
    return { targetName: isSet(object.targetName) ? String(object.targetName) : "" };
  },

  toJSON(message: PlatformCategory_LinkViewEvent): unknown {
    const obj: any = {};
    if (message.targetName !== "") {
      obj.targetName = message.targetName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_LinkViewEvent>, I>>(base?: I): PlatformCategory_LinkViewEvent {
    return PlatformCategory_LinkViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_LinkViewEvent>, I>>(
    object: I,
  ): PlatformCategory_LinkViewEvent {
    const message = createBasePlatformCategory_LinkViewEvent();
    message.targetName = object.targetName ?? "";
    return message;
  },
};

function createBasePlatformCategory_RadioClickEvent(): PlatformCategory_RadioClickEvent {
  return { targetName: "", optionName: "" };
}

export const PlatformCategory_RadioClickEvent = {
  fromJSON(object: any): PlatformCategory_RadioClickEvent {
    return {
      targetName: isSet(object.targetName) ? String(object.targetName) : "",
      optionName: isSet(object.optionName) ? String(object.optionName) : "",
    };
  },

  toJSON(message: PlatformCategory_RadioClickEvent): unknown {
    const obj: any = {};
    if (message.targetName !== "") {
      obj.targetName = message.targetName;
    }
    if (message.optionName !== "") {
      obj.optionName = message.optionName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_RadioClickEvent>, I>>(
    base?: I,
  ): PlatformCategory_RadioClickEvent {
    return PlatformCategory_RadioClickEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_RadioClickEvent>, I>>(
    object: I,
  ): PlatformCategory_RadioClickEvent {
    const message = createBasePlatformCategory_RadioClickEvent();
    message.targetName = object.targetName ?? "";
    message.optionName = object.optionName ?? "";
    return message;
  },
};

function createBasePlatformCategory_RadioViewEvent(): PlatformCategory_RadioViewEvent {
  return { targetName: "" };
}

export const PlatformCategory_RadioViewEvent = {
  fromJSON(object: any): PlatformCategory_RadioViewEvent {
    return { targetName: isSet(object.targetName) ? String(object.targetName) : "" };
  },

  toJSON(message: PlatformCategory_RadioViewEvent): unknown {
    const obj: any = {};
    if (message.targetName !== "") {
      obj.targetName = message.targetName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_RadioViewEvent>, I>>(base?: I): PlatformCategory_RadioViewEvent {
    return PlatformCategory_RadioViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_RadioViewEvent>, I>>(
    object: I,
  ): PlatformCategory_RadioViewEvent {
    const message = createBasePlatformCategory_RadioViewEvent();
    message.targetName = object.targetName ?? "";
    return message;
  },
};

function createBasePlatformCategory_TextViewEvent(): PlatformCategory_TextViewEvent {
  return { targetName: "" };
}

export const PlatformCategory_TextViewEvent = {
  fromJSON(object: any): PlatformCategory_TextViewEvent {
    return { targetName: isSet(object.targetName) ? String(object.targetName) : "" };
  },

  toJSON(message: PlatformCategory_TextViewEvent): unknown {
    const obj: any = {};
    if (message.targetName !== "") {
      obj.targetName = message.targetName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_TextViewEvent>, I>>(base?: I): PlatformCategory_TextViewEvent {
    return PlatformCategory_TextViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_TextViewEvent>, I>>(
    object: I,
  ): PlatformCategory_TextViewEvent {
    const message = createBasePlatformCategory_TextViewEvent();
    message.targetName = object.targetName ?? "";
    return message;
  },
};

function createBasePlatformCategory_PageViewEvent(): PlatformCategory_PageViewEvent {
  return {};
}

export const PlatformCategory_PageViewEvent = {
  fromJSON(_: any): PlatformCategory_PageViewEvent {
    return {};
  },

  toJSON(_: PlatformCategory_PageViewEvent): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_PageViewEvent>, I>>(base?: I): PlatformCategory_PageViewEvent {
    return PlatformCategory_PageViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_PageViewEvent>, I>>(_: I): PlatformCategory_PageViewEvent {
    const message = createBasePlatformCategory_PageViewEvent();
    return message;
  },
};

function createBasePlatformCategory_ScreenViewEvent(): PlatformCategory_ScreenViewEvent {
  return {};
}

export const PlatformCategory_ScreenViewEvent = {
  fromJSON(_: any): PlatformCategory_ScreenViewEvent {
    return {};
  },

  toJSON(_: PlatformCategory_ScreenViewEvent): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_ScreenViewEvent>, I>>(
    base?: I,
  ): PlatformCategory_ScreenViewEvent {
    return PlatformCategory_ScreenViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_ScreenViewEvent>, I>>(
    _: I,
  ): PlatformCategory_ScreenViewEvent {
    const message = createBasePlatformCategory_ScreenViewEvent();
    return message;
  },
};

function createBasePlatformCategory_CustomerOneTimeExperienceEvent(): PlatformCategory_CustomerOneTimeExperienceEvent {
  return { experienceName: "" };
}

export const PlatformCategory_CustomerOneTimeExperienceEvent = {
  fromJSON(object: any): PlatformCategory_CustomerOneTimeExperienceEvent {
    return { experienceName: isSet(object.experienceName) ? String(object.experienceName) : "" };
  },

  toJSON(message: PlatformCategory_CustomerOneTimeExperienceEvent): unknown {
    const obj: any = {};
    if (message.experienceName !== "") {
      obj.experienceName = message.experienceName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_CustomerOneTimeExperienceEvent>, I>>(
    base?: I,
  ): PlatformCategory_CustomerOneTimeExperienceEvent {
    return PlatformCategory_CustomerOneTimeExperienceEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_CustomerOneTimeExperienceEvent>, I>>(
    object: I,
  ): PlatformCategory_CustomerOneTimeExperienceEvent {
    const message = createBasePlatformCategory_CustomerOneTimeExperienceEvent();
    message.experienceName = object.experienceName ?? "";
    return message;
  },
};

function createBasePlatformCategory_LeadOneTimeExperienceEvent(): PlatformCategory_LeadOneTimeExperienceEvent {
  return { experienceName: "", leadId: "" };
}

export const PlatformCategory_LeadOneTimeExperienceEvent = {
  fromJSON(object: any): PlatformCategory_LeadOneTimeExperienceEvent {
    return {
      experienceName: isSet(object.experienceName) ? String(object.experienceName) : "",
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
    };
  },

  toJSON(message: PlatformCategory_LeadOneTimeExperienceEvent): unknown {
    const obj: any = {};
    if (message.experienceName !== "") {
      obj.experienceName = message.experienceName;
    }
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_LeadOneTimeExperienceEvent>, I>>(
    base?: I,
  ): PlatformCategory_LeadOneTimeExperienceEvent {
    return PlatformCategory_LeadOneTimeExperienceEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_LeadOneTimeExperienceEvent>, I>>(
    object: I,
  ): PlatformCategory_LeadOneTimeExperienceEvent {
    const message = createBasePlatformCategory_LeadOneTimeExperienceEvent();
    message.experienceName = object.experienceName ?? "";
    message.leadId = object.leadId ?? "";
    return message;
  },
};

function createBasePlatformCategory_CustomerUnsubscribeEvent(): PlatformCategory_CustomerUnsubscribeEvent {
  return { feedName: "" };
}

export const PlatformCategory_CustomerUnsubscribeEvent = {
  fromJSON(object: any): PlatformCategory_CustomerUnsubscribeEvent {
    return { feedName: isSet(object.feedName) ? String(object.feedName) : "" };
  },

  toJSON(message: PlatformCategory_CustomerUnsubscribeEvent): unknown {
    const obj: any = {};
    if (message.feedName !== "") {
      obj.feedName = message.feedName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformCategory_CustomerUnsubscribeEvent>, I>>(
    base?: I,
  ): PlatformCategory_CustomerUnsubscribeEvent {
    return PlatformCategory_CustomerUnsubscribeEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformCategory_CustomerUnsubscribeEvent>, I>>(
    object: I,
  ): PlatformCategory_CustomerUnsubscribeEvent {
    const message = createBasePlatformCategory_CustomerUnsubscribeEvent();
    message.feedName = object.feedName ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
