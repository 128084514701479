/* eslint-disable */

export const protobufPackage = "od_protos.journey.data.categories";

export interface RetailWebUnlockCategory {
  event?:
    | { $case: "viewEvent"; viewEvent: RetailWebUnlockCategory_ViewEvent }
    | { $case: "locationPermissionEvent"; locationPermissionEvent: RetailWebUnlockCategory_LocationPermissionEvent }
    | { $case: "contactUsEvent"; contactUsEvent: RetailWebUnlockCategory_ContactUsEvent }
    | { $case: "propertyStatusEvent"; propertyStatusEvent: RetailWebUnlockCategory_PropertyStatusEvent }
    | { $case: "idVerificationEvent"; idVerificationEvent: RetailWebUnlockCategory_IDVerificationEvent }
    | {
      $case: "idVerificationSubmissionEvent";
      idVerificationSubmissionEvent: RetailWebUnlockCategory_IDVerificationSubmissionEvent;
    }
    | { $case: "unlockAttemptEvent"; unlockAttemptEvent: RetailWebUnlockCategory_UnlockAttemptEvent }
    | { $case: "generalErrorEvent"; generalErrorEvent: RetailWebUnlockCategory_GeneralErrorEvent }
    | undefined;
}

export enum RetailWebUnlockCategory_StepType {
  UNKNOWN_STEP = 0,
  PROPERTY_CONFIRMATION_STEPS = 1,
  ONBOARDING_STEPS = 2,
  ID_VERIFICATION_STEPS = 3,
  UNLOCK_HOME_STEPS = 4,
  GENERAL_ERROR = 5,
  UNRECOGNIZED = -1,
}

export function retailWebUnlockCategory_StepTypeFromJSON(object: any): RetailWebUnlockCategory_StepType {
  switch (object) {
    case 0:
    case "UNKNOWN_STEP":
      return RetailWebUnlockCategory_StepType.UNKNOWN_STEP;
    case 1:
    case "PROPERTY_CONFIRMATION_STEPS":
      return RetailWebUnlockCategory_StepType.PROPERTY_CONFIRMATION_STEPS;
    case 2:
    case "ONBOARDING_STEPS":
      return RetailWebUnlockCategory_StepType.ONBOARDING_STEPS;
    case 3:
    case "ID_VERIFICATION_STEPS":
      return RetailWebUnlockCategory_StepType.ID_VERIFICATION_STEPS;
    case 4:
    case "UNLOCK_HOME_STEPS":
      return RetailWebUnlockCategory_StepType.UNLOCK_HOME_STEPS;
    case 5:
    case "GENERAL_ERROR":
      return RetailWebUnlockCategory_StepType.GENERAL_ERROR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RetailWebUnlockCategory_StepType.UNRECOGNIZED;
  }
}

export function retailWebUnlockCategory_StepTypeToJSON(object: RetailWebUnlockCategory_StepType): string {
  switch (object) {
    case RetailWebUnlockCategory_StepType.UNKNOWN_STEP:
      return "UNKNOWN_STEP";
    case RetailWebUnlockCategory_StepType.PROPERTY_CONFIRMATION_STEPS:
      return "PROPERTY_CONFIRMATION_STEPS";
    case RetailWebUnlockCategory_StepType.ONBOARDING_STEPS:
      return "ONBOARDING_STEPS";
    case RetailWebUnlockCategory_StepType.ID_VERIFICATION_STEPS:
      return "ID_VERIFICATION_STEPS";
    case RetailWebUnlockCategory_StepType.UNLOCK_HOME_STEPS:
      return "UNLOCK_HOME_STEPS";
    case RetailWebUnlockCategory_StepType.GENERAL_ERROR:
      return "GENERAL_ERROR";
    case RetailWebUnlockCategory_StepType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum RetailWebUnlockCategory_ListingValidationFailureFlavor {
  UNKNOWN_LISTING_FAILURE = 0,
  TEMPORARILY_OFF_MARKET = 1,
  TRADITIONAL_LISTING = 2,
  AFTER_HOURS_TOURING = 3,
  HOME_UNDER_CONTRACT = 4,
  HOME_SOLD = 5,
  PPM = 6,
  UNRECOGNIZED = -1,
}

export function retailWebUnlockCategory_ListingValidationFailureFlavorFromJSON(
  object: any,
): RetailWebUnlockCategory_ListingValidationFailureFlavor {
  switch (object) {
    case 0:
    case "UNKNOWN_LISTING_FAILURE":
      return RetailWebUnlockCategory_ListingValidationFailureFlavor.UNKNOWN_LISTING_FAILURE;
    case 1:
    case "TEMPORARILY_OFF_MARKET":
      return RetailWebUnlockCategory_ListingValidationFailureFlavor.TEMPORARILY_OFF_MARKET;
    case 2:
    case "TRADITIONAL_LISTING":
      return RetailWebUnlockCategory_ListingValidationFailureFlavor.TRADITIONAL_LISTING;
    case 3:
    case "AFTER_HOURS_TOURING":
      return RetailWebUnlockCategory_ListingValidationFailureFlavor.AFTER_HOURS_TOURING;
    case 4:
    case "HOME_UNDER_CONTRACT":
      return RetailWebUnlockCategory_ListingValidationFailureFlavor.HOME_UNDER_CONTRACT;
    case 5:
    case "HOME_SOLD":
      return RetailWebUnlockCategory_ListingValidationFailureFlavor.HOME_SOLD;
    case 6:
    case "PPM":
      return RetailWebUnlockCategory_ListingValidationFailureFlavor.PPM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RetailWebUnlockCategory_ListingValidationFailureFlavor.UNRECOGNIZED;
  }
}

export function retailWebUnlockCategory_ListingValidationFailureFlavorToJSON(
  object: RetailWebUnlockCategory_ListingValidationFailureFlavor,
): string {
  switch (object) {
    case RetailWebUnlockCategory_ListingValidationFailureFlavor.UNKNOWN_LISTING_FAILURE:
      return "UNKNOWN_LISTING_FAILURE";
    case RetailWebUnlockCategory_ListingValidationFailureFlavor.TEMPORARILY_OFF_MARKET:
      return "TEMPORARILY_OFF_MARKET";
    case RetailWebUnlockCategory_ListingValidationFailureFlavor.TRADITIONAL_LISTING:
      return "TRADITIONAL_LISTING";
    case RetailWebUnlockCategory_ListingValidationFailureFlavor.AFTER_HOURS_TOURING:
      return "AFTER_HOURS_TOURING";
    case RetailWebUnlockCategory_ListingValidationFailureFlavor.HOME_UNDER_CONTRACT:
      return "HOME_UNDER_CONTRACT";
    case RetailWebUnlockCategory_ListingValidationFailureFlavor.HOME_SOLD:
      return "HOME_SOLD";
    case RetailWebUnlockCategory_ListingValidationFailureFlavor.PPM:
      return "PPM";
    case RetailWebUnlockCategory_ListingValidationFailureFlavor.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum RetailWebUnlockCategory_IDVerificationActions {
  UNKNOWN_IDV_ACTION = 0,
  ALLOW_CAMERA = 1,
  DISALLOW_CAMERA = 2,
  SCAN_FRONT = 3,
  SCAN_BACK = 4,
  UNRECOGNIZED = -1,
}

export function retailWebUnlockCategory_IDVerificationActionsFromJSON(
  object: any,
): RetailWebUnlockCategory_IDVerificationActions {
  switch (object) {
    case 0:
    case "UNKNOWN_IDV_ACTION":
      return RetailWebUnlockCategory_IDVerificationActions.UNKNOWN_IDV_ACTION;
    case 1:
    case "ALLOW_CAMERA":
      return RetailWebUnlockCategory_IDVerificationActions.ALLOW_CAMERA;
    case 2:
    case "DISALLOW_CAMERA":
      return RetailWebUnlockCategory_IDVerificationActions.DISALLOW_CAMERA;
    case 3:
    case "SCAN_FRONT":
      return RetailWebUnlockCategory_IDVerificationActions.SCAN_FRONT;
    case 4:
    case "SCAN_BACK":
      return RetailWebUnlockCategory_IDVerificationActions.SCAN_BACK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RetailWebUnlockCategory_IDVerificationActions.UNRECOGNIZED;
  }
}

export function retailWebUnlockCategory_IDVerificationActionsToJSON(
  object: RetailWebUnlockCategory_IDVerificationActions,
): string {
  switch (object) {
    case RetailWebUnlockCategory_IDVerificationActions.UNKNOWN_IDV_ACTION:
      return "UNKNOWN_IDV_ACTION";
    case RetailWebUnlockCategory_IDVerificationActions.ALLOW_CAMERA:
      return "ALLOW_CAMERA";
    case RetailWebUnlockCategory_IDVerificationActions.DISALLOW_CAMERA:
      return "DISALLOW_CAMERA";
    case RetailWebUnlockCategory_IDVerificationActions.SCAN_FRONT:
      return "SCAN_FRONT";
    case RetailWebUnlockCategory_IDVerificationActions.SCAN_BACK:
      return "SCAN_BACK";
    case RetailWebUnlockCategory_IDVerificationActions.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum RetailWebUnlockCategory_IDVerificationFailureFlavor {
  UNKNOWN_IDV_FAILURE = 0,
  OD_SERVERSIDE_ERROR = 1,
  EXPIRED_ID = 2,
  MITEK_ERROR = 3,
  UNDERAGE_ID = 4,
  UNRECOGNIZED = -1,
}

export function retailWebUnlockCategory_IDVerificationFailureFlavorFromJSON(
  object: any,
): RetailWebUnlockCategory_IDVerificationFailureFlavor {
  switch (object) {
    case 0:
    case "UNKNOWN_IDV_FAILURE":
      return RetailWebUnlockCategory_IDVerificationFailureFlavor.UNKNOWN_IDV_FAILURE;
    case 1:
    case "OD_SERVERSIDE_ERROR":
      return RetailWebUnlockCategory_IDVerificationFailureFlavor.OD_SERVERSIDE_ERROR;
    case 2:
    case "EXPIRED_ID":
      return RetailWebUnlockCategory_IDVerificationFailureFlavor.EXPIRED_ID;
    case 3:
    case "MITEK_ERROR":
      return RetailWebUnlockCategory_IDVerificationFailureFlavor.MITEK_ERROR;
    case 4:
    case "UNDERAGE_ID":
      return RetailWebUnlockCategory_IDVerificationFailureFlavor.UNDERAGE_ID;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RetailWebUnlockCategory_IDVerificationFailureFlavor.UNRECOGNIZED;
  }
}

export function retailWebUnlockCategory_IDVerificationFailureFlavorToJSON(
  object: RetailWebUnlockCategory_IDVerificationFailureFlavor,
): string {
  switch (object) {
    case RetailWebUnlockCategory_IDVerificationFailureFlavor.UNKNOWN_IDV_FAILURE:
      return "UNKNOWN_IDV_FAILURE";
    case RetailWebUnlockCategory_IDVerificationFailureFlavor.OD_SERVERSIDE_ERROR:
      return "OD_SERVERSIDE_ERROR";
    case RetailWebUnlockCategory_IDVerificationFailureFlavor.EXPIRED_ID:
      return "EXPIRED_ID";
    case RetailWebUnlockCategory_IDVerificationFailureFlavor.MITEK_ERROR:
      return "MITEK_ERROR";
    case RetailWebUnlockCategory_IDVerificationFailureFlavor.UNDERAGE_ID:
      return "UNDERAGE_ID";
    case RetailWebUnlockCategory_IDVerificationFailureFlavor.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum RetailWebUnlockCategory_UnlockFailureFlavor {
  UNKNOWN_UNLOCK_FAILURE = 0,
  CODE_UNLOCK = 1,
  FAILED_UNLOCK = 2,
  NEED_MORE_INFO = 3,
  UNRECOGNIZED = -1,
}

export function retailWebUnlockCategory_UnlockFailureFlavorFromJSON(
  object: any,
): RetailWebUnlockCategory_UnlockFailureFlavor {
  switch (object) {
    case 0:
    case "UNKNOWN_UNLOCK_FAILURE":
      return RetailWebUnlockCategory_UnlockFailureFlavor.UNKNOWN_UNLOCK_FAILURE;
    case 1:
    case "CODE_UNLOCK":
      return RetailWebUnlockCategory_UnlockFailureFlavor.CODE_UNLOCK;
    case 2:
    case "FAILED_UNLOCK":
      return RetailWebUnlockCategory_UnlockFailureFlavor.FAILED_UNLOCK;
    case 3:
    case "NEED_MORE_INFO":
      return RetailWebUnlockCategory_UnlockFailureFlavor.NEED_MORE_INFO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RetailWebUnlockCategory_UnlockFailureFlavor.UNRECOGNIZED;
  }
}

export function retailWebUnlockCategory_UnlockFailureFlavorToJSON(
  object: RetailWebUnlockCategory_UnlockFailureFlavor,
): string {
  switch (object) {
    case RetailWebUnlockCategory_UnlockFailureFlavor.UNKNOWN_UNLOCK_FAILURE:
      return "UNKNOWN_UNLOCK_FAILURE";
    case RetailWebUnlockCategory_UnlockFailureFlavor.CODE_UNLOCK:
      return "CODE_UNLOCK";
    case RetailWebUnlockCategory_UnlockFailureFlavor.FAILED_UNLOCK:
      return "FAILED_UNLOCK";
    case RetailWebUnlockCategory_UnlockFailureFlavor.NEED_MORE_INFO:
      return "NEED_MORE_INFO";
    case RetailWebUnlockCategory_UnlockFailureFlavor.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface RetailWebUnlockCategory_ViewEvent {
  /** listing_id is not set in start of the flow, so we allow it to be null */
  listingId?:
    | string
    | undefined;
  /** address_id is not set in start of the flow, so we allow it to be null */
  addressUuid?: string | undefined;
  stepType: RetailWebUnlockCategory_StepType;
  step: string;
}

export interface RetailWebUnlockCategory_LocationPermissionEvent {
  locationPermission: boolean;
}

export interface RetailWebUnlockCategory_ContactUsEvent {
  target: string;
}

export interface RetailWebUnlockCategory_PropertyStatusEvent {
  listingId: string;
  addressUuid: string;
  tourUnavailableReason: RetailWebUnlockCategory_ListingValidationFailureFlavor;
}

export interface RetailWebUnlockCategory_IDVerificationEvent {
  action: RetailWebUnlockCategory_IDVerificationActions;
}

export interface RetailWebUnlockCategory_IDVerificationSubmissionEvent {
  success: boolean;
  failureReason: RetailWebUnlockCategory_IDVerificationFailureFlavor;
}

export interface RetailWebUnlockCategory_UnlockAttemptEvent {
  listingId: string;
  addressUuid: string;
  success: boolean;
  failureReason: RetailWebUnlockCategory_UnlockFailureFlavor;
}

export interface RetailWebUnlockCategory_GeneralErrorEvent {
  listingId?: string | undefined;
  addressUuid?: string | undefined;
  error: string;
}

function createBaseRetailWebUnlockCategory(): RetailWebUnlockCategory {
  return { event: undefined };
}

export const RetailWebUnlockCategory = {
  fromJSON(object: any): RetailWebUnlockCategory {
    return {
      event: isSet(object.viewEvent)
        ? { $case: "viewEvent", viewEvent: RetailWebUnlockCategory_ViewEvent.fromJSON(object.viewEvent) }
        : isSet(object.locationPermissionEvent)
        ? {
          $case: "locationPermissionEvent",
          locationPermissionEvent: RetailWebUnlockCategory_LocationPermissionEvent.fromJSON(
            object.locationPermissionEvent,
          ),
        }
        : isSet(object.contactUsEvent)
        ? {
          $case: "contactUsEvent",
          contactUsEvent: RetailWebUnlockCategory_ContactUsEvent.fromJSON(object.contactUsEvent),
        }
        : isSet(object.propertyStatusEvent)
        ? {
          $case: "propertyStatusEvent",
          propertyStatusEvent: RetailWebUnlockCategory_PropertyStatusEvent.fromJSON(object.propertyStatusEvent),
        }
        : isSet(object.idVerificationEvent)
        ? {
          $case: "idVerificationEvent",
          idVerificationEvent: RetailWebUnlockCategory_IDVerificationEvent.fromJSON(object.idVerificationEvent),
        }
        : isSet(object.idVerificationSubmissionEvent)
        ? {
          $case: "idVerificationSubmissionEvent",
          idVerificationSubmissionEvent: RetailWebUnlockCategory_IDVerificationSubmissionEvent.fromJSON(
            object.idVerificationSubmissionEvent,
          ),
        }
        : isSet(object.unlockAttemptEvent)
        ? {
          $case: "unlockAttemptEvent",
          unlockAttemptEvent: RetailWebUnlockCategory_UnlockAttemptEvent.fromJSON(object.unlockAttemptEvent),
        }
        : isSet(object.generalErrorEvent)
        ? {
          $case: "generalErrorEvent",
          generalErrorEvent: RetailWebUnlockCategory_GeneralErrorEvent.fromJSON(object.generalErrorEvent),
        }
        : undefined,
    };
  },

  toJSON(message: RetailWebUnlockCategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "viewEvent") {
      obj.viewEvent = RetailWebUnlockCategory_ViewEvent.toJSON(message.event.viewEvent);
    }
    if (message.event?.$case === "locationPermissionEvent") {
      obj.locationPermissionEvent = RetailWebUnlockCategory_LocationPermissionEvent.toJSON(
        message.event.locationPermissionEvent,
      );
    }
    if (message.event?.$case === "contactUsEvent") {
      obj.contactUsEvent = RetailWebUnlockCategory_ContactUsEvent.toJSON(message.event.contactUsEvent);
    }
    if (message.event?.$case === "propertyStatusEvent") {
      obj.propertyStatusEvent = RetailWebUnlockCategory_PropertyStatusEvent.toJSON(message.event.propertyStatusEvent);
    }
    if (message.event?.$case === "idVerificationEvent") {
      obj.idVerificationEvent = RetailWebUnlockCategory_IDVerificationEvent.toJSON(message.event.idVerificationEvent);
    }
    if (message.event?.$case === "idVerificationSubmissionEvent") {
      obj.idVerificationSubmissionEvent = RetailWebUnlockCategory_IDVerificationSubmissionEvent.toJSON(
        message.event.idVerificationSubmissionEvent,
      );
    }
    if (message.event?.$case === "unlockAttemptEvent") {
      obj.unlockAttemptEvent = RetailWebUnlockCategory_UnlockAttemptEvent.toJSON(message.event.unlockAttemptEvent);
    }
    if (message.event?.$case === "generalErrorEvent") {
      obj.generalErrorEvent = RetailWebUnlockCategory_GeneralErrorEvent.toJSON(message.event.generalErrorEvent);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RetailWebUnlockCategory>, I>>(base?: I): RetailWebUnlockCategory {
    return RetailWebUnlockCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RetailWebUnlockCategory>, I>>(object: I): RetailWebUnlockCategory {
    const message = createBaseRetailWebUnlockCategory();
    if (
      object.event?.$case === "viewEvent" && object.event?.viewEvent !== undefined && object.event?.viewEvent !== null
    ) {
      message.event = {
        $case: "viewEvent",
        viewEvent: RetailWebUnlockCategory_ViewEvent.fromPartial(object.event.viewEvent),
      };
    }
    if (
      object.event?.$case === "locationPermissionEvent" &&
      object.event?.locationPermissionEvent !== undefined &&
      object.event?.locationPermissionEvent !== null
    ) {
      message.event = {
        $case: "locationPermissionEvent",
        locationPermissionEvent: RetailWebUnlockCategory_LocationPermissionEvent.fromPartial(
          object.event.locationPermissionEvent,
        ),
      };
    }
    if (
      object.event?.$case === "contactUsEvent" &&
      object.event?.contactUsEvent !== undefined &&
      object.event?.contactUsEvent !== null
    ) {
      message.event = {
        $case: "contactUsEvent",
        contactUsEvent: RetailWebUnlockCategory_ContactUsEvent.fromPartial(object.event.contactUsEvent),
      };
    }
    if (
      object.event?.$case === "propertyStatusEvent" &&
      object.event?.propertyStatusEvent !== undefined &&
      object.event?.propertyStatusEvent !== null
    ) {
      message.event = {
        $case: "propertyStatusEvent",
        propertyStatusEvent: RetailWebUnlockCategory_PropertyStatusEvent.fromPartial(object.event.propertyStatusEvent),
      };
    }
    if (
      object.event?.$case === "idVerificationEvent" &&
      object.event?.idVerificationEvent !== undefined &&
      object.event?.idVerificationEvent !== null
    ) {
      message.event = {
        $case: "idVerificationEvent",
        idVerificationEvent: RetailWebUnlockCategory_IDVerificationEvent.fromPartial(object.event.idVerificationEvent),
      };
    }
    if (
      object.event?.$case === "idVerificationSubmissionEvent" &&
      object.event?.idVerificationSubmissionEvent !== undefined &&
      object.event?.idVerificationSubmissionEvent !== null
    ) {
      message.event = {
        $case: "idVerificationSubmissionEvent",
        idVerificationSubmissionEvent: RetailWebUnlockCategory_IDVerificationSubmissionEvent.fromPartial(
          object.event.idVerificationSubmissionEvent,
        ),
      };
    }
    if (
      object.event?.$case === "unlockAttemptEvent" &&
      object.event?.unlockAttemptEvent !== undefined &&
      object.event?.unlockAttemptEvent !== null
    ) {
      message.event = {
        $case: "unlockAttemptEvent",
        unlockAttemptEvent: RetailWebUnlockCategory_UnlockAttemptEvent.fromPartial(object.event.unlockAttemptEvent),
      };
    }
    if (
      object.event?.$case === "generalErrorEvent" &&
      object.event?.generalErrorEvent !== undefined &&
      object.event?.generalErrorEvent !== null
    ) {
      message.event = {
        $case: "generalErrorEvent",
        generalErrorEvent: RetailWebUnlockCategory_GeneralErrorEvent.fromPartial(object.event.generalErrorEvent),
      };
    }
    return message;
  },
};

function createBaseRetailWebUnlockCategory_ViewEvent(): RetailWebUnlockCategory_ViewEvent {
  return { listingId: undefined, addressUuid: undefined, stepType: 0, step: "" };
}

export const RetailWebUnlockCategory_ViewEvent = {
  fromJSON(object: any): RetailWebUnlockCategory_ViewEvent {
    return {
      listingId: isSet(object.listingId) ? String(object.listingId) : undefined,
      addressUuid: isSet(object.addressUuid) ? String(object.addressUuid) : undefined,
      stepType: isSet(object.stepType) ? retailWebUnlockCategory_StepTypeFromJSON(object.stepType) : 0,
      step: isSet(object.step) ? String(object.step) : "",
    };
  },

  toJSON(message: RetailWebUnlockCategory_ViewEvent): unknown {
    const obj: any = {};
    if (message.listingId !== undefined) {
      obj.listingId = message.listingId;
    }
    if (message.addressUuid !== undefined) {
      obj.addressUuid = message.addressUuid;
    }
    if (message.stepType !== 0) {
      obj.stepType = retailWebUnlockCategory_StepTypeToJSON(message.stepType);
    }
    if (message.step !== "") {
      obj.step = message.step;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RetailWebUnlockCategory_ViewEvent>, I>>(
    base?: I,
  ): RetailWebUnlockCategory_ViewEvent {
    return RetailWebUnlockCategory_ViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RetailWebUnlockCategory_ViewEvent>, I>>(
    object: I,
  ): RetailWebUnlockCategory_ViewEvent {
    const message = createBaseRetailWebUnlockCategory_ViewEvent();
    message.listingId = object.listingId ?? undefined;
    message.addressUuid = object.addressUuid ?? undefined;
    message.stepType = object.stepType ?? 0;
    message.step = object.step ?? "";
    return message;
  },
};

function createBaseRetailWebUnlockCategory_LocationPermissionEvent(): RetailWebUnlockCategory_LocationPermissionEvent {
  return { locationPermission: false };
}

export const RetailWebUnlockCategory_LocationPermissionEvent = {
  fromJSON(object: any): RetailWebUnlockCategory_LocationPermissionEvent {
    return { locationPermission: isSet(object.locationPermission) ? Boolean(object.locationPermission) : false };
  },

  toJSON(message: RetailWebUnlockCategory_LocationPermissionEvent): unknown {
    const obj: any = {};
    if (message.locationPermission === true) {
      obj.locationPermission = message.locationPermission;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RetailWebUnlockCategory_LocationPermissionEvent>, I>>(
    base?: I,
  ): RetailWebUnlockCategory_LocationPermissionEvent {
    return RetailWebUnlockCategory_LocationPermissionEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RetailWebUnlockCategory_LocationPermissionEvent>, I>>(
    object: I,
  ): RetailWebUnlockCategory_LocationPermissionEvent {
    const message = createBaseRetailWebUnlockCategory_LocationPermissionEvent();
    message.locationPermission = object.locationPermission ?? false;
    return message;
  },
};

function createBaseRetailWebUnlockCategory_ContactUsEvent(): RetailWebUnlockCategory_ContactUsEvent {
  return { target: "" };
}

export const RetailWebUnlockCategory_ContactUsEvent = {
  fromJSON(object: any): RetailWebUnlockCategory_ContactUsEvent {
    return { target: isSet(object.target) ? String(object.target) : "" };
  },

  toJSON(message: RetailWebUnlockCategory_ContactUsEvent): unknown {
    const obj: any = {};
    if (message.target !== "") {
      obj.target = message.target;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RetailWebUnlockCategory_ContactUsEvent>, I>>(
    base?: I,
  ): RetailWebUnlockCategory_ContactUsEvent {
    return RetailWebUnlockCategory_ContactUsEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RetailWebUnlockCategory_ContactUsEvent>, I>>(
    object: I,
  ): RetailWebUnlockCategory_ContactUsEvent {
    const message = createBaseRetailWebUnlockCategory_ContactUsEvent();
    message.target = object.target ?? "";
    return message;
  },
};

function createBaseRetailWebUnlockCategory_PropertyStatusEvent(): RetailWebUnlockCategory_PropertyStatusEvent {
  return { listingId: "", addressUuid: "", tourUnavailableReason: 0 };
}

export const RetailWebUnlockCategory_PropertyStatusEvent = {
  fromJSON(object: any): RetailWebUnlockCategory_PropertyStatusEvent {
    return {
      listingId: isSet(object.listingId) ? String(object.listingId) : "",
      addressUuid: isSet(object.addressUuid) ? String(object.addressUuid) : "",
      tourUnavailableReason: isSet(object.tourUnavailableReason)
        ? retailWebUnlockCategory_ListingValidationFailureFlavorFromJSON(object.tourUnavailableReason)
        : 0,
    };
  },

  toJSON(message: RetailWebUnlockCategory_PropertyStatusEvent): unknown {
    const obj: any = {};
    if (message.listingId !== "") {
      obj.listingId = message.listingId;
    }
    if (message.addressUuid !== "") {
      obj.addressUuid = message.addressUuid;
    }
    if (message.tourUnavailableReason !== 0) {
      obj.tourUnavailableReason = retailWebUnlockCategory_ListingValidationFailureFlavorToJSON(
        message.tourUnavailableReason,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RetailWebUnlockCategory_PropertyStatusEvent>, I>>(
    base?: I,
  ): RetailWebUnlockCategory_PropertyStatusEvent {
    return RetailWebUnlockCategory_PropertyStatusEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RetailWebUnlockCategory_PropertyStatusEvent>, I>>(
    object: I,
  ): RetailWebUnlockCategory_PropertyStatusEvent {
    const message = createBaseRetailWebUnlockCategory_PropertyStatusEvent();
    message.listingId = object.listingId ?? "";
    message.addressUuid = object.addressUuid ?? "";
    message.tourUnavailableReason = object.tourUnavailableReason ?? 0;
    return message;
  },
};

function createBaseRetailWebUnlockCategory_IDVerificationEvent(): RetailWebUnlockCategory_IDVerificationEvent {
  return { action: 0 };
}

export const RetailWebUnlockCategory_IDVerificationEvent = {
  fromJSON(object: any): RetailWebUnlockCategory_IDVerificationEvent {
    return { action: isSet(object.action) ? retailWebUnlockCategory_IDVerificationActionsFromJSON(object.action) : 0 };
  },

  toJSON(message: RetailWebUnlockCategory_IDVerificationEvent): unknown {
    const obj: any = {};
    if (message.action !== 0) {
      obj.action = retailWebUnlockCategory_IDVerificationActionsToJSON(message.action);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RetailWebUnlockCategory_IDVerificationEvent>, I>>(
    base?: I,
  ): RetailWebUnlockCategory_IDVerificationEvent {
    return RetailWebUnlockCategory_IDVerificationEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RetailWebUnlockCategory_IDVerificationEvent>, I>>(
    object: I,
  ): RetailWebUnlockCategory_IDVerificationEvent {
    const message = createBaseRetailWebUnlockCategory_IDVerificationEvent();
    message.action = object.action ?? 0;
    return message;
  },
};

function createBaseRetailWebUnlockCategory_IDVerificationSubmissionEvent(): RetailWebUnlockCategory_IDVerificationSubmissionEvent {
  return { success: false, failureReason: 0 };
}

export const RetailWebUnlockCategory_IDVerificationSubmissionEvent = {
  fromJSON(object: any): RetailWebUnlockCategory_IDVerificationSubmissionEvent {
    return {
      success: isSet(object.success) ? Boolean(object.success) : false,
      failureReason: isSet(object.failureReason)
        ? retailWebUnlockCategory_IDVerificationFailureFlavorFromJSON(object.failureReason)
        : 0,
    };
  },

  toJSON(message: RetailWebUnlockCategory_IDVerificationSubmissionEvent): unknown {
    const obj: any = {};
    if (message.success === true) {
      obj.success = message.success;
    }
    if (message.failureReason !== 0) {
      obj.failureReason = retailWebUnlockCategory_IDVerificationFailureFlavorToJSON(message.failureReason);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RetailWebUnlockCategory_IDVerificationSubmissionEvent>, I>>(
    base?: I,
  ): RetailWebUnlockCategory_IDVerificationSubmissionEvent {
    return RetailWebUnlockCategory_IDVerificationSubmissionEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RetailWebUnlockCategory_IDVerificationSubmissionEvent>, I>>(
    object: I,
  ): RetailWebUnlockCategory_IDVerificationSubmissionEvent {
    const message = createBaseRetailWebUnlockCategory_IDVerificationSubmissionEvent();
    message.success = object.success ?? false;
    message.failureReason = object.failureReason ?? 0;
    return message;
  },
};

function createBaseRetailWebUnlockCategory_UnlockAttemptEvent(): RetailWebUnlockCategory_UnlockAttemptEvent {
  return { listingId: "", addressUuid: "", success: false, failureReason: 0 };
}

export const RetailWebUnlockCategory_UnlockAttemptEvent = {
  fromJSON(object: any): RetailWebUnlockCategory_UnlockAttemptEvent {
    return {
      listingId: isSet(object.listingId) ? String(object.listingId) : "",
      addressUuid: isSet(object.addressUuid) ? String(object.addressUuid) : "",
      success: isSet(object.success) ? Boolean(object.success) : false,
      failureReason: isSet(object.failureReason)
        ? retailWebUnlockCategory_UnlockFailureFlavorFromJSON(object.failureReason)
        : 0,
    };
  },

  toJSON(message: RetailWebUnlockCategory_UnlockAttemptEvent): unknown {
    const obj: any = {};
    if (message.listingId !== "") {
      obj.listingId = message.listingId;
    }
    if (message.addressUuid !== "") {
      obj.addressUuid = message.addressUuid;
    }
    if (message.success === true) {
      obj.success = message.success;
    }
    if (message.failureReason !== 0) {
      obj.failureReason = retailWebUnlockCategory_UnlockFailureFlavorToJSON(message.failureReason);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RetailWebUnlockCategory_UnlockAttemptEvent>, I>>(
    base?: I,
  ): RetailWebUnlockCategory_UnlockAttemptEvent {
    return RetailWebUnlockCategory_UnlockAttemptEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RetailWebUnlockCategory_UnlockAttemptEvent>, I>>(
    object: I,
  ): RetailWebUnlockCategory_UnlockAttemptEvent {
    const message = createBaseRetailWebUnlockCategory_UnlockAttemptEvent();
    message.listingId = object.listingId ?? "";
    message.addressUuid = object.addressUuid ?? "";
    message.success = object.success ?? false;
    message.failureReason = object.failureReason ?? 0;
    return message;
  },
};

function createBaseRetailWebUnlockCategory_GeneralErrorEvent(): RetailWebUnlockCategory_GeneralErrorEvent {
  return { listingId: undefined, addressUuid: undefined, error: "" };
}

export const RetailWebUnlockCategory_GeneralErrorEvent = {
  fromJSON(object: any): RetailWebUnlockCategory_GeneralErrorEvent {
    return {
      listingId: isSet(object.listingId) ? String(object.listingId) : undefined,
      addressUuid: isSet(object.addressUuid) ? String(object.addressUuid) : undefined,
      error: isSet(object.error) ? String(object.error) : "",
    };
  },

  toJSON(message: RetailWebUnlockCategory_GeneralErrorEvent): unknown {
    const obj: any = {};
    if (message.listingId !== undefined) {
      obj.listingId = message.listingId;
    }
    if (message.addressUuid !== undefined) {
      obj.addressUuid = message.addressUuid;
    }
    if (message.error !== "") {
      obj.error = message.error;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RetailWebUnlockCategory_GeneralErrorEvent>, I>>(
    base?: I,
  ): RetailWebUnlockCategory_GeneralErrorEvent {
    return RetailWebUnlockCategory_GeneralErrorEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RetailWebUnlockCategory_GeneralErrorEvent>, I>>(
    object: I,
  ): RetailWebUnlockCategory_GeneralErrorEvent {
    const message = createBaseRetailWebUnlockCategory_GeneralErrorEvent();
    message.listingId = object.listingId ?? undefined;
    message.addressUuid = object.addressUuid ?? undefined;
    message.error = object.error ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
