/* eslint-disable */

export const protobufPackage = "od_protos.mortgage.data";

/** Next ID: 3 */
export interface CreditRange {
  min: number;
  max: number;
}

function createBaseCreditRange(): CreditRange {
  return { min: 0, max: 0 };
}

export const CreditRange = {
  fromJSON(object: any): CreditRange {
    return { min: isSet(object.min) ? Number(object.min) : 0, max: isSet(object.max) ? Number(object.max) : 0 };
  },

  toJSON(message: CreditRange): unknown {
    const obj: any = {};
    if (message.min !== 0) {
      obj.min = Math.round(message.min);
    }
    if (message.max !== 0) {
      obj.max = Math.round(message.max);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreditRange>, I>>(base?: I): CreditRange {
    return CreditRange.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreditRange>, I>>(object: I): CreditRange {
    const message = createBaseCreditRange();
    message.min = object.min ?? 0;
    message.max = object.max ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
