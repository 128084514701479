/* eslint-disable */
import { Money } from "../../../common/money";
import {
  LwcProductView_Intent,
  lwcProductView_IntentFromJSON,
  lwcProductView_IntentToJSON,
} from "../../../listings/data/list_with_certainty_product";

export const protobufPackage = "od_protos.journey.data.categories";

export interface SellListCategory {
  event?:
    | { $case: "offerView"; offerView: SellListCategory_OfferViewEvent }
    | { $case: "qualification"; qualification: SellListCategory_QualificationEvent }
    | { $case: "intentUpdate"; intentUpdate: SellListCategory_IntentUpdateEvent }
    | undefined;
}

/** Triggered when an LwC-enrolled lead views the offer dashboard */
export interface SellListCategory_OfferViewEvent {
  sellDirectLeadToken: string;
  /** This is populated with a UUID, not a numeric ID as the name suggests */
  sellDirectOfferId: string;
  listingRangeMin?: Money | undefined;
  listingRangeMax?: Money | undefined;
  recommendedProduct?: string | undefined;
  offerUuid: string;
  isFirstOffer: boolean;
}

/** Triggered when a Sell Direct lead's LwC qualification status changes */
export interface SellListCategory_QualificationEvent {
  lwcProductId: string;
  sellDirectLeadToken: string;
  qualificationDecision: SellListCategory_QualificationEvent_QualificationDecision;
}

export enum SellListCategory_QualificationEvent_QualificationDecision {
  UNKNOWN = 0,
  PRODUCT_QUALIFIED = 1,
  PRODUCT_DENIED = 2,
  PRODUCT_UNDENIED = 3,
  UNRECOGNIZED = -1,
}

export function sellListCategory_QualificationEvent_QualificationDecisionFromJSON(
  object: any,
): SellListCategory_QualificationEvent_QualificationDecision {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return SellListCategory_QualificationEvent_QualificationDecision.UNKNOWN;
    case 1:
    case "PRODUCT_QUALIFIED":
      return SellListCategory_QualificationEvent_QualificationDecision.PRODUCT_QUALIFIED;
    case 2:
    case "PRODUCT_DENIED":
      return SellListCategory_QualificationEvent_QualificationDecision.PRODUCT_DENIED;
    case 3:
    case "PRODUCT_UNDENIED":
      return SellListCategory_QualificationEvent_QualificationDecision.PRODUCT_UNDENIED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellListCategory_QualificationEvent_QualificationDecision.UNRECOGNIZED;
  }
}

export function sellListCategory_QualificationEvent_QualificationDecisionToJSON(
  object: SellListCategory_QualificationEvent_QualificationDecision,
): string {
  switch (object) {
    case SellListCategory_QualificationEvent_QualificationDecision.UNKNOWN:
      return "UNKNOWN";
    case SellListCategory_QualificationEvent_QualificationDecision.PRODUCT_QUALIFIED:
      return "PRODUCT_QUALIFIED";
    case SellListCategory_QualificationEvent_QualificationDecision.PRODUCT_DENIED:
      return "PRODUCT_DENIED";
    case SellListCategory_QualificationEvent_QualificationDecision.PRODUCT_UNDENIED:
      return "PRODUCT_UNDENIED";
    case SellListCategory_QualificationEvent_QualificationDecision.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Triggered when an LwcProduct intent is updated */
export interface SellListCategory_IntentUpdateEvent {
  lwcProductId: string;
  sellDirectLeadToken: string;
  oldIntent: LwcProductView_Intent;
  newIntent: LwcProductView_Intent;
}

function createBaseSellListCategory(): SellListCategory {
  return { event: undefined };
}

export const SellListCategory = {
  fromJSON(object: any): SellListCategory {
    return {
      event: isSet(object.offerView)
        ? { $case: "offerView", offerView: SellListCategory_OfferViewEvent.fromJSON(object.offerView) }
        : isSet(object.qualification)
        ? { $case: "qualification", qualification: SellListCategory_QualificationEvent.fromJSON(object.qualification) }
        : isSet(object.intentUpdate)
        ? { $case: "intentUpdate", intentUpdate: SellListCategory_IntentUpdateEvent.fromJSON(object.intentUpdate) }
        : undefined,
    };
  },

  toJSON(message: SellListCategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "offerView") {
      obj.offerView = SellListCategory_OfferViewEvent.toJSON(message.event.offerView);
    }
    if (message.event?.$case === "qualification") {
      obj.qualification = SellListCategory_QualificationEvent.toJSON(message.event.qualification);
    }
    if (message.event?.$case === "intentUpdate") {
      obj.intentUpdate = SellListCategory_IntentUpdateEvent.toJSON(message.event.intentUpdate);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellListCategory>, I>>(base?: I): SellListCategory {
    return SellListCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellListCategory>, I>>(object: I): SellListCategory {
    const message = createBaseSellListCategory();
    if (
      object.event?.$case === "offerView" && object.event?.offerView !== undefined && object.event?.offerView !== null
    ) {
      message.event = {
        $case: "offerView",
        offerView: SellListCategory_OfferViewEvent.fromPartial(object.event.offerView),
      };
    }
    if (
      object.event?.$case === "qualification" &&
      object.event?.qualification !== undefined &&
      object.event?.qualification !== null
    ) {
      message.event = {
        $case: "qualification",
        qualification: SellListCategory_QualificationEvent.fromPartial(object.event.qualification),
      };
    }
    if (
      object.event?.$case === "intentUpdate" &&
      object.event?.intentUpdate !== undefined &&
      object.event?.intentUpdate !== null
    ) {
      message.event = {
        $case: "intentUpdate",
        intentUpdate: SellListCategory_IntentUpdateEvent.fromPartial(object.event.intentUpdate),
      };
    }
    return message;
  },
};

function createBaseSellListCategory_OfferViewEvent(): SellListCategory_OfferViewEvent {
  return {
    sellDirectLeadToken: "",
    sellDirectOfferId: "",
    listingRangeMin: undefined,
    listingRangeMax: undefined,
    recommendedProduct: undefined,
    offerUuid: "",
    isFirstOffer: false,
  };
}

export const SellListCategory_OfferViewEvent = {
  fromJSON(object: any): SellListCategory_OfferViewEvent {
    return {
      sellDirectLeadToken: isSet(object.sellDirectLeadToken) ? String(object.sellDirectLeadToken) : "",
      sellDirectOfferId: isSet(object.sellDirectOfferId) ? String(object.sellDirectOfferId) : "",
      listingRangeMin: isSet(object.listingRangeMin) ? Money.fromJSON(object.listingRangeMin) : undefined,
      listingRangeMax: isSet(object.listingRangeMax) ? Money.fromJSON(object.listingRangeMax) : undefined,
      recommendedProduct: isSet(object.recommendedProduct) ? String(object.recommendedProduct) : undefined,
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
      isFirstOffer: isSet(object.isFirstOffer) ? Boolean(object.isFirstOffer) : false,
    };
  },

  toJSON(message: SellListCategory_OfferViewEvent): unknown {
    const obj: any = {};
    if (message.sellDirectLeadToken !== "") {
      obj.sellDirectLeadToken = message.sellDirectLeadToken;
    }
    if (message.sellDirectOfferId !== "") {
      obj.sellDirectOfferId = message.sellDirectOfferId;
    }
    if (message.listingRangeMin !== undefined) {
      obj.listingRangeMin = Money.toJSON(message.listingRangeMin);
    }
    if (message.listingRangeMax !== undefined) {
      obj.listingRangeMax = Money.toJSON(message.listingRangeMax);
    }
    if (message.recommendedProduct !== undefined) {
      obj.recommendedProduct = message.recommendedProduct;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    if (message.isFirstOffer === true) {
      obj.isFirstOffer = message.isFirstOffer;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellListCategory_OfferViewEvent>, I>>(base?: I): SellListCategory_OfferViewEvent {
    return SellListCategory_OfferViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellListCategory_OfferViewEvent>, I>>(
    object: I,
  ): SellListCategory_OfferViewEvent {
    const message = createBaseSellListCategory_OfferViewEvent();
    message.sellDirectLeadToken = object.sellDirectLeadToken ?? "";
    message.sellDirectOfferId = object.sellDirectOfferId ?? "";
    message.listingRangeMin = (object.listingRangeMin !== undefined && object.listingRangeMin !== null)
      ? Money.fromPartial(object.listingRangeMin)
      : undefined;
    message.listingRangeMax = (object.listingRangeMax !== undefined && object.listingRangeMax !== null)
      ? Money.fromPartial(object.listingRangeMax)
      : undefined;
    message.recommendedProduct = object.recommendedProduct ?? undefined;
    message.offerUuid = object.offerUuid ?? "";
    message.isFirstOffer = object.isFirstOffer ?? false;
    return message;
  },
};

function createBaseSellListCategory_QualificationEvent(): SellListCategory_QualificationEvent {
  return { lwcProductId: "", sellDirectLeadToken: "", qualificationDecision: 0 };
}

export const SellListCategory_QualificationEvent = {
  fromJSON(object: any): SellListCategory_QualificationEvent {
    return {
      lwcProductId: isSet(object.lwcProductId) ? String(object.lwcProductId) : "",
      sellDirectLeadToken: isSet(object.sellDirectLeadToken) ? String(object.sellDirectLeadToken) : "",
      qualificationDecision: isSet(object.qualificationDecision)
        ? sellListCategory_QualificationEvent_QualificationDecisionFromJSON(object.qualificationDecision)
        : 0,
    };
  },

  toJSON(message: SellListCategory_QualificationEvent): unknown {
    const obj: any = {};
    if (message.lwcProductId !== "") {
      obj.lwcProductId = message.lwcProductId;
    }
    if (message.sellDirectLeadToken !== "") {
      obj.sellDirectLeadToken = message.sellDirectLeadToken;
    }
    if (message.qualificationDecision !== 0) {
      obj.qualificationDecision = sellListCategory_QualificationEvent_QualificationDecisionToJSON(
        message.qualificationDecision,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellListCategory_QualificationEvent>, I>>(
    base?: I,
  ): SellListCategory_QualificationEvent {
    return SellListCategory_QualificationEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellListCategory_QualificationEvent>, I>>(
    object: I,
  ): SellListCategory_QualificationEvent {
    const message = createBaseSellListCategory_QualificationEvent();
    message.lwcProductId = object.lwcProductId ?? "";
    message.sellDirectLeadToken = object.sellDirectLeadToken ?? "";
    message.qualificationDecision = object.qualificationDecision ?? 0;
    return message;
  },
};

function createBaseSellListCategory_IntentUpdateEvent(): SellListCategory_IntentUpdateEvent {
  return { lwcProductId: "", sellDirectLeadToken: "", oldIntent: 0, newIntent: 0 };
}

export const SellListCategory_IntentUpdateEvent = {
  fromJSON(object: any): SellListCategory_IntentUpdateEvent {
    return {
      lwcProductId: isSet(object.lwcProductId) ? String(object.lwcProductId) : "",
      sellDirectLeadToken: isSet(object.sellDirectLeadToken) ? String(object.sellDirectLeadToken) : "",
      oldIntent: isSet(object.oldIntent) ? lwcProductView_IntentFromJSON(object.oldIntent) : 0,
      newIntent: isSet(object.newIntent) ? lwcProductView_IntentFromJSON(object.newIntent) : 0,
    };
  },

  toJSON(message: SellListCategory_IntentUpdateEvent): unknown {
    const obj: any = {};
    if (message.lwcProductId !== "") {
      obj.lwcProductId = message.lwcProductId;
    }
    if (message.sellDirectLeadToken !== "") {
      obj.sellDirectLeadToken = message.sellDirectLeadToken;
    }
    if (message.oldIntent !== 0) {
      obj.oldIntent = lwcProductView_IntentToJSON(message.oldIntent);
    }
    if (message.newIntent !== 0) {
      obj.newIntent = lwcProductView_IntentToJSON(message.newIntent);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellListCategory_IntentUpdateEvent>, I>>(
    base?: I,
  ): SellListCategory_IntentUpdateEvent {
    return SellListCategory_IntentUpdateEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellListCategory_IntentUpdateEvent>, I>>(
    object: I,
  ): SellListCategory_IntentUpdateEvent {
    const message = createBaseSellListCategory_IntentUpdateEvent();
    message.lwcProductId = object.lwcProductId ?? "";
    message.sellDirectLeadToken = object.sellDirectLeadToken ?? "";
    message.oldIntent = object.oldIntent ?? 0;
    message.newIntent = object.newIntent ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
