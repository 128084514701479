/* eslint-disable */
import { Money } from "../../common/money";

export const protobufPackage = "od_protos.ballpark.data";

export enum Source {
  UNKNOWN_SOURCE = 0,
  BULK_OVM = 1,
  REALTY_TRAC = 2,
  UNRECOGNIZED = -1,
}

export function sourceFromJSON(object: any): Source {
  switch (object) {
    case 0:
    case "UNKNOWN_SOURCE":
      return Source.UNKNOWN_SOURCE;
    case 1:
    case "BULK_OVM":
      return Source.BULK_OVM;
    case 2:
    case "REALTY_TRAC":
      return Source.REALTY_TRAC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Source.UNRECOGNIZED;
  }
}

export function sourceToJSON(object: Source): string {
  switch (object) {
    case Source.UNKNOWN_SOURCE:
      return "UNKNOWN_SOURCE";
    case Source.BULK_OVM:
      return "BULK_OVM";
    case Source.REALTY_TRAC:
      return "REALTY_TRAC";
    case Source.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Next ID: 15 */
export interface HomeValue {
  addressUuid: string;
  valuationCents: number;
  valuationCeilingCents: number;
  valuationFloorCents: number;
  comps: Comp[];
  source: Source;
  headlinePrice?: Money | undefined;
  headlineRangeLower?: Money | undefined;
  headlineRangeUpper?: Money | undefined;
  expectedError?: number | undefined;
}

/** Next ID: 5 */
export interface Comp {
  addressUuid: string;
}

function createBaseHomeValue(): HomeValue {
  return {
    addressUuid: "",
    valuationCents: 0,
    valuationCeilingCents: 0,
    valuationFloorCents: 0,
    comps: [],
    source: 0,
    headlinePrice: undefined,
    headlineRangeLower: undefined,
    headlineRangeUpper: undefined,
    expectedError: undefined,
  };
}

export const HomeValue = {
  fromJSON(object: any): HomeValue {
    return {
      addressUuid: isSet(object.addressUuid) ? String(object.addressUuid) : "",
      valuationCents: isSet(object.valuationCents) ? Number(object.valuationCents) : 0,
      valuationCeilingCents: isSet(object.valuationCeilingCents) ? Number(object.valuationCeilingCents) : 0,
      valuationFloorCents: isSet(object.valuationFloorCents) ? Number(object.valuationFloorCents) : 0,
      comps: Array.isArray(object?.comps) ? object.comps.map((e: any) => Comp.fromJSON(e)) : [],
      source: isSet(object.source) ? sourceFromJSON(object.source) : 0,
      headlinePrice: isSet(object.headlinePrice) ? Money.fromJSON(object.headlinePrice) : undefined,
      headlineRangeLower: isSet(object.headlineRangeLower) ? Money.fromJSON(object.headlineRangeLower) : undefined,
      headlineRangeUpper: isSet(object.headlineRangeUpper) ? Money.fromJSON(object.headlineRangeUpper) : undefined,
      expectedError: isSet(object.expectedError) ? Number(object.expectedError) : undefined,
    };
  },

  toJSON(message: HomeValue): unknown {
    const obj: any = {};
    if (message.addressUuid !== "") {
      obj.addressUuid = message.addressUuid;
    }
    if (message.valuationCents !== 0) {
      obj.valuationCents = Math.round(message.valuationCents);
    }
    if (message.valuationCeilingCents !== 0) {
      obj.valuationCeilingCents = Math.round(message.valuationCeilingCents);
    }
    if (message.valuationFloorCents !== 0) {
      obj.valuationFloorCents = Math.round(message.valuationFloorCents);
    }
    if (message.comps?.length) {
      obj.comps = message.comps.map((e) => Comp.toJSON(e));
    }
    if (message.source !== 0) {
      obj.source = sourceToJSON(message.source);
    }
    if (message.headlinePrice !== undefined) {
      obj.headlinePrice = Money.toJSON(message.headlinePrice);
    }
    if (message.headlineRangeLower !== undefined) {
      obj.headlineRangeLower = Money.toJSON(message.headlineRangeLower);
    }
    if (message.headlineRangeUpper !== undefined) {
      obj.headlineRangeUpper = Money.toJSON(message.headlineRangeUpper);
    }
    if (message.expectedError !== undefined) {
      obj.expectedError = message.expectedError;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeValue>, I>>(base?: I): HomeValue {
    return HomeValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeValue>, I>>(object: I): HomeValue {
    const message = createBaseHomeValue();
    message.addressUuid = object.addressUuid ?? "";
    message.valuationCents = object.valuationCents ?? 0;
    message.valuationCeilingCents = object.valuationCeilingCents ?? 0;
    message.valuationFloorCents = object.valuationFloorCents ?? 0;
    message.comps = object.comps?.map((e) => Comp.fromPartial(e)) || [];
    message.source = object.source ?? 0;
    message.headlinePrice = (object.headlinePrice !== undefined && object.headlinePrice !== null)
      ? Money.fromPartial(object.headlinePrice)
      : undefined;
    message.headlineRangeLower = (object.headlineRangeLower !== undefined && object.headlineRangeLower !== null)
      ? Money.fromPartial(object.headlineRangeLower)
      : undefined;
    message.headlineRangeUpper = (object.headlineRangeUpper !== undefined && object.headlineRangeUpper !== null)
      ? Money.fromPartial(object.headlineRangeUpper)
      : undefined;
    message.expectedError = object.expectedError ?? undefined;
    return message;
  },
};

function createBaseComp(): Comp {
  return { addressUuid: "" };
}

export const Comp = {
  fromJSON(object: any): Comp {
    return { addressUuid: isSet(object.addressUuid) ? String(object.addressUuid) : "" };
  },

  toJSON(message: Comp): unknown {
    const obj: any = {};
    if (message.addressUuid !== "") {
      obj.addressUuid = message.addressUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Comp>, I>>(base?: I): Comp {
    return Comp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Comp>, I>>(object: I): Comp {
    const message = createBaseComp();
    message.addressUuid = object.addressUuid ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
