/* eslint-disable */
import { Timestamp } from "../../../../google/protobuf/timestamp";

export const protobufPackage = "od_protos.journey.data.categories";

export interface SellOfferReviewCategory {
  event?:
    | { $case: "scheduled"; scheduled: SellOfferReviewCategory_OfferReviewScheduledEvent }
    | { $case: "completed"; completed: SellOfferReviewCategory_OfferReviewCompletedEvent }
    | { $case: "rescheduled"; rescheduled: SellOfferReviewCategory_OfferReviewRescheduledEvent }
    | { $case: "canceled"; canceled: SellOfferReviewCategory_OfferReviewCanceledEvent }
    | undefined;
}

export enum SellOfferReviewCategory_ORType {
  UNKNOWN_OR_TYPE = 0,
  OR = 1,
  ORVA = 2,
  UNRECOGNIZED = -1,
}

export function sellOfferReviewCategory_ORTypeFromJSON(object: any): SellOfferReviewCategory_ORType {
  switch (object) {
    case 0:
    case "UNKNOWN_OR_TYPE":
      return SellOfferReviewCategory_ORType.UNKNOWN_OR_TYPE;
    case 1:
    case "OR":
      return SellOfferReviewCategory_ORType.OR;
    case 2:
    case "ORVA":
      return SellOfferReviewCategory_ORType.ORVA;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellOfferReviewCategory_ORType.UNRECOGNIZED;
  }
}

export function sellOfferReviewCategory_ORTypeToJSON(object: SellOfferReviewCategory_ORType): string {
  switch (object) {
    case SellOfferReviewCategory_ORType.UNKNOWN_OR_TYPE:
      return "UNKNOWN_OR_TYPE";
    case SellOfferReviewCategory_ORType.OR:
      return "OR";
    case SellOfferReviewCategory_ORType.ORVA:
      return "ORVA";
    case SellOfferReviewCategory_ORType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SellOfferReviewCategory_OROutcome {
  UNKNOWN_OR_OUTCOME = 0,
  NO_SHOW = 1,
  CAN_COMPLETE_OR = 2,
  CALL_NOT_COMPLETE_RESCHEDULE_OR = 3,
  CALL_NOT_COMPLETE_SENT_SSVA = 4,
  AGENT_CUSTOMER_HANDED_OFF_TO_AEP = 5,
  DID_NOT_CONVERT = 6,
  OPENDOOR_CANNOT_PURCHASE_OOBB = 7,
  UNRECOGNIZED = -1,
}

export function sellOfferReviewCategory_OROutcomeFromJSON(object: any): SellOfferReviewCategory_OROutcome {
  switch (object) {
    case 0:
    case "UNKNOWN_OR_OUTCOME":
      return SellOfferReviewCategory_OROutcome.UNKNOWN_OR_OUTCOME;
    case 1:
    case "NO_SHOW":
      return SellOfferReviewCategory_OROutcome.NO_SHOW;
    case 2:
    case "CAN_COMPLETE_OR":
      return SellOfferReviewCategory_OROutcome.CAN_COMPLETE_OR;
    case 3:
    case "CALL_NOT_COMPLETE_RESCHEDULE_OR":
      return SellOfferReviewCategory_OROutcome.CALL_NOT_COMPLETE_RESCHEDULE_OR;
    case 4:
    case "CALL_NOT_COMPLETE_SENT_SSVA":
      return SellOfferReviewCategory_OROutcome.CALL_NOT_COMPLETE_SENT_SSVA;
    case 5:
    case "AGENT_CUSTOMER_HANDED_OFF_TO_AEP":
      return SellOfferReviewCategory_OROutcome.AGENT_CUSTOMER_HANDED_OFF_TO_AEP;
    case 6:
    case "DID_NOT_CONVERT":
      return SellOfferReviewCategory_OROutcome.DID_NOT_CONVERT;
    case 7:
    case "OPENDOOR_CANNOT_PURCHASE_OOBB":
      return SellOfferReviewCategory_OROutcome.OPENDOOR_CANNOT_PURCHASE_OOBB;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellOfferReviewCategory_OROutcome.UNRECOGNIZED;
  }
}

export function sellOfferReviewCategory_OROutcomeToJSON(object: SellOfferReviewCategory_OROutcome): string {
  switch (object) {
    case SellOfferReviewCategory_OROutcome.UNKNOWN_OR_OUTCOME:
      return "UNKNOWN_OR_OUTCOME";
    case SellOfferReviewCategory_OROutcome.NO_SHOW:
      return "NO_SHOW";
    case SellOfferReviewCategory_OROutcome.CAN_COMPLETE_OR:
      return "CAN_COMPLETE_OR";
    case SellOfferReviewCategory_OROutcome.CALL_NOT_COMPLETE_RESCHEDULE_OR:
      return "CALL_NOT_COMPLETE_RESCHEDULE_OR";
    case SellOfferReviewCategory_OROutcome.CALL_NOT_COMPLETE_SENT_SSVA:
      return "CALL_NOT_COMPLETE_SENT_SSVA";
    case SellOfferReviewCategory_OROutcome.AGENT_CUSTOMER_HANDED_OFF_TO_AEP:
      return "AGENT_CUSTOMER_HANDED_OFF_TO_AEP";
    case SellOfferReviewCategory_OROutcome.DID_NOT_CONVERT:
      return "DID_NOT_CONVERT";
    case SellOfferReviewCategory_OROutcome.OPENDOOR_CANNOT_PURCHASE_OOBB:
      return "OPENDOOR_CANNOT_PURCHASE_OOBB";
    case SellOfferReviewCategory_OROutcome.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SellOfferReviewCategory_OfferReviewScheduledEvent {
  leadId: string;
  offerUuid: string;
  orType: SellOfferReviewCategory_ORType;
  scheduledDatetime?: Date | undefined;
}

export interface SellOfferReviewCategory_OfferReviewCompletedEvent {
  leadId: string;
  offerUuid: string;
  orType: SellOfferReviewCategory_ORType;
  outcome: SellOfferReviewCategory_OROutcome;
}

export interface SellOfferReviewCategory_OfferReviewRescheduledEvent {
  leadId: string;
  offerUuid: string;
  orType: SellOfferReviewCategory_ORType;
  scheduledDatetime?: Date | undefined;
}

export interface SellOfferReviewCategory_OfferReviewCanceledEvent {
  leadId: string;
  offerUuid: string;
  orType: SellOfferReviewCategory_ORType;
  reason?: string | undefined;
}

function createBaseSellOfferReviewCategory(): SellOfferReviewCategory {
  return { event: undefined };
}

export const SellOfferReviewCategory = {
  fromJSON(object: any): SellOfferReviewCategory {
    return {
      event: isSet(object.scheduled)
        ? {
          $case: "scheduled",
          scheduled: SellOfferReviewCategory_OfferReviewScheduledEvent.fromJSON(object.scheduled),
        }
        : isSet(object.completed)
        ? {
          $case: "completed",
          completed: SellOfferReviewCategory_OfferReviewCompletedEvent.fromJSON(object.completed),
        }
        : isSet(object.rescheduled)
        ? {
          $case: "rescheduled",
          rescheduled: SellOfferReviewCategory_OfferReviewRescheduledEvent.fromJSON(object.rescheduled),
        }
        : isSet(object.canceled)
        ? { $case: "canceled", canceled: SellOfferReviewCategory_OfferReviewCanceledEvent.fromJSON(object.canceled) }
        : undefined,
    };
  },

  toJSON(message: SellOfferReviewCategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "scheduled") {
      obj.scheduled = SellOfferReviewCategory_OfferReviewScheduledEvent.toJSON(message.event.scheduled);
    }
    if (message.event?.$case === "completed") {
      obj.completed = SellOfferReviewCategory_OfferReviewCompletedEvent.toJSON(message.event.completed);
    }
    if (message.event?.$case === "rescheduled") {
      obj.rescheduled = SellOfferReviewCategory_OfferReviewRescheduledEvent.toJSON(message.event.rescheduled);
    }
    if (message.event?.$case === "canceled") {
      obj.canceled = SellOfferReviewCategory_OfferReviewCanceledEvent.toJSON(message.event.canceled);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellOfferReviewCategory>, I>>(base?: I): SellOfferReviewCategory {
    return SellOfferReviewCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellOfferReviewCategory>, I>>(object: I): SellOfferReviewCategory {
    const message = createBaseSellOfferReviewCategory();
    if (
      object.event?.$case === "scheduled" && object.event?.scheduled !== undefined && object.event?.scheduled !== null
    ) {
      message.event = {
        $case: "scheduled",
        scheduled: SellOfferReviewCategory_OfferReviewScheduledEvent.fromPartial(object.event.scheduled),
      };
    }
    if (
      object.event?.$case === "completed" && object.event?.completed !== undefined && object.event?.completed !== null
    ) {
      message.event = {
        $case: "completed",
        completed: SellOfferReviewCategory_OfferReviewCompletedEvent.fromPartial(object.event.completed),
      };
    }
    if (
      object.event?.$case === "rescheduled" &&
      object.event?.rescheduled !== undefined &&
      object.event?.rescheduled !== null
    ) {
      message.event = {
        $case: "rescheduled",
        rescheduled: SellOfferReviewCategory_OfferReviewRescheduledEvent.fromPartial(object.event.rescheduled),
      };
    }
    if (object.event?.$case === "canceled" && object.event?.canceled !== undefined && object.event?.canceled !== null) {
      message.event = {
        $case: "canceled",
        canceled: SellOfferReviewCategory_OfferReviewCanceledEvent.fromPartial(object.event.canceled),
      };
    }
    return message;
  },
};

function createBaseSellOfferReviewCategory_OfferReviewScheduledEvent(): SellOfferReviewCategory_OfferReviewScheduledEvent {
  return { leadId: "", offerUuid: "", orType: 0, scheduledDatetime: undefined };
}

export const SellOfferReviewCategory_OfferReviewScheduledEvent = {
  fromJSON(object: any): SellOfferReviewCategory_OfferReviewScheduledEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
      orType: isSet(object.orType) ? sellOfferReviewCategory_ORTypeFromJSON(object.orType) : 0,
      scheduledDatetime: isSet(object.scheduledDatetime) ? fromJsonTimestamp(object.scheduledDatetime) : undefined,
    };
  },

  toJSON(message: SellOfferReviewCategory_OfferReviewScheduledEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    if (message.orType !== 0) {
      obj.orType = sellOfferReviewCategory_ORTypeToJSON(message.orType);
    }
    if (message.scheduledDatetime !== undefined) {
      obj.scheduledDatetime = message.scheduledDatetime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellOfferReviewCategory_OfferReviewScheduledEvent>, I>>(
    base?: I,
  ): SellOfferReviewCategory_OfferReviewScheduledEvent {
    return SellOfferReviewCategory_OfferReviewScheduledEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellOfferReviewCategory_OfferReviewScheduledEvent>, I>>(
    object: I,
  ): SellOfferReviewCategory_OfferReviewScheduledEvent {
    const message = createBaseSellOfferReviewCategory_OfferReviewScheduledEvent();
    message.leadId = object.leadId ?? "";
    message.offerUuid = object.offerUuid ?? "";
    message.orType = object.orType ?? 0;
    message.scheduledDatetime = object.scheduledDatetime ?? undefined;
    return message;
  },
};

function createBaseSellOfferReviewCategory_OfferReviewCompletedEvent(): SellOfferReviewCategory_OfferReviewCompletedEvent {
  return { leadId: "", offerUuid: "", orType: 0, outcome: 0 };
}

export const SellOfferReviewCategory_OfferReviewCompletedEvent = {
  fromJSON(object: any): SellOfferReviewCategory_OfferReviewCompletedEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
      orType: isSet(object.orType) ? sellOfferReviewCategory_ORTypeFromJSON(object.orType) : 0,
      outcome: isSet(object.outcome) ? sellOfferReviewCategory_OROutcomeFromJSON(object.outcome) : 0,
    };
  },

  toJSON(message: SellOfferReviewCategory_OfferReviewCompletedEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    if (message.orType !== 0) {
      obj.orType = sellOfferReviewCategory_ORTypeToJSON(message.orType);
    }
    if (message.outcome !== 0) {
      obj.outcome = sellOfferReviewCategory_OROutcomeToJSON(message.outcome);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellOfferReviewCategory_OfferReviewCompletedEvent>, I>>(
    base?: I,
  ): SellOfferReviewCategory_OfferReviewCompletedEvent {
    return SellOfferReviewCategory_OfferReviewCompletedEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellOfferReviewCategory_OfferReviewCompletedEvent>, I>>(
    object: I,
  ): SellOfferReviewCategory_OfferReviewCompletedEvent {
    const message = createBaseSellOfferReviewCategory_OfferReviewCompletedEvent();
    message.leadId = object.leadId ?? "";
    message.offerUuid = object.offerUuid ?? "";
    message.orType = object.orType ?? 0;
    message.outcome = object.outcome ?? 0;
    return message;
  },
};

function createBaseSellOfferReviewCategory_OfferReviewRescheduledEvent(): SellOfferReviewCategory_OfferReviewRescheduledEvent {
  return { leadId: "", offerUuid: "", orType: 0, scheduledDatetime: undefined };
}

export const SellOfferReviewCategory_OfferReviewRescheduledEvent = {
  fromJSON(object: any): SellOfferReviewCategory_OfferReviewRescheduledEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
      orType: isSet(object.orType) ? sellOfferReviewCategory_ORTypeFromJSON(object.orType) : 0,
      scheduledDatetime: isSet(object.scheduledDatetime) ? fromJsonTimestamp(object.scheduledDatetime) : undefined,
    };
  },

  toJSON(message: SellOfferReviewCategory_OfferReviewRescheduledEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    if (message.orType !== 0) {
      obj.orType = sellOfferReviewCategory_ORTypeToJSON(message.orType);
    }
    if (message.scheduledDatetime !== undefined) {
      obj.scheduledDatetime = message.scheduledDatetime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellOfferReviewCategory_OfferReviewRescheduledEvent>, I>>(
    base?: I,
  ): SellOfferReviewCategory_OfferReviewRescheduledEvent {
    return SellOfferReviewCategory_OfferReviewRescheduledEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellOfferReviewCategory_OfferReviewRescheduledEvent>, I>>(
    object: I,
  ): SellOfferReviewCategory_OfferReviewRescheduledEvent {
    const message = createBaseSellOfferReviewCategory_OfferReviewRescheduledEvent();
    message.leadId = object.leadId ?? "";
    message.offerUuid = object.offerUuid ?? "";
    message.orType = object.orType ?? 0;
    message.scheduledDatetime = object.scheduledDatetime ?? undefined;
    return message;
  },
};

function createBaseSellOfferReviewCategory_OfferReviewCanceledEvent(): SellOfferReviewCategory_OfferReviewCanceledEvent {
  return { leadId: "", offerUuid: "", orType: 0, reason: undefined };
}

export const SellOfferReviewCategory_OfferReviewCanceledEvent = {
  fromJSON(object: any): SellOfferReviewCategory_OfferReviewCanceledEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
      orType: isSet(object.orType) ? sellOfferReviewCategory_ORTypeFromJSON(object.orType) : 0,
      reason: isSet(object.reason) ? String(object.reason) : undefined,
    };
  },

  toJSON(message: SellOfferReviewCategory_OfferReviewCanceledEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    if (message.orType !== 0) {
      obj.orType = sellOfferReviewCategory_ORTypeToJSON(message.orType);
    }
    if (message.reason !== undefined) {
      obj.reason = message.reason;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellOfferReviewCategory_OfferReviewCanceledEvent>, I>>(
    base?: I,
  ): SellOfferReviewCategory_OfferReviewCanceledEvent {
    return SellOfferReviewCategory_OfferReviewCanceledEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellOfferReviewCategory_OfferReviewCanceledEvent>, I>>(
    object: I,
  ): SellOfferReviewCategory_OfferReviewCanceledEvent {
    const message = createBaseSellOfferReviewCategory_OfferReviewCanceledEvent();
    message.leadId = object.leadId ?? "";
    message.offerUuid = object.offerUuid ?? "";
    message.orType = object.orType ?? 0;
    message.reason = object.reason ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
