/* eslint-disable */
import { Timestamp } from "../../../google/protobuf/timestamp";
import { State, stateFromJSON, stateToJSON } from "../../common/state/states";
import { DenialReason, denialReasonFromJSON, denialReasonToJSON } from "../../sell/common/common";
import {
  SellerInput_Channel,
  sellerInput_ChannelFromJSON,
  sellerInput_ChannelToJSON,
} from "../../sell/reception/data/seller_input";
import { CustomerEventNote } from "./events/customer_event";
import { HomeConditionResponse } from "./sell_direct/home_condition_response";

export const protobufPackage = "od_protos.web.data";

/**
 * Which system generated the lead.
 * Next ID: 3
 */
export interface LeadSource {
  type: LeadSource_Type;
  id: string;
}

export enum LeadSource_Type {
  UNKNOWN_TYPE = 0,
  RECEPTION = 1,
  PARTNERSHIP = 2,
  UNRECOGNIZED = -1,
}

export function leadSource_TypeFromJSON(object: any): LeadSource_Type {
  switch (object) {
    case 0:
    case "UNKNOWN_TYPE":
      return LeadSource_Type.UNKNOWN_TYPE;
    case 1:
    case "RECEPTION":
      return LeadSource_Type.RECEPTION;
    case 2:
    case "PARTNERSHIP":
      return LeadSource_Type.PARTNERSHIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LeadSource_Type.UNRECOGNIZED;
  }
}

export function leadSource_TypeToJSON(object: LeadSource_Type): string {
  switch (object) {
    case LeadSource_Type.UNKNOWN_TYPE:
      return "UNKNOWN_TYPE";
    case LeadSource_Type.RECEPTION:
      return "RECEPTION";
    case LeadSource_Type.PARTNERSHIP:
      return "PARTNERSHIP";
    case LeadSource_Type.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * LeadParams represents parameters to query a lead by (e.g. address token and customer uuid).
 * Next ID: 3
 */
export interface LeadQueryParams {
  addressToken: string;
  customerUuid: string;
}

/**
 * Deprecated in favor of web/data/sell_direct/lead.proto
 * A lead in Web.
 * Next ID: 43
 */
export interface Lead {
  /**
   * Is the lead a participant in twilio pilot
   *
   * @deprecated
   */
  isInTwilioVideoPilot: boolean;
  /**
   * Is the lead a participant in zoom pilot
   *
   * @deprecated
   */
  isInZoomPilot: boolean;
  /**
   * The order the customer should be recommended to sell
   *
   * @deprecated
   */
  sellYourWayProductPrioritization: Lead_SellYourWayProduct[];
  /** Primary identifier for a lead in Web */
  id: string;
  /** Seller lead uuid for a lead */
  sellerLeadUuid: string;
  /** Latest sell direct offer id for a lead */
  latestOfferId: string;
  /** Timestamp of latest offer refresh request for lead in web */
  latestRefreshRequestedAt?:
    | Date
    | undefined;
  /** Unique token string associated with lead in web */
  token: string;
  /** Is lead listed on the market */
  isListed: boolean;
  /** Seller input uuid for a lead */
  sellerInputUuid: string;
  /**
   * Latest virtual assessment id for a lead, it could be orva or ssva
   * TODO(LiangJin): deprecate this one when migrated to latest_self_serve_virtual_assessment_id and latest_offer_review_virtual_assessment_id
   */
  latestVirtualAssessmentId: string;
  /** Second latest offer */
  previousOfferId: string;
  /** Latest offer review details */
  lastOfferReviewDetails?:
    | OfferReviewDetails
    | undefined;
  /** Experience partner details */
  experiencePartner?:
    | ExperiencePartner
    | undefined;
  /** Customer event notes for lead */
  customerEventNotes: CustomerEventNote[];
  /** Lead is considered to be an agent_experience type (based on channnel) */
  isAgentExperience: boolean;
  /** The address of the lead */
  addressId: string;
  /** The customer who originated the lead. */
  customerUuid: string;
  /** The email of the customer who originated the lead (or the overridden email) */
  email: string;
  /** If lead is qualified for sell direct */
  isQualified: boolean;
  /** When the lead was qualified for sell direct */
  qualifiedAt?:
    | Date
    | undefined;
  /** When the lead was created */
  createdAt?:
    | Date
    | undefined;
  /** Latest self_serve_virtual_assessment id for a lead */
  latestSelfServeVirtualAssessmentId: string;
  /** Latest offer_review_virtual_assessment id for a lead */
  latestOfferReviewVirtualAssessmentId: string;
  /** The reason the lead was denied. If not denied, this will be the zero value (NO_DENIAL) */
  denialReason: DenialReason;
  /** User's input for home condition flow */
  homeConditionResponse?:
    | HomeConditionResponse
    | undefined;
  /** Channel for the lead */
  channel: SellerInput_Channel;
  /** All customers associated with the lead */
  customerIds: string[];
  /** Whether we allow a virtual EA to be done for this lead */
  isEligibleForVirtualEa: boolean;
  /** If the lead is enrolled in the Exclusive Offers program */
  inExclusiveOffers: boolean;
  /** Link to the ssva upload page */
  sellerSsvaUploadPage: string;
  /** Mortgage payoff amount for the lead */
  mortgagePayoffAmountCents?:
    | number
    | undefined;
  /** Notes if the lead has been denied */
  denialNotes: string;
  /**
   * Variant for the painted door CTA experiment
   * Can be removed after experiment conclusion
   * Will be empty string if not in experiment
   */
  hubPrimaryCtaPaintedDoorExperimentVariant: string;
  /**
   * Variant for the customer success CTA experiment
   * Can be removed after experiment conclusion
   * Will be empty string if not in experiment
   */
  hubPrimaryCtaCustomerSuccessExperimentVariant: string;
}

export enum Lead_SellYourWayProduct {
  UNKNOWN = 0,
  LIST_WITH_CERTAINTY = 1,
  SELL_DIRECT = 2,
  UNRECOGNIZED = -1,
}

export function lead_SellYourWayProductFromJSON(object: any): Lead_SellYourWayProduct {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return Lead_SellYourWayProduct.UNKNOWN;
    case 1:
    case "LIST_WITH_CERTAINTY":
      return Lead_SellYourWayProduct.LIST_WITH_CERTAINTY;
    case 2:
    case "SELL_DIRECT":
      return Lead_SellYourWayProduct.SELL_DIRECT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Lead_SellYourWayProduct.UNRECOGNIZED;
  }
}

export function lead_SellYourWayProductToJSON(object: Lead_SellYourWayProduct): string {
  switch (object) {
    case Lead_SellYourWayProduct.UNKNOWN:
      return "UNKNOWN";
    case Lead_SellYourWayProduct.LIST_WITH_CERTAINTY:
      return "LIST_WITH_CERTAINTY";
    case Lead_SellYourWayProduct.SELL_DIRECT:
      return "SELL_DIRECT";
    case Lead_SellYourWayProduct.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PrivateLead {
  lead?:
    | Lead
    | undefined;
  /** Status note taken by EPs */
  statusNote: string;
  displayCategoryForOpsAcquisition: string;
}

/** Next ID: 5 */
export interface OfferReviewDetails {
  id: string;
  offerReviewOutcome: string;
  offerReviewAt?: Date | undefined;
  calendlyData?: CalendlyData | undefined;
}

/** Next ID: 4 */
export interface CalendlyData {
  id: string;
  calendar: string;
  source: string;
}

/** Next ID: 8 */
export interface ExperiencePartner {
  id: string;
  name: string;
  /** the external_email for the experience_partner */
  email: string;
  displayPhoneNumber: string;
  photoUrl: string;
  brokerageLicenses: BrokerageLicense[];
  humanToken: string;
}

/** Next ID: 5 */
export interface BrokerageLicense {
  id: string;
  /** Deprecated, use `state` instead. */
  geographicState: string;
  state: State;
  licenseNumber: string;
}

/**
 * Request object used by ListLeadsRequest.
 * Next ID: 5
 */
export interface LeadIdentifier {
  lead?:
    | { $case: "source"; source: LeadSource }
    | { $case: "params"; params: LeadQueryParams }
    | { $case: "token"; token: string }
    | { $case: "sellerLeadUuid"; sellerLeadUuid: string }
    | undefined;
}

function createBaseLeadSource(): LeadSource {
  return { type: 0, id: "" };
}

export const LeadSource = {
  fromJSON(object: any): LeadSource {
    return {
      type: isSet(object.type) ? leadSource_TypeFromJSON(object.type) : 0,
      id: isSet(object.id) ? String(object.id) : "",
    };
  },

  toJSON(message: LeadSource): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = leadSource_TypeToJSON(message.type);
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LeadSource>, I>>(base?: I): LeadSource {
    return LeadSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LeadSource>, I>>(object: I): LeadSource {
    const message = createBaseLeadSource();
    message.type = object.type ?? 0;
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseLeadQueryParams(): LeadQueryParams {
  return { addressToken: "", customerUuid: "" };
}

export const LeadQueryParams = {
  fromJSON(object: any): LeadQueryParams {
    return {
      addressToken: isSet(object.addressToken) ? String(object.addressToken) : "",
      customerUuid: isSet(object.customerUuid) ? String(object.customerUuid) : "",
    };
  },

  toJSON(message: LeadQueryParams): unknown {
    const obj: any = {};
    if (message.addressToken !== "") {
      obj.addressToken = message.addressToken;
    }
    if (message.customerUuid !== "") {
      obj.customerUuid = message.customerUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LeadQueryParams>, I>>(base?: I): LeadQueryParams {
    return LeadQueryParams.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LeadQueryParams>, I>>(object: I): LeadQueryParams {
    const message = createBaseLeadQueryParams();
    message.addressToken = object.addressToken ?? "";
    message.customerUuid = object.customerUuid ?? "";
    return message;
  },
};

function createBaseLead(): Lead {
  return {
    isInTwilioVideoPilot: false,
    isInZoomPilot: false,
    sellYourWayProductPrioritization: [],
    id: "",
    sellerLeadUuid: "",
    latestOfferId: "",
    latestRefreshRequestedAt: undefined,
    token: "",
    isListed: false,
    sellerInputUuid: "",
    latestVirtualAssessmentId: "",
    previousOfferId: "",
    lastOfferReviewDetails: undefined,
    experiencePartner: undefined,
    customerEventNotes: [],
    isAgentExperience: false,
    addressId: "",
    customerUuid: "",
    email: "",
    isQualified: false,
    qualifiedAt: undefined,
    createdAt: undefined,
    latestSelfServeVirtualAssessmentId: "",
    latestOfferReviewVirtualAssessmentId: "",
    denialReason: 0,
    homeConditionResponse: undefined,
    channel: 0,
    customerIds: [],
    isEligibleForVirtualEa: false,
    inExclusiveOffers: false,
    sellerSsvaUploadPage: "",
    mortgagePayoffAmountCents: undefined,
    denialNotes: "",
    hubPrimaryCtaPaintedDoorExperimentVariant: "",
    hubPrimaryCtaCustomerSuccessExperimentVariant: "",
  };
}

export const Lead = {
  fromJSON(object: any): Lead {
    return {
      isInTwilioVideoPilot: isSet(object.isInTwilioVideoPilot) ? Boolean(object.isInTwilioVideoPilot) : false,
      isInZoomPilot: isSet(object.isInZoomPilot) ? Boolean(object.isInZoomPilot) : false,
      sellYourWayProductPrioritization: Array.isArray(object?.sellYourWayProductPrioritization)
        ? object.sellYourWayProductPrioritization.map((e: any) => lead_SellYourWayProductFromJSON(e))
        : [],
      id: isSet(object.id) ? String(object.id) : "",
      sellerLeadUuid: isSet(object.sellerLeadUuid) ? String(object.sellerLeadUuid) : "",
      latestOfferId: isSet(object.latestOfferId) ? String(object.latestOfferId) : "",
      latestRefreshRequestedAt: isSet(object.latestRefreshRequestedAt)
        ? fromJsonTimestamp(object.latestRefreshRequestedAt)
        : undefined,
      token: isSet(object.token) ? String(object.token) : "",
      isListed: isSet(object.isListed) ? Boolean(object.isListed) : false,
      sellerInputUuid: isSet(object.sellerInputUuid) ? String(object.sellerInputUuid) : "",
      latestVirtualAssessmentId: isSet(object.latestVirtualAssessmentId)
        ? String(object.latestVirtualAssessmentId)
        : "",
      previousOfferId: isSet(object.previousOfferId) ? String(object.previousOfferId) : "",
      lastOfferReviewDetails: isSet(object.lastOfferReviewDetails)
        ? OfferReviewDetails.fromJSON(object.lastOfferReviewDetails)
        : undefined,
      experiencePartner: isSet(object.experiencePartner)
        ? ExperiencePartner.fromJSON(object.experiencePartner)
        : undefined,
      customerEventNotes: Array.isArray(object?.customerEventNotes)
        ? object.customerEventNotes.map((e: any) => CustomerEventNote.fromJSON(e))
        : [],
      isAgentExperience: isSet(object.isAgentExperience) ? Boolean(object.isAgentExperience) : false,
      addressId: isSet(object.addressId) ? String(object.addressId) : "",
      customerUuid: isSet(object.customerUuid) ? String(object.customerUuid) : "",
      email: isSet(object.email) ? String(object.email) : "",
      isQualified: isSet(object.isQualified) ? Boolean(object.isQualified) : false,
      qualifiedAt: isSet(object.qualifiedAt) ? fromJsonTimestamp(object.qualifiedAt) : undefined,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      latestSelfServeVirtualAssessmentId: isSet(object.latestSelfServeVirtualAssessmentId)
        ? String(object.latestSelfServeVirtualAssessmentId)
        : "",
      latestOfferReviewVirtualAssessmentId: isSet(object.latestOfferReviewVirtualAssessmentId)
        ? String(object.latestOfferReviewVirtualAssessmentId)
        : "",
      denialReason: isSet(object.denialReason) ? denialReasonFromJSON(object.denialReason) : 0,
      homeConditionResponse: isSet(object.homeConditionResponse)
        ? HomeConditionResponse.fromJSON(object.homeConditionResponse)
        : undefined,
      channel: isSet(object.channel) ? sellerInput_ChannelFromJSON(object.channel) : 0,
      customerIds: Array.isArray(object?.customerIds)
        ? object.customerIds.map((e: any) => String(e))
        : [],
      isEligibleForVirtualEa: isSet(object.isEligibleForVirtualEa) ? Boolean(object.isEligibleForVirtualEa) : false,
      inExclusiveOffers: isSet(object.inExclusiveOffers) ? Boolean(object.inExclusiveOffers) : false,
      sellerSsvaUploadPage: isSet(object.sellerSsvaUploadPage) ? String(object.sellerSsvaUploadPage) : "",
      mortgagePayoffAmountCents: isSet(object.mortgagePayoffAmountCents)
        ? Number(object.mortgagePayoffAmountCents)
        : undefined,
      denialNotes: isSet(object.denialNotes) ? String(object.denialNotes) : "",
      hubPrimaryCtaPaintedDoorExperimentVariant: isSet(object.hubPrimaryCtaPaintedDoorExperimentVariant)
        ? String(object.hubPrimaryCtaPaintedDoorExperimentVariant)
        : "",
      hubPrimaryCtaCustomerSuccessExperimentVariant: isSet(object.hubPrimaryCtaCustomerSuccessExperimentVariant)
        ? String(object.hubPrimaryCtaCustomerSuccessExperimentVariant)
        : "",
    };
  },

  toJSON(message: Lead): unknown {
    const obj: any = {};
    if (message.isInTwilioVideoPilot === true) {
      obj.isInTwilioVideoPilot = message.isInTwilioVideoPilot;
    }
    if (message.isInZoomPilot === true) {
      obj.isInZoomPilot = message.isInZoomPilot;
    }
    if (message.sellYourWayProductPrioritization?.length) {
      obj.sellYourWayProductPrioritization = message.sellYourWayProductPrioritization.map((e) =>
        lead_SellYourWayProductToJSON(e)
      );
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.sellerLeadUuid !== "") {
      obj.sellerLeadUuid = message.sellerLeadUuid;
    }
    if (message.latestOfferId !== "") {
      obj.latestOfferId = message.latestOfferId;
    }
    if (message.latestRefreshRequestedAt !== undefined) {
      obj.latestRefreshRequestedAt = message.latestRefreshRequestedAt.toISOString();
    }
    if (message.token !== "") {
      obj.token = message.token;
    }
    if (message.isListed === true) {
      obj.isListed = message.isListed;
    }
    if (message.sellerInputUuid !== "") {
      obj.sellerInputUuid = message.sellerInputUuid;
    }
    if (message.latestVirtualAssessmentId !== "") {
      obj.latestVirtualAssessmentId = message.latestVirtualAssessmentId;
    }
    if (message.previousOfferId !== "") {
      obj.previousOfferId = message.previousOfferId;
    }
    if (message.lastOfferReviewDetails !== undefined) {
      obj.lastOfferReviewDetails = OfferReviewDetails.toJSON(message.lastOfferReviewDetails);
    }
    if (message.experiencePartner !== undefined) {
      obj.experiencePartner = ExperiencePartner.toJSON(message.experiencePartner);
    }
    if (message.customerEventNotes?.length) {
      obj.customerEventNotes = message.customerEventNotes.map((e) => CustomerEventNote.toJSON(e));
    }
    if (message.isAgentExperience === true) {
      obj.isAgentExperience = message.isAgentExperience;
    }
    if (message.addressId !== "") {
      obj.addressId = message.addressId;
    }
    if (message.customerUuid !== "") {
      obj.customerUuid = message.customerUuid;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.isQualified === true) {
      obj.isQualified = message.isQualified;
    }
    if (message.qualifiedAt !== undefined) {
      obj.qualifiedAt = message.qualifiedAt.toISOString();
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.latestSelfServeVirtualAssessmentId !== "") {
      obj.latestSelfServeVirtualAssessmentId = message.latestSelfServeVirtualAssessmentId;
    }
    if (message.latestOfferReviewVirtualAssessmentId !== "") {
      obj.latestOfferReviewVirtualAssessmentId = message.latestOfferReviewVirtualAssessmentId;
    }
    if (message.denialReason !== 0) {
      obj.denialReason = denialReasonToJSON(message.denialReason);
    }
    if (message.homeConditionResponse !== undefined) {
      obj.homeConditionResponse = HomeConditionResponse.toJSON(message.homeConditionResponse);
    }
    if (message.channel !== 0) {
      obj.channel = sellerInput_ChannelToJSON(message.channel);
    }
    if (message.customerIds?.length) {
      obj.customerIds = message.customerIds;
    }
    if (message.isEligibleForVirtualEa === true) {
      obj.isEligibleForVirtualEa = message.isEligibleForVirtualEa;
    }
    if (message.inExclusiveOffers === true) {
      obj.inExclusiveOffers = message.inExclusiveOffers;
    }
    if (message.sellerSsvaUploadPage !== "") {
      obj.sellerSsvaUploadPage = message.sellerSsvaUploadPage;
    }
    if (message.mortgagePayoffAmountCents !== undefined) {
      obj.mortgagePayoffAmountCents = message.mortgagePayoffAmountCents;
    }
    if (message.denialNotes !== "") {
      obj.denialNotes = message.denialNotes;
    }
    if (message.hubPrimaryCtaPaintedDoorExperimentVariant !== "") {
      obj.hubPrimaryCtaPaintedDoorExperimentVariant = message.hubPrimaryCtaPaintedDoorExperimentVariant;
    }
    if (message.hubPrimaryCtaCustomerSuccessExperimentVariant !== "") {
      obj.hubPrimaryCtaCustomerSuccessExperimentVariant = message.hubPrimaryCtaCustomerSuccessExperimentVariant;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Lead>, I>>(base?: I): Lead {
    return Lead.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Lead>, I>>(object: I): Lead {
    const message = createBaseLead();
    message.isInTwilioVideoPilot = object.isInTwilioVideoPilot ?? false;
    message.isInZoomPilot = object.isInZoomPilot ?? false;
    message.sellYourWayProductPrioritization = object.sellYourWayProductPrioritization?.map((e) => e) || [];
    message.id = object.id ?? "";
    message.sellerLeadUuid = object.sellerLeadUuid ?? "";
    message.latestOfferId = object.latestOfferId ?? "";
    message.latestRefreshRequestedAt = object.latestRefreshRequestedAt ?? undefined;
    message.token = object.token ?? "";
    message.isListed = object.isListed ?? false;
    message.sellerInputUuid = object.sellerInputUuid ?? "";
    message.latestVirtualAssessmentId = object.latestVirtualAssessmentId ?? "";
    message.previousOfferId = object.previousOfferId ?? "";
    message.lastOfferReviewDetails =
      (object.lastOfferReviewDetails !== undefined && object.lastOfferReviewDetails !== null)
        ? OfferReviewDetails.fromPartial(object.lastOfferReviewDetails)
        : undefined;
    message.experiencePartner = (object.experiencePartner !== undefined && object.experiencePartner !== null)
      ? ExperiencePartner.fromPartial(object.experiencePartner)
      : undefined;
    message.customerEventNotes = object.customerEventNotes?.map((e) => CustomerEventNote.fromPartial(e)) || [];
    message.isAgentExperience = object.isAgentExperience ?? false;
    message.addressId = object.addressId ?? "";
    message.customerUuid = object.customerUuid ?? "";
    message.email = object.email ?? "";
    message.isQualified = object.isQualified ?? false;
    message.qualifiedAt = object.qualifiedAt ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.latestSelfServeVirtualAssessmentId = object.latestSelfServeVirtualAssessmentId ?? "";
    message.latestOfferReviewVirtualAssessmentId = object.latestOfferReviewVirtualAssessmentId ?? "";
    message.denialReason = object.denialReason ?? 0;
    message.homeConditionResponse =
      (object.homeConditionResponse !== undefined && object.homeConditionResponse !== null)
        ? HomeConditionResponse.fromPartial(object.homeConditionResponse)
        : undefined;
    message.channel = object.channel ?? 0;
    message.customerIds = object.customerIds?.map((e) => e) || [];
    message.isEligibleForVirtualEa = object.isEligibleForVirtualEa ?? false;
    message.inExclusiveOffers = object.inExclusiveOffers ?? false;
    message.sellerSsvaUploadPage = object.sellerSsvaUploadPage ?? "";
    message.mortgagePayoffAmountCents = object.mortgagePayoffAmountCents ?? undefined;
    message.denialNotes = object.denialNotes ?? "";
    message.hubPrimaryCtaPaintedDoorExperimentVariant = object.hubPrimaryCtaPaintedDoorExperimentVariant ?? "";
    message.hubPrimaryCtaCustomerSuccessExperimentVariant = object.hubPrimaryCtaCustomerSuccessExperimentVariant ?? "";
    return message;
  },
};

function createBasePrivateLead(): PrivateLead {
  return { lead: undefined, statusNote: "", displayCategoryForOpsAcquisition: "" };
}

export const PrivateLead = {
  fromJSON(object: any): PrivateLead {
    return {
      lead: isSet(object.lead) ? Lead.fromJSON(object.lead) : undefined,
      statusNote: isSet(object.statusNote) ? String(object.statusNote) : "",
      displayCategoryForOpsAcquisition: isSet(object.displayCategoryForOpsAcquisition)
        ? String(object.displayCategoryForOpsAcquisition)
        : "",
    };
  },

  toJSON(message: PrivateLead): unknown {
    const obj: any = {};
    if (message.lead !== undefined) {
      obj.lead = Lead.toJSON(message.lead);
    }
    if (message.statusNote !== "") {
      obj.statusNote = message.statusNote;
    }
    if (message.displayCategoryForOpsAcquisition !== "") {
      obj.displayCategoryForOpsAcquisition = message.displayCategoryForOpsAcquisition;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PrivateLead>, I>>(base?: I): PrivateLead {
    return PrivateLead.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PrivateLead>, I>>(object: I): PrivateLead {
    const message = createBasePrivateLead();
    message.lead = (object.lead !== undefined && object.lead !== null) ? Lead.fromPartial(object.lead) : undefined;
    message.statusNote = object.statusNote ?? "";
    message.displayCategoryForOpsAcquisition = object.displayCategoryForOpsAcquisition ?? "";
    return message;
  },
};

function createBaseOfferReviewDetails(): OfferReviewDetails {
  return { id: "", offerReviewOutcome: "", offerReviewAt: undefined, calendlyData: undefined };
}

export const OfferReviewDetails = {
  fromJSON(object: any): OfferReviewDetails {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      offerReviewOutcome: isSet(object.offerReviewOutcome) ? String(object.offerReviewOutcome) : "",
      offerReviewAt: isSet(object.offerReviewAt) ? fromJsonTimestamp(object.offerReviewAt) : undefined,
      calendlyData: isSet(object.calendlyData) ? CalendlyData.fromJSON(object.calendlyData) : undefined,
    };
  },

  toJSON(message: OfferReviewDetails): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.offerReviewOutcome !== "") {
      obj.offerReviewOutcome = message.offerReviewOutcome;
    }
    if (message.offerReviewAt !== undefined) {
      obj.offerReviewAt = message.offerReviewAt.toISOString();
    }
    if (message.calendlyData !== undefined) {
      obj.calendlyData = CalendlyData.toJSON(message.calendlyData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OfferReviewDetails>, I>>(base?: I): OfferReviewDetails {
    return OfferReviewDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OfferReviewDetails>, I>>(object: I): OfferReviewDetails {
    const message = createBaseOfferReviewDetails();
    message.id = object.id ?? "";
    message.offerReviewOutcome = object.offerReviewOutcome ?? "";
    message.offerReviewAt = object.offerReviewAt ?? undefined;
    message.calendlyData = (object.calendlyData !== undefined && object.calendlyData !== null)
      ? CalendlyData.fromPartial(object.calendlyData)
      : undefined;
    return message;
  },
};

function createBaseCalendlyData(): CalendlyData {
  return { id: "", calendar: "", source: "" };
}

export const CalendlyData = {
  fromJSON(object: any): CalendlyData {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      calendar: isSet(object.calendar) ? String(object.calendar) : "",
      source: isSet(object.source) ? String(object.source) : "",
    };
  },

  toJSON(message: CalendlyData): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.calendar !== "") {
      obj.calendar = message.calendar;
    }
    if (message.source !== "") {
      obj.source = message.source;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CalendlyData>, I>>(base?: I): CalendlyData {
    return CalendlyData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CalendlyData>, I>>(object: I): CalendlyData {
    const message = createBaseCalendlyData();
    message.id = object.id ?? "";
    message.calendar = object.calendar ?? "";
    message.source = object.source ?? "";
    return message;
  },
};

function createBaseExperiencePartner(): ExperiencePartner {
  return { id: "", name: "", email: "", displayPhoneNumber: "", photoUrl: "", brokerageLicenses: [], humanToken: "" };
}

export const ExperiencePartner = {
  fromJSON(object: any): ExperiencePartner {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      email: isSet(object.email) ? String(object.email) : "",
      displayPhoneNumber: isSet(object.displayPhoneNumber) ? String(object.displayPhoneNumber) : "",
      photoUrl: isSet(object.photoUrl) ? String(object.photoUrl) : "",
      brokerageLicenses: Array.isArray(object?.brokerageLicenses)
        ? object.brokerageLicenses.map((e: any) => BrokerageLicense.fromJSON(e))
        : [],
      humanToken: isSet(object.humanToken) ? String(object.humanToken) : "",
    };
  },

  toJSON(message: ExperiencePartner): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.displayPhoneNumber !== "") {
      obj.displayPhoneNumber = message.displayPhoneNumber;
    }
    if (message.photoUrl !== "") {
      obj.photoUrl = message.photoUrl;
    }
    if (message.brokerageLicenses?.length) {
      obj.brokerageLicenses = message.brokerageLicenses.map((e) => BrokerageLicense.toJSON(e));
    }
    if (message.humanToken !== "") {
      obj.humanToken = message.humanToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExperiencePartner>, I>>(base?: I): ExperiencePartner {
    return ExperiencePartner.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExperiencePartner>, I>>(object: I): ExperiencePartner {
    const message = createBaseExperiencePartner();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.displayPhoneNumber = object.displayPhoneNumber ?? "";
    message.photoUrl = object.photoUrl ?? "";
    message.brokerageLicenses = object.brokerageLicenses?.map((e) => BrokerageLicense.fromPartial(e)) || [];
    message.humanToken = object.humanToken ?? "";
    return message;
  },
};

function createBaseBrokerageLicense(): BrokerageLicense {
  return { id: "", geographicState: "", state: 0, licenseNumber: "" };
}

export const BrokerageLicense = {
  fromJSON(object: any): BrokerageLicense {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      geographicState: isSet(object.geographicState) ? String(object.geographicState) : "",
      state: isSet(object.state) ? stateFromJSON(object.state) : 0,
      licenseNumber: isSet(object.licenseNumber) ? String(object.licenseNumber) : "",
    };
  },

  toJSON(message: BrokerageLicense): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.geographicState !== "") {
      obj.geographicState = message.geographicState;
    }
    if (message.state !== 0) {
      obj.state = stateToJSON(message.state);
    }
    if (message.licenseNumber !== "") {
      obj.licenseNumber = message.licenseNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BrokerageLicense>, I>>(base?: I): BrokerageLicense {
    return BrokerageLicense.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BrokerageLicense>, I>>(object: I): BrokerageLicense {
    const message = createBaseBrokerageLicense();
    message.id = object.id ?? "";
    message.geographicState = object.geographicState ?? "";
    message.state = object.state ?? 0;
    message.licenseNumber = object.licenseNumber ?? "";
    return message;
  },
};

function createBaseLeadIdentifier(): LeadIdentifier {
  return { lead: undefined };
}

export const LeadIdentifier = {
  fromJSON(object: any): LeadIdentifier {
    return {
      lead: isSet(object.source)
        ? { $case: "source", source: LeadSource.fromJSON(object.source) }
        : isSet(object.params)
        ? { $case: "params", params: LeadQueryParams.fromJSON(object.params) }
        : isSet(object.token)
        ? { $case: "token", token: String(object.token) }
        : isSet(object.sellerLeadUuid)
        ? { $case: "sellerLeadUuid", sellerLeadUuid: String(object.sellerLeadUuid) }
        : undefined,
    };
  },

  toJSON(message: LeadIdentifier): unknown {
    const obj: any = {};
    if (message.lead?.$case === "source") {
      obj.source = LeadSource.toJSON(message.lead.source);
    }
    if (message.lead?.$case === "params") {
      obj.params = LeadQueryParams.toJSON(message.lead.params);
    }
    if (message.lead?.$case === "token") {
      obj.token = message.lead.token;
    }
    if (message.lead?.$case === "sellerLeadUuid") {
      obj.sellerLeadUuid = message.lead.sellerLeadUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LeadIdentifier>, I>>(base?: I): LeadIdentifier {
    return LeadIdentifier.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LeadIdentifier>, I>>(object: I): LeadIdentifier {
    const message = createBaseLeadIdentifier();
    if (object.lead?.$case === "source" && object.lead?.source !== undefined && object.lead?.source !== null) {
      message.lead = { $case: "source", source: LeadSource.fromPartial(object.lead.source) };
    }
    if (object.lead?.$case === "params" && object.lead?.params !== undefined && object.lead?.params !== null) {
      message.lead = { $case: "params", params: LeadQueryParams.fromPartial(object.lead.params) };
    }
    if (object.lead?.$case === "token" && object.lead?.token !== undefined && object.lead?.token !== null) {
      message.lead = { $case: "token", token: object.lead.token };
    }
    if (
      object.lead?.$case === "sellerLeadUuid" &&
      object.lead?.sellerLeadUuid !== undefined &&
      object.lead?.sellerLeadUuid !== null
    ) {
      message.lead = { $case: "sellerLeadUuid", sellerLeadUuid: object.lead.sellerLeadUuid };
    }
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
