/* eslint-disable */
import { Timestamp } from "../../../google/protobuf/timestamp";
import { DateMessage } from "../../common/date";
import {
  OfferRequest_Source,
  offerRequest_SourceFromJSON,
  offerRequest_SourceToJSON,
} from "../../common/sell/offer_request";
import { Comp } from "./comp";
import { Subsidy } from "./contract_change";
import { LateCheckout } from "./late_checkout";
import { ExperiencePartner } from "./lead";
import { TitleContact, TitleOrganization } from "./title_contact";

export const protobufPackage = "od_protos.web.data";

/** Next ID: 5 */
export enum ExperimentsEnum {
  UNKNOWN_EXPERIMENT = 0,
  GUARANTEE_AT_PRELIM_OFFER = 1,
  NO_VIRTUAL_ASSESSMENT = 2,
  SELLER_LOW_OFFER_CONFIDENCE = 3,
  LWC_WITH_ALA = 4,
  UNRECOGNIZED = -1,
}

export function experimentsEnumFromJSON(object: any): ExperimentsEnum {
  switch (object) {
    case 0:
    case "UNKNOWN_EXPERIMENT":
      return ExperimentsEnum.UNKNOWN_EXPERIMENT;
    case 1:
    case "GUARANTEE_AT_PRELIM_OFFER":
      return ExperimentsEnum.GUARANTEE_AT_PRELIM_OFFER;
    case 2:
    case "NO_VIRTUAL_ASSESSMENT":
      return ExperimentsEnum.NO_VIRTUAL_ASSESSMENT;
    case 3:
    case "SELLER_LOW_OFFER_CONFIDENCE":
      return ExperimentsEnum.SELLER_LOW_OFFER_CONFIDENCE;
    case 4:
    case "LWC_WITH_ALA":
      return ExperimentsEnum.LWC_WITH_ALA;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ExperimentsEnum.UNRECOGNIZED;
  }
}

export function experimentsEnumToJSON(object: ExperimentsEnum): string {
  switch (object) {
    case ExperimentsEnum.UNKNOWN_EXPERIMENT:
      return "UNKNOWN_EXPERIMENT";
    case ExperimentsEnum.GUARANTEE_AT_PRELIM_OFFER:
      return "GUARANTEE_AT_PRELIM_OFFER";
    case ExperimentsEnum.NO_VIRTUAL_ASSESSMENT:
      return "NO_VIRTUAL_ASSESSMENT";
    case ExperimentsEnum.SELLER_LOW_OFFER_CONFIDENCE:
      return "SELLER_LOW_OFFER_CONFIDENCE";
    case ExperimentsEnum.LWC_WITH_ALA:
      return "LWC_WITH_ALA";
    case ExperimentsEnum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Next ID: 5 */
export enum OccupancyAtVa {
  UNKNOWN_OCCUPANCY = 0,
  OWNER_OCCUPIED = 1,
  TENANT_OCCUPIED = 2,
  LIVE_FAR_AWAY = 3,
  OTHER_OCCUPANCY = 4,
  UNRECOGNIZED = -1,
}

export function occupancyAtVaFromJSON(object: any): OccupancyAtVa {
  switch (object) {
    case 0:
    case "UNKNOWN_OCCUPANCY":
      return OccupancyAtVa.UNKNOWN_OCCUPANCY;
    case 1:
    case "OWNER_OCCUPIED":
      return OccupancyAtVa.OWNER_OCCUPIED;
    case 2:
    case "TENANT_OCCUPIED":
      return OccupancyAtVa.TENANT_OCCUPIED;
    case 3:
    case "LIVE_FAR_AWAY":
      return OccupancyAtVa.LIVE_FAR_AWAY;
    case 4:
    case "OTHER_OCCUPANCY":
      return OccupancyAtVa.OTHER_OCCUPANCY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OccupancyAtVa.UNRECOGNIZED;
  }
}

export function occupancyAtVaToJSON(object: OccupancyAtVa): string {
  switch (object) {
    case OccupancyAtVa.UNKNOWN_OCCUPANCY:
      return "UNKNOWN_OCCUPANCY";
    case OccupancyAtVa.OWNER_OCCUPIED:
      return "OWNER_OCCUPIED";
    case OccupancyAtVa.TENANT_OCCUPIED:
      return "TENANT_OCCUPIED";
    case OccupancyAtVa.LIVE_FAR_AWAY:
      return "LIVE_FAR_AWAY";
    case OccupancyAtVa.OTHER_OCCUPANCY:
      return "OTHER_OCCUPANCY";
    case OccupancyAtVa.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Next ID: 2 */
export enum FeatureFlagEnum {
  UNKNOWN_FEATURE_FLAG = 0,
  UNEXPIRE_OFFER_WITH_VALID_UNDERWRITING = 1,
  UNRECOGNIZED = -1,
}

export function featureFlagEnumFromJSON(object: any): FeatureFlagEnum {
  switch (object) {
    case 0:
    case "UNKNOWN_FEATURE_FLAG":
      return FeatureFlagEnum.UNKNOWN_FEATURE_FLAG;
    case 1:
    case "UNEXPIRE_OFFER_WITH_VALID_UNDERWRITING":
      return FeatureFlagEnum.UNEXPIRE_OFFER_WITH_VALID_UNDERWRITING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FeatureFlagEnum.UNRECOGNIZED;
  }
}

export function featureFlagEnumToJSON(object: FeatureFlagEnum): string {
  switch (object) {
    case FeatureFlagEnum.UNKNOWN_FEATURE_FLAG:
      return "UNKNOWN_FEATURE_FLAG";
    case FeatureFlagEnum.UNEXPIRE_OFFER_WITH_VALID_UNDERWRITING:
      return "UNEXPIRE_OFFER_WITH_VALID_UNDERWRITING";
    case FeatureFlagEnum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Next ID: 5 */
export enum Reaction {
  VERY_UNSATISFIED = 0,
  UNSATISFIED = 1,
  OKAY = 2,
  SATISFIED = 3,
  VERY_SATISFIED = 4,
  UNRECOGNIZED = -1,
}

export function reactionFromJSON(object: any): Reaction {
  switch (object) {
    case 0:
    case "VERY_UNSATISFIED":
      return Reaction.VERY_UNSATISFIED;
    case 1:
    case "UNSATISFIED":
      return Reaction.UNSATISFIED;
    case 2:
    case "OKAY":
      return Reaction.OKAY;
    case 3:
    case "SATISFIED":
      return Reaction.SATISFIED;
    case 4:
    case "VERY_SATISFIED":
      return Reaction.VERY_SATISFIED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Reaction.UNRECOGNIZED;
  }
}

export function reactionToJSON(object: Reaction): string {
  switch (object) {
    case Reaction.VERY_UNSATISFIED:
      return "VERY_UNSATISFIED";
    case Reaction.UNSATISFIED:
      return "UNSATISFIED";
    case Reaction.OKAY:
      return "OKAY";
    case Reaction.SATISFIED:
      return "SATISFIED";
    case Reaction.VERY_SATISFIED:
      return "VERY_SATISFIED";
    case Reaction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SpecialFlagType {
  DEFAULT = 0,
  INFO = 1,
  SUCCESS = 2,
  WARNING = 3,
  DANGER = 4,
  UNRECOGNIZED = -1,
}

export function specialFlagTypeFromJSON(object: any): SpecialFlagType {
  switch (object) {
    case 0:
    case "DEFAULT":
      return SpecialFlagType.DEFAULT;
    case 1:
    case "INFO":
      return SpecialFlagType.INFO;
    case 2:
    case "SUCCESS":
      return SpecialFlagType.SUCCESS;
    case 3:
    case "WARNING":
      return SpecialFlagType.WARNING;
    case 4:
    case "DANGER":
      return SpecialFlagType.DANGER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SpecialFlagType.UNRECOGNIZED;
  }
}

export function specialFlagTypeToJSON(object: SpecialFlagType): string {
  switch (object) {
    case SpecialFlagType.DEFAULT:
      return "DEFAULT";
    case SpecialFlagType.INFO:
      return "INFO";
    case SpecialFlagType.SUCCESS:
      return "SUCCESS";
    case SpecialFlagType.WARNING:
      return "WARNING";
    case SpecialFlagType.DANGER:
      return "DANGER";
    case SpecialFlagType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum LowOfferConfidenceVariant {
  UNKNOWN_VARIANT = 0,
  INELIGIBLE = 1,
  CONTROL = 2,
  TREATMENT = 3,
  UNRECOGNIZED = -1,
}

export function lowOfferConfidenceVariantFromJSON(object: any): LowOfferConfidenceVariant {
  switch (object) {
    case 0:
    case "UNKNOWN_VARIANT":
      return LowOfferConfidenceVariant.UNKNOWN_VARIANT;
    case 1:
    case "INELIGIBLE":
      return LowOfferConfidenceVariant.INELIGIBLE;
    case 2:
    case "CONTROL":
      return LowOfferConfidenceVariant.CONTROL;
    case 3:
    case "TREATMENT":
      return LowOfferConfidenceVariant.TREATMENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LowOfferConfidenceVariant.UNRECOGNIZED;
  }
}

export function lowOfferConfidenceVariantToJSON(object: LowOfferConfidenceVariant): string {
  switch (object) {
    case LowOfferConfidenceVariant.UNKNOWN_VARIANT:
      return "UNKNOWN_VARIANT";
    case LowOfferConfidenceVariant.INELIGIBLE:
      return "INELIGIBLE";
    case LowOfferConfidenceVariant.CONTROL:
      return "CONTROL";
    case LowOfferConfidenceVariant.TREATMENT:
      return "TREATMENT";
    case LowOfferConfidenceVariant.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Data specific to a sell direct offer from Web
 * Next ID: 73
 */
export interface Offer {
  id: string;
  state: Offer_State;
  /** An offer will be accepted once underwriting is completed which is what this field indicates */
  acceptedAt?: Date | undefined;
  buyerExpiresAt?: Date | undefined;
  closedDate?:
    | DateMessage
    | undefined;
  /** Deprecated, use closed_date */
  closedAt?: Date | undefined;
  contingenciesReleasedDate?:
    | DateMessage
    | undefined;
  /** Deprecated, use contingencies_released_date */
  contingenciesReleasedAt?: Date | undefined;
  enteredContractAt?: Date | undefined;
  expiredAt?: Date | undefined;
  sentAt?: Date | undefined;
  viewedAt?: Date | undefined;
  withdrawnAt?: Date | undefined;
  coBrokePercentage?: number | undefined;
  headlinePriceCents: number;
  netProceedsCents: number;
  closingCostsCents: number;
  closingCostsPercentage?: number | undefined;
  serviceChargeCents: number;
  serviceChargePercentage?: number | undefined;
  sellerVisibleValuationRangeStartCents: number;
  sellerVisibleValuationRangeEndCents: number;
  internalFeeCents: number;
  initialInspectionAt?: Date | undefined;
  comps: Comp[];
  underwritingId: string;
  isFirstOffer: boolean;
  coeDate?:
    | DateMessage
    | undefined;
  /** Deprecated, use coe_date */
  closeEscrowDate?: Date | undefined;
  enabledExperiments: Experiment[];
  maxRepairCostsEstimateCents: number;
  canChangeCloseEscrowDate: boolean;
  coeDateChangeDeadline?:
    | DateMessage
    | undefined;
  /** Deprecated, use coe_date_change_deadline */
  closeEscrowDateChangeDeadline?: Date | undefined;
  hasPostInspectionExperience: boolean;
  purchaseAgreementExpiresAt?: Date | undefined;
  token: string;
  purchaseAgreementCompletedAt?: Date | undefined;
  createdAt?:
    | Date
    | undefined;
  /** Link to seller offer dashboard */
  offerDashboardUrl: string;
  /** Experience partner details */
  experiencePartner?: ExperiencePartner | undefined;
  customerExperiencePartner?: ExperiencePartner | undefined;
  interestedInTradeIn: boolean;
  tradeInSavingsCents: number;
  preliminaryOfferExpirationDate?: Date | undefined;
  enabledFeatureFlags: FeatureFlag[];
  /** Used by partnerships for bulk seller endpoint */
  numericId: number;
  leadToken: string;
  /** The occupancy when the user was in the va stage */
  occupancyAtVa: OccupancyAtVa;
  /** User's reaction to the offer price. */
  offerReactions: OfferReaction[];
  addressId: string;
  /** When a user actually viewed their finalized offer */
  finalOfferViewedAt?: Date | undefined;
  offerReviewAt?: Date | undefined;
  lateCheckoutDate?: DateMessage | undefined;
  titleContact?: TitleContact | undefined;
  titleOrg?:
    | TitleOrganization
    | undefined;
  /** Signatories on the contract */
  signatories: Signatory[];
  offerRequestSource: OfferRequest_Source;
  lateCheckout?: LateCheckout | undefined;
  lateCheckoutSecurityDepositCents: number;
  /** Upper bound of estimated home value if listed on the MLS */
  listingEstimateCeilingCents: number;
  /** Lower bound of estimated home value if listed on the MLS */
  listingEstimateFloorCents: number;
  expirationInfo?:
    | ExpirationInfo
    | undefined;
  /** Internal access link to seller offer dashboard */
  offerDashboardInternalUrl: string;
  isLatestOffer: boolean;
  /** Available COE Dates to choose from */
  coeAvailableDatesInternal: DateMessage[];
  /** The credits that have been granted to the seller */
  sellerCredits: Subsidy[];
  /** Our estimated listing price point for a home if listed on the MLS */
  listingPriceEstimateCents: number;
  /** Estimate level for how far off the mean offer value is likely to be from the truth */
  offerConfidenceLevel: string;
  numberOfOffers: number;
  /** Link to schedule home assessment dashboard */
  scheduleHomeAssessmentUrl: string;
  arvCents: number;
}

/** Next ID: 16 */
export enum Offer_State {
  /** UNKNOWN - States all defined in web/app/models/offer.rb */
  UNKNOWN = 0,
  DRAFT = 1,
  READY_TO_SEND = 2,
  SEND_IN_PROGRESS = 3,
  SENT = 4,
  VIEWING = 5,
  ACCEPTED_OFFER = 6,
  ACCEPTED = 7,
  IN_CONTRACT = 8,
  CONTINGENCY_RELEASED = 9,
  CLOSED = 10,
  WITHDRAWN = 11,
  EXPIRED = 12,
  REQUESTED_TO_LIST = 13,
  /** WAITING_FOR_OTHER_OFFERS - deprecated */
  WAITING_FOR_OTHER_OFFERS = 14,
  /**
   * WAITING_FOR_LIST_WITH_CERTAINTY - Holding offer for list with certainty pilot
   * TODO: Deprecate in favor of FINAL_OFFER_UNRELEASED
   */
  WAITING_FOR_LIST_WITH_CERTAINTY = 15,
  /** FINAL_OFFER_UNRELEASED - final offer is ready, but not yet released to the seller */
  FINAL_OFFER_UNRELEASED = 16,
  UNRECOGNIZED = -1,
}

export function offer_StateFromJSON(object: any): Offer_State {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return Offer_State.UNKNOWN;
    case 1:
    case "DRAFT":
      return Offer_State.DRAFT;
    case 2:
    case "READY_TO_SEND":
      return Offer_State.READY_TO_SEND;
    case 3:
    case "SEND_IN_PROGRESS":
      return Offer_State.SEND_IN_PROGRESS;
    case 4:
    case "SENT":
      return Offer_State.SENT;
    case 5:
    case "VIEWING":
      return Offer_State.VIEWING;
    case 6:
    case "ACCEPTED_OFFER":
      return Offer_State.ACCEPTED_OFFER;
    case 7:
    case "ACCEPTED":
      return Offer_State.ACCEPTED;
    case 8:
    case "IN_CONTRACT":
      return Offer_State.IN_CONTRACT;
    case 9:
    case "CONTINGENCY_RELEASED":
      return Offer_State.CONTINGENCY_RELEASED;
    case 10:
    case "CLOSED":
      return Offer_State.CLOSED;
    case 11:
    case "WITHDRAWN":
      return Offer_State.WITHDRAWN;
    case 12:
    case "EXPIRED":
      return Offer_State.EXPIRED;
    case 13:
    case "REQUESTED_TO_LIST":
      return Offer_State.REQUESTED_TO_LIST;
    case 14:
    case "WAITING_FOR_OTHER_OFFERS":
      return Offer_State.WAITING_FOR_OTHER_OFFERS;
    case 15:
    case "WAITING_FOR_LIST_WITH_CERTAINTY":
      return Offer_State.WAITING_FOR_LIST_WITH_CERTAINTY;
    case 16:
    case "FINAL_OFFER_UNRELEASED":
      return Offer_State.FINAL_OFFER_UNRELEASED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Offer_State.UNRECOGNIZED;
  }
}

export function offer_StateToJSON(object: Offer_State): string {
  switch (object) {
    case Offer_State.UNKNOWN:
      return "UNKNOWN";
    case Offer_State.DRAFT:
      return "DRAFT";
    case Offer_State.READY_TO_SEND:
      return "READY_TO_SEND";
    case Offer_State.SEND_IN_PROGRESS:
      return "SEND_IN_PROGRESS";
    case Offer_State.SENT:
      return "SENT";
    case Offer_State.VIEWING:
      return "VIEWING";
    case Offer_State.ACCEPTED_OFFER:
      return "ACCEPTED_OFFER";
    case Offer_State.ACCEPTED:
      return "ACCEPTED";
    case Offer_State.IN_CONTRACT:
      return "IN_CONTRACT";
    case Offer_State.CONTINGENCY_RELEASED:
      return "CONTINGENCY_RELEASED";
    case Offer_State.CLOSED:
      return "CLOSED";
    case Offer_State.WITHDRAWN:
      return "WITHDRAWN";
    case Offer_State.EXPIRED:
      return "EXPIRED";
    case Offer_State.REQUESTED_TO_LIST:
      return "REQUESTED_TO_LIST";
    case Offer_State.WAITING_FOR_OTHER_OFFERS:
      return "WAITING_FOR_OTHER_OFFERS";
    case Offer_State.WAITING_FOR_LIST_WITH_CERTAINTY:
      return "WAITING_FOR_LIST_WITH_CERTAINTY";
    case Offer_State.FINAL_OFFER_UNRELEASED:
      return "FINAL_OFFER_UNRELEASED";
    case Offer_State.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ExpirationInfo {
  date?: Date | undefined;
  nextStep: string;
}

/** Next ID: 2 */
export interface Experiment {
  experimentEnum: ExperimentsEnum;
}

/** Next ID: 2 */
export interface FeatureFlag {
  featureFlag: FeatureFlagEnum;
}

/** Next ID: 2 */
export interface OfferIdentifiers {
  identifiers: string[];
}

/**
 * OfferQueryParams represents parameters to query offers by (e.g. address token and customer uuid).
 * Next ID: 3
 */
export interface OfferQueryParams {
  addressToken: string;
  customerUuid: string;
  /**
   * Using the human token will only retrieve a limited amount of offers (20) for performance reasons
   * You probably want to use a combination of Lead fetching/Offer fetching in order to grab every single offer for a given human
   */
  humanToken: string;
}

/**
 * UserReactionToPrice represents user's reaction to the offer price.
 * Next ID: 4
 */
export interface OfferReaction {
  reaction: Reaction;
  humanToken: string;
  textResponse: string;
}

/**
 * Signatory represents a signatory on the contract
 * Next ID: 2
 */
export interface Signatory {
  name: string;
}

export interface PrivateOfferData {
  specialFlags: SpecialFlag[];
  internalFeePercentage?: number | undefined;
}

export interface SpecialFlag {
  text: string;
  type: SpecialFlagType;
}

export interface OfferDataForIterable {
  sellerLeadUuid: string;
  offerRequestSource: OfferRequest_Source;
  valuationCents: number;
}

function createBaseOffer(): Offer {
  return {
    id: "",
    state: 0,
    acceptedAt: undefined,
    buyerExpiresAt: undefined,
    closedDate: undefined,
    closedAt: undefined,
    contingenciesReleasedDate: undefined,
    contingenciesReleasedAt: undefined,
    enteredContractAt: undefined,
    expiredAt: undefined,
    sentAt: undefined,
    viewedAt: undefined,
    withdrawnAt: undefined,
    coBrokePercentage: undefined,
    headlinePriceCents: 0,
    netProceedsCents: 0,
    closingCostsCents: 0,
    closingCostsPercentage: undefined,
    serviceChargeCents: 0,
    serviceChargePercentage: undefined,
    sellerVisibleValuationRangeStartCents: 0,
    sellerVisibleValuationRangeEndCents: 0,
    internalFeeCents: 0,
    initialInspectionAt: undefined,
    comps: [],
    underwritingId: "",
    isFirstOffer: false,
    coeDate: undefined,
    closeEscrowDate: undefined,
    enabledExperiments: [],
    maxRepairCostsEstimateCents: 0,
    canChangeCloseEscrowDate: false,
    coeDateChangeDeadline: undefined,
    closeEscrowDateChangeDeadline: undefined,
    hasPostInspectionExperience: false,
    purchaseAgreementExpiresAt: undefined,
    token: "",
    purchaseAgreementCompletedAt: undefined,
    createdAt: undefined,
    offerDashboardUrl: "",
    experiencePartner: undefined,
    customerExperiencePartner: undefined,
    interestedInTradeIn: false,
    tradeInSavingsCents: 0,
    preliminaryOfferExpirationDate: undefined,
    enabledFeatureFlags: [],
    numericId: 0,
    leadToken: "",
    occupancyAtVa: 0,
    offerReactions: [],
    addressId: "",
    finalOfferViewedAt: undefined,
    offerReviewAt: undefined,
    lateCheckoutDate: undefined,
    titleContact: undefined,
    titleOrg: undefined,
    signatories: [],
    offerRequestSource: 0,
    lateCheckout: undefined,
    lateCheckoutSecurityDepositCents: 0,
    listingEstimateCeilingCents: 0,
    listingEstimateFloorCents: 0,
    expirationInfo: undefined,
    offerDashboardInternalUrl: "",
    isLatestOffer: false,
    coeAvailableDatesInternal: [],
    sellerCredits: [],
    listingPriceEstimateCents: 0,
    offerConfidenceLevel: "",
    numberOfOffers: 0,
    scheduleHomeAssessmentUrl: "",
    arvCents: 0,
  };
}

export const Offer = {
  fromJSON(object: any): Offer {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      state: isSet(object.state) ? offer_StateFromJSON(object.state) : 0,
      acceptedAt: isSet(object.acceptedAt) ? fromJsonTimestamp(object.acceptedAt) : undefined,
      buyerExpiresAt: isSet(object.buyerExpiresAt) ? fromJsonTimestamp(object.buyerExpiresAt) : undefined,
      closedDate: isSet(object.closedDate) ? DateMessage.fromJSON(object.closedDate) : undefined,
      closedAt: isSet(object.closedAt) ? fromJsonTimestamp(object.closedAt) : undefined,
      contingenciesReleasedDate: isSet(object.contingenciesReleasedDate)
        ? DateMessage.fromJSON(object.contingenciesReleasedDate)
        : undefined,
      contingenciesReleasedAt: isSet(object.contingenciesReleasedAt)
        ? fromJsonTimestamp(object.contingenciesReleasedAt)
        : undefined,
      enteredContractAt: isSet(object.enteredContractAt) ? fromJsonTimestamp(object.enteredContractAt) : undefined,
      expiredAt: isSet(object.expiredAt) ? fromJsonTimestamp(object.expiredAt) : undefined,
      sentAt: isSet(object.sentAt) ? fromJsonTimestamp(object.sentAt) : undefined,
      viewedAt: isSet(object.viewedAt) ? fromJsonTimestamp(object.viewedAt) : undefined,
      withdrawnAt: isSet(object.withdrawnAt) ? fromJsonTimestamp(object.withdrawnAt) : undefined,
      coBrokePercentage: isSet(object.coBrokePercentage) ? Number(object.coBrokePercentage) : undefined,
      headlinePriceCents: isSet(object.headlinePriceCents) ? Number(object.headlinePriceCents) : 0,
      netProceedsCents: isSet(object.netProceedsCents) ? Number(object.netProceedsCents) : 0,
      closingCostsCents: isSet(object.closingCostsCents) ? Number(object.closingCostsCents) : 0,
      closingCostsPercentage: isSet(object.closingCostsPercentage) ? Number(object.closingCostsPercentage) : undefined,
      serviceChargeCents: isSet(object.serviceChargeCents) ? Number(object.serviceChargeCents) : 0,
      serviceChargePercentage: isSet(object.serviceChargePercentage)
        ? Number(object.serviceChargePercentage)
        : undefined,
      sellerVisibleValuationRangeStartCents: isSet(object.sellerVisibleValuationRangeStartCents)
        ? Number(object.sellerVisibleValuationRangeStartCents)
        : 0,
      sellerVisibleValuationRangeEndCents: isSet(object.sellerVisibleValuationRangeEndCents)
        ? Number(object.sellerVisibleValuationRangeEndCents)
        : 0,
      internalFeeCents: isSet(object.internalFeeCents) ? Number(object.internalFeeCents) : 0,
      initialInspectionAt: isSet(object.initialInspectionAt)
        ? fromJsonTimestamp(object.initialInspectionAt)
        : undefined,
      comps: Array.isArray(object?.comps)
        ? object.comps.map((e: any) => Comp.fromJSON(e))
        : [],
      underwritingId: isSet(object.underwritingId) ? String(object.underwritingId) : "",
      isFirstOffer: isSet(object.isFirstOffer) ? Boolean(object.isFirstOffer) : false,
      coeDate: isSet(object.coeDate) ? DateMessage.fromJSON(object.coeDate) : undefined,
      closeEscrowDate: isSet(object.closeEscrowDate) ? fromJsonTimestamp(object.closeEscrowDate) : undefined,
      enabledExperiments: Array.isArray(object?.enabledExperiments)
        ? object.enabledExperiments.map((e: any) => Experiment.fromJSON(e))
        : [],
      maxRepairCostsEstimateCents: isSet(object.maxRepairCostsEstimateCents)
        ? Number(object.maxRepairCostsEstimateCents)
        : 0,
      canChangeCloseEscrowDate: isSet(object.canChangeCloseEscrowDate)
        ? Boolean(object.canChangeCloseEscrowDate)
        : false,
      coeDateChangeDeadline: isSet(object.coeDateChangeDeadline)
        ? DateMessage.fromJSON(object.coeDateChangeDeadline)
        : undefined,
      closeEscrowDateChangeDeadline: isSet(object.closeEscrowDateChangeDeadline)
        ? fromJsonTimestamp(object.closeEscrowDateChangeDeadline)
        : undefined,
      hasPostInspectionExperience: isSet(object.hasPostInspectionExperience)
        ? Boolean(object.hasPostInspectionExperience)
        : false,
      purchaseAgreementExpiresAt: isSet(object.purchaseAgreementExpiresAt)
        ? fromJsonTimestamp(object.purchaseAgreementExpiresAt)
        : undefined,
      token: isSet(object.token) ? String(object.token) : "",
      purchaseAgreementCompletedAt: isSet(object.purchaseAgreementCompletedAt)
        ? fromJsonTimestamp(object.purchaseAgreementCompletedAt)
        : undefined,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      offerDashboardUrl: isSet(object.offerDashboardUrl) ? String(object.offerDashboardUrl) : "",
      experiencePartner: isSet(object.experiencePartner)
        ? ExperiencePartner.fromJSON(object.experiencePartner)
        : undefined,
      customerExperiencePartner: isSet(object.customerExperiencePartner)
        ? ExperiencePartner.fromJSON(object.customerExperiencePartner)
        : undefined,
      interestedInTradeIn: isSet(object.interestedInTradeIn) ? Boolean(object.interestedInTradeIn) : false,
      tradeInSavingsCents: isSet(object.tradeInSavingsCents) ? Number(object.tradeInSavingsCents) : 0,
      preliminaryOfferExpirationDate: isSet(object.preliminaryOfferExpirationDate)
        ? fromJsonTimestamp(object.preliminaryOfferExpirationDate)
        : undefined,
      enabledFeatureFlags: Array.isArray(object?.enabledFeatureFlags)
        ? object.enabledFeatureFlags.map((e: any) => FeatureFlag.fromJSON(e))
        : [],
      numericId: isSet(object.numericId) ? Number(object.numericId) : 0,
      leadToken: isSet(object.leadToken) ? String(object.leadToken) : "",
      occupancyAtVa: isSet(object.occupancyAtVa) ? occupancyAtVaFromJSON(object.occupancyAtVa) : 0,
      offerReactions: Array.isArray(object?.offerReactions)
        ? object.offerReactions.map((e: any) => OfferReaction.fromJSON(e))
        : [],
      addressId: isSet(object.addressId) ? String(object.addressId) : "",
      finalOfferViewedAt: isSet(object.finalOfferViewedAt) ? fromJsonTimestamp(object.finalOfferViewedAt) : undefined,
      offerReviewAt: isSet(object.offerReviewAt) ? fromJsonTimestamp(object.offerReviewAt) : undefined,
      lateCheckoutDate: isSet(object.lateCheckoutDate) ? DateMessage.fromJSON(object.lateCheckoutDate) : undefined,
      titleContact: isSet(object.titleContact) ? TitleContact.fromJSON(object.titleContact) : undefined,
      titleOrg: isSet(object.titleOrg) ? TitleOrganization.fromJSON(object.titleOrg) : undefined,
      signatories: Array.isArray(object?.signatories)
        ? object.signatories.map((e: any) => Signatory.fromJSON(e))
        : [],
      offerRequestSource: isSet(object.offerRequestSource) ? offerRequest_SourceFromJSON(object.offerRequestSource) : 0,
      lateCheckout: isSet(object.lateCheckout) ? LateCheckout.fromJSON(object.lateCheckout) : undefined,
      lateCheckoutSecurityDepositCents: isSet(object.lateCheckoutSecurityDepositCents)
        ? Number(object.lateCheckoutSecurityDepositCents)
        : 0,
      listingEstimateCeilingCents: isSet(object.listingEstimateCeilingCents)
        ? Number(object.listingEstimateCeilingCents)
        : 0,
      listingEstimateFloorCents: isSet(object.listingEstimateFloorCents) ? Number(object.listingEstimateFloorCents) : 0,
      expirationInfo: isSet(object.expirationInfo) ? ExpirationInfo.fromJSON(object.expirationInfo) : undefined,
      offerDashboardInternalUrl: isSet(object.offerDashboardInternalUrl)
        ? String(object.offerDashboardInternalUrl)
        : "",
      isLatestOffer: isSet(object.isLatestOffer) ? Boolean(object.isLatestOffer) : false,
      coeAvailableDatesInternal: Array.isArray(object?.coeAvailableDatesInternal)
        ? object.coeAvailableDatesInternal.map((e: any) => DateMessage.fromJSON(e))
        : [],
      sellerCredits: Array.isArray(object?.sellerCredits)
        ? object.sellerCredits.map((e: any) => Subsidy.fromJSON(e))
        : [],
      listingPriceEstimateCents: isSet(object.listingPriceEstimateCents) ? Number(object.listingPriceEstimateCents) : 0,
      offerConfidenceLevel: isSet(object.offerConfidenceLevel) ? String(object.offerConfidenceLevel) : "",
      numberOfOffers: isSet(object.numberOfOffers) ? Number(object.numberOfOffers) : 0,
      scheduleHomeAssessmentUrl: isSet(object.scheduleHomeAssessmentUrl)
        ? String(object.scheduleHomeAssessmentUrl)
        : "",
      arvCents: isSet(object.arvCents) ? Number(object.arvCents) : 0,
    };
  },

  toJSON(message: Offer): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.state !== 0) {
      obj.state = offer_StateToJSON(message.state);
    }
    if (message.acceptedAt !== undefined) {
      obj.acceptedAt = message.acceptedAt.toISOString();
    }
    if (message.buyerExpiresAt !== undefined) {
      obj.buyerExpiresAt = message.buyerExpiresAt.toISOString();
    }
    if (message.closedDate !== undefined) {
      obj.closedDate = DateMessage.toJSON(message.closedDate);
    }
    if (message.closedAt !== undefined) {
      obj.closedAt = message.closedAt.toISOString();
    }
    if (message.contingenciesReleasedDate !== undefined) {
      obj.contingenciesReleasedDate = DateMessage.toJSON(message.contingenciesReleasedDate);
    }
    if (message.contingenciesReleasedAt !== undefined) {
      obj.contingenciesReleasedAt = message.contingenciesReleasedAt.toISOString();
    }
    if (message.enteredContractAt !== undefined) {
      obj.enteredContractAt = message.enteredContractAt.toISOString();
    }
    if (message.expiredAt !== undefined) {
      obj.expiredAt = message.expiredAt.toISOString();
    }
    if (message.sentAt !== undefined) {
      obj.sentAt = message.sentAt.toISOString();
    }
    if (message.viewedAt !== undefined) {
      obj.viewedAt = message.viewedAt.toISOString();
    }
    if (message.withdrawnAt !== undefined) {
      obj.withdrawnAt = message.withdrawnAt.toISOString();
    }
    if (message.coBrokePercentage !== undefined) {
      obj.coBrokePercentage = message.coBrokePercentage;
    }
    if (message.headlinePriceCents !== 0) {
      obj.headlinePriceCents = Math.round(message.headlinePriceCents);
    }
    if (message.netProceedsCents !== 0) {
      obj.netProceedsCents = Math.round(message.netProceedsCents);
    }
    if (message.closingCostsCents !== 0) {
      obj.closingCostsCents = Math.round(message.closingCostsCents);
    }
    if (message.closingCostsPercentage !== undefined) {
      obj.closingCostsPercentage = message.closingCostsPercentage;
    }
    if (message.serviceChargeCents !== 0) {
      obj.serviceChargeCents = Math.round(message.serviceChargeCents);
    }
    if (message.serviceChargePercentage !== undefined) {
      obj.serviceChargePercentage = message.serviceChargePercentage;
    }
    if (message.sellerVisibleValuationRangeStartCents !== 0) {
      obj.sellerVisibleValuationRangeStartCents = Math.round(message.sellerVisibleValuationRangeStartCents);
    }
    if (message.sellerVisibleValuationRangeEndCents !== 0) {
      obj.sellerVisibleValuationRangeEndCents = Math.round(message.sellerVisibleValuationRangeEndCents);
    }
    if (message.internalFeeCents !== 0) {
      obj.internalFeeCents = Math.round(message.internalFeeCents);
    }
    if (message.initialInspectionAt !== undefined) {
      obj.initialInspectionAt = message.initialInspectionAt.toISOString();
    }
    if (message.comps?.length) {
      obj.comps = message.comps.map((e) => Comp.toJSON(e));
    }
    if (message.underwritingId !== "") {
      obj.underwritingId = message.underwritingId;
    }
    if (message.isFirstOffer === true) {
      obj.isFirstOffer = message.isFirstOffer;
    }
    if (message.coeDate !== undefined) {
      obj.coeDate = DateMessage.toJSON(message.coeDate);
    }
    if (message.closeEscrowDate !== undefined) {
      obj.closeEscrowDate = message.closeEscrowDate.toISOString();
    }
    if (message.enabledExperiments?.length) {
      obj.enabledExperiments = message.enabledExperiments.map((e) => Experiment.toJSON(e));
    }
    if (message.maxRepairCostsEstimateCents !== 0) {
      obj.maxRepairCostsEstimateCents = Math.round(message.maxRepairCostsEstimateCents);
    }
    if (message.canChangeCloseEscrowDate === true) {
      obj.canChangeCloseEscrowDate = message.canChangeCloseEscrowDate;
    }
    if (message.coeDateChangeDeadline !== undefined) {
      obj.coeDateChangeDeadline = DateMessage.toJSON(message.coeDateChangeDeadline);
    }
    if (message.closeEscrowDateChangeDeadline !== undefined) {
      obj.closeEscrowDateChangeDeadline = message.closeEscrowDateChangeDeadline.toISOString();
    }
    if (message.hasPostInspectionExperience === true) {
      obj.hasPostInspectionExperience = message.hasPostInspectionExperience;
    }
    if (message.purchaseAgreementExpiresAt !== undefined) {
      obj.purchaseAgreementExpiresAt = message.purchaseAgreementExpiresAt.toISOString();
    }
    if (message.token !== "") {
      obj.token = message.token;
    }
    if (message.purchaseAgreementCompletedAt !== undefined) {
      obj.purchaseAgreementCompletedAt = message.purchaseAgreementCompletedAt.toISOString();
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.offerDashboardUrl !== "") {
      obj.offerDashboardUrl = message.offerDashboardUrl;
    }
    if (message.experiencePartner !== undefined) {
      obj.experiencePartner = ExperiencePartner.toJSON(message.experiencePartner);
    }
    if (message.customerExperiencePartner !== undefined) {
      obj.customerExperiencePartner = ExperiencePartner.toJSON(message.customerExperiencePartner);
    }
    if (message.interestedInTradeIn === true) {
      obj.interestedInTradeIn = message.interestedInTradeIn;
    }
    if (message.tradeInSavingsCents !== 0) {
      obj.tradeInSavingsCents = Math.round(message.tradeInSavingsCents);
    }
    if (message.preliminaryOfferExpirationDate !== undefined) {
      obj.preliminaryOfferExpirationDate = message.preliminaryOfferExpirationDate.toISOString();
    }
    if (message.enabledFeatureFlags?.length) {
      obj.enabledFeatureFlags = message.enabledFeatureFlags.map((e) => FeatureFlag.toJSON(e));
    }
    if (message.numericId !== 0) {
      obj.numericId = Math.round(message.numericId);
    }
    if (message.leadToken !== "") {
      obj.leadToken = message.leadToken;
    }
    if (message.occupancyAtVa !== 0) {
      obj.occupancyAtVa = occupancyAtVaToJSON(message.occupancyAtVa);
    }
    if (message.offerReactions?.length) {
      obj.offerReactions = message.offerReactions.map((e) => OfferReaction.toJSON(e));
    }
    if (message.addressId !== "") {
      obj.addressId = message.addressId;
    }
    if (message.finalOfferViewedAt !== undefined) {
      obj.finalOfferViewedAt = message.finalOfferViewedAt.toISOString();
    }
    if (message.offerReviewAt !== undefined) {
      obj.offerReviewAt = message.offerReviewAt.toISOString();
    }
    if (message.lateCheckoutDate !== undefined) {
      obj.lateCheckoutDate = DateMessage.toJSON(message.lateCheckoutDate);
    }
    if (message.titleContact !== undefined) {
      obj.titleContact = TitleContact.toJSON(message.titleContact);
    }
    if (message.titleOrg !== undefined) {
      obj.titleOrg = TitleOrganization.toJSON(message.titleOrg);
    }
    if (message.signatories?.length) {
      obj.signatories = message.signatories.map((e) => Signatory.toJSON(e));
    }
    if (message.offerRequestSource !== 0) {
      obj.offerRequestSource = offerRequest_SourceToJSON(message.offerRequestSource);
    }
    if (message.lateCheckout !== undefined) {
      obj.lateCheckout = LateCheckout.toJSON(message.lateCheckout);
    }
    if (message.lateCheckoutSecurityDepositCents !== 0) {
      obj.lateCheckoutSecurityDepositCents = Math.round(message.lateCheckoutSecurityDepositCents);
    }
    if (message.listingEstimateCeilingCents !== 0) {
      obj.listingEstimateCeilingCents = Math.round(message.listingEstimateCeilingCents);
    }
    if (message.listingEstimateFloorCents !== 0) {
      obj.listingEstimateFloorCents = Math.round(message.listingEstimateFloorCents);
    }
    if (message.expirationInfo !== undefined) {
      obj.expirationInfo = ExpirationInfo.toJSON(message.expirationInfo);
    }
    if (message.offerDashboardInternalUrl !== "") {
      obj.offerDashboardInternalUrl = message.offerDashboardInternalUrl;
    }
    if (message.isLatestOffer === true) {
      obj.isLatestOffer = message.isLatestOffer;
    }
    if (message.coeAvailableDatesInternal?.length) {
      obj.coeAvailableDatesInternal = message.coeAvailableDatesInternal.map((e) => DateMessage.toJSON(e));
    }
    if (message.sellerCredits?.length) {
      obj.sellerCredits = message.sellerCredits.map((e) => Subsidy.toJSON(e));
    }
    if (message.listingPriceEstimateCents !== 0) {
      obj.listingPriceEstimateCents = Math.round(message.listingPriceEstimateCents);
    }
    if (message.offerConfidenceLevel !== "") {
      obj.offerConfidenceLevel = message.offerConfidenceLevel;
    }
    if (message.numberOfOffers !== 0) {
      obj.numberOfOffers = Math.round(message.numberOfOffers);
    }
    if (message.scheduleHomeAssessmentUrl !== "") {
      obj.scheduleHomeAssessmentUrl = message.scheduleHomeAssessmentUrl;
    }
    if (message.arvCents !== 0) {
      obj.arvCents = Math.round(message.arvCents);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Offer>, I>>(base?: I): Offer {
    return Offer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Offer>, I>>(object: I): Offer {
    const message = createBaseOffer();
    message.id = object.id ?? "";
    message.state = object.state ?? 0;
    message.acceptedAt = object.acceptedAt ?? undefined;
    message.buyerExpiresAt = object.buyerExpiresAt ?? undefined;
    message.closedDate = (object.closedDate !== undefined && object.closedDate !== null)
      ? DateMessage.fromPartial(object.closedDate)
      : undefined;
    message.closedAt = object.closedAt ?? undefined;
    message.contingenciesReleasedDate =
      (object.contingenciesReleasedDate !== undefined && object.contingenciesReleasedDate !== null)
        ? DateMessage.fromPartial(object.contingenciesReleasedDate)
        : undefined;
    message.contingenciesReleasedAt = object.contingenciesReleasedAt ?? undefined;
    message.enteredContractAt = object.enteredContractAt ?? undefined;
    message.expiredAt = object.expiredAt ?? undefined;
    message.sentAt = object.sentAt ?? undefined;
    message.viewedAt = object.viewedAt ?? undefined;
    message.withdrawnAt = object.withdrawnAt ?? undefined;
    message.coBrokePercentage = object.coBrokePercentage ?? undefined;
    message.headlinePriceCents = object.headlinePriceCents ?? 0;
    message.netProceedsCents = object.netProceedsCents ?? 0;
    message.closingCostsCents = object.closingCostsCents ?? 0;
    message.closingCostsPercentage = object.closingCostsPercentage ?? undefined;
    message.serviceChargeCents = object.serviceChargeCents ?? 0;
    message.serviceChargePercentage = object.serviceChargePercentage ?? undefined;
    message.sellerVisibleValuationRangeStartCents = object.sellerVisibleValuationRangeStartCents ?? 0;
    message.sellerVisibleValuationRangeEndCents = object.sellerVisibleValuationRangeEndCents ?? 0;
    message.internalFeeCents = object.internalFeeCents ?? 0;
    message.initialInspectionAt = object.initialInspectionAt ?? undefined;
    message.comps = object.comps?.map((e) => Comp.fromPartial(e)) || [];
    message.underwritingId = object.underwritingId ?? "";
    message.isFirstOffer = object.isFirstOffer ?? false;
    message.coeDate = (object.coeDate !== undefined && object.coeDate !== null)
      ? DateMessage.fromPartial(object.coeDate)
      : undefined;
    message.closeEscrowDate = object.closeEscrowDate ?? undefined;
    message.enabledExperiments = object.enabledExperiments?.map((e) => Experiment.fromPartial(e)) || [];
    message.maxRepairCostsEstimateCents = object.maxRepairCostsEstimateCents ?? 0;
    message.canChangeCloseEscrowDate = object.canChangeCloseEscrowDate ?? false;
    message.coeDateChangeDeadline =
      (object.coeDateChangeDeadline !== undefined && object.coeDateChangeDeadline !== null)
        ? DateMessage.fromPartial(object.coeDateChangeDeadline)
        : undefined;
    message.closeEscrowDateChangeDeadline = object.closeEscrowDateChangeDeadline ?? undefined;
    message.hasPostInspectionExperience = object.hasPostInspectionExperience ?? false;
    message.purchaseAgreementExpiresAt = object.purchaseAgreementExpiresAt ?? undefined;
    message.token = object.token ?? "";
    message.purchaseAgreementCompletedAt = object.purchaseAgreementCompletedAt ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.offerDashboardUrl = object.offerDashboardUrl ?? "";
    message.experiencePartner = (object.experiencePartner !== undefined && object.experiencePartner !== null)
      ? ExperiencePartner.fromPartial(object.experiencePartner)
      : undefined;
    message.customerExperiencePartner =
      (object.customerExperiencePartner !== undefined && object.customerExperiencePartner !== null)
        ? ExperiencePartner.fromPartial(object.customerExperiencePartner)
        : undefined;
    message.interestedInTradeIn = object.interestedInTradeIn ?? false;
    message.tradeInSavingsCents = object.tradeInSavingsCents ?? 0;
    message.preliminaryOfferExpirationDate = object.preliminaryOfferExpirationDate ?? undefined;
    message.enabledFeatureFlags = object.enabledFeatureFlags?.map((e) => FeatureFlag.fromPartial(e)) || [];
    message.numericId = object.numericId ?? 0;
    message.leadToken = object.leadToken ?? "";
    message.occupancyAtVa = object.occupancyAtVa ?? 0;
    message.offerReactions = object.offerReactions?.map((e) => OfferReaction.fromPartial(e)) || [];
    message.addressId = object.addressId ?? "";
    message.finalOfferViewedAt = object.finalOfferViewedAt ?? undefined;
    message.offerReviewAt = object.offerReviewAt ?? undefined;
    message.lateCheckoutDate = (object.lateCheckoutDate !== undefined && object.lateCheckoutDate !== null)
      ? DateMessage.fromPartial(object.lateCheckoutDate)
      : undefined;
    message.titleContact = (object.titleContact !== undefined && object.titleContact !== null)
      ? TitleContact.fromPartial(object.titleContact)
      : undefined;
    message.titleOrg = (object.titleOrg !== undefined && object.titleOrg !== null)
      ? TitleOrganization.fromPartial(object.titleOrg)
      : undefined;
    message.signatories = object.signatories?.map((e) => Signatory.fromPartial(e)) || [];
    message.offerRequestSource = object.offerRequestSource ?? 0;
    message.lateCheckout = (object.lateCheckout !== undefined && object.lateCheckout !== null)
      ? LateCheckout.fromPartial(object.lateCheckout)
      : undefined;
    message.lateCheckoutSecurityDepositCents = object.lateCheckoutSecurityDepositCents ?? 0;
    message.listingEstimateCeilingCents = object.listingEstimateCeilingCents ?? 0;
    message.listingEstimateFloorCents = object.listingEstimateFloorCents ?? 0;
    message.expirationInfo = (object.expirationInfo !== undefined && object.expirationInfo !== null)
      ? ExpirationInfo.fromPartial(object.expirationInfo)
      : undefined;
    message.offerDashboardInternalUrl = object.offerDashboardInternalUrl ?? "";
    message.isLatestOffer = object.isLatestOffer ?? false;
    message.coeAvailableDatesInternal = object.coeAvailableDatesInternal?.map((e) => DateMessage.fromPartial(e)) || [];
    message.sellerCredits = object.sellerCredits?.map((e) => Subsidy.fromPartial(e)) || [];
    message.listingPriceEstimateCents = object.listingPriceEstimateCents ?? 0;
    message.offerConfidenceLevel = object.offerConfidenceLevel ?? "";
    message.numberOfOffers = object.numberOfOffers ?? 0;
    message.scheduleHomeAssessmentUrl = object.scheduleHomeAssessmentUrl ?? "";
    message.arvCents = object.arvCents ?? 0;
    return message;
  },
};

function createBaseExpirationInfo(): ExpirationInfo {
  return { date: undefined, nextStep: "" };
}

export const ExpirationInfo = {
  fromJSON(object: any): ExpirationInfo {
    return {
      date: isSet(object.date) ? fromJsonTimestamp(object.date) : undefined,
      nextStep: isSet(object.nextStep) ? String(object.nextStep) : "",
    };
  },

  toJSON(message: ExpirationInfo): unknown {
    const obj: any = {};
    if (message.date !== undefined) {
      obj.date = message.date.toISOString();
    }
    if (message.nextStep !== "") {
      obj.nextStep = message.nextStep;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpirationInfo>, I>>(base?: I): ExpirationInfo {
    return ExpirationInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExpirationInfo>, I>>(object: I): ExpirationInfo {
    const message = createBaseExpirationInfo();
    message.date = object.date ?? undefined;
    message.nextStep = object.nextStep ?? "";
    return message;
  },
};

function createBaseExperiment(): Experiment {
  return { experimentEnum: 0 };
}

export const Experiment = {
  fromJSON(object: any): Experiment {
    return { experimentEnum: isSet(object.experimentEnum) ? experimentsEnumFromJSON(object.experimentEnum) : 0 };
  },

  toJSON(message: Experiment): unknown {
    const obj: any = {};
    if (message.experimentEnum !== 0) {
      obj.experimentEnum = experimentsEnumToJSON(message.experimentEnum);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Experiment>, I>>(base?: I): Experiment {
    return Experiment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Experiment>, I>>(object: I): Experiment {
    const message = createBaseExperiment();
    message.experimentEnum = object.experimentEnum ?? 0;
    return message;
  },
};

function createBaseFeatureFlag(): FeatureFlag {
  return { featureFlag: 0 };
}

export const FeatureFlag = {
  fromJSON(object: any): FeatureFlag {
    return { featureFlag: isSet(object.featureFlag) ? featureFlagEnumFromJSON(object.featureFlag) : 0 };
  },

  toJSON(message: FeatureFlag): unknown {
    const obj: any = {};
    if (message.featureFlag !== 0) {
      obj.featureFlag = featureFlagEnumToJSON(message.featureFlag);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FeatureFlag>, I>>(base?: I): FeatureFlag {
    return FeatureFlag.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FeatureFlag>, I>>(object: I): FeatureFlag {
    const message = createBaseFeatureFlag();
    message.featureFlag = object.featureFlag ?? 0;
    return message;
  },
};

function createBaseOfferIdentifiers(): OfferIdentifiers {
  return { identifiers: [] };
}

export const OfferIdentifiers = {
  fromJSON(object: any): OfferIdentifiers {
    return { identifiers: Array.isArray(object?.identifiers) ? object.identifiers.map((e: any) => String(e)) : [] };
  },

  toJSON(message: OfferIdentifiers): unknown {
    const obj: any = {};
    if (message.identifiers?.length) {
      obj.identifiers = message.identifiers;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OfferIdentifiers>, I>>(base?: I): OfferIdentifiers {
    return OfferIdentifiers.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OfferIdentifiers>, I>>(object: I): OfferIdentifiers {
    const message = createBaseOfferIdentifiers();
    message.identifiers = object.identifiers?.map((e) => e) || [];
    return message;
  },
};

function createBaseOfferQueryParams(): OfferQueryParams {
  return { addressToken: "", customerUuid: "", humanToken: "" };
}

export const OfferQueryParams = {
  fromJSON(object: any): OfferQueryParams {
    return {
      addressToken: isSet(object.addressToken) ? String(object.addressToken) : "",
      customerUuid: isSet(object.customerUuid) ? String(object.customerUuid) : "",
      humanToken: isSet(object.humanToken) ? String(object.humanToken) : "",
    };
  },

  toJSON(message: OfferQueryParams): unknown {
    const obj: any = {};
    if (message.addressToken !== "") {
      obj.addressToken = message.addressToken;
    }
    if (message.customerUuid !== "") {
      obj.customerUuid = message.customerUuid;
    }
    if (message.humanToken !== "") {
      obj.humanToken = message.humanToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OfferQueryParams>, I>>(base?: I): OfferQueryParams {
    return OfferQueryParams.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OfferQueryParams>, I>>(object: I): OfferQueryParams {
    const message = createBaseOfferQueryParams();
    message.addressToken = object.addressToken ?? "";
    message.customerUuid = object.customerUuid ?? "";
    message.humanToken = object.humanToken ?? "";
    return message;
  },
};

function createBaseOfferReaction(): OfferReaction {
  return { reaction: 0, humanToken: "", textResponse: "" };
}

export const OfferReaction = {
  fromJSON(object: any): OfferReaction {
    return {
      reaction: isSet(object.reaction) ? reactionFromJSON(object.reaction) : 0,
      humanToken: isSet(object.humanToken) ? String(object.humanToken) : "",
      textResponse: isSet(object.textResponse) ? String(object.textResponse) : "",
    };
  },

  toJSON(message: OfferReaction): unknown {
    const obj: any = {};
    if (message.reaction !== 0) {
      obj.reaction = reactionToJSON(message.reaction);
    }
    if (message.humanToken !== "") {
      obj.humanToken = message.humanToken;
    }
    if (message.textResponse !== "") {
      obj.textResponse = message.textResponse;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OfferReaction>, I>>(base?: I): OfferReaction {
    return OfferReaction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OfferReaction>, I>>(object: I): OfferReaction {
    const message = createBaseOfferReaction();
    message.reaction = object.reaction ?? 0;
    message.humanToken = object.humanToken ?? "";
    message.textResponse = object.textResponse ?? "";
    return message;
  },
};

function createBaseSignatory(): Signatory {
  return { name: "" };
}

export const Signatory = {
  fromJSON(object: any): Signatory {
    return { name: isSet(object.name) ? String(object.name) : "" };
  },

  toJSON(message: Signatory): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Signatory>, I>>(base?: I): Signatory {
    return Signatory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Signatory>, I>>(object: I): Signatory {
    const message = createBaseSignatory();
    message.name = object.name ?? "";
    return message;
  },
};

function createBasePrivateOfferData(): PrivateOfferData {
  return { specialFlags: [], internalFeePercentage: undefined };
}

export const PrivateOfferData = {
  fromJSON(object: any): PrivateOfferData {
    return {
      specialFlags: Array.isArray(object?.specialFlags)
        ? object.specialFlags.map((e: any) => SpecialFlag.fromJSON(e))
        : [],
      internalFeePercentage: isSet(object.internalFeePercentage) ? Number(object.internalFeePercentage) : undefined,
    };
  },

  toJSON(message: PrivateOfferData): unknown {
    const obj: any = {};
    if (message.specialFlags?.length) {
      obj.specialFlags = message.specialFlags.map((e) => SpecialFlag.toJSON(e));
    }
    if (message.internalFeePercentage !== undefined) {
      obj.internalFeePercentage = message.internalFeePercentage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PrivateOfferData>, I>>(base?: I): PrivateOfferData {
    return PrivateOfferData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PrivateOfferData>, I>>(object: I): PrivateOfferData {
    const message = createBasePrivateOfferData();
    message.specialFlags = object.specialFlags?.map((e) => SpecialFlag.fromPartial(e)) || [];
    message.internalFeePercentage = object.internalFeePercentage ?? undefined;
    return message;
  },
};

function createBaseSpecialFlag(): SpecialFlag {
  return { text: "", type: 0 };
}

export const SpecialFlag = {
  fromJSON(object: any): SpecialFlag {
    return {
      text: isSet(object.text) ? String(object.text) : "",
      type: isSet(object.type) ? specialFlagTypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: SpecialFlag): unknown {
    const obj: any = {};
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.type !== 0) {
      obj.type = specialFlagTypeToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpecialFlag>, I>>(base?: I): SpecialFlag {
    return SpecialFlag.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpecialFlag>, I>>(object: I): SpecialFlag {
    const message = createBaseSpecialFlag();
    message.text = object.text ?? "";
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseOfferDataForIterable(): OfferDataForIterable {
  return { sellerLeadUuid: "", offerRequestSource: 0, valuationCents: 0 };
}

export const OfferDataForIterable = {
  fromJSON(object: any): OfferDataForIterable {
    return {
      sellerLeadUuid: isSet(object.sellerLeadUuid) ? String(object.sellerLeadUuid) : "",
      offerRequestSource: isSet(object.offerRequestSource) ? offerRequest_SourceFromJSON(object.offerRequestSource) : 0,
      valuationCents: isSet(object.valuationCents) ? Number(object.valuationCents) : 0,
    };
  },

  toJSON(message: OfferDataForIterable): unknown {
    const obj: any = {};
    if (message.sellerLeadUuid !== "") {
      obj.sellerLeadUuid = message.sellerLeadUuid;
    }
    if (message.offerRequestSource !== 0) {
      obj.offerRequestSource = offerRequest_SourceToJSON(message.offerRequestSource);
    }
    if (message.valuationCents !== 0) {
      obj.valuationCents = Math.round(message.valuationCents);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OfferDataForIterable>, I>>(base?: I): OfferDataForIterable {
    return OfferDataForIterable.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OfferDataForIterable>, I>>(object: I): OfferDataForIterable {
    const message = createBaseOfferDataForIterable();
    message.sellerLeadUuid = object.sellerLeadUuid ?? "";
    message.offerRequestSource = object.offerRequestSource ?? 0;
    message.valuationCents = object.valuationCents ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
