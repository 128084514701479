/* eslint-disable */
import {
  OfferRequest_Source,
  offerRequest_SourceFromJSON,
  offerRequest_SourceToJSON,
} from "../../../common/sell/offer_request";
import {
  SellerInput_Channel,
  sellerInput_ChannelFromJSON,
  sellerInput_ChannelToJSON,
} from "../../../sell/reception/data/seller_input";
import { Offer_State, offer_StateFromJSON, offer_StateToJSON } from "../../../sell/seller/data/offer";

export const protobufPackage = "od_protos.journey.data.categories";

export interface SellDirectCategory {
  event?:
    | { $case: "qualification"; qualification: SellDirectCategory_QualificationEvent }
    | { $case: "offerCreated"; offerCreated: SellDirectCategory_OfferCreatedEvent }
    | { $case: "offerView"; offerView: SellDirectCategory_OfferViewEvent }
    | { $case: "offerFallthrough"; offerFallthrough: SellDirectCategory_OfferFallthroughEvent }
    | { $case: "finalOfferCreated"; finalOfferCreated: SellDirectCategory_FinalOfferCreatedEvent }
    | { $case: "signStart"; signStart: SellDirectCategory_SignStartEvent }
    | { $case: "signQuestionsSubmit"; signQuestionsSubmit: SellDirectCategory_SignQuestionsSubmitEvent }
    | { $case: "signComplete"; signComplete: SellDirectCategory_SignCompleteEvent }
    | undefined;
}

export enum SellDirectCategory_FallthroughType {
  UNKNOWN_FALLTHROUGH_TYPE = 0,
  OD_WALK = 1,
  SELLER_WALK = 2,
  EXPIRATION = 3,
  UNRECOGNIZED = -1,
}

export function sellDirectCategory_FallthroughTypeFromJSON(object: any): SellDirectCategory_FallthroughType {
  switch (object) {
    case 0:
    case "UNKNOWN_FALLTHROUGH_TYPE":
      return SellDirectCategory_FallthroughType.UNKNOWN_FALLTHROUGH_TYPE;
    case 1:
    case "OD_WALK":
      return SellDirectCategory_FallthroughType.OD_WALK;
    case 2:
    case "SELLER_WALK":
      return SellDirectCategory_FallthroughType.SELLER_WALK;
    case 3:
    case "EXPIRATION":
      return SellDirectCategory_FallthroughType.EXPIRATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellDirectCategory_FallthroughType.UNRECOGNIZED;
  }
}

export function sellDirectCategory_FallthroughTypeToJSON(object: SellDirectCategory_FallthroughType): string {
  switch (object) {
    case SellDirectCategory_FallthroughType.UNKNOWN_FALLTHROUGH_TYPE:
      return "UNKNOWN_FALLTHROUGH_TYPE";
    case SellDirectCategory_FallthroughType.OD_WALK:
      return "OD_WALK";
    case SellDirectCategory_FallthroughType.SELLER_WALK:
      return "SELLER_WALK";
    case SellDirectCategory_FallthroughType.EXPIRATION:
      return "EXPIRATION";
    case SellDirectCategory_FallthroughType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * sell_direct_qualification_event
 * Triggered whenever qualification for the Sell Direct product changes
 */
export interface SellDirectCategory_QualificationEvent {
  sellerInputId: string;
  qualificationChannel: SellerInput_Channel;
  qualificationDecision: SellDirectCategory_QualificationEvent_QualificationDecision;
  denialReason?: string | undefined;
}

export enum SellDirectCategory_QualificationEvent_QualificationDecision {
  UNKNOWN = 0,
  PRODUCT_QUALIFIED = 1,
  PRODUCT_DENIED = 2,
  PRODUCT_UNDENIED = 3,
  UNRECOGNIZED = -1,
}

export function sellDirectCategory_QualificationEvent_QualificationDecisionFromJSON(
  object: any,
): SellDirectCategory_QualificationEvent_QualificationDecision {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return SellDirectCategory_QualificationEvent_QualificationDecision.UNKNOWN;
    case 1:
    case "PRODUCT_QUALIFIED":
      return SellDirectCategory_QualificationEvent_QualificationDecision.PRODUCT_QUALIFIED;
    case 2:
    case "PRODUCT_DENIED":
      return SellDirectCategory_QualificationEvent_QualificationDecision.PRODUCT_DENIED;
    case 3:
    case "PRODUCT_UNDENIED":
      return SellDirectCategory_QualificationEvent_QualificationDecision.PRODUCT_UNDENIED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellDirectCategory_QualificationEvent_QualificationDecision.UNRECOGNIZED;
  }
}

export function sellDirectCategory_QualificationEvent_QualificationDecisionToJSON(
  object: SellDirectCategory_QualificationEvent_QualificationDecision,
): string {
  switch (object) {
    case SellDirectCategory_QualificationEvent_QualificationDecision.UNKNOWN:
      return "UNKNOWN";
    case SellDirectCategory_QualificationEvent_QualificationDecision.PRODUCT_QUALIFIED:
      return "PRODUCT_QUALIFIED";
    case SellDirectCategory_QualificationEvent_QualificationDecision.PRODUCT_DENIED:
      return "PRODUCT_DENIED";
    case SellDirectCategory_QualificationEvent_QualificationDecision.PRODUCT_UNDENIED:
      return "PRODUCT_UNDENIED";
    case SellDirectCategory_QualificationEvent_QualificationDecision.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SellDirectCategory_OfferCreatedEvent {
  offerCreationSource: OfferRequest_Source;
  opendoorInitiated: boolean;
  offerIsRefresh: boolean;
  offerRefreshIndex?: number | undefined;
  leadId: string;
  offerId: string;
  headlineOffer: number;
  internalFeePct: number;
  headlineFeePct: number;
  offerUuid: string;
  sellerInputId: string;
  ovmExpectedError: number;
}

export interface SellDirectCategory_OfferViewEvent {
  leadId: string;
  /** This is populated with a UUID, not a numeric ID as the name suggests */
  offerId: string;
  offerState: Offer_State;
  offerUuid: string;
  isFirstOffer: boolean;
}

export interface SellDirectCategory_OfferFallthroughEvent {
  leadId: string;
  offerId: string;
  fallthroughType: SellDirectCategory_FallthroughType;
  offerUuid: string;
}

export interface SellDirectCategory_FinalOfferCreatedEvent {
  leadId: string;
  offerId: string;
  headlinePrice: number;
  finalizedFeePct: number;
  offerUuid: string;
  ovmExpectedError: number;
  underwritingId: string;
}

export interface SellDirectCategory_SignStartEvent {
  leadId: string;
  offerId: string;
  offerUuid: string;
}

export interface SellDirectCategory_SignQuestionsSubmitEvent {
  leadId: string;
  offerId: string;
  offerUuid: string;
}

export interface SellDirectCategory_SignCompleteEvent {
  leadId: string;
  offerId: string;
  offerUuid: string;
  headlinePrice: number;
}

function createBaseSellDirectCategory(): SellDirectCategory {
  return { event: undefined };
}

export const SellDirectCategory = {
  fromJSON(object: any): SellDirectCategory {
    return {
      event: isSet(object.qualification)
        ? {
          $case: "qualification",
          qualification: SellDirectCategory_QualificationEvent.fromJSON(object.qualification),
        }
        : isSet(object.offerCreated)
        ? { $case: "offerCreated", offerCreated: SellDirectCategory_OfferCreatedEvent.fromJSON(object.offerCreated) }
        : isSet(object.offerView)
        ? { $case: "offerView", offerView: SellDirectCategory_OfferViewEvent.fromJSON(object.offerView) }
        : isSet(object.offerFallthrough)
        ? {
          $case: "offerFallthrough",
          offerFallthrough: SellDirectCategory_OfferFallthroughEvent.fromJSON(object.offerFallthrough),
        }
        : isSet(object.finalOfferCreated)
        ? {
          $case: "finalOfferCreated",
          finalOfferCreated: SellDirectCategory_FinalOfferCreatedEvent.fromJSON(object.finalOfferCreated),
        }
        : isSet(object.signStart)
        ? { $case: "signStart", signStart: SellDirectCategory_SignStartEvent.fromJSON(object.signStart) }
        : isSet(object.signQuestionsSubmit)
        ? {
          $case: "signQuestionsSubmit",
          signQuestionsSubmit: SellDirectCategory_SignQuestionsSubmitEvent.fromJSON(object.signQuestionsSubmit),
        }
        : isSet(object.signComplete)
        ? { $case: "signComplete", signComplete: SellDirectCategory_SignCompleteEvent.fromJSON(object.signComplete) }
        : undefined,
    };
  },

  toJSON(message: SellDirectCategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "qualification") {
      obj.qualification = SellDirectCategory_QualificationEvent.toJSON(message.event.qualification);
    }
    if (message.event?.$case === "offerCreated") {
      obj.offerCreated = SellDirectCategory_OfferCreatedEvent.toJSON(message.event.offerCreated);
    }
    if (message.event?.$case === "offerView") {
      obj.offerView = SellDirectCategory_OfferViewEvent.toJSON(message.event.offerView);
    }
    if (message.event?.$case === "offerFallthrough") {
      obj.offerFallthrough = SellDirectCategory_OfferFallthroughEvent.toJSON(message.event.offerFallthrough);
    }
    if (message.event?.$case === "finalOfferCreated") {
      obj.finalOfferCreated = SellDirectCategory_FinalOfferCreatedEvent.toJSON(message.event.finalOfferCreated);
    }
    if (message.event?.$case === "signStart") {
      obj.signStart = SellDirectCategory_SignStartEvent.toJSON(message.event.signStart);
    }
    if (message.event?.$case === "signQuestionsSubmit") {
      obj.signQuestionsSubmit = SellDirectCategory_SignQuestionsSubmitEvent.toJSON(message.event.signQuestionsSubmit);
    }
    if (message.event?.$case === "signComplete") {
      obj.signComplete = SellDirectCategory_SignCompleteEvent.toJSON(message.event.signComplete);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellDirectCategory>, I>>(base?: I): SellDirectCategory {
    return SellDirectCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellDirectCategory>, I>>(object: I): SellDirectCategory {
    const message = createBaseSellDirectCategory();
    if (
      object.event?.$case === "qualification" &&
      object.event?.qualification !== undefined &&
      object.event?.qualification !== null
    ) {
      message.event = {
        $case: "qualification",
        qualification: SellDirectCategory_QualificationEvent.fromPartial(object.event.qualification),
      };
    }
    if (
      object.event?.$case === "offerCreated" &&
      object.event?.offerCreated !== undefined &&
      object.event?.offerCreated !== null
    ) {
      message.event = {
        $case: "offerCreated",
        offerCreated: SellDirectCategory_OfferCreatedEvent.fromPartial(object.event.offerCreated),
      };
    }
    if (
      object.event?.$case === "offerView" && object.event?.offerView !== undefined && object.event?.offerView !== null
    ) {
      message.event = {
        $case: "offerView",
        offerView: SellDirectCategory_OfferViewEvent.fromPartial(object.event.offerView),
      };
    }
    if (
      object.event?.$case === "offerFallthrough" &&
      object.event?.offerFallthrough !== undefined &&
      object.event?.offerFallthrough !== null
    ) {
      message.event = {
        $case: "offerFallthrough",
        offerFallthrough: SellDirectCategory_OfferFallthroughEvent.fromPartial(object.event.offerFallthrough),
      };
    }
    if (
      object.event?.$case === "finalOfferCreated" &&
      object.event?.finalOfferCreated !== undefined &&
      object.event?.finalOfferCreated !== null
    ) {
      message.event = {
        $case: "finalOfferCreated",
        finalOfferCreated: SellDirectCategory_FinalOfferCreatedEvent.fromPartial(object.event.finalOfferCreated),
      };
    }
    if (
      object.event?.$case === "signStart" && object.event?.signStart !== undefined && object.event?.signStart !== null
    ) {
      message.event = {
        $case: "signStart",
        signStart: SellDirectCategory_SignStartEvent.fromPartial(object.event.signStart),
      };
    }
    if (
      object.event?.$case === "signQuestionsSubmit" &&
      object.event?.signQuestionsSubmit !== undefined &&
      object.event?.signQuestionsSubmit !== null
    ) {
      message.event = {
        $case: "signQuestionsSubmit",
        signQuestionsSubmit: SellDirectCategory_SignQuestionsSubmitEvent.fromPartial(object.event.signQuestionsSubmit),
      };
    }
    if (
      object.event?.$case === "signComplete" &&
      object.event?.signComplete !== undefined &&
      object.event?.signComplete !== null
    ) {
      message.event = {
        $case: "signComplete",
        signComplete: SellDirectCategory_SignCompleteEvent.fromPartial(object.event.signComplete),
      };
    }
    return message;
  },
};

function createBaseSellDirectCategory_QualificationEvent(): SellDirectCategory_QualificationEvent {
  return { sellerInputId: "", qualificationChannel: 0, qualificationDecision: 0, denialReason: undefined };
}

export const SellDirectCategory_QualificationEvent = {
  fromJSON(object: any): SellDirectCategory_QualificationEvent {
    return {
      sellerInputId: isSet(object.sellerInputId) ? String(object.sellerInputId) : "",
      qualificationChannel: isSet(object.qualificationChannel)
        ? sellerInput_ChannelFromJSON(object.qualificationChannel)
        : 0,
      qualificationDecision: isSet(object.qualificationDecision)
        ? sellDirectCategory_QualificationEvent_QualificationDecisionFromJSON(object.qualificationDecision)
        : 0,
      denialReason: isSet(object.denialReason) ? String(object.denialReason) : undefined,
    };
  },

  toJSON(message: SellDirectCategory_QualificationEvent): unknown {
    const obj: any = {};
    if (message.sellerInputId !== "") {
      obj.sellerInputId = message.sellerInputId;
    }
    if (message.qualificationChannel !== 0) {
      obj.qualificationChannel = sellerInput_ChannelToJSON(message.qualificationChannel);
    }
    if (message.qualificationDecision !== 0) {
      obj.qualificationDecision = sellDirectCategory_QualificationEvent_QualificationDecisionToJSON(
        message.qualificationDecision,
      );
    }
    if (message.denialReason !== undefined) {
      obj.denialReason = message.denialReason;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellDirectCategory_QualificationEvent>, I>>(
    base?: I,
  ): SellDirectCategory_QualificationEvent {
    return SellDirectCategory_QualificationEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellDirectCategory_QualificationEvent>, I>>(
    object: I,
  ): SellDirectCategory_QualificationEvent {
    const message = createBaseSellDirectCategory_QualificationEvent();
    message.sellerInputId = object.sellerInputId ?? "";
    message.qualificationChannel = object.qualificationChannel ?? 0;
    message.qualificationDecision = object.qualificationDecision ?? 0;
    message.denialReason = object.denialReason ?? undefined;
    return message;
  },
};

function createBaseSellDirectCategory_OfferCreatedEvent(): SellDirectCategory_OfferCreatedEvent {
  return {
    offerCreationSource: 0,
    opendoorInitiated: false,
    offerIsRefresh: false,
    offerRefreshIndex: undefined,
    leadId: "",
    offerId: "",
    headlineOffer: 0,
    internalFeePct: 0,
    headlineFeePct: 0,
    offerUuid: "",
    sellerInputId: "",
    ovmExpectedError: 0,
  };
}

export const SellDirectCategory_OfferCreatedEvent = {
  fromJSON(object: any): SellDirectCategory_OfferCreatedEvent {
    return {
      offerCreationSource: isSet(object.offerCreationSource)
        ? offerRequest_SourceFromJSON(object.offerCreationSource)
        : 0,
      opendoorInitiated: isSet(object.opendoorInitiated) ? Boolean(object.opendoorInitiated) : false,
      offerIsRefresh: isSet(object.offerIsRefresh) ? Boolean(object.offerIsRefresh) : false,
      offerRefreshIndex: isSet(object.offerRefreshIndex) ? Number(object.offerRefreshIndex) : undefined,
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      headlineOffer: isSet(object.headlineOffer) ? Number(object.headlineOffer) : 0,
      internalFeePct: isSet(object.internalFeePct) ? Number(object.internalFeePct) : 0,
      headlineFeePct: isSet(object.headlineFeePct) ? Number(object.headlineFeePct) : 0,
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
      sellerInputId: isSet(object.sellerInputId) ? String(object.sellerInputId) : "",
      ovmExpectedError: isSet(object.ovmExpectedError) ? Number(object.ovmExpectedError) : 0,
    };
  },

  toJSON(message: SellDirectCategory_OfferCreatedEvent): unknown {
    const obj: any = {};
    if (message.offerCreationSource !== 0) {
      obj.offerCreationSource = offerRequest_SourceToJSON(message.offerCreationSource);
    }
    if (message.opendoorInitiated === true) {
      obj.opendoorInitiated = message.opendoorInitiated;
    }
    if (message.offerIsRefresh === true) {
      obj.offerIsRefresh = message.offerIsRefresh;
    }
    if (message.offerRefreshIndex !== undefined) {
      obj.offerRefreshIndex = message.offerRefreshIndex;
    }
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerId !== "") {
      obj.offerId = message.offerId;
    }
    if (message.headlineOffer !== 0) {
      obj.headlineOffer = Math.round(message.headlineOffer);
    }
    if (message.internalFeePct !== 0) {
      obj.internalFeePct = message.internalFeePct;
    }
    if (message.headlineFeePct !== 0) {
      obj.headlineFeePct = message.headlineFeePct;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    if (message.sellerInputId !== "") {
      obj.sellerInputId = message.sellerInputId;
    }
    if (message.ovmExpectedError !== 0) {
      obj.ovmExpectedError = message.ovmExpectedError;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellDirectCategory_OfferCreatedEvent>, I>>(
    base?: I,
  ): SellDirectCategory_OfferCreatedEvent {
    return SellDirectCategory_OfferCreatedEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellDirectCategory_OfferCreatedEvent>, I>>(
    object: I,
  ): SellDirectCategory_OfferCreatedEvent {
    const message = createBaseSellDirectCategory_OfferCreatedEvent();
    message.offerCreationSource = object.offerCreationSource ?? 0;
    message.opendoorInitiated = object.opendoorInitiated ?? false;
    message.offerIsRefresh = object.offerIsRefresh ?? false;
    message.offerRefreshIndex = object.offerRefreshIndex ?? undefined;
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.headlineOffer = object.headlineOffer ?? 0;
    message.internalFeePct = object.internalFeePct ?? 0;
    message.headlineFeePct = object.headlineFeePct ?? 0;
    message.offerUuid = object.offerUuid ?? "";
    message.sellerInputId = object.sellerInputId ?? "";
    message.ovmExpectedError = object.ovmExpectedError ?? 0;
    return message;
  },
};

function createBaseSellDirectCategory_OfferViewEvent(): SellDirectCategory_OfferViewEvent {
  return { leadId: "", offerId: "", offerState: 0, offerUuid: "", isFirstOffer: false };
}

export const SellDirectCategory_OfferViewEvent = {
  fromJSON(object: any): SellDirectCategory_OfferViewEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      offerState: isSet(object.offerState) ? offer_StateFromJSON(object.offerState) : 0,
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
      isFirstOffer: isSet(object.isFirstOffer) ? Boolean(object.isFirstOffer) : false,
    };
  },

  toJSON(message: SellDirectCategory_OfferViewEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerId !== "") {
      obj.offerId = message.offerId;
    }
    if (message.offerState !== 0) {
      obj.offerState = offer_StateToJSON(message.offerState);
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    if (message.isFirstOffer === true) {
      obj.isFirstOffer = message.isFirstOffer;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellDirectCategory_OfferViewEvent>, I>>(
    base?: I,
  ): SellDirectCategory_OfferViewEvent {
    return SellDirectCategory_OfferViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellDirectCategory_OfferViewEvent>, I>>(
    object: I,
  ): SellDirectCategory_OfferViewEvent {
    const message = createBaseSellDirectCategory_OfferViewEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.offerState = object.offerState ?? 0;
    message.offerUuid = object.offerUuid ?? "";
    message.isFirstOffer = object.isFirstOffer ?? false;
    return message;
  },
};

function createBaseSellDirectCategory_OfferFallthroughEvent(): SellDirectCategory_OfferFallthroughEvent {
  return { leadId: "", offerId: "", fallthroughType: 0, offerUuid: "" };
}

export const SellDirectCategory_OfferFallthroughEvent = {
  fromJSON(object: any): SellDirectCategory_OfferFallthroughEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      fallthroughType: isSet(object.fallthroughType)
        ? sellDirectCategory_FallthroughTypeFromJSON(object.fallthroughType)
        : 0,
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
    };
  },

  toJSON(message: SellDirectCategory_OfferFallthroughEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerId !== "") {
      obj.offerId = message.offerId;
    }
    if (message.fallthroughType !== 0) {
      obj.fallthroughType = sellDirectCategory_FallthroughTypeToJSON(message.fallthroughType);
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellDirectCategory_OfferFallthroughEvent>, I>>(
    base?: I,
  ): SellDirectCategory_OfferFallthroughEvent {
    return SellDirectCategory_OfferFallthroughEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellDirectCategory_OfferFallthroughEvent>, I>>(
    object: I,
  ): SellDirectCategory_OfferFallthroughEvent {
    const message = createBaseSellDirectCategory_OfferFallthroughEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.fallthroughType = object.fallthroughType ?? 0;
    message.offerUuid = object.offerUuid ?? "";
    return message;
  },
};

function createBaseSellDirectCategory_FinalOfferCreatedEvent(): SellDirectCategory_FinalOfferCreatedEvent {
  return {
    leadId: "",
    offerId: "",
    headlinePrice: 0,
    finalizedFeePct: 0,
    offerUuid: "",
    ovmExpectedError: 0,
    underwritingId: "",
  };
}

export const SellDirectCategory_FinalOfferCreatedEvent = {
  fromJSON(object: any): SellDirectCategory_FinalOfferCreatedEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      headlinePrice: isSet(object.headlinePrice) ? Number(object.headlinePrice) : 0,
      finalizedFeePct: isSet(object.finalizedFeePct) ? Number(object.finalizedFeePct) : 0,
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
      ovmExpectedError: isSet(object.ovmExpectedError) ? Number(object.ovmExpectedError) : 0,
      underwritingId: isSet(object.underwritingId) ? String(object.underwritingId) : "",
    };
  },

  toJSON(message: SellDirectCategory_FinalOfferCreatedEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerId !== "") {
      obj.offerId = message.offerId;
    }
    if (message.headlinePrice !== 0) {
      obj.headlinePrice = Math.round(message.headlinePrice);
    }
    if (message.finalizedFeePct !== 0) {
      obj.finalizedFeePct = message.finalizedFeePct;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    if (message.ovmExpectedError !== 0) {
      obj.ovmExpectedError = message.ovmExpectedError;
    }
    if (message.underwritingId !== "") {
      obj.underwritingId = message.underwritingId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellDirectCategory_FinalOfferCreatedEvent>, I>>(
    base?: I,
  ): SellDirectCategory_FinalOfferCreatedEvent {
    return SellDirectCategory_FinalOfferCreatedEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellDirectCategory_FinalOfferCreatedEvent>, I>>(
    object: I,
  ): SellDirectCategory_FinalOfferCreatedEvent {
    const message = createBaseSellDirectCategory_FinalOfferCreatedEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.headlinePrice = object.headlinePrice ?? 0;
    message.finalizedFeePct = object.finalizedFeePct ?? 0;
    message.offerUuid = object.offerUuid ?? "";
    message.ovmExpectedError = object.ovmExpectedError ?? 0;
    message.underwritingId = object.underwritingId ?? "";
    return message;
  },
};

function createBaseSellDirectCategory_SignStartEvent(): SellDirectCategory_SignStartEvent {
  return { leadId: "", offerId: "", offerUuid: "" };
}

export const SellDirectCategory_SignStartEvent = {
  fromJSON(object: any): SellDirectCategory_SignStartEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
    };
  },

  toJSON(message: SellDirectCategory_SignStartEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerId !== "") {
      obj.offerId = message.offerId;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellDirectCategory_SignStartEvent>, I>>(
    base?: I,
  ): SellDirectCategory_SignStartEvent {
    return SellDirectCategory_SignStartEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellDirectCategory_SignStartEvent>, I>>(
    object: I,
  ): SellDirectCategory_SignStartEvent {
    const message = createBaseSellDirectCategory_SignStartEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.offerUuid = object.offerUuid ?? "";
    return message;
  },
};

function createBaseSellDirectCategory_SignQuestionsSubmitEvent(): SellDirectCategory_SignQuestionsSubmitEvent {
  return { leadId: "", offerId: "", offerUuid: "" };
}

export const SellDirectCategory_SignQuestionsSubmitEvent = {
  fromJSON(object: any): SellDirectCategory_SignQuestionsSubmitEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
    };
  },

  toJSON(message: SellDirectCategory_SignQuestionsSubmitEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerId !== "") {
      obj.offerId = message.offerId;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellDirectCategory_SignQuestionsSubmitEvent>, I>>(
    base?: I,
  ): SellDirectCategory_SignQuestionsSubmitEvent {
    return SellDirectCategory_SignQuestionsSubmitEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellDirectCategory_SignQuestionsSubmitEvent>, I>>(
    object: I,
  ): SellDirectCategory_SignQuestionsSubmitEvent {
    const message = createBaseSellDirectCategory_SignQuestionsSubmitEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.offerUuid = object.offerUuid ?? "";
    return message;
  },
};

function createBaseSellDirectCategory_SignCompleteEvent(): SellDirectCategory_SignCompleteEvent {
  return { leadId: "", offerId: "", offerUuid: "", headlinePrice: 0 };
}

export const SellDirectCategory_SignCompleteEvent = {
  fromJSON(object: any): SellDirectCategory_SignCompleteEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
      headlinePrice: isSet(object.headlinePrice) ? Number(object.headlinePrice) : 0,
    };
  },

  toJSON(message: SellDirectCategory_SignCompleteEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerId !== "") {
      obj.offerId = message.offerId;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    if (message.headlinePrice !== 0) {
      obj.headlinePrice = Math.round(message.headlinePrice);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellDirectCategory_SignCompleteEvent>, I>>(
    base?: I,
  ): SellDirectCategory_SignCompleteEvent {
    return SellDirectCategory_SignCompleteEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellDirectCategory_SignCompleteEvent>, I>>(
    object: I,
  ): SellDirectCategory_SignCompleteEvent {
    const message = createBaseSellDirectCategory_SignCompleteEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.offerUuid = object.offerUuid ?? "";
    message.headlinePrice = object.headlinePrice ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
