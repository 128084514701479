/* eslint-disable */
import { OfferRequest as OfferRequest1 } from "../../../common/sell/offer_request";
import {
  SelfServiceVirtualAssessmentExperimentGroup,
  selfServiceVirtualAssessmentExperimentGroupFromJSON,
  selfServiceVirtualAssessmentExperimentGroupToJSON,
} from "../../common/common";

export const protobufPackage = "od_protos.sell.seller.data";

/**
 * Data specific to the Opendoor direct offer. This includes both data
 * provided by the seller and by Web.
 *
 * Next ID: 7
 */
export interface OpendoorDirectSpecificData {
  /**
   * References Web's offers.token field. The reason token is here and not the
   * offer's source_id is because Web reuses the token when it creates new
   * offers for existing leads.
   *
   * When making calls to Web using this token (e.g. to the /seller_offers/
   * endpoints), you should include the source_id as part of the request for
   * Web to validate that Seller's offer and Web's offer match.
   */
  webToken: string;
  offerFeedback?: OfferFeedback | undefined;
  tradeIn?:
    | TradeInSpecificData
    | undefined;
  /** @deprecated */
  offerRequests: OfferRequest[];
  selfServiceVirtualAssessmentRecordingData?: SelfServiceVirtualAssessmentData | undefined;
  offerRequestsCommon: OfferRequest1[];
}

/**
 * This message encapsulates all self service virtual experiment data.
 * Next ID: 3
 */
export interface SelfServiceVirtualAssessmentData {
  experimentGroup: SelfServiceVirtualAssessmentExperimentGroup;
  virtualAssessmentRecordings: VirtualAssessmentRecording[];
}

/**
 * This message encapulates all data about a virtual assessment recording. Recordings are uploaded to an S3 bucket and are grouped
 * by seller input.
 * Next ID: 4
 */
export interface VirtualAssessmentRecording {
  recordedFeature: VirtualAssessmentRecording_RecordedFeature;
  /** filename is the filename of the recording in S3 */
  filename: string;
  /** s3_directory is the S3 directory that the recording is in */
  s3Directory: string;
}

/** RecordedFeature is the feature of the home being recorded. */
export enum VirtualAssessmentRecording_RecordedFeature {
  UNKNOWN = 0,
  KITCHEN = 1,
  LIVING_ROOM = 2,
  MASTER_BEDROOM_AND_BATHROOM = 3,
  BACKYARD = 4,
  FRONTYARD = 5,
  WHOLE_HOME = 6,
  UTILITIES = 7,
  SELLER_DISCLOSURE_DOCUMENT = 8,
  UNRECOGNIZED = -1,
}

export function virtualAssessmentRecording_RecordedFeatureFromJSON(
  object: any,
): VirtualAssessmentRecording_RecordedFeature {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return VirtualAssessmentRecording_RecordedFeature.UNKNOWN;
    case 1:
    case "KITCHEN":
      return VirtualAssessmentRecording_RecordedFeature.KITCHEN;
    case 2:
    case "LIVING_ROOM":
      return VirtualAssessmentRecording_RecordedFeature.LIVING_ROOM;
    case 3:
    case "MASTER_BEDROOM_AND_BATHROOM":
      return VirtualAssessmentRecording_RecordedFeature.MASTER_BEDROOM_AND_BATHROOM;
    case 4:
    case "BACKYARD":
      return VirtualAssessmentRecording_RecordedFeature.BACKYARD;
    case 5:
    case "FRONTYARD":
      return VirtualAssessmentRecording_RecordedFeature.FRONTYARD;
    case 6:
    case "WHOLE_HOME":
      return VirtualAssessmentRecording_RecordedFeature.WHOLE_HOME;
    case 7:
    case "UTILITIES":
      return VirtualAssessmentRecording_RecordedFeature.UTILITIES;
    case 8:
    case "SELLER_DISCLOSURE_DOCUMENT":
      return VirtualAssessmentRecording_RecordedFeature.SELLER_DISCLOSURE_DOCUMENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VirtualAssessmentRecording_RecordedFeature.UNRECOGNIZED;
  }
}

export function virtualAssessmentRecording_RecordedFeatureToJSON(
  object: VirtualAssessmentRecording_RecordedFeature,
): string {
  switch (object) {
    case VirtualAssessmentRecording_RecordedFeature.UNKNOWN:
      return "UNKNOWN";
    case VirtualAssessmentRecording_RecordedFeature.KITCHEN:
      return "KITCHEN";
    case VirtualAssessmentRecording_RecordedFeature.LIVING_ROOM:
      return "LIVING_ROOM";
    case VirtualAssessmentRecording_RecordedFeature.MASTER_BEDROOM_AND_BATHROOM:
      return "MASTER_BEDROOM_AND_BATHROOM";
    case VirtualAssessmentRecording_RecordedFeature.BACKYARD:
      return "BACKYARD";
    case VirtualAssessmentRecording_RecordedFeature.FRONTYARD:
      return "FRONTYARD";
    case VirtualAssessmentRecording_RecordedFeature.WHOLE_HOME:
      return "WHOLE_HOME";
    case VirtualAssessmentRecording_RecordedFeature.UTILITIES:
      return "UTILITIES";
    case VirtualAssessmentRecording_RecordedFeature.SELLER_DISCLOSURE_DOCUMENT:
      return "SELLER_DISCLOSURE_DOCUMENT";
    case VirtualAssessmentRecording_RecordedFeature.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * This message encapsulates all trade in related data.
 * Next ID: 2
 */
export interface TradeInSpecificData {
  interestedInTradeIn: TradeInSpecificData_Interest;
}

export enum TradeInSpecificData_Interest {
  UNSET = 0,
  INTERESTED = 1,
  NOT_INTERESTED = 2,
  UNRECOGNIZED = -1,
}

export function tradeInSpecificData_InterestFromJSON(object: any): TradeInSpecificData_Interest {
  switch (object) {
    case 0:
    case "UNSET":
      return TradeInSpecificData_Interest.UNSET;
    case 1:
    case "INTERESTED":
      return TradeInSpecificData_Interest.INTERESTED;
    case 2:
    case "NOT_INTERESTED":
      return TradeInSpecificData_Interest.NOT_INTERESTED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TradeInSpecificData_Interest.UNRECOGNIZED;
  }
}

export function tradeInSpecificData_InterestToJSON(object: TradeInSpecificData_Interest): string {
  switch (object) {
    case TradeInSpecificData_Interest.UNSET:
      return "UNSET";
    case TradeInSpecificData_Interest.INTERESTED:
      return "INTERESTED";
    case TradeInSpecificData_Interest.NOT_INTERESTED:
      return "NOT_INTERESTED";
    case TradeInSpecificData_Interest.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Next ID: 3 */
export interface OfferFeedback {
  sellerFeedback?:
    | SellerFeedback
    | undefined;
  /** Flag indicating that the seller has completed providing their feedback. */
  sellerComplete: boolean;
}

/**
 * Feedback provided by the seller for our offer. The schema is defined in
 * this way to maintain compatibility with existing feedback stored in
 * Web's `offer_feedbacks` table.
 *
 * Next ID: 22
 */
export interface SellerFeedback {
  /** Fields set by Admin */
  adminDidUploadDocuments?: string | undefined;
  adminNotes?:
    | string
    | undefined;
  /** Feedback */
  feedback?:
    | string
    | undefined;
  /** Value too low */
  homeValueTooLowPrimaryReason?: string | undefined;
  homeValueTooLowSecondaryReason?: string | undefined;
  homeValueTooLowEstHomeValueCents?: number | undefined;
  homeValueTooLowFreeform?:
    | string
    | undefined;
  /** Fees too high */
  feesTooHighFreeform?:
    | string
    | undefined;
  /** Incorrect home details */
  homeValueTooLowIncorrectHomeDetailsFreeform?: string | undefined;
  homeValueTooLowIncorrectHomeDetailsUploads: SellerFeedback_Upload[];
  /** Lower than other */
  homeValueTooLowLowerThanOtherAgentQuoteCents?: number | undefined;
  homeValueTooLowLowerThanOtherFreeform?: string | undefined;
  homeValueTooLowLowerThanOtherSource?: string | undefined;
  homeValueTooLowLowerThanOtherUploads: SellerFeedback_Upload[];
  /** Missed comp */
  homeValueTooLowMissedCompFreeform?: string | undefined;
  homeValueTooLowMissedCompReason?: string | undefined;
  homeValueTooLowMissedCompUploads: SellerFeedback_Upload[];
  /** Missed upgrades */
  homeValueTooLowMissedUpgradesFreeform?: string | undefined;
  homeValueTooLowMissedUpgradesUpgrades?: SellerFeedback_Upgrades | undefined;
  homeValueTooLowMissedUpgradesUploads: SellerFeedback_Upload[];
  /** Looks fair */
  looksFairNotReadyToSell?: string | undefined;
}

/**
 * Upload represents a file uploaded by the seller. Currently, uploads are
 * done using Filestack and these fields come from Filestack's responses.
 * Next ID: 5
 */
export interface SellerFeedback_Upload {
  /** e.g. uploads/photos/Offer/653575/uIGB437XTmahVntUIuWY_0F6712FD-8E93-4470-9380-AB16625BDCAD.jpeg */
  key: string;
  /** e.g. https://cdn.filestackcontent.com/i2prVhOZSTect0Rot4fm */
  url: string;
  /** e.g. 0F6712FD-8E93-4470-9380-AB16625BDCAD.jpeg */
  filename: string;
  /** e.g. image/jpeg */
  mimetype: string;
}

/**
 * Upgrades that were missed that may help in re-evaluating the offer.
 * Next ID: 4
 */
export interface SellerFeedback_Upgrades {
  /** Square footage, room additions, etc. */
  incorrectHomeInfo: boolean;
  /** Flooring, appliances, countertops, etc. */
  majorUpgrades: boolean;
  other: boolean;
}

/**
 * TODO: replace this OfferRequest with common/sell/offer.proto:OfferRequest
 * Next ID: 3
 */
export interface OfferRequest {
  source: OfferRequest_Source;
  id: number;
}

/** Next ID: 27 */
export enum OfferRequest_Source {
  UNKNOWN_OFFER_SOURCE = 0,
  REFRESH_ADMIN = 1,
  REFRESH_OFFER_DASHBOARD = 2,
  REFRESH_BROKERAGE_OFFER_DASHBOARD = 3,
  REFRESH_SELLER_FEEDBACK = 4,
  REFRESH_REENGAGEMENT_EMAILS = 5,
  REFRESH_CORRECTED_SQ_FT_DATA = 6,
  INITIAL_LEAD_FLOW = 7,
  INCOMPLETE_INITIAL_LEAD_FLOW = 8,
  OFFER_REENGAGEMENT_CAMPAIGN = 9,
  AUTOMATED_REENGAGEMENT_OFFER = 10,
  MOBILE_APP = 11,
  REIT_BULK_REQUEST = 12,
  OFFER_REVIEW = 13,
  /** FLYWHEEL - FLYWHEEL is used for offer requests on leads from the FLYWHEEL channel */
  FLYWHEEL = 14,
  /** EMAIL_PROSPECTING - EMAIL_PROSPECTING is used for offer requests on leads from the EMAIL_PROSPECTING channel */
  EMAIL_PROSPECTING = 15,
  /** OUTBOUND_OFFERS_LISTED - OUTBOUND_OFFERS_LISTED is used for 2020 Hackweek experiment of sending offers to listed homes */
  OUTBOUND_OFFERS_LISTED = 16,
  /** REFRESH_PARTNER_REFERRAL - REFRESH_PARTNER_REFERRAL is used for offers refreshed from the partnerships referral dashboard */
  REFRESH_PARTNER_REFERRAL = 17,
  /** HOME_RESERVE - HOME_RESERVE is used for offers requests on leads from the HOME_RESERVE channel */
  HOME_RESERVE = 18,
  /** HOMEPAGE_RETURN_EXPERIENCE - HOMEPAGE_RETURN_EXPERIENCE is used for offer refreshes that are triggered from the homepage */
  HOMEPAGE_RETURN_EXPERIENCE = 19,
  /** REFRESH_HOME_DETAILS - REFRESH_HOME_DETAILS is used for offer refreshes from the offer dashboard home details page */
  REFRESH_HOME_DETAILS = 20,
  /** REFRESH_FROM_ENG - REFRESH_FROM_ENG is used for backfilling any refresh properties that expierenced a break in flow due to an error */
  REFRESH_FROM_ENG = 21,
  /** REFRESH_PARTNER_CTA - REFRESH_PARTNER_CTA is used for offer requests that come from Opendoor partners */
  REFRESH_PARTNER_CTA = 22,
  /** RETURN_TO_HUB_AUTO_REFRESH - RETURN_TO_HUB_AUTO_REFRESH is used for offer refreshes automatically made when users return to the Hub */
  RETURN_TO_HUB_AUTO_REFRESH = 23,
  EXCLUSIVE_OFFERS = 24,
  REFRESH_PREVIOUSLY_DENIED = 25,
  /** AUTOMATED_AGENT_REENGAGEMENT_OFFER - AUTOMATED_AGENT_REENGAGEMENT_OFFER is used for agent refreshes triggered via the daily DAG */
  AUTOMATED_AGENT_REENGAGEMENT_OFFER = 26,
  UNRECOGNIZED = -1,
}

export function offerRequest_SourceFromJSON(object: any): OfferRequest_Source {
  switch (object) {
    case 0:
    case "UNKNOWN_OFFER_SOURCE":
      return OfferRequest_Source.UNKNOWN_OFFER_SOURCE;
    case 1:
    case "REFRESH_ADMIN":
      return OfferRequest_Source.REFRESH_ADMIN;
    case 2:
    case "REFRESH_OFFER_DASHBOARD":
      return OfferRequest_Source.REFRESH_OFFER_DASHBOARD;
    case 3:
    case "REFRESH_BROKERAGE_OFFER_DASHBOARD":
      return OfferRequest_Source.REFRESH_BROKERAGE_OFFER_DASHBOARD;
    case 4:
    case "REFRESH_SELLER_FEEDBACK":
      return OfferRequest_Source.REFRESH_SELLER_FEEDBACK;
    case 5:
    case "REFRESH_REENGAGEMENT_EMAILS":
      return OfferRequest_Source.REFRESH_REENGAGEMENT_EMAILS;
    case 6:
    case "REFRESH_CORRECTED_SQ_FT_DATA":
      return OfferRequest_Source.REFRESH_CORRECTED_SQ_FT_DATA;
    case 7:
    case "INITIAL_LEAD_FLOW":
      return OfferRequest_Source.INITIAL_LEAD_FLOW;
    case 8:
    case "INCOMPLETE_INITIAL_LEAD_FLOW":
      return OfferRequest_Source.INCOMPLETE_INITIAL_LEAD_FLOW;
    case 9:
    case "OFFER_REENGAGEMENT_CAMPAIGN":
      return OfferRequest_Source.OFFER_REENGAGEMENT_CAMPAIGN;
    case 10:
    case "AUTOMATED_REENGAGEMENT_OFFER":
      return OfferRequest_Source.AUTOMATED_REENGAGEMENT_OFFER;
    case 11:
    case "MOBILE_APP":
      return OfferRequest_Source.MOBILE_APP;
    case 12:
    case "REIT_BULK_REQUEST":
      return OfferRequest_Source.REIT_BULK_REQUEST;
    case 13:
    case "OFFER_REVIEW":
      return OfferRequest_Source.OFFER_REVIEW;
    case 14:
    case "FLYWHEEL":
      return OfferRequest_Source.FLYWHEEL;
    case 15:
    case "EMAIL_PROSPECTING":
      return OfferRequest_Source.EMAIL_PROSPECTING;
    case 16:
    case "OUTBOUND_OFFERS_LISTED":
      return OfferRequest_Source.OUTBOUND_OFFERS_LISTED;
    case 17:
    case "REFRESH_PARTNER_REFERRAL":
      return OfferRequest_Source.REFRESH_PARTNER_REFERRAL;
    case 18:
    case "HOME_RESERVE":
      return OfferRequest_Source.HOME_RESERVE;
    case 19:
    case "HOMEPAGE_RETURN_EXPERIENCE":
      return OfferRequest_Source.HOMEPAGE_RETURN_EXPERIENCE;
    case 20:
    case "REFRESH_HOME_DETAILS":
      return OfferRequest_Source.REFRESH_HOME_DETAILS;
    case 21:
    case "REFRESH_FROM_ENG":
      return OfferRequest_Source.REFRESH_FROM_ENG;
    case 22:
    case "REFRESH_PARTNER_CTA":
      return OfferRequest_Source.REFRESH_PARTNER_CTA;
    case 23:
    case "RETURN_TO_HUB_AUTO_REFRESH":
      return OfferRequest_Source.RETURN_TO_HUB_AUTO_REFRESH;
    case 24:
    case "EXCLUSIVE_OFFERS":
      return OfferRequest_Source.EXCLUSIVE_OFFERS;
    case 25:
    case "REFRESH_PREVIOUSLY_DENIED":
      return OfferRequest_Source.REFRESH_PREVIOUSLY_DENIED;
    case 26:
    case "AUTOMATED_AGENT_REENGAGEMENT_OFFER":
      return OfferRequest_Source.AUTOMATED_AGENT_REENGAGEMENT_OFFER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OfferRequest_Source.UNRECOGNIZED;
  }
}

export function offerRequest_SourceToJSON(object: OfferRequest_Source): string {
  switch (object) {
    case OfferRequest_Source.UNKNOWN_OFFER_SOURCE:
      return "UNKNOWN_OFFER_SOURCE";
    case OfferRequest_Source.REFRESH_ADMIN:
      return "REFRESH_ADMIN";
    case OfferRequest_Source.REFRESH_OFFER_DASHBOARD:
      return "REFRESH_OFFER_DASHBOARD";
    case OfferRequest_Source.REFRESH_BROKERAGE_OFFER_DASHBOARD:
      return "REFRESH_BROKERAGE_OFFER_DASHBOARD";
    case OfferRequest_Source.REFRESH_SELLER_FEEDBACK:
      return "REFRESH_SELLER_FEEDBACK";
    case OfferRequest_Source.REFRESH_REENGAGEMENT_EMAILS:
      return "REFRESH_REENGAGEMENT_EMAILS";
    case OfferRequest_Source.REFRESH_CORRECTED_SQ_FT_DATA:
      return "REFRESH_CORRECTED_SQ_FT_DATA";
    case OfferRequest_Source.INITIAL_LEAD_FLOW:
      return "INITIAL_LEAD_FLOW";
    case OfferRequest_Source.INCOMPLETE_INITIAL_LEAD_FLOW:
      return "INCOMPLETE_INITIAL_LEAD_FLOW";
    case OfferRequest_Source.OFFER_REENGAGEMENT_CAMPAIGN:
      return "OFFER_REENGAGEMENT_CAMPAIGN";
    case OfferRequest_Source.AUTOMATED_REENGAGEMENT_OFFER:
      return "AUTOMATED_REENGAGEMENT_OFFER";
    case OfferRequest_Source.MOBILE_APP:
      return "MOBILE_APP";
    case OfferRequest_Source.REIT_BULK_REQUEST:
      return "REIT_BULK_REQUEST";
    case OfferRequest_Source.OFFER_REVIEW:
      return "OFFER_REVIEW";
    case OfferRequest_Source.FLYWHEEL:
      return "FLYWHEEL";
    case OfferRequest_Source.EMAIL_PROSPECTING:
      return "EMAIL_PROSPECTING";
    case OfferRequest_Source.OUTBOUND_OFFERS_LISTED:
      return "OUTBOUND_OFFERS_LISTED";
    case OfferRequest_Source.REFRESH_PARTNER_REFERRAL:
      return "REFRESH_PARTNER_REFERRAL";
    case OfferRequest_Source.HOME_RESERVE:
      return "HOME_RESERVE";
    case OfferRequest_Source.HOMEPAGE_RETURN_EXPERIENCE:
      return "HOMEPAGE_RETURN_EXPERIENCE";
    case OfferRequest_Source.REFRESH_HOME_DETAILS:
      return "REFRESH_HOME_DETAILS";
    case OfferRequest_Source.REFRESH_FROM_ENG:
      return "REFRESH_FROM_ENG";
    case OfferRequest_Source.REFRESH_PARTNER_CTA:
      return "REFRESH_PARTNER_CTA";
    case OfferRequest_Source.RETURN_TO_HUB_AUTO_REFRESH:
      return "RETURN_TO_HUB_AUTO_REFRESH";
    case OfferRequest_Source.EXCLUSIVE_OFFERS:
      return "EXCLUSIVE_OFFERS";
    case OfferRequest_Source.REFRESH_PREVIOUSLY_DENIED:
      return "REFRESH_PREVIOUSLY_DENIED";
    case OfferRequest_Source.AUTOMATED_AGENT_REENGAGEMENT_OFFER:
      return "AUTOMATED_AGENT_REENGAGEMENT_OFFER";
    case OfferRequest_Source.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseOpendoorDirectSpecificData(): OpendoorDirectSpecificData {
  return {
    webToken: "",
    offerFeedback: undefined,
    tradeIn: undefined,
    offerRequests: [],
    selfServiceVirtualAssessmentRecordingData: undefined,
    offerRequestsCommon: [],
  };
}

export const OpendoorDirectSpecificData = {
  fromJSON(object: any): OpendoorDirectSpecificData {
    return {
      webToken: isSet(object.webToken) ? String(object.webToken) : "",
      offerFeedback: isSet(object.offerFeedback) ? OfferFeedback.fromJSON(object.offerFeedback) : undefined,
      tradeIn: isSet(object.tradeIn) ? TradeInSpecificData.fromJSON(object.tradeIn) : undefined,
      offerRequests: Array.isArray(object?.offerRequests)
        ? object.offerRequests.map((e: any) => OfferRequest.fromJSON(e))
        : [],
      selfServiceVirtualAssessmentRecordingData: isSet(object.selfServiceVirtualAssessmentRecordingData)
        ? SelfServiceVirtualAssessmentData.fromJSON(object.selfServiceVirtualAssessmentRecordingData)
        : undefined,
      offerRequestsCommon: Array.isArray(object?.offerRequestsCommon)
        ? object.offerRequestsCommon.map((e: any) => OfferRequest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: OpendoorDirectSpecificData): unknown {
    const obj: any = {};
    if (message.webToken !== "") {
      obj.webToken = message.webToken;
    }
    if (message.offerFeedback !== undefined) {
      obj.offerFeedback = OfferFeedback.toJSON(message.offerFeedback);
    }
    if (message.tradeIn !== undefined) {
      obj.tradeIn = TradeInSpecificData.toJSON(message.tradeIn);
    }
    if (message.offerRequests?.length) {
      obj.offerRequests = message.offerRequests.map((e) => OfferRequest.toJSON(e));
    }
    if (message.selfServiceVirtualAssessmentRecordingData !== undefined) {
      obj.selfServiceVirtualAssessmentRecordingData = SelfServiceVirtualAssessmentData.toJSON(
        message.selfServiceVirtualAssessmentRecordingData,
      );
    }
    if (message.offerRequestsCommon?.length) {
      obj.offerRequestsCommon = message.offerRequestsCommon.map((e) => OfferRequest.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OpendoorDirectSpecificData>, I>>(base?: I): OpendoorDirectSpecificData {
    return OpendoorDirectSpecificData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OpendoorDirectSpecificData>, I>>(object: I): OpendoorDirectSpecificData {
    const message = createBaseOpendoorDirectSpecificData();
    message.webToken = object.webToken ?? "";
    message.offerFeedback = (object.offerFeedback !== undefined && object.offerFeedback !== null)
      ? OfferFeedback.fromPartial(object.offerFeedback)
      : undefined;
    message.tradeIn = (object.tradeIn !== undefined && object.tradeIn !== null)
      ? TradeInSpecificData.fromPartial(object.tradeIn)
      : undefined;
    message.offerRequests = object.offerRequests?.map((e) => OfferRequest.fromPartial(e)) || [];
    message.selfServiceVirtualAssessmentRecordingData =
      (object.selfServiceVirtualAssessmentRecordingData !== undefined &&
          object.selfServiceVirtualAssessmentRecordingData !== null)
        ? SelfServiceVirtualAssessmentData.fromPartial(object.selfServiceVirtualAssessmentRecordingData)
        : undefined;
    message.offerRequestsCommon = object.offerRequestsCommon?.map((e) => OfferRequest1.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSelfServiceVirtualAssessmentData(): SelfServiceVirtualAssessmentData {
  return { experimentGroup: 0, virtualAssessmentRecordings: [] };
}

export const SelfServiceVirtualAssessmentData = {
  fromJSON(object: any): SelfServiceVirtualAssessmentData {
    return {
      experimentGroup: isSet(object.experimentGroup)
        ? selfServiceVirtualAssessmentExperimentGroupFromJSON(object.experimentGroup)
        : 0,
      virtualAssessmentRecordings: Array.isArray(object?.virtualAssessmentRecordings)
        ? object.virtualAssessmentRecordings.map((e: any) => VirtualAssessmentRecording.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SelfServiceVirtualAssessmentData): unknown {
    const obj: any = {};
    if (message.experimentGroup !== 0) {
      obj.experimentGroup = selfServiceVirtualAssessmentExperimentGroupToJSON(message.experimentGroup);
    }
    if (message.virtualAssessmentRecordings?.length) {
      obj.virtualAssessmentRecordings = message.virtualAssessmentRecordings.map((e) =>
        VirtualAssessmentRecording.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SelfServiceVirtualAssessmentData>, I>>(
    base?: I,
  ): SelfServiceVirtualAssessmentData {
    return SelfServiceVirtualAssessmentData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SelfServiceVirtualAssessmentData>, I>>(
    object: I,
  ): SelfServiceVirtualAssessmentData {
    const message = createBaseSelfServiceVirtualAssessmentData();
    message.experimentGroup = object.experimentGroup ?? 0;
    message.virtualAssessmentRecordings =
      object.virtualAssessmentRecordings?.map((e) => VirtualAssessmentRecording.fromPartial(e)) || [];
    return message;
  },
};

function createBaseVirtualAssessmentRecording(): VirtualAssessmentRecording {
  return { recordedFeature: 0, filename: "", s3Directory: "" };
}

export const VirtualAssessmentRecording = {
  fromJSON(object: any): VirtualAssessmentRecording {
    return {
      recordedFeature: isSet(object.recordedFeature)
        ? virtualAssessmentRecording_RecordedFeatureFromJSON(object.recordedFeature)
        : 0,
      filename: isSet(object.filename) ? String(object.filename) : "",
      s3Directory: isSet(object.s3Directory) ? String(object.s3Directory) : "",
    };
  },

  toJSON(message: VirtualAssessmentRecording): unknown {
    const obj: any = {};
    if (message.recordedFeature !== 0) {
      obj.recordedFeature = virtualAssessmentRecording_RecordedFeatureToJSON(message.recordedFeature);
    }
    if (message.filename !== "") {
      obj.filename = message.filename;
    }
    if (message.s3Directory !== "") {
      obj.s3Directory = message.s3Directory;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VirtualAssessmentRecording>, I>>(base?: I): VirtualAssessmentRecording {
    return VirtualAssessmentRecording.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VirtualAssessmentRecording>, I>>(object: I): VirtualAssessmentRecording {
    const message = createBaseVirtualAssessmentRecording();
    message.recordedFeature = object.recordedFeature ?? 0;
    message.filename = object.filename ?? "";
    message.s3Directory = object.s3Directory ?? "";
    return message;
  },
};

function createBaseTradeInSpecificData(): TradeInSpecificData {
  return { interestedInTradeIn: 0 };
}

export const TradeInSpecificData = {
  fromJSON(object: any): TradeInSpecificData {
    return {
      interestedInTradeIn: isSet(object.interestedInTradeIn)
        ? tradeInSpecificData_InterestFromJSON(object.interestedInTradeIn)
        : 0,
    };
  },

  toJSON(message: TradeInSpecificData): unknown {
    const obj: any = {};
    if (message.interestedInTradeIn !== 0) {
      obj.interestedInTradeIn = tradeInSpecificData_InterestToJSON(message.interestedInTradeIn);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradeInSpecificData>, I>>(base?: I): TradeInSpecificData {
    return TradeInSpecificData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradeInSpecificData>, I>>(object: I): TradeInSpecificData {
    const message = createBaseTradeInSpecificData();
    message.interestedInTradeIn = object.interestedInTradeIn ?? 0;
    return message;
  },
};

function createBaseOfferFeedback(): OfferFeedback {
  return { sellerFeedback: undefined, sellerComplete: false };
}

export const OfferFeedback = {
  fromJSON(object: any): OfferFeedback {
    return {
      sellerFeedback: isSet(object.sellerFeedback) ? SellerFeedback.fromJSON(object.sellerFeedback) : undefined,
      sellerComplete: isSet(object.sellerComplete) ? Boolean(object.sellerComplete) : false,
    };
  },

  toJSON(message: OfferFeedback): unknown {
    const obj: any = {};
    if (message.sellerFeedback !== undefined) {
      obj.sellerFeedback = SellerFeedback.toJSON(message.sellerFeedback);
    }
    if (message.sellerComplete === true) {
      obj.sellerComplete = message.sellerComplete;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OfferFeedback>, I>>(base?: I): OfferFeedback {
    return OfferFeedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OfferFeedback>, I>>(object: I): OfferFeedback {
    const message = createBaseOfferFeedback();
    message.sellerFeedback = (object.sellerFeedback !== undefined && object.sellerFeedback !== null)
      ? SellerFeedback.fromPartial(object.sellerFeedback)
      : undefined;
    message.sellerComplete = object.sellerComplete ?? false;
    return message;
  },
};

function createBaseSellerFeedback(): SellerFeedback {
  return {
    adminDidUploadDocuments: undefined,
    adminNotes: undefined,
    feedback: undefined,
    homeValueTooLowPrimaryReason: undefined,
    homeValueTooLowSecondaryReason: undefined,
    homeValueTooLowEstHomeValueCents: undefined,
    homeValueTooLowFreeform: undefined,
    feesTooHighFreeform: undefined,
    homeValueTooLowIncorrectHomeDetailsFreeform: undefined,
    homeValueTooLowIncorrectHomeDetailsUploads: [],
    homeValueTooLowLowerThanOtherAgentQuoteCents: undefined,
    homeValueTooLowLowerThanOtherFreeform: undefined,
    homeValueTooLowLowerThanOtherSource: undefined,
    homeValueTooLowLowerThanOtherUploads: [],
    homeValueTooLowMissedCompFreeform: undefined,
    homeValueTooLowMissedCompReason: undefined,
    homeValueTooLowMissedCompUploads: [],
    homeValueTooLowMissedUpgradesFreeform: undefined,
    homeValueTooLowMissedUpgradesUpgrades: undefined,
    homeValueTooLowMissedUpgradesUploads: [],
    looksFairNotReadyToSell: undefined,
  };
}

export const SellerFeedback = {
  fromJSON(object: any): SellerFeedback {
    return {
      adminDidUploadDocuments: isSet(object["admin.did_upload_documents"])
        ? String(object["admin.did_upload_documents"])
        : undefined,
      adminNotes: isSet(object["admin.notes"]) ? String(object["admin.notes"]) : undefined,
      feedback: isSet(object.feedback) ? String(object.feedback) : undefined,
      homeValueTooLowPrimaryReason: isSet(object["home_value_too_low.primary_reason"])
        ? String(object["home_value_too_low.primary_reason"])
        : undefined,
      homeValueTooLowSecondaryReason: isSet(object["home_value_too_low.secondary_reason"])
        ? String(object["home_value_too_low.secondary_reason"])
        : undefined,
      homeValueTooLowEstHomeValueCents: isSet(object["home_value_too_low.est_home_value_cents"])
        ? Number(object["home_value_too_low.est_home_value_cents"])
        : undefined,
      homeValueTooLowFreeform: isSet(object["home_value_too_low.freeform"])
        ? String(object["home_value_too_low.freeform"])
        : undefined,
      feesTooHighFreeform: isSet(object["fees_too_high.freeform"])
        ? String(object["fees_too_high.freeform"])
        : undefined,
      homeValueTooLowIncorrectHomeDetailsFreeform: isSet(object["home_value_too_low.incorrect_home_details.freeform"])
        ? String(object["home_value_too_low.incorrect_home_details.freeform"])
        : undefined,
      homeValueTooLowIncorrectHomeDetailsUploads:
        Array.isArray(object?.["home_value_too_low.incorrect_home_details.uploads"])
          ? object["home_value_too_low.incorrect_home_details.uploads"].map((e: any) =>
            SellerFeedback_Upload.fromJSON(e)
          )
          : [],
      homeValueTooLowLowerThanOtherAgentQuoteCents:
        isSet(object["home_value_too_low.lower_than_other.agent_quote_cents"])
          ? Number(object["home_value_too_low.lower_than_other.agent_quote_cents"])
          : undefined,
      homeValueTooLowLowerThanOtherFreeform: isSet(object["home_value_too_low.lower_than_other.freeform"])
        ? String(object["home_value_too_low.lower_than_other.freeform"])
        : undefined,
      homeValueTooLowLowerThanOtherSource: isSet(object["home_value_too_low.lower_than_other.source"])
        ? String(object["home_value_too_low.lower_than_other.source"])
        : undefined,
      homeValueTooLowLowerThanOtherUploads: Array.isArray(object?.["home_value_too_low.lower_than_other.uploads"])
        ? object["home_value_too_low.lower_than_other.uploads"].map((e: any) => SellerFeedback_Upload.fromJSON(e))
        : [],
      homeValueTooLowMissedCompFreeform: isSet(object["home_value_too_low.missed_comp.freeform"])
        ? String(object["home_value_too_low.missed_comp.freeform"])
        : undefined,
      homeValueTooLowMissedCompReason: isSet(object["home_value_too_low.missed_comp.reason"])
        ? String(object["home_value_too_low.missed_comp.reason"])
        : undefined,
      homeValueTooLowMissedCompUploads: Array.isArray(object?.["home_value_too_low.missed_comp.uploads"])
        ? object["home_value_too_low.missed_comp.uploads"].map((e: any) => SellerFeedback_Upload.fromJSON(e))
        : [],
      homeValueTooLowMissedUpgradesFreeform: isSet(object["home_value_too_low.missed_upgrades.freeform"])
        ? String(object["home_value_too_low.missed_upgrades.freeform"])
        : undefined,
      homeValueTooLowMissedUpgradesUpgrades: isSet(object["home_value_too_low.missed_upgrades.upgrades"])
        ? SellerFeedback_Upgrades.fromJSON(object["home_value_too_low.missed_upgrades.upgrades"])
        : undefined,
      homeValueTooLowMissedUpgradesUploads: Array.isArray(object?.["home_value_too_low.missed_upgrades.uploads"])
        ? object["home_value_too_low.missed_upgrades.uploads"].map((e: any) => SellerFeedback_Upload.fromJSON(e))
        : [],
      looksFairNotReadyToSell: isSet(object["looks_fair.not_ready_to_sell"])
        ? String(object["looks_fair.not_ready_to_sell"])
        : undefined,
    };
  },

  toJSON(message: SellerFeedback): unknown {
    const obj: any = {};
    if (message.adminDidUploadDocuments !== undefined) {
      obj["admin.did_upload_documents"] = message.adminDidUploadDocuments;
    }
    if (message.adminNotes !== undefined) {
      obj["admin.notes"] = message.adminNotes;
    }
    if (message.feedback !== undefined) {
      obj.feedback = message.feedback;
    }
    if (message.homeValueTooLowPrimaryReason !== undefined) {
      obj["home_value_too_low.primary_reason"] = message.homeValueTooLowPrimaryReason;
    }
    if (message.homeValueTooLowSecondaryReason !== undefined) {
      obj["home_value_too_low.secondary_reason"] = message.homeValueTooLowSecondaryReason;
    }
    if (message.homeValueTooLowEstHomeValueCents !== undefined) {
      obj["home_value_too_low.est_home_value_cents"] = message.homeValueTooLowEstHomeValueCents;
    }
    if (message.homeValueTooLowFreeform !== undefined) {
      obj["home_value_too_low.freeform"] = message.homeValueTooLowFreeform;
    }
    if (message.feesTooHighFreeform !== undefined) {
      obj["fees_too_high.freeform"] = message.feesTooHighFreeform;
    }
    if (message.homeValueTooLowIncorrectHomeDetailsFreeform !== undefined) {
      obj["home_value_too_low.incorrect_home_details.freeform"] = message.homeValueTooLowIncorrectHomeDetailsFreeform;
    }
    if (message.homeValueTooLowIncorrectHomeDetailsUploads?.length) {
      obj["home_value_too_low.incorrect_home_details.uploads"] = message.homeValueTooLowIncorrectHomeDetailsUploads.map(
        (e) => SellerFeedback_Upload.toJSON(e)
      );
    }
    if (message.homeValueTooLowLowerThanOtherAgentQuoteCents !== undefined) {
      obj["home_value_too_low.lower_than_other.agent_quote_cents"] =
        message.homeValueTooLowLowerThanOtherAgentQuoteCents;
    }
    if (message.homeValueTooLowLowerThanOtherFreeform !== undefined) {
      obj["home_value_too_low.lower_than_other.freeform"] = message.homeValueTooLowLowerThanOtherFreeform;
    }
    if (message.homeValueTooLowLowerThanOtherSource !== undefined) {
      obj["home_value_too_low.lower_than_other.source"] = message.homeValueTooLowLowerThanOtherSource;
    }
    if (message.homeValueTooLowLowerThanOtherUploads?.length) {
      obj["home_value_too_low.lower_than_other.uploads"] = message.homeValueTooLowLowerThanOtherUploads.map((e) =>
        SellerFeedback_Upload.toJSON(e)
      );
    }
    if (message.homeValueTooLowMissedCompFreeform !== undefined) {
      obj["home_value_too_low.missed_comp.freeform"] = message.homeValueTooLowMissedCompFreeform;
    }
    if (message.homeValueTooLowMissedCompReason !== undefined) {
      obj["home_value_too_low.missed_comp.reason"] = message.homeValueTooLowMissedCompReason;
    }
    if (message.homeValueTooLowMissedCompUploads?.length) {
      obj["home_value_too_low.missed_comp.uploads"] = message.homeValueTooLowMissedCompUploads.map((e) =>
        SellerFeedback_Upload.toJSON(e)
      );
    }
    if (message.homeValueTooLowMissedUpgradesFreeform !== undefined) {
      obj["home_value_too_low.missed_upgrades.freeform"] = message.homeValueTooLowMissedUpgradesFreeform;
    }
    if (message.homeValueTooLowMissedUpgradesUpgrades !== undefined) {
      obj["home_value_too_low.missed_upgrades.upgrades"] = SellerFeedback_Upgrades.toJSON(
        message.homeValueTooLowMissedUpgradesUpgrades,
      );
    }
    if (message.homeValueTooLowMissedUpgradesUploads?.length) {
      obj["home_value_too_low.missed_upgrades.uploads"] = message.homeValueTooLowMissedUpgradesUploads.map((e) =>
        SellerFeedback_Upload.toJSON(e)
      );
    }
    if (message.looksFairNotReadyToSell !== undefined) {
      obj["looks_fair.not_ready_to_sell"] = message.looksFairNotReadyToSell;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellerFeedback>, I>>(base?: I): SellerFeedback {
    return SellerFeedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellerFeedback>, I>>(object: I): SellerFeedback {
    const message = createBaseSellerFeedback();
    message.adminDidUploadDocuments = object.adminDidUploadDocuments ?? undefined;
    message.adminNotes = object.adminNotes ?? undefined;
    message.feedback = object.feedback ?? undefined;
    message.homeValueTooLowPrimaryReason = object.homeValueTooLowPrimaryReason ?? undefined;
    message.homeValueTooLowSecondaryReason = object.homeValueTooLowSecondaryReason ?? undefined;
    message.homeValueTooLowEstHomeValueCents = object.homeValueTooLowEstHomeValueCents ?? undefined;
    message.homeValueTooLowFreeform = object.homeValueTooLowFreeform ?? undefined;
    message.feesTooHighFreeform = object.feesTooHighFreeform ?? undefined;
    message.homeValueTooLowIncorrectHomeDetailsFreeform = object.homeValueTooLowIncorrectHomeDetailsFreeform ??
      undefined;
    message.homeValueTooLowIncorrectHomeDetailsUploads =
      object.homeValueTooLowIncorrectHomeDetailsUploads?.map((e) => SellerFeedback_Upload.fromPartial(e)) || [];
    message.homeValueTooLowLowerThanOtherAgentQuoteCents = object.homeValueTooLowLowerThanOtherAgentQuoteCents ??
      undefined;
    message.homeValueTooLowLowerThanOtherFreeform = object.homeValueTooLowLowerThanOtherFreeform ?? undefined;
    message.homeValueTooLowLowerThanOtherSource = object.homeValueTooLowLowerThanOtherSource ?? undefined;
    message.homeValueTooLowLowerThanOtherUploads =
      object.homeValueTooLowLowerThanOtherUploads?.map((e) => SellerFeedback_Upload.fromPartial(e)) || [];
    message.homeValueTooLowMissedCompFreeform = object.homeValueTooLowMissedCompFreeform ?? undefined;
    message.homeValueTooLowMissedCompReason = object.homeValueTooLowMissedCompReason ?? undefined;
    message.homeValueTooLowMissedCompUploads =
      object.homeValueTooLowMissedCompUploads?.map((e) => SellerFeedback_Upload.fromPartial(e)) || [];
    message.homeValueTooLowMissedUpgradesFreeform = object.homeValueTooLowMissedUpgradesFreeform ?? undefined;
    message.homeValueTooLowMissedUpgradesUpgrades =
      (object.homeValueTooLowMissedUpgradesUpgrades !== undefined &&
          object.homeValueTooLowMissedUpgradesUpgrades !== null)
        ? SellerFeedback_Upgrades.fromPartial(object.homeValueTooLowMissedUpgradesUpgrades)
        : undefined;
    message.homeValueTooLowMissedUpgradesUploads =
      object.homeValueTooLowMissedUpgradesUploads?.map((e) => SellerFeedback_Upload.fromPartial(e)) || [];
    message.looksFairNotReadyToSell = object.looksFairNotReadyToSell ?? undefined;
    return message;
  },
};

function createBaseSellerFeedback_Upload(): SellerFeedback_Upload {
  return { key: "", url: "", filename: "", mimetype: "" };
}

export const SellerFeedback_Upload = {
  fromJSON(object: any): SellerFeedback_Upload {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      url: isSet(object.url) ? String(object.url) : "",
      filename: isSet(object.filename) ? String(object.filename) : "",
      mimetype: isSet(object.mimetype) ? String(object.mimetype) : "",
    };
  },

  toJSON(message: SellerFeedback_Upload): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.filename !== "") {
      obj.filename = message.filename;
    }
    if (message.mimetype !== "") {
      obj.mimetype = message.mimetype;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellerFeedback_Upload>, I>>(base?: I): SellerFeedback_Upload {
    return SellerFeedback_Upload.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellerFeedback_Upload>, I>>(object: I): SellerFeedback_Upload {
    const message = createBaseSellerFeedback_Upload();
    message.key = object.key ?? "";
    message.url = object.url ?? "";
    message.filename = object.filename ?? "";
    message.mimetype = object.mimetype ?? "";
    return message;
  },
};

function createBaseSellerFeedback_Upgrades(): SellerFeedback_Upgrades {
  return { incorrectHomeInfo: false, majorUpgrades: false, other: false };
}

export const SellerFeedback_Upgrades = {
  fromJSON(object: any): SellerFeedback_Upgrades {
    return {
      incorrectHomeInfo: isSet(object.incorrect_home_info) ? Boolean(object.incorrect_home_info) : false,
      majorUpgrades: isSet(object.major_upgrades) ? Boolean(object.major_upgrades) : false,
      other: isSet(object.other) ? Boolean(object.other) : false,
    };
  },

  toJSON(message: SellerFeedback_Upgrades): unknown {
    const obj: any = {};
    if (message.incorrectHomeInfo === true) {
      obj.incorrect_home_info = message.incorrectHomeInfo;
    }
    if (message.majorUpgrades === true) {
      obj.major_upgrades = message.majorUpgrades;
    }
    if (message.other === true) {
      obj.other = message.other;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellerFeedback_Upgrades>, I>>(base?: I): SellerFeedback_Upgrades {
    return SellerFeedback_Upgrades.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellerFeedback_Upgrades>, I>>(object: I): SellerFeedback_Upgrades {
    const message = createBaseSellerFeedback_Upgrades();
    message.incorrectHomeInfo = object.incorrectHomeInfo ?? false;
    message.majorUpgrades = object.majorUpgrades ?? false;
    message.other = object.other ?? false;
    return message;
  },
};

function createBaseOfferRequest(): OfferRequest {
  return { source: 0, id: 0 };
}

export const OfferRequest = {
  fromJSON(object: any): OfferRequest {
    return {
      source: isSet(object.source) ? offerRequest_SourceFromJSON(object.source) : 0,
      id: isSet(object.id) ? Number(object.id) : 0,
    };
  },

  toJSON(message: OfferRequest): unknown {
    const obj: any = {};
    if (message.source !== 0) {
      obj.source = offerRequest_SourceToJSON(message.source);
    }
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OfferRequest>, I>>(base?: I): OfferRequest {
    return OfferRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OfferRequest>, I>>(object: I): OfferRequest {
    const message = createBaseOfferRequest();
    message.source = object.source ?? 0;
    message.id = object.id ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
