/* eslint-disable */
import { DateMessage } from "../../common/date";

export const protobufPackage = "od_protos.web.data";

export enum SubsidyType {
  CX_SUBSIDY = 0,
  HOME_BUILDER = 1,
  NEW_PARTNERSHIP = 2,
  AGENT = 3,
  EMPLOYEE_SUBSIDY = 4,
  OPENDOOR_EXCLUSIVES_SIGNAGE_INCENTIVE = 5,
  VAL_CREDIT_SUBSIDY = 6,
  REPAIR_ADJUSTMENT = 7,
  COMPETITOR_MATCH = 8,
  OPENDOOR_EXCLUSIVES_HIGHER_OFFER_GUARANTEE_INCENTIVE = 9,
  UNRECOGNIZED = -1,
}

export function subsidyTypeFromJSON(object: any): SubsidyType {
  switch (object) {
    case 0:
    case "CX_SUBSIDY":
      return SubsidyType.CX_SUBSIDY;
    case 1:
    case "HOME_BUILDER":
      return SubsidyType.HOME_BUILDER;
    case 2:
    case "NEW_PARTNERSHIP":
      return SubsidyType.NEW_PARTNERSHIP;
    case 3:
    case "AGENT":
      return SubsidyType.AGENT;
    case 4:
    case "EMPLOYEE_SUBSIDY":
      return SubsidyType.EMPLOYEE_SUBSIDY;
    case 5:
    case "OPENDOOR_EXCLUSIVES_SIGNAGE_INCENTIVE":
      return SubsidyType.OPENDOOR_EXCLUSIVES_SIGNAGE_INCENTIVE;
    case 6:
    case "VAL_CREDIT_SUBSIDY":
      return SubsidyType.VAL_CREDIT_SUBSIDY;
    case 7:
    case "REPAIR_ADJUSTMENT":
      return SubsidyType.REPAIR_ADJUSTMENT;
    case 8:
    case "COMPETITOR_MATCH":
      return SubsidyType.COMPETITOR_MATCH;
    case 9:
    case "OPENDOOR_EXCLUSIVES_HIGHER_OFFER_GUARANTEE_INCENTIVE":
      return SubsidyType.OPENDOOR_EXCLUSIVES_HIGHER_OFFER_GUARANTEE_INCENTIVE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SubsidyType.UNRECOGNIZED;
  }
}

export function subsidyTypeToJSON(object: SubsidyType): string {
  switch (object) {
    case SubsidyType.CX_SUBSIDY:
      return "CX_SUBSIDY";
    case SubsidyType.HOME_BUILDER:
      return "HOME_BUILDER";
    case SubsidyType.NEW_PARTNERSHIP:
      return "NEW_PARTNERSHIP";
    case SubsidyType.AGENT:
      return "AGENT";
    case SubsidyType.EMPLOYEE_SUBSIDY:
      return "EMPLOYEE_SUBSIDY";
    case SubsidyType.OPENDOOR_EXCLUSIVES_SIGNAGE_INCENTIVE:
      return "OPENDOOR_EXCLUSIVES_SIGNAGE_INCENTIVE";
    case SubsidyType.VAL_CREDIT_SUBSIDY:
      return "VAL_CREDIT_SUBSIDY";
    case SubsidyType.REPAIR_ADJUSTMENT:
      return "REPAIR_ADJUSTMENT";
    case SubsidyType.COMPETITOR_MATCH:
      return "COMPETITOR_MATCH";
    case SubsidyType.OPENDOOR_EXCLUSIVES_HIGHER_OFFER_GUARANTEE_INCENTIVE:
      return "OPENDOOR_EXCLUSIVES_HIGHER_OFFER_GUARANTEE_INCENTIVE";
    case SubsidyType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PrimaryCancellationReason {
  OPENDOOR_WALK = 0,
  SELLER_INITIATED = 1,
  UNRECOGNIZED = -1,
}

export function primaryCancellationReasonFromJSON(object: any): PrimaryCancellationReason {
  switch (object) {
    case 0:
    case "OPENDOOR_WALK":
      return PrimaryCancellationReason.OPENDOOR_WALK;
    case 1:
    case "SELLER_INITIATED":
      return PrimaryCancellationReason.SELLER_INITIATED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PrimaryCancellationReason.UNRECOGNIZED;
  }
}

export function primaryCancellationReasonToJSON(object: PrimaryCancellationReason): string {
  switch (object) {
    case PrimaryCancellationReason.OPENDOOR_WALK:
      return "OPENDOOR_WALK";
    case PrimaryCancellationReason.SELLER_INITIATED:
      return "SELLER_INITIATED";
    case PrimaryCancellationReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum OpendoorWalkSecondaryCancellationReason {
  SEE_UNDERWRITING = 0,
  ADJACENT_TO_STABILIZATION = 1,
  OPENDOOR_ADDENDUM = 2,
  UNRECOGNIZED = -1,
}

export function opendoorWalkSecondaryCancellationReasonFromJSON(object: any): OpendoorWalkSecondaryCancellationReason {
  switch (object) {
    case 0:
    case "SEE_UNDERWRITING":
      return OpendoorWalkSecondaryCancellationReason.SEE_UNDERWRITING;
    case 1:
    case "ADJACENT_TO_STABILIZATION":
      return OpendoorWalkSecondaryCancellationReason.ADJACENT_TO_STABILIZATION;
    case 2:
    case "OPENDOOR_ADDENDUM":
      return OpendoorWalkSecondaryCancellationReason.OPENDOOR_ADDENDUM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OpendoorWalkSecondaryCancellationReason.UNRECOGNIZED;
  }
}

export function opendoorWalkSecondaryCancellationReasonToJSON(object: OpendoorWalkSecondaryCancellationReason): string {
  switch (object) {
    case OpendoorWalkSecondaryCancellationReason.SEE_UNDERWRITING:
      return "SEE_UNDERWRITING";
    case OpendoorWalkSecondaryCancellationReason.ADJACENT_TO_STABILIZATION:
      return "ADJACENT_TO_STABILIZATION";
    case OpendoorWalkSecondaryCancellationReason.OPENDOOR_ADDENDUM:
      return "OPENDOOR_ADDENDUM";
    case OpendoorWalkSecondaryCancellationReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SellerInitiatedSecondaryCancellationReason {
  CANNOT_FIND_NEW_HOME = 0,
  NOT_SELLING = 1,
  TRADITIONAL_AGENT = 2,
  COMPETITION = 3,
  NET_CASH = 4,
  MACRO = 5,
  OTHER_REASON = 6,
  UNRECOGNIZED = -1,
}

export function sellerInitiatedSecondaryCancellationReasonFromJSON(
  object: any,
): SellerInitiatedSecondaryCancellationReason {
  switch (object) {
    case 0:
    case "CANNOT_FIND_NEW_HOME":
      return SellerInitiatedSecondaryCancellationReason.CANNOT_FIND_NEW_HOME;
    case 1:
    case "NOT_SELLING":
      return SellerInitiatedSecondaryCancellationReason.NOT_SELLING;
    case 2:
    case "TRADITIONAL_AGENT":
      return SellerInitiatedSecondaryCancellationReason.TRADITIONAL_AGENT;
    case 3:
    case "COMPETITION":
      return SellerInitiatedSecondaryCancellationReason.COMPETITION;
    case 4:
    case "NET_CASH":
      return SellerInitiatedSecondaryCancellationReason.NET_CASH;
    case 5:
    case "MACRO":
      return SellerInitiatedSecondaryCancellationReason.MACRO;
    case 6:
    case "OTHER_REASON":
      return SellerInitiatedSecondaryCancellationReason.OTHER_REASON;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellerInitiatedSecondaryCancellationReason.UNRECOGNIZED;
  }
}

export function sellerInitiatedSecondaryCancellationReasonToJSON(
  object: SellerInitiatedSecondaryCancellationReason,
): string {
  switch (object) {
    case SellerInitiatedSecondaryCancellationReason.CANNOT_FIND_NEW_HOME:
      return "CANNOT_FIND_NEW_HOME";
    case SellerInitiatedSecondaryCancellationReason.NOT_SELLING:
      return "NOT_SELLING";
    case SellerInitiatedSecondaryCancellationReason.TRADITIONAL_AGENT:
      return "TRADITIONAL_AGENT";
    case SellerInitiatedSecondaryCancellationReason.COMPETITION:
      return "COMPETITION";
    case SellerInitiatedSecondaryCancellationReason.NET_CASH:
      return "NET_CASH";
    case SellerInitiatedSecondaryCancellationReason.MACRO:
      return "MACRO";
    case SellerInitiatedSecondaryCancellationReason.OTHER_REASON:
      return "OTHER_REASON";
    case SellerInitiatedSecondaryCancellationReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum COEChangeReason {
  SELLER_CHANGE_IN_PLANS = 0,
  SELLER_HANDLING_REPAIRS = 1,
  SELLER_INFORMATION_SHEET = 2,
  SELLER_MOVING_ISSUE = 3,
  SELLER_NEXT_HOME = 4,
  SELLER_UNREALISTIC_COE = 5,
  SELLER_UNRESPONSIVE = 6,
  SELLER_OTHER = 7,
  HOA = 8,
  OD_FWT_MISS = 9,
  OD_INSP_AVAILABILITY = 10,
  OD_NEGOTIATING_REPAIRS = 11,
  OD_REPAIRS_NOT_READY = 12,
  OD_OTHER = 13,
  TITLE_SURVEY = 14,
  TITLE_HOA = 15,
  TITLE_COMMITMENT = 16,
  TITLE_FUNDING = 17,
  TITLE_CLOSING_APPT = 18,
  TITLE_MORTGAGE_PAYOFF = 19,
  TITLE_OTHER = 20,
  TITLE_UNRESPONSIVE = 21,
  SOLAR_TRANSFER = 22,
  UNRECOGNIZED = -1,
}

export function cOEChangeReasonFromJSON(object: any): COEChangeReason {
  switch (object) {
    case 0:
    case "SELLER_CHANGE_IN_PLANS":
      return COEChangeReason.SELLER_CHANGE_IN_PLANS;
    case 1:
    case "SELLER_HANDLING_REPAIRS":
      return COEChangeReason.SELLER_HANDLING_REPAIRS;
    case 2:
    case "SELLER_INFORMATION_SHEET":
      return COEChangeReason.SELLER_INFORMATION_SHEET;
    case 3:
    case "SELLER_MOVING_ISSUE":
      return COEChangeReason.SELLER_MOVING_ISSUE;
    case 4:
    case "SELLER_NEXT_HOME":
      return COEChangeReason.SELLER_NEXT_HOME;
    case 5:
    case "SELLER_UNREALISTIC_COE":
      return COEChangeReason.SELLER_UNREALISTIC_COE;
    case 6:
    case "SELLER_UNRESPONSIVE":
      return COEChangeReason.SELLER_UNRESPONSIVE;
    case 7:
    case "SELLER_OTHER":
      return COEChangeReason.SELLER_OTHER;
    case 8:
    case "HOA":
      return COEChangeReason.HOA;
    case 9:
    case "OD_FWT_MISS":
      return COEChangeReason.OD_FWT_MISS;
    case 10:
    case "OD_INSP_AVAILABILITY":
      return COEChangeReason.OD_INSP_AVAILABILITY;
    case 11:
    case "OD_NEGOTIATING_REPAIRS":
      return COEChangeReason.OD_NEGOTIATING_REPAIRS;
    case 12:
    case "OD_REPAIRS_NOT_READY":
      return COEChangeReason.OD_REPAIRS_NOT_READY;
    case 13:
    case "OD_OTHER":
      return COEChangeReason.OD_OTHER;
    case 14:
    case "TITLE_SURVEY":
      return COEChangeReason.TITLE_SURVEY;
    case 15:
    case "TITLE_HOA":
      return COEChangeReason.TITLE_HOA;
    case 16:
    case "TITLE_COMMITMENT":
      return COEChangeReason.TITLE_COMMITMENT;
    case 17:
    case "TITLE_FUNDING":
      return COEChangeReason.TITLE_FUNDING;
    case 18:
    case "TITLE_CLOSING_APPT":
      return COEChangeReason.TITLE_CLOSING_APPT;
    case 19:
    case "TITLE_MORTGAGE_PAYOFF":
      return COEChangeReason.TITLE_MORTGAGE_PAYOFF;
    case 20:
    case "TITLE_OTHER":
      return COEChangeReason.TITLE_OTHER;
    case 21:
    case "TITLE_UNRESPONSIVE":
      return COEChangeReason.TITLE_UNRESPONSIVE;
    case 22:
    case "SOLAR_TRANSFER":
      return COEChangeReason.SOLAR_TRANSFER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return COEChangeReason.UNRECOGNIZED;
  }
}

export function cOEChangeReasonToJSON(object: COEChangeReason): string {
  switch (object) {
    case COEChangeReason.SELLER_CHANGE_IN_PLANS:
      return "SELLER_CHANGE_IN_PLANS";
    case COEChangeReason.SELLER_HANDLING_REPAIRS:
      return "SELLER_HANDLING_REPAIRS";
    case COEChangeReason.SELLER_INFORMATION_SHEET:
      return "SELLER_INFORMATION_SHEET";
    case COEChangeReason.SELLER_MOVING_ISSUE:
      return "SELLER_MOVING_ISSUE";
    case COEChangeReason.SELLER_NEXT_HOME:
      return "SELLER_NEXT_HOME";
    case COEChangeReason.SELLER_UNREALISTIC_COE:
      return "SELLER_UNREALISTIC_COE";
    case COEChangeReason.SELLER_UNRESPONSIVE:
      return "SELLER_UNRESPONSIVE";
    case COEChangeReason.SELLER_OTHER:
      return "SELLER_OTHER";
    case COEChangeReason.HOA:
      return "HOA";
    case COEChangeReason.OD_FWT_MISS:
      return "OD_FWT_MISS";
    case COEChangeReason.OD_INSP_AVAILABILITY:
      return "OD_INSP_AVAILABILITY";
    case COEChangeReason.OD_NEGOTIATING_REPAIRS:
      return "OD_NEGOTIATING_REPAIRS";
    case COEChangeReason.OD_REPAIRS_NOT_READY:
      return "OD_REPAIRS_NOT_READY";
    case COEChangeReason.OD_OTHER:
      return "OD_OTHER";
    case COEChangeReason.TITLE_SURVEY:
      return "TITLE_SURVEY";
    case COEChangeReason.TITLE_HOA:
      return "TITLE_HOA";
    case COEChangeReason.TITLE_COMMITMENT:
      return "TITLE_COMMITMENT";
    case COEChangeReason.TITLE_FUNDING:
      return "TITLE_FUNDING";
    case COEChangeReason.TITLE_CLOSING_APPT:
      return "TITLE_CLOSING_APPT";
    case COEChangeReason.TITLE_MORTGAGE_PAYOFF:
      return "TITLE_MORTGAGE_PAYOFF";
    case COEChangeReason.TITLE_OTHER:
      return "TITLE_OTHER";
    case COEChangeReason.TITLE_UNRESPONSIVE:
      return "TITLE_UNRESPONSIVE";
    case COEChangeReason.SOLAR_TRANSFER:
      return "SOLAR_TRANSFER";
    case COEChangeReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SubsidyRequest {
  amountCents: number;
  type: SubsidyType;
}

export interface Subsidy {
  amountCents: number;
  type: SubsidyType;
  active: boolean;
}

export interface RepairAdjustment {
  description: string;
  assetIds: string[];
}

export interface COEChangeRequest {
  newCoeDate?: DateMessage | undefined;
  changeReason: COEChangeReason;
}

export interface CancellationRequest {
  primaryReason: PrimaryCancellationReason;
  secondaryReason?: { $case: "opendoorWalkReason"; opendoorWalkReason: OpendoorWalkSecondaryCancellationReason } | {
    $case: "sellerInitiatedReason";
    sellerInitiatedReason: SellerInitiatedSecondaryCancellationReason;
  } | undefined;
}

export interface ContractChangeRequestOutcomeTaskData {
  ticketUrl: string;
}

function createBaseSubsidyRequest(): SubsidyRequest {
  return { amountCents: 0, type: 0 };
}

export const SubsidyRequest = {
  fromJSON(object: any): SubsidyRequest {
    return {
      amountCents: isSet(object.amountCents) ? Number(object.amountCents) : 0,
      type: isSet(object.type) ? subsidyTypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: SubsidyRequest): unknown {
    const obj: any = {};
    if (message.amountCents !== 0) {
      obj.amountCents = Math.round(message.amountCents);
    }
    if (message.type !== 0) {
      obj.type = subsidyTypeToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubsidyRequest>, I>>(base?: I): SubsidyRequest {
    return SubsidyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubsidyRequest>, I>>(object: I): SubsidyRequest {
    const message = createBaseSubsidyRequest();
    message.amountCents = object.amountCents ?? 0;
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseSubsidy(): Subsidy {
  return { amountCents: 0, type: 0, active: false };
}

export const Subsidy = {
  fromJSON(object: any): Subsidy {
    return {
      amountCents: isSet(object.amountCents) ? Number(object.amountCents) : 0,
      type: isSet(object.type) ? subsidyTypeFromJSON(object.type) : 0,
      active: isSet(object.active) ? Boolean(object.active) : false,
    };
  },

  toJSON(message: Subsidy): unknown {
    const obj: any = {};
    if (message.amountCents !== 0) {
      obj.amountCents = Math.round(message.amountCents);
    }
    if (message.type !== 0) {
      obj.type = subsidyTypeToJSON(message.type);
    }
    if (message.active === true) {
      obj.active = message.active;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Subsidy>, I>>(base?: I): Subsidy {
    return Subsidy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Subsidy>, I>>(object: I): Subsidy {
    const message = createBaseSubsidy();
    message.amountCents = object.amountCents ?? 0;
    message.type = object.type ?? 0;
    message.active = object.active ?? false;
    return message;
  },
};

function createBaseRepairAdjustment(): RepairAdjustment {
  return { description: "", assetIds: [] };
}

export const RepairAdjustment = {
  fromJSON(object: any): RepairAdjustment {
    return {
      description: isSet(object.description) ? String(object.description) : "",
      assetIds: Array.isArray(object?.assetIds) ? object.assetIds.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: RepairAdjustment): unknown {
    const obj: any = {};
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.assetIds?.length) {
      obj.assetIds = message.assetIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RepairAdjustment>, I>>(base?: I): RepairAdjustment {
    return RepairAdjustment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RepairAdjustment>, I>>(object: I): RepairAdjustment {
    const message = createBaseRepairAdjustment();
    message.description = object.description ?? "";
    message.assetIds = object.assetIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseCOEChangeRequest(): COEChangeRequest {
  return { newCoeDate: undefined, changeReason: 0 };
}

export const COEChangeRequest = {
  fromJSON(object: any): COEChangeRequest {
    return {
      newCoeDate: isSet(object.newCoeDate) ? DateMessage.fromJSON(object.newCoeDate) : undefined,
      changeReason: isSet(object.changeReason) ? cOEChangeReasonFromJSON(object.changeReason) : 0,
    };
  },

  toJSON(message: COEChangeRequest): unknown {
    const obj: any = {};
    if (message.newCoeDate !== undefined) {
      obj.newCoeDate = DateMessage.toJSON(message.newCoeDate);
    }
    if (message.changeReason !== 0) {
      obj.changeReason = cOEChangeReasonToJSON(message.changeReason);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<COEChangeRequest>, I>>(base?: I): COEChangeRequest {
    return COEChangeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<COEChangeRequest>, I>>(object: I): COEChangeRequest {
    const message = createBaseCOEChangeRequest();
    message.newCoeDate = (object.newCoeDate !== undefined && object.newCoeDate !== null)
      ? DateMessage.fromPartial(object.newCoeDate)
      : undefined;
    message.changeReason = object.changeReason ?? 0;
    return message;
  },
};

function createBaseCancellationRequest(): CancellationRequest {
  return { primaryReason: 0, secondaryReason: undefined };
}

export const CancellationRequest = {
  fromJSON(object: any): CancellationRequest {
    return {
      primaryReason: isSet(object.primaryReason) ? primaryCancellationReasonFromJSON(object.primaryReason) : 0,
      secondaryReason: isSet(object.opendoorWalkReason)
        ? {
          $case: "opendoorWalkReason",
          opendoorWalkReason: opendoorWalkSecondaryCancellationReasonFromJSON(object.opendoorWalkReason),
        }
        : isSet(object.sellerInitiatedReason)
        ? {
          $case: "sellerInitiatedReason",
          sellerInitiatedReason: sellerInitiatedSecondaryCancellationReasonFromJSON(object.sellerInitiatedReason),
        }
        : undefined,
    };
  },

  toJSON(message: CancellationRequest): unknown {
    const obj: any = {};
    if (message.primaryReason !== 0) {
      obj.primaryReason = primaryCancellationReasonToJSON(message.primaryReason);
    }
    if (message.secondaryReason?.$case === "opendoorWalkReason") {
      obj.opendoorWalkReason = opendoorWalkSecondaryCancellationReasonToJSON(
        message.secondaryReason.opendoorWalkReason,
      );
    }
    if (message.secondaryReason?.$case === "sellerInitiatedReason") {
      obj.sellerInitiatedReason = sellerInitiatedSecondaryCancellationReasonToJSON(
        message.secondaryReason.sellerInitiatedReason,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CancellationRequest>, I>>(base?: I): CancellationRequest {
    return CancellationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancellationRequest>, I>>(object: I): CancellationRequest {
    const message = createBaseCancellationRequest();
    message.primaryReason = object.primaryReason ?? 0;
    if (
      object.secondaryReason?.$case === "opendoorWalkReason" &&
      object.secondaryReason?.opendoorWalkReason !== undefined &&
      object.secondaryReason?.opendoorWalkReason !== null
    ) {
      message.secondaryReason = {
        $case: "opendoorWalkReason",
        opendoorWalkReason: object.secondaryReason.opendoorWalkReason,
      };
    }
    if (
      object.secondaryReason?.$case === "sellerInitiatedReason" &&
      object.secondaryReason?.sellerInitiatedReason !== undefined &&
      object.secondaryReason?.sellerInitiatedReason !== null
    ) {
      message.secondaryReason = {
        $case: "sellerInitiatedReason",
        sellerInitiatedReason: object.secondaryReason.sellerInitiatedReason,
      };
    }
    return message;
  },
};

function createBaseContractChangeRequestOutcomeTaskData(): ContractChangeRequestOutcomeTaskData {
  return { ticketUrl: "" };
}

export const ContractChangeRequestOutcomeTaskData = {
  fromJSON(object: any): ContractChangeRequestOutcomeTaskData {
    return { ticketUrl: isSet(object.ticketUrl) ? String(object.ticketUrl) : "" };
  },

  toJSON(message: ContractChangeRequestOutcomeTaskData): unknown {
    const obj: any = {};
    if (message.ticketUrl !== "") {
      obj.ticketUrl = message.ticketUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContractChangeRequestOutcomeTaskData>, I>>(
    base?: I,
  ): ContractChangeRequestOutcomeTaskData {
    return ContractChangeRequestOutcomeTaskData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContractChangeRequestOutcomeTaskData>, I>>(
    object: I,
  ): ContractChangeRequestOutcomeTaskData {
    const message = createBaseContractChangeRequestOutcomeTaskData();
    message.ticketUrl = object.ticketUrl ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
