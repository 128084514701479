/* eslint-disable */
import { Timestamp } from "../../../../google/protobuf/timestamp";

export const protobufPackage = "od_protos.journey.data.categories";

export interface SellORVACategory {
  event?:
    | { $case: "schedule"; schedule: SellORVACategory_ScheduleEvent }
    | { $case: "reschedule"; reschedule: SellORVACategory_RescheduleEvent }
    | { $case: "cancel"; cancel: SellORVACategory_CancelEvent }
    | undefined;
}

export enum SellORVACategory_ORVACancellationReason {
  CANCELLATION_REASON_UNKNOWN = 0,
  CEP_CANNOT_MAKE_IT = 1,
  CUSTOMER_CANNOT_MAKE_IT = 2,
  UNRECOGNIZED = -1,
}

export function sellORVACategory_ORVACancellationReasonFromJSON(object: any): SellORVACategory_ORVACancellationReason {
  switch (object) {
    case 0:
    case "CANCELLATION_REASON_UNKNOWN":
      return SellORVACategory_ORVACancellationReason.CANCELLATION_REASON_UNKNOWN;
    case 1:
    case "CEP_CANNOT_MAKE_IT":
      return SellORVACategory_ORVACancellationReason.CEP_CANNOT_MAKE_IT;
    case 2:
    case "CUSTOMER_CANNOT_MAKE_IT":
      return SellORVACategory_ORVACancellationReason.CUSTOMER_CANNOT_MAKE_IT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellORVACategory_ORVACancellationReason.UNRECOGNIZED;
  }
}

export function sellORVACategory_ORVACancellationReasonToJSON(object: SellORVACategory_ORVACancellationReason): string {
  switch (object) {
    case SellORVACategory_ORVACancellationReason.CANCELLATION_REASON_UNKNOWN:
      return "CANCELLATION_REASON_UNKNOWN";
    case SellORVACategory_ORVACancellationReason.CEP_CANNOT_MAKE_IT:
      return "CEP_CANNOT_MAKE_IT";
    case SellORVACategory_ORVACancellationReason.CUSTOMER_CANNOT_MAKE_IT:
      return "CUSTOMER_CANNOT_MAKE_IT";
    case SellORVACategory_ORVACancellationReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SellORVACategory_ScheduleEvent {
  leadId: string;
  offerId: string;
  scheduledDatetime?: Date | undefined;
  agentScheduled: string;
  offerUuid: string;
}

export interface SellORVACategory_RescheduleEvent {
  leadId: string;
  offerId: string;
  offerUuid: string;
}

export interface SellORVACategory_CancelEvent {
  leadId: string;
  offerId: string;
  offerUuid: string;
  cancellationReason: SellORVACategory_ORVACancellationReason;
  cancellationSource?: string | undefined;
}

function createBaseSellORVACategory(): SellORVACategory {
  return { event: undefined };
}

export const SellORVACategory = {
  fromJSON(object: any): SellORVACategory {
    return {
      event: isSet(object.schedule)
        ? { $case: "schedule", schedule: SellORVACategory_ScheduleEvent.fromJSON(object.schedule) }
        : isSet(object.reschedule)
        ? { $case: "reschedule", reschedule: SellORVACategory_RescheduleEvent.fromJSON(object.reschedule) }
        : isSet(object.cancel)
        ? { $case: "cancel", cancel: SellORVACategory_CancelEvent.fromJSON(object.cancel) }
        : undefined,
    };
  },

  toJSON(message: SellORVACategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "schedule") {
      obj.schedule = SellORVACategory_ScheduleEvent.toJSON(message.event.schedule);
    }
    if (message.event?.$case === "reschedule") {
      obj.reschedule = SellORVACategory_RescheduleEvent.toJSON(message.event.reschedule);
    }
    if (message.event?.$case === "cancel") {
      obj.cancel = SellORVACategory_CancelEvent.toJSON(message.event.cancel);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellORVACategory>, I>>(base?: I): SellORVACategory {
    return SellORVACategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellORVACategory>, I>>(object: I): SellORVACategory {
    const message = createBaseSellORVACategory();
    if (object.event?.$case === "schedule" && object.event?.schedule !== undefined && object.event?.schedule !== null) {
      message.event = {
        $case: "schedule",
        schedule: SellORVACategory_ScheduleEvent.fromPartial(object.event.schedule),
      };
    }
    if (
      object.event?.$case === "reschedule" &&
      object.event?.reschedule !== undefined &&
      object.event?.reschedule !== null
    ) {
      message.event = {
        $case: "reschedule",
        reschedule: SellORVACategory_RescheduleEvent.fromPartial(object.event.reschedule),
      };
    }
    if (object.event?.$case === "cancel" && object.event?.cancel !== undefined && object.event?.cancel !== null) {
      message.event = { $case: "cancel", cancel: SellORVACategory_CancelEvent.fromPartial(object.event.cancel) };
    }
    return message;
  },
};

function createBaseSellORVACategory_ScheduleEvent(): SellORVACategory_ScheduleEvent {
  return { leadId: "", offerId: "", scheduledDatetime: undefined, agentScheduled: "", offerUuid: "" };
}

export const SellORVACategory_ScheduleEvent = {
  fromJSON(object: any): SellORVACategory_ScheduleEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      scheduledDatetime: isSet(object.scheduledDatetime) ? fromJsonTimestamp(object.scheduledDatetime) : undefined,
      agentScheduled: isSet(object.agentScheduled) ? String(object.agentScheduled) : "",
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
    };
  },

  toJSON(message: SellORVACategory_ScheduleEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerId !== "") {
      obj.offerId = message.offerId;
    }
    if (message.scheduledDatetime !== undefined) {
      obj.scheduledDatetime = message.scheduledDatetime.toISOString();
    }
    if (message.agentScheduled !== "") {
      obj.agentScheduled = message.agentScheduled;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellORVACategory_ScheduleEvent>, I>>(base?: I): SellORVACategory_ScheduleEvent {
    return SellORVACategory_ScheduleEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellORVACategory_ScheduleEvent>, I>>(
    object: I,
  ): SellORVACategory_ScheduleEvent {
    const message = createBaseSellORVACategory_ScheduleEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.scheduledDatetime = object.scheduledDatetime ?? undefined;
    message.agentScheduled = object.agentScheduled ?? "";
    message.offerUuid = object.offerUuid ?? "";
    return message;
  },
};

function createBaseSellORVACategory_RescheduleEvent(): SellORVACategory_RescheduleEvent {
  return { leadId: "", offerId: "", offerUuid: "" };
}

export const SellORVACategory_RescheduleEvent = {
  fromJSON(object: any): SellORVACategory_RescheduleEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
    };
  },

  toJSON(message: SellORVACategory_RescheduleEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerId !== "") {
      obj.offerId = message.offerId;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellORVACategory_RescheduleEvent>, I>>(
    base?: I,
  ): SellORVACategory_RescheduleEvent {
    return SellORVACategory_RescheduleEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellORVACategory_RescheduleEvent>, I>>(
    object: I,
  ): SellORVACategory_RescheduleEvent {
    const message = createBaseSellORVACategory_RescheduleEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.offerUuid = object.offerUuid ?? "";
    return message;
  },
};

function createBaseSellORVACategory_CancelEvent(): SellORVACategory_CancelEvent {
  return { leadId: "", offerId: "", offerUuid: "", cancellationReason: 0, cancellationSource: undefined };
}

export const SellORVACategory_CancelEvent = {
  fromJSON(object: any): SellORVACategory_CancelEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
      cancellationReason: isSet(object.cancellationReason)
        ? sellORVACategory_ORVACancellationReasonFromJSON(object.cancellationReason)
        : 0,
      cancellationSource: isSet(object.cancellationSource) ? String(object.cancellationSource) : undefined,
    };
  },

  toJSON(message: SellORVACategory_CancelEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerId !== "") {
      obj.offerId = message.offerId;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    if (message.cancellationReason !== 0) {
      obj.cancellationReason = sellORVACategory_ORVACancellationReasonToJSON(message.cancellationReason);
    }
    if (message.cancellationSource !== undefined) {
      obj.cancellationSource = message.cancellationSource;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellORVACategory_CancelEvent>, I>>(base?: I): SellORVACategory_CancelEvent {
    return SellORVACategory_CancelEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellORVACategory_CancelEvent>, I>>(object: I): SellORVACategory_CancelEvent {
    const message = createBaseSellORVACategory_CancelEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.offerUuid = object.offerUuid ?? "";
    message.cancellationReason = object.cancellationReason ?? 0;
    message.cancellationSource = object.cancellationSource ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
