/* eslint-disable */

export const protobufPackage = "od_protos.common.state";

/** Next ID: 66 */
export enum State {
  UNKNOWN = 0,
  /** AA - Armed Forces Americas */
  AA = 63,
  /** AE - Armed Forces Europe */
  AE = 65,
  /** AL - Alabama State */
  AL = 1,
  /** AK - Alaska State */
  AK = 2,
  /** AP - Armed Forces Pacific */
  AP = 64,
  /** AS - American Samoa */
  AS = 58,
  /** AZ - Arizona State */
  AZ = 4,
  /** AR - Arkansas State */
  AR = 5,
  /** CA - California State */
  CA = 6,
  /** CO - Colorado State */
  CO = 8,
  /** CT - Connecticut State */
  CT = 9,
  /** DE - Delaware State */
  DE = 10,
  /** DC - District of Columbia Federal district */
  DC = 11,
  /** FL - Florida State */
  FL = 12,
  /** GA - Georgia State */
  GA = 13,
  /** GU - Guam */
  GU = 59,
  /** HI - Hawaii State */
  HI = 15,
  /** ID - Idaho State */
  ID = 16,
  /** IL - Illinois State */
  IL = 17,
  /** IN - Indiana State */
  IN = 18,
  /** IA - Iowa State */
  IA = 19,
  /** KS - Kansas State */
  KS = 20,
  /** KY - Kentucky State (Commonwealth) */
  KY = 21,
  /** LA - Louisiana State */
  LA = 22,
  /** ME - Maine State */
  ME = 23,
  /** MD - Maryland State */
  MD = 24,
  /** MA - Massachusetts State (Commonwealth) */
  MA = 25,
  /** MI - Michigan State */
  MI = 26,
  /** MN - Minnesota State */
  MN = 27,
  /** MS - Mississippi State */
  MS = 28,
  /** MO - Missouri State */
  MO = 29,
  /** MT - Montana State */
  MT = 30,
  /** NE - Nebraska State */
  NE = 31,
  /** NV - Nevada State */
  NV = 32,
  /** NH - New Hampshire State */
  NH = 33,
  /** NJ - New Jersey State */
  NJ = 34,
  /** NM - New Mexico State */
  NM = 35,
  /** NY - New York State */
  NY = 36,
  /** NC - North Carolina */
  NC = 37,
  /** ND - North Dakota State */
  ND = 38,
  /** MP - Northern Mariana Islands (Commonwealth) */
  MP = 60,
  /** OH - Ohio State */
  OH = 39,
  /** OK - Oklahoma State */
  OK = 40,
  /** OR - Oregon State */
  OR = 41,
  /** PA - Pennsylvania State (Commonwealth) */
  PA = 42,
  /** PR - Puerto Rico (Commonwealth) */
  PR = 61,
  /** RI - Rhode Island State */
  RI = 44,
  /** SC - South Carolina State */
  SC = 45,
  /** SD - South Dakota State */
  SD = 46,
  /** TN - Tennessee State */
  TN = 47,
  /** TX - Texas State */
  TX = 48,
  /** UT - Utah State */
  UT = 49,
  /** VT - Vermont State */
  VT = 50,
  /** VI - Virgin Islands */
  VI = 62,
  /** VA - Virginia State (Commonwealth) */
  VA = 51,
  /** WA - Washington State */
  WA = 57,
  /** WV - West Virginia State */
  WV = 54,
  /** WI - Wisconsin	State */
  WI = 55,
  /** WY - Wyoming	State */
  WY = 56,
  UNRECOGNIZED = -1,
}

export function stateFromJSON(object: any): State {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return State.UNKNOWN;
    case 63:
    case "AA":
      return State.AA;
    case 65:
    case "AE":
      return State.AE;
    case 1:
    case "AL":
      return State.AL;
    case 2:
    case "AK":
      return State.AK;
    case 64:
    case "AP":
      return State.AP;
    case 58:
    case "AS":
      return State.AS;
    case 4:
    case "AZ":
      return State.AZ;
    case 5:
    case "AR":
      return State.AR;
    case 6:
    case "CA":
      return State.CA;
    case 8:
    case "CO":
      return State.CO;
    case 9:
    case "CT":
      return State.CT;
    case 10:
    case "DE":
      return State.DE;
    case 11:
    case "DC":
      return State.DC;
    case 12:
    case "FL":
      return State.FL;
    case 13:
    case "GA":
      return State.GA;
    case 59:
    case "GU":
      return State.GU;
    case 15:
    case "HI":
      return State.HI;
    case 16:
    case "ID":
      return State.ID;
    case 17:
    case "IL":
      return State.IL;
    case 18:
    case "IN":
      return State.IN;
    case 19:
    case "IA":
      return State.IA;
    case 20:
    case "KS":
      return State.KS;
    case 21:
    case "KY":
      return State.KY;
    case 22:
    case "LA":
      return State.LA;
    case 23:
    case "ME":
      return State.ME;
    case 24:
    case "MD":
      return State.MD;
    case 25:
    case "MA":
      return State.MA;
    case 26:
    case "MI":
      return State.MI;
    case 27:
    case "MN":
      return State.MN;
    case 28:
    case "MS":
      return State.MS;
    case 29:
    case "MO":
      return State.MO;
    case 30:
    case "MT":
      return State.MT;
    case 31:
    case "NE":
      return State.NE;
    case 32:
    case "NV":
      return State.NV;
    case 33:
    case "NH":
      return State.NH;
    case 34:
    case "NJ":
      return State.NJ;
    case 35:
    case "NM":
      return State.NM;
    case 36:
    case "NY":
      return State.NY;
    case 37:
    case "NC":
      return State.NC;
    case 38:
    case "ND":
      return State.ND;
    case 60:
    case "MP":
      return State.MP;
    case 39:
    case "OH":
      return State.OH;
    case 40:
    case "OK":
      return State.OK;
    case 41:
    case "OR":
      return State.OR;
    case 42:
    case "PA":
      return State.PA;
    case 61:
    case "PR":
      return State.PR;
    case 44:
    case "RI":
      return State.RI;
    case 45:
    case "SC":
      return State.SC;
    case 46:
    case "SD":
      return State.SD;
    case 47:
    case "TN":
      return State.TN;
    case 48:
    case "TX":
      return State.TX;
    case 49:
    case "UT":
      return State.UT;
    case 50:
    case "VT":
      return State.VT;
    case 62:
    case "VI":
      return State.VI;
    case 51:
    case "VA":
      return State.VA;
    case 57:
    case "WA":
      return State.WA;
    case 54:
    case "WV":
      return State.WV;
    case 55:
    case "WI":
      return State.WI;
    case 56:
    case "WY":
      return State.WY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return State.UNRECOGNIZED;
  }
}

export function stateToJSON(object: State): string {
  switch (object) {
    case State.UNKNOWN:
      return "UNKNOWN";
    case State.AA:
      return "AA";
    case State.AE:
      return "AE";
    case State.AL:
      return "AL";
    case State.AK:
      return "AK";
    case State.AP:
      return "AP";
    case State.AS:
      return "AS";
    case State.AZ:
      return "AZ";
    case State.AR:
      return "AR";
    case State.CA:
      return "CA";
    case State.CO:
      return "CO";
    case State.CT:
      return "CT";
    case State.DE:
      return "DE";
    case State.DC:
      return "DC";
    case State.FL:
      return "FL";
    case State.GA:
      return "GA";
    case State.GU:
      return "GU";
    case State.HI:
      return "HI";
    case State.ID:
      return "ID";
    case State.IL:
      return "IL";
    case State.IN:
      return "IN";
    case State.IA:
      return "IA";
    case State.KS:
      return "KS";
    case State.KY:
      return "KY";
    case State.LA:
      return "LA";
    case State.ME:
      return "ME";
    case State.MD:
      return "MD";
    case State.MA:
      return "MA";
    case State.MI:
      return "MI";
    case State.MN:
      return "MN";
    case State.MS:
      return "MS";
    case State.MO:
      return "MO";
    case State.MT:
      return "MT";
    case State.NE:
      return "NE";
    case State.NV:
      return "NV";
    case State.NH:
      return "NH";
    case State.NJ:
      return "NJ";
    case State.NM:
      return "NM";
    case State.NY:
      return "NY";
    case State.NC:
      return "NC";
    case State.ND:
      return "ND";
    case State.MP:
      return "MP";
    case State.OH:
      return "OH";
    case State.OK:
      return "OK";
    case State.OR:
      return "OR";
    case State.PA:
      return "PA";
    case State.PR:
      return "PR";
    case State.RI:
      return "RI";
    case State.SC:
      return "SC";
    case State.SD:
      return "SD";
    case State.TN:
      return "TN";
    case State.TX:
      return "TX";
    case State.UT:
      return "UT";
    case State.VT:
      return "VT";
    case State.VI:
      return "VI";
    case State.VA:
      return "VA";
    case State.WA:
      return "WA";
    case State.WV:
      return "WV";
    case State.WI:
      return "WI";
    case State.WY:
      return "WY";
    case State.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
