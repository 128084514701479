/* eslint-disable */
import { Answers } from "../../../sell/reception/data/answers";

export const protobufPackage = "od_protos.journey.data.categories";

export interface SellOnboardingCategory {
  event?: { $case: "questionsSubmit"; questionsSubmit: SellOnboardingCategory_QuestionsSubmitEvent } | undefined;
}

export interface SellOnboardingCategory_QuestionsSubmitEvent {
  sellerInputId: string;
  answers?: Answers | undefined;
}

function createBaseSellOnboardingCategory(): SellOnboardingCategory {
  return { event: undefined };
}

export const SellOnboardingCategory = {
  fromJSON(object: any): SellOnboardingCategory {
    return {
      event: isSet(object.questionsSubmit)
        ? {
          $case: "questionsSubmit",
          questionsSubmit: SellOnboardingCategory_QuestionsSubmitEvent.fromJSON(object.questionsSubmit),
        }
        : undefined,
    };
  },

  toJSON(message: SellOnboardingCategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "questionsSubmit") {
      obj.questionsSubmit = SellOnboardingCategory_QuestionsSubmitEvent.toJSON(message.event.questionsSubmit);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellOnboardingCategory>, I>>(base?: I): SellOnboardingCategory {
    return SellOnboardingCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellOnboardingCategory>, I>>(object: I): SellOnboardingCategory {
    const message = createBaseSellOnboardingCategory();
    if (
      object.event?.$case === "questionsSubmit" &&
      object.event?.questionsSubmit !== undefined &&
      object.event?.questionsSubmit !== null
    ) {
      message.event = {
        $case: "questionsSubmit",
        questionsSubmit: SellOnboardingCategory_QuestionsSubmitEvent.fromPartial(object.event.questionsSubmit),
      };
    }
    return message;
  },
};

function createBaseSellOnboardingCategory_QuestionsSubmitEvent(): SellOnboardingCategory_QuestionsSubmitEvent {
  return { sellerInputId: "", answers: undefined };
}

export const SellOnboardingCategory_QuestionsSubmitEvent = {
  fromJSON(object: any): SellOnboardingCategory_QuestionsSubmitEvent {
    return {
      sellerInputId: isSet(object.sellerInputId) ? String(object.sellerInputId) : "",
      answers: isSet(object.answers) ? Answers.fromJSON(object.answers) : undefined,
    };
  },

  toJSON(message: SellOnboardingCategory_QuestionsSubmitEvent): unknown {
    const obj: any = {};
    if (message.sellerInputId !== "") {
      obj.sellerInputId = message.sellerInputId;
    }
    if (message.answers !== undefined) {
      obj.answers = Answers.toJSON(message.answers);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellOnboardingCategory_QuestionsSubmitEvent>, I>>(
    base?: I,
  ): SellOnboardingCategory_QuestionsSubmitEvent {
    return SellOnboardingCategory_QuestionsSubmitEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellOnboardingCategory_QuestionsSubmitEvent>, I>>(
    object: I,
  ): SellOnboardingCategory_QuestionsSubmitEvent {
    const message = createBaseSellOnboardingCategory_QuestionsSubmitEvent();
    message.sellerInputId = object.sellerInputId ?? "";
    message.answers = (object.answers !== undefined && object.answers !== null)
      ? Answers.fromPartial(object.answers)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
