/* eslint-disable */
import { Timestamp } from "../../../../google/protobuf/timestamp";

export const protobufPackage = "od_protos.web.data.events";

/** TODO: Define enum in web/data or web/customer_ops then deprecate this. */
export enum EventType {
  UNKNOWN = 0,
  AGENT_REFERRAL_CREATED = 1,
  AGENT_REFERRAL_OFFER_SMS_SENT = 2,
  AGENT_REFERRAL_UPDATED = 3,
  ANALYTICS = 4,
  ASSOCIATION = 5,
  ASSOCIATION_CHANGED = 6,
  ATTRIBUTE_CHANGED = 7,
  BLACKLIST_UNLOCK_ATTEMPT = 8,
  BUYER_ATTENTION_WORTHY_SIGNAL = 9,
  BUYER_LEAD_FROM_SELLER = 10,
  BUYER_SIGNUP = 11,
  CENTRALIZED_SHOWING_SERVICE_FEEDBACK = 12,
  CODE_MISSING_UNLOCK_ATTEMPT = 13,
  CODELESS_UNLOCK_FAILED_ATTEMPT = 14,
  CONVERSATION_REASSIGNED = 15,
  COVID_SAFETY_WINDOW_UNLOCK_ATTEMPT = 16,
  CUSTOMER_DATA = 17,
  DELIGHTED_SMS_SURVEY_REQUEST = 18,
  DELIGHTED_SURVEY_REQUEST = 19,
  DELIGHTED_SURVEY_RESPONSE = 20,
  DEVICE_INSTALL = 21,
  EMAIL = 22,
  EXTERNAL_INTEREST = 23,
  GRAYLIST_UNLOCK_ATTEMPT = 24,
  HOME_FEEDBACK_LEFT = 25,
  HOME_ISSUE_REPORTED = 26,
  HOMEBUILDER_OR_OUTCOME = 27,
  ID_VERIFICATION = 28,
  INITIAL_UNLOCK_ATTEMPT = 29,
  INSUFFICIENT_VERSION_UNLOCK_ATTEMPT = 30,
  LISTING_INTEREST = 31,
  LISTING_NOT_FOUND_UNLOCK_ATTEMPT = 32,
  LISTING_OFFER_NOTIFICATION = 33,
  LISTING_OFFER_SUBMITTED = 34,
  LISTING_RESERVATION = 35,
  LWOD_OFFER_SENT = 36,
  LWOD_OR_OUTCOME = 37,
  MERGE = 38,
  NEEDS_ATTENTION_FOLLOWUP_TIME = 39,
  NEEDS_ATTENTION_RESOLVED = 40,
  NOTE = 41,
  OFFER_ON_INTERESTED_HOME = 42,
  OFFER_SENT = 43,
  OPEN_LISTINGS_OFFER_STATE = 44,
  OPEN_LISTINGS_TOUR_COMPLETED = 45,
  OUT_OF_OFFICE_REPLY = 46,
  OUTREACH_OUTCOME = 47,
  OUTSIDE_VISITING_HOURS_UNLOCK_ATTEMPT = 48,
  PARTICIPANT_CHANGED = 49,
  PHONE_CALL = 50,
  POST_FRMS_UNTRUSTED_UNLOCK_NOT_SENT = 51,
  PPM_UNLOCK_ATTEMPT = 52,
  PUSH_NOTIFICATION = 53,
  REPEAT_VISIT = 54,
  SMS_OPT_OUT_NOTIFICATION = 55,
  SCHEDULE_TOUR_REQUEST = 56,
  SELF_TOUR_FEEDBACK = 58,
  SHOWINGTIME_FEEDBACK = 59,
  SMS = 60,
  SOLD_UNLOCK_ATTEMPT = 61,
  SYSTEM = 62,
  TOM_UNLOCK_ATTEMPT = 63,
  TRIAGE_SMS_RESPONSE_PHOTO_UPLOAD = 64,
  TRUST = 65,
  UNDER_COVID_LOCKDOWN = 66,
  UNKNOWN_ERROR = 67,
  UNLOCK = 68,
  VISIT_EXPERIENCE_FEEDBACK_APPRECIATION = 69,
  VISIT_EXPERIENCE_FEEDBACK_REQUEST = 70,
  VISIT_EXPERIENCE_FEEDBACK_RESPONSE = 71,
  VISIT_EXPERIENCE_SCORE_REQUEST = 72,
  VISIT_EXPERIENCE_SCORE_RESPONSE = 73,
  VOICEMAIL = 74,
  WEB_CHAT = 75,
  COMBINED_DECISION_CALL_OUTCOME = 76,
  REQUEST_FOR_LWOD_AGENT = 77,
  OR_VA_OUTCOME = 78,
  UNRECOGNIZED = -1,
}

export function eventTypeFromJSON(object: any): EventType {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return EventType.UNKNOWN;
    case 1:
    case "AGENT_REFERRAL_CREATED":
      return EventType.AGENT_REFERRAL_CREATED;
    case 2:
    case "AGENT_REFERRAL_OFFER_SMS_SENT":
      return EventType.AGENT_REFERRAL_OFFER_SMS_SENT;
    case 3:
    case "AGENT_REFERRAL_UPDATED":
      return EventType.AGENT_REFERRAL_UPDATED;
    case 4:
    case "ANALYTICS":
      return EventType.ANALYTICS;
    case 5:
    case "ASSOCIATION":
      return EventType.ASSOCIATION;
    case 6:
    case "ASSOCIATION_CHANGED":
      return EventType.ASSOCIATION_CHANGED;
    case 7:
    case "ATTRIBUTE_CHANGED":
      return EventType.ATTRIBUTE_CHANGED;
    case 8:
    case "BLACKLIST_UNLOCK_ATTEMPT":
      return EventType.BLACKLIST_UNLOCK_ATTEMPT;
    case 9:
    case "BUYER_ATTENTION_WORTHY_SIGNAL":
      return EventType.BUYER_ATTENTION_WORTHY_SIGNAL;
    case 10:
    case "BUYER_LEAD_FROM_SELLER":
      return EventType.BUYER_LEAD_FROM_SELLER;
    case 11:
    case "BUYER_SIGNUP":
      return EventType.BUYER_SIGNUP;
    case 12:
    case "CENTRALIZED_SHOWING_SERVICE_FEEDBACK":
      return EventType.CENTRALIZED_SHOWING_SERVICE_FEEDBACK;
    case 13:
    case "CODE_MISSING_UNLOCK_ATTEMPT":
      return EventType.CODE_MISSING_UNLOCK_ATTEMPT;
    case 14:
    case "CODELESS_UNLOCK_FAILED_ATTEMPT":
      return EventType.CODELESS_UNLOCK_FAILED_ATTEMPT;
    case 15:
    case "CONVERSATION_REASSIGNED":
      return EventType.CONVERSATION_REASSIGNED;
    case 16:
    case "COVID_SAFETY_WINDOW_UNLOCK_ATTEMPT":
      return EventType.COVID_SAFETY_WINDOW_UNLOCK_ATTEMPT;
    case 17:
    case "CUSTOMER_DATA":
      return EventType.CUSTOMER_DATA;
    case 18:
    case "DELIGHTED_SMS_SURVEY_REQUEST":
      return EventType.DELIGHTED_SMS_SURVEY_REQUEST;
    case 19:
    case "DELIGHTED_SURVEY_REQUEST":
      return EventType.DELIGHTED_SURVEY_REQUEST;
    case 20:
    case "DELIGHTED_SURVEY_RESPONSE":
      return EventType.DELIGHTED_SURVEY_RESPONSE;
    case 21:
    case "DEVICE_INSTALL":
      return EventType.DEVICE_INSTALL;
    case 22:
    case "EMAIL":
      return EventType.EMAIL;
    case 23:
    case "EXTERNAL_INTEREST":
      return EventType.EXTERNAL_INTEREST;
    case 24:
    case "GRAYLIST_UNLOCK_ATTEMPT":
      return EventType.GRAYLIST_UNLOCK_ATTEMPT;
    case 25:
    case "HOME_FEEDBACK_LEFT":
      return EventType.HOME_FEEDBACK_LEFT;
    case 26:
    case "HOME_ISSUE_REPORTED":
      return EventType.HOME_ISSUE_REPORTED;
    case 27:
    case "HOMEBUILDER_OR_OUTCOME":
      return EventType.HOMEBUILDER_OR_OUTCOME;
    case 28:
    case "ID_VERIFICATION":
      return EventType.ID_VERIFICATION;
    case 29:
    case "INITIAL_UNLOCK_ATTEMPT":
      return EventType.INITIAL_UNLOCK_ATTEMPT;
    case 30:
    case "INSUFFICIENT_VERSION_UNLOCK_ATTEMPT":
      return EventType.INSUFFICIENT_VERSION_UNLOCK_ATTEMPT;
    case 31:
    case "LISTING_INTEREST":
      return EventType.LISTING_INTEREST;
    case 32:
    case "LISTING_NOT_FOUND_UNLOCK_ATTEMPT":
      return EventType.LISTING_NOT_FOUND_UNLOCK_ATTEMPT;
    case 33:
    case "LISTING_OFFER_NOTIFICATION":
      return EventType.LISTING_OFFER_NOTIFICATION;
    case 34:
    case "LISTING_OFFER_SUBMITTED":
      return EventType.LISTING_OFFER_SUBMITTED;
    case 35:
    case "LISTING_RESERVATION":
      return EventType.LISTING_RESERVATION;
    case 36:
    case "LWOD_OFFER_SENT":
      return EventType.LWOD_OFFER_SENT;
    case 37:
    case "LWOD_OR_OUTCOME":
      return EventType.LWOD_OR_OUTCOME;
    case 38:
    case "MERGE":
      return EventType.MERGE;
    case 39:
    case "NEEDS_ATTENTION_FOLLOWUP_TIME":
      return EventType.NEEDS_ATTENTION_FOLLOWUP_TIME;
    case 40:
    case "NEEDS_ATTENTION_RESOLVED":
      return EventType.NEEDS_ATTENTION_RESOLVED;
    case 41:
    case "NOTE":
      return EventType.NOTE;
    case 42:
    case "OFFER_ON_INTERESTED_HOME":
      return EventType.OFFER_ON_INTERESTED_HOME;
    case 43:
    case "OFFER_SENT":
      return EventType.OFFER_SENT;
    case 44:
    case "OPEN_LISTINGS_OFFER_STATE":
      return EventType.OPEN_LISTINGS_OFFER_STATE;
    case 45:
    case "OPEN_LISTINGS_TOUR_COMPLETED":
      return EventType.OPEN_LISTINGS_TOUR_COMPLETED;
    case 46:
    case "OUT_OF_OFFICE_REPLY":
      return EventType.OUT_OF_OFFICE_REPLY;
    case 47:
    case "OUTREACH_OUTCOME":
      return EventType.OUTREACH_OUTCOME;
    case 48:
    case "OUTSIDE_VISITING_HOURS_UNLOCK_ATTEMPT":
      return EventType.OUTSIDE_VISITING_HOURS_UNLOCK_ATTEMPT;
    case 49:
    case "PARTICIPANT_CHANGED":
      return EventType.PARTICIPANT_CHANGED;
    case 50:
    case "PHONE_CALL":
      return EventType.PHONE_CALL;
    case 51:
    case "POST_FRMS_UNTRUSTED_UNLOCK_NOT_SENT":
      return EventType.POST_FRMS_UNTRUSTED_UNLOCK_NOT_SENT;
    case 52:
    case "PPM_UNLOCK_ATTEMPT":
      return EventType.PPM_UNLOCK_ATTEMPT;
    case 53:
    case "PUSH_NOTIFICATION":
      return EventType.PUSH_NOTIFICATION;
    case 54:
    case "REPEAT_VISIT":
      return EventType.REPEAT_VISIT;
    case 55:
    case "SMS_OPT_OUT_NOTIFICATION":
      return EventType.SMS_OPT_OUT_NOTIFICATION;
    case 56:
    case "SCHEDULE_TOUR_REQUEST":
      return EventType.SCHEDULE_TOUR_REQUEST;
    case 58:
    case "SELF_TOUR_FEEDBACK":
      return EventType.SELF_TOUR_FEEDBACK;
    case 59:
    case "SHOWINGTIME_FEEDBACK":
      return EventType.SHOWINGTIME_FEEDBACK;
    case 60:
    case "SMS":
      return EventType.SMS;
    case 61:
    case "SOLD_UNLOCK_ATTEMPT":
      return EventType.SOLD_UNLOCK_ATTEMPT;
    case 62:
    case "SYSTEM":
      return EventType.SYSTEM;
    case 63:
    case "TOM_UNLOCK_ATTEMPT":
      return EventType.TOM_UNLOCK_ATTEMPT;
    case 64:
    case "TRIAGE_SMS_RESPONSE_PHOTO_UPLOAD":
      return EventType.TRIAGE_SMS_RESPONSE_PHOTO_UPLOAD;
    case 65:
    case "TRUST":
      return EventType.TRUST;
    case 66:
    case "UNDER_COVID_LOCKDOWN":
      return EventType.UNDER_COVID_LOCKDOWN;
    case 67:
    case "UNKNOWN_ERROR":
      return EventType.UNKNOWN_ERROR;
    case 68:
    case "UNLOCK":
      return EventType.UNLOCK;
    case 69:
    case "VISIT_EXPERIENCE_FEEDBACK_APPRECIATION":
      return EventType.VISIT_EXPERIENCE_FEEDBACK_APPRECIATION;
    case 70:
    case "VISIT_EXPERIENCE_FEEDBACK_REQUEST":
      return EventType.VISIT_EXPERIENCE_FEEDBACK_REQUEST;
    case 71:
    case "VISIT_EXPERIENCE_FEEDBACK_RESPONSE":
      return EventType.VISIT_EXPERIENCE_FEEDBACK_RESPONSE;
    case 72:
    case "VISIT_EXPERIENCE_SCORE_REQUEST":
      return EventType.VISIT_EXPERIENCE_SCORE_REQUEST;
    case 73:
    case "VISIT_EXPERIENCE_SCORE_RESPONSE":
      return EventType.VISIT_EXPERIENCE_SCORE_RESPONSE;
    case 74:
    case "VOICEMAIL":
      return EventType.VOICEMAIL;
    case 75:
    case "WEB_CHAT":
      return EventType.WEB_CHAT;
    case 76:
    case "COMBINED_DECISION_CALL_OUTCOME":
      return EventType.COMBINED_DECISION_CALL_OUTCOME;
    case 77:
    case "REQUEST_FOR_LWOD_AGENT":
      return EventType.REQUEST_FOR_LWOD_AGENT;
    case 78:
    case "OR_VA_OUTCOME":
      return EventType.OR_VA_OUTCOME;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EventType.UNRECOGNIZED;
  }
}

export function eventTypeToJSON(object: EventType): string {
  switch (object) {
    case EventType.UNKNOWN:
      return "UNKNOWN";
    case EventType.AGENT_REFERRAL_CREATED:
      return "AGENT_REFERRAL_CREATED";
    case EventType.AGENT_REFERRAL_OFFER_SMS_SENT:
      return "AGENT_REFERRAL_OFFER_SMS_SENT";
    case EventType.AGENT_REFERRAL_UPDATED:
      return "AGENT_REFERRAL_UPDATED";
    case EventType.ANALYTICS:
      return "ANALYTICS";
    case EventType.ASSOCIATION:
      return "ASSOCIATION";
    case EventType.ASSOCIATION_CHANGED:
      return "ASSOCIATION_CHANGED";
    case EventType.ATTRIBUTE_CHANGED:
      return "ATTRIBUTE_CHANGED";
    case EventType.BLACKLIST_UNLOCK_ATTEMPT:
      return "BLACKLIST_UNLOCK_ATTEMPT";
    case EventType.BUYER_ATTENTION_WORTHY_SIGNAL:
      return "BUYER_ATTENTION_WORTHY_SIGNAL";
    case EventType.BUYER_LEAD_FROM_SELLER:
      return "BUYER_LEAD_FROM_SELLER";
    case EventType.BUYER_SIGNUP:
      return "BUYER_SIGNUP";
    case EventType.CENTRALIZED_SHOWING_SERVICE_FEEDBACK:
      return "CENTRALIZED_SHOWING_SERVICE_FEEDBACK";
    case EventType.CODE_MISSING_UNLOCK_ATTEMPT:
      return "CODE_MISSING_UNLOCK_ATTEMPT";
    case EventType.CODELESS_UNLOCK_FAILED_ATTEMPT:
      return "CODELESS_UNLOCK_FAILED_ATTEMPT";
    case EventType.CONVERSATION_REASSIGNED:
      return "CONVERSATION_REASSIGNED";
    case EventType.COVID_SAFETY_WINDOW_UNLOCK_ATTEMPT:
      return "COVID_SAFETY_WINDOW_UNLOCK_ATTEMPT";
    case EventType.CUSTOMER_DATA:
      return "CUSTOMER_DATA";
    case EventType.DELIGHTED_SMS_SURVEY_REQUEST:
      return "DELIGHTED_SMS_SURVEY_REQUEST";
    case EventType.DELIGHTED_SURVEY_REQUEST:
      return "DELIGHTED_SURVEY_REQUEST";
    case EventType.DELIGHTED_SURVEY_RESPONSE:
      return "DELIGHTED_SURVEY_RESPONSE";
    case EventType.DEVICE_INSTALL:
      return "DEVICE_INSTALL";
    case EventType.EMAIL:
      return "EMAIL";
    case EventType.EXTERNAL_INTEREST:
      return "EXTERNAL_INTEREST";
    case EventType.GRAYLIST_UNLOCK_ATTEMPT:
      return "GRAYLIST_UNLOCK_ATTEMPT";
    case EventType.HOME_FEEDBACK_LEFT:
      return "HOME_FEEDBACK_LEFT";
    case EventType.HOME_ISSUE_REPORTED:
      return "HOME_ISSUE_REPORTED";
    case EventType.HOMEBUILDER_OR_OUTCOME:
      return "HOMEBUILDER_OR_OUTCOME";
    case EventType.ID_VERIFICATION:
      return "ID_VERIFICATION";
    case EventType.INITIAL_UNLOCK_ATTEMPT:
      return "INITIAL_UNLOCK_ATTEMPT";
    case EventType.INSUFFICIENT_VERSION_UNLOCK_ATTEMPT:
      return "INSUFFICIENT_VERSION_UNLOCK_ATTEMPT";
    case EventType.LISTING_INTEREST:
      return "LISTING_INTEREST";
    case EventType.LISTING_NOT_FOUND_UNLOCK_ATTEMPT:
      return "LISTING_NOT_FOUND_UNLOCK_ATTEMPT";
    case EventType.LISTING_OFFER_NOTIFICATION:
      return "LISTING_OFFER_NOTIFICATION";
    case EventType.LISTING_OFFER_SUBMITTED:
      return "LISTING_OFFER_SUBMITTED";
    case EventType.LISTING_RESERVATION:
      return "LISTING_RESERVATION";
    case EventType.LWOD_OFFER_SENT:
      return "LWOD_OFFER_SENT";
    case EventType.LWOD_OR_OUTCOME:
      return "LWOD_OR_OUTCOME";
    case EventType.MERGE:
      return "MERGE";
    case EventType.NEEDS_ATTENTION_FOLLOWUP_TIME:
      return "NEEDS_ATTENTION_FOLLOWUP_TIME";
    case EventType.NEEDS_ATTENTION_RESOLVED:
      return "NEEDS_ATTENTION_RESOLVED";
    case EventType.NOTE:
      return "NOTE";
    case EventType.OFFER_ON_INTERESTED_HOME:
      return "OFFER_ON_INTERESTED_HOME";
    case EventType.OFFER_SENT:
      return "OFFER_SENT";
    case EventType.OPEN_LISTINGS_OFFER_STATE:
      return "OPEN_LISTINGS_OFFER_STATE";
    case EventType.OPEN_LISTINGS_TOUR_COMPLETED:
      return "OPEN_LISTINGS_TOUR_COMPLETED";
    case EventType.OUT_OF_OFFICE_REPLY:
      return "OUT_OF_OFFICE_REPLY";
    case EventType.OUTREACH_OUTCOME:
      return "OUTREACH_OUTCOME";
    case EventType.OUTSIDE_VISITING_HOURS_UNLOCK_ATTEMPT:
      return "OUTSIDE_VISITING_HOURS_UNLOCK_ATTEMPT";
    case EventType.PARTICIPANT_CHANGED:
      return "PARTICIPANT_CHANGED";
    case EventType.PHONE_CALL:
      return "PHONE_CALL";
    case EventType.POST_FRMS_UNTRUSTED_UNLOCK_NOT_SENT:
      return "POST_FRMS_UNTRUSTED_UNLOCK_NOT_SENT";
    case EventType.PPM_UNLOCK_ATTEMPT:
      return "PPM_UNLOCK_ATTEMPT";
    case EventType.PUSH_NOTIFICATION:
      return "PUSH_NOTIFICATION";
    case EventType.REPEAT_VISIT:
      return "REPEAT_VISIT";
    case EventType.SMS_OPT_OUT_NOTIFICATION:
      return "SMS_OPT_OUT_NOTIFICATION";
    case EventType.SCHEDULE_TOUR_REQUEST:
      return "SCHEDULE_TOUR_REQUEST";
    case EventType.SELF_TOUR_FEEDBACK:
      return "SELF_TOUR_FEEDBACK";
    case EventType.SHOWINGTIME_FEEDBACK:
      return "SHOWINGTIME_FEEDBACK";
    case EventType.SMS:
      return "SMS";
    case EventType.SOLD_UNLOCK_ATTEMPT:
      return "SOLD_UNLOCK_ATTEMPT";
    case EventType.SYSTEM:
      return "SYSTEM";
    case EventType.TOM_UNLOCK_ATTEMPT:
      return "TOM_UNLOCK_ATTEMPT";
    case EventType.TRIAGE_SMS_RESPONSE_PHOTO_UPLOAD:
      return "TRIAGE_SMS_RESPONSE_PHOTO_UPLOAD";
    case EventType.TRUST:
      return "TRUST";
    case EventType.UNDER_COVID_LOCKDOWN:
      return "UNDER_COVID_LOCKDOWN";
    case EventType.UNKNOWN_ERROR:
      return "UNKNOWN_ERROR";
    case EventType.UNLOCK:
      return "UNLOCK";
    case EventType.VISIT_EXPERIENCE_FEEDBACK_APPRECIATION:
      return "VISIT_EXPERIENCE_FEEDBACK_APPRECIATION";
    case EventType.VISIT_EXPERIENCE_FEEDBACK_REQUEST:
      return "VISIT_EXPERIENCE_FEEDBACK_REQUEST";
    case EventType.VISIT_EXPERIENCE_FEEDBACK_RESPONSE:
      return "VISIT_EXPERIENCE_FEEDBACK_RESPONSE";
    case EventType.VISIT_EXPERIENCE_SCORE_REQUEST:
      return "VISIT_EXPERIENCE_SCORE_REQUEST";
    case EventType.VISIT_EXPERIENCE_SCORE_RESPONSE:
      return "VISIT_EXPERIENCE_SCORE_RESPONSE";
    case EventType.VOICEMAIL:
      return "VOICEMAIL";
    case EventType.WEB_CHAT:
      return "WEB_CHAT";
    case EventType.COMBINED_DECISION_CALL_OUTCOME:
      return "COMBINED_DECISION_CALL_OUTCOME";
    case EventType.REQUEST_FOR_LWOD_AGENT:
      return "REQUEST_FOR_LWOD_AGENT";
    case EventType.OR_VA_OUTCOME:
      return "OR_VA_OUTCOME";
    case EventType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** TODO: Define message in web/data or web/customer_ops then deprecate this. */
export interface CustomerEvent {
  timestamp?: Date | undefined;
  id: number;
  eventType: EventType;
  eventableId: number;
  eventableType: string;
  metadata: string;
  content: string;
  customerUuid: string;
}

/** Next ID: 5 */
export interface CustomerEventNote {
  id: number;
  notes: string;
  timestamp?: Date | undefined;
  eventType: EventType;
}

function createBaseCustomerEvent(): CustomerEvent {
  return {
    timestamp: undefined,
    id: 0,
    eventType: 0,
    eventableId: 0,
    eventableType: "",
    metadata: "",
    content: "",
    customerUuid: "",
  };
}

export const CustomerEvent = {
  fromJSON(object: any): CustomerEvent {
    return {
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
      id: isSet(object.id) ? Number(object.id) : 0,
      eventType: isSet(object.eventType) ? eventTypeFromJSON(object.eventType) : 0,
      eventableId: isSet(object.eventableId) ? Number(object.eventableId) : 0,
      eventableType: isSet(object.eventableType) ? String(object.eventableType) : "",
      metadata: isSet(object.metadata) ? String(object.metadata) : "",
      content: isSet(object.content) ? String(object.content) : "",
      customerUuid: isSet(object.customerUuid) ? String(object.customerUuid) : "",
    };
  },

  toJSON(message: CustomerEvent): unknown {
    const obj: any = {};
    if (message.timestamp !== undefined) {
      obj.timestamp = message.timestamp.toISOString();
    }
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.eventType !== 0) {
      obj.eventType = eventTypeToJSON(message.eventType);
    }
    if (message.eventableId !== 0) {
      obj.eventableId = Math.round(message.eventableId);
    }
    if (message.eventableType !== "") {
      obj.eventableType = message.eventableType;
    }
    if (message.metadata !== "") {
      obj.metadata = message.metadata;
    }
    if (message.content !== "") {
      obj.content = message.content;
    }
    if (message.customerUuid !== "") {
      obj.customerUuid = message.customerUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomerEvent>, I>>(base?: I): CustomerEvent {
    return CustomerEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CustomerEvent>, I>>(object: I): CustomerEvent {
    const message = createBaseCustomerEvent();
    message.timestamp = object.timestamp ?? undefined;
    message.id = object.id ?? 0;
    message.eventType = object.eventType ?? 0;
    message.eventableId = object.eventableId ?? 0;
    message.eventableType = object.eventableType ?? "";
    message.metadata = object.metadata ?? "";
    message.content = object.content ?? "";
    message.customerUuid = object.customerUuid ?? "";
    return message;
  },
};

function createBaseCustomerEventNote(): CustomerEventNote {
  return { id: 0, notes: "", timestamp: undefined, eventType: 0 };
}

export const CustomerEventNote = {
  fromJSON(object: any): CustomerEventNote {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      notes: isSet(object.notes) ? String(object.notes) : "",
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
      eventType: isSet(object.eventType) ? eventTypeFromJSON(object.eventType) : 0,
    };
  },

  toJSON(message: CustomerEventNote): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.notes !== "") {
      obj.notes = message.notes;
    }
    if (message.timestamp !== undefined) {
      obj.timestamp = message.timestamp.toISOString();
    }
    if (message.eventType !== 0) {
      obj.eventType = eventTypeToJSON(message.eventType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomerEventNote>, I>>(base?: I): CustomerEventNote {
    return CustomerEventNote.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CustomerEventNote>, I>>(object: I): CustomerEventNote {
    const message = createBaseCustomerEventNote();
    message.id = object.id ?? 0;
    message.notes = object.notes ?? "";
    message.timestamp = object.timestamp ?? undefined;
    message.eventType = object.eventType ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
