/* eslint-disable */

export const protobufPackage = "od_protos.journey.data.categories";

export interface AuthenticationCategory {
  event?:
    | { $case: "login"; login: AuthenticationCategory_LoginEvent }
    | { $case: "loginFailure"; loginFailure: AuthenticationCategory_LoginFailureEvent }
    | { $case: "registration"; registration: AuthenticationCategory_RegistrationEvent }
    | { $case: "registrationFailure"; registrationFailure: AuthenticationCategory_RegistrationFailureEvent }
    | undefined;
}

export enum AuthenticationCategory_AuthMethod {
  METHOD_UNSPECIFIED = 0,
  EMAIL_PASSWORD = 1,
  LITE = 2,
  GOOGLE = 3,
  FACEBOOK = 4,
  SESSION_ALREADY_EXISTS = 5,
  UNRECOGNIZED = -1,
}

export function authenticationCategory_AuthMethodFromJSON(object: any): AuthenticationCategory_AuthMethod {
  switch (object) {
    case 0:
    case "METHOD_UNSPECIFIED":
      return AuthenticationCategory_AuthMethod.METHOD_UNSPECIFIED;
    case 1:
    case "EMAIL_PASSWORD":
      return AuthenticationCategory_AuthMethod.EMAIL_PASSWORD;
    case 2:
    case "LITE":
      return AuthenticationCategory_AuthMethod.LITE;
    case 3:
    case "GOOGLE":
      return AuthenticationCategory_AuthMethod.GOOGLE;
    case 4:
    case "FACEBOOK":
      return AuthenticationCategory_AuthMethod.FACEBOOK;
    case 5:
    case "SESSION_ALREADY_EXISTS":
      return AuthenticationCategory_AuthMethod.SESSION_ALREADY_EXISTS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AuthenticationCategory_AuthMethod.UNRECOGNIZED;
  }
}

export function authenticationCategory_AuthMethodToJSON(object: AuthenticationCategory_AuthMethod): string {
  switch (object) {
    case AuthenticationCategory_AuthMethod.METHOD_UNSPECIFIED:
      return "METHOD_UNSPECIFIED";
    case AuthenticationCategory_AuthMethod.EMAIL_PASSWORD:
      return "EMAIL_PASSWORD";
    case AuthenticationCategory_AuthMethod.LITE:
      return "LITE";
    case AuthenticationCategory_AuthMethod.GOOGLE:
      return "GOOGLE";
    case AuthenticationCategory_AuthMethod.FACEBOOK:
      return "FACEBOOK";
    case AuthenticationCategory_AuthMethod.SESSION_ALREADY_EXISTS:
      return "SESSION_ALREADY_EXISTS";
    case AuthenticationCategory_AuthMethod.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface AuthenticationCategory_LoginEvent {
  authMethod: AuthenticationCategory_AuthMethod;
}

export interface AuthenticationCategory_LoginFailureEvent {
  authMethod: AuthenticationCategory_AuthMethod;
}

export interface AuthenticationCategory_RegistrationEvent {
  authMethod: AuthenticationCategory_AuthMethod;
  email: string;
  phone?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
}

export interface AuthenticationCategory_RegistrationFailureEvent {
  authMethod: AuthenticationCategory_AuthMethod;
}

function createBaseAuthenticationCategory(): AuthenticationCategory {
  return { event: undefined };
}

export const AuthenticationCategory = {
  fromJSON(object: any): AuthenticationCategory {
    return {
      event: isSet(object.login)
        ? { $case: "login", login: AuthenticationCategory_LoginEvent.fromJSON(object.login) }
        : isSet(object.loginFailure)
        ? {
          $case: "loginFailure",
          loginFailure: AuthenticationCategory_LoginFailureEvent.fromJSON(object.loginFailure),
        }
        : isSet(object.registration)
        ? {
          $case: "registration",
          registration: AuthenticationCategory_RegistrationEvent.fromJSON(object.registration),
        }
        : isSet(object.registrationFailure)
        ? {
          $case: "registrationFailure",
          registrationFailure: AuthenticationCategory_RegistrationFailureEvent.fromJSON(object.registrationFailure),
        }
        : undefined,
    };
  },

  toJSON(message: AuthenticationCategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "login") {
      obj.login = AuthenticationCategory_LoginEvent.toJSON(message.event.login);
    }
    if (message.event?.$case === "loginFailure") {
      obj.loginFailure = AuthenticationCategory_LoginFailureEvent.toJSON(message.event.loginFailure);
    }
    if (message.event?.$case === "registration") {
      obj.registration = AuthenticationCategory_RegistrationEvent.toJSON(message.event.registration);
    }
    if (message.event?.$case === "registrationFailure") {
      obj.registrationFailure = AuthenticationCategory_RegistrationFailureEvent.toJSON(
        message.event.registrationFailure,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthenticationCategory>, I>>(base?: I): AuthenticationCategory {
    return AuthenticationCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthenticationCategory>, I>>(object: I): AuthenticationCategory {
    const message = createBaseAuthenticationCategory();
    if (object.event?.$case === "login" && object.event?.login !== undefined && object.event?.login !== null) {
      message.event = { $case: "login", login: AuthenticationCategory_LoginEvent.fromPartial(object.event.login) };
    }
    if (
      object.event?.$case === "loginFailure" &&
      object.event?.loginFailure !== undefined &&
      object.event?.loginFailure !== null
    ) {
      message.event = {
        $case: "loginFailure",
        loginFailure: AuthenticationCategory_LoginFailureEvent.fromPartial(object.event.loginFailure),
      };
    }
    if (
      object.event?.$case === "registration" &&
      object.event?.registration !== undefined &&
      object.event?.registration !== null
    ) {
      message.event = {
        $case: "registration",
        registration: AuthenticationCategory_RegistrationEvent.fromPartial(object.event.registration),
      };
    }
    if (
      object.event?.$case === "registrationFailure" &&
      object.event?.registrationFailure !== undefined &&
      object.event?.registrationFailure !== null
    ) {
      message.event = {
        $case: "registrationFailure",
        registrationFailure: AuthenticationCategory_RegistrationFailureEvent.fromPartial(
          object.event.registrationFailure,
        ),
      };
    }
    return message;
  },
};

function createBaseAuthenticationCategory_LoginEvent(): AuthenticationCategory_LoginEvent {
  return { authMethod: 0 };
}

export const AuthenticationCategory_LoginEvent = {
  fromJSON(object: any): AuthenticationCategory_LoginEvent {
    return { authMethod: isSet(object.authMethod) ? authenticationCategory_AuthMethodFromJSON(object.authMethod) : 0 };
  },

  toJSON(message: AuthenticationCategory_LoginEvent): unknown {
    const obj: any = {};
    if (message.authMethod !== 0) {
      obj.authMethod = authenticationCategory_AuthMethodToJSON(message.authMethod);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthenticationCategory_LoginEvent>, I>>(
    base?: I,
  ): AuthenticationCategory_LoginEvent {
    return AuthenticationCategory_LoginEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthenticationCategory_LoginEvent>, I>>(
    object: I,
  ): AuthenticationCategory_LoginEvent {
    const message = createBaseAuthenticationCategory_LoginEvent();
    message.authMethod = object.authMethod ?? 0;
    return message;
  },
};

function createBaseAuthenticationCategory_LoginFailureEvent(): AuthenticationCategory_LoginFailureEvent {
  return { authMethod: 0 };
}

export const AuthenticationCategory_LoginFailureEvent = {
  fromJSON(object: any): AuthenticationCategory_LoginFailureEvent {
    return { authMethod: isSet(object.authMethod) ? authenticationCategory_AuthMethodFromJSON(object.authMethod) : 0 };
  },

  toJSON(message: AuthenticationCategory_LoginFailureEvent): unknown {
    const obj: any = {};
    if (message.authMethod !== 0) {
      obj.authMethod = authenticationCategory_AuthMethodToJSON(message.authMethod);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthenticationCategory_LoginFailureEvent>, I>>(
    base?: I,
  ): AuthenticationCategory_LoginFailureEvent {
    return AuthenticationCategory_LoginFailureEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthenticationCategory_LoginFailureEvent>, I>>(
    object: I,
  ): AuthenticationCategory_LoginFailureEvent {
    const message = createBaseAuthenticationCategory_LoginFailureEvent();
    message.authMethod = object.authMethod ?? 0;
    return message;
  },
};

function createBaseAuthenticationCategory_RegistrationEvent(): AuthenticationCategory_RegistrationEvent {
  return { authMethod: 0, email: "", phone: undefined, firstName: undefined, lastName: undefined };
}

export const AuthenticationCategory_RegistrationEvent = {
  fromJSON(object: any): AuthenticationCategory_RegistrationEvent {
    return {
      authMethod: isSet(object.authMethod) ? authenticationCategory_AuthMethodFromJSON(object.authMethod) : 0,
      email: isSet(object.email) ? String(object.email) : "",
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
    };
  },

  toJSON(message: AuthenticationCategory_RegistrationEvent): unknown {
    const obj: any = {};
    if (message.authMethod !== 0) {
      obj.authMethod = authenticationCategory_AuthMethodToJSON(message.authMethod);
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.phone !== undefined) {
      obj.phone = message.phone;
    }
    if (message.firstName !== undefined) {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== undefined) {
      obj.lastName = message.lastName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthenticationCategory_RegistrationEvent>, I>>(
    base?: I,
  ): AuthenticationCategory_RegistrationEvent {
    return AuthenticationCategory_RegistrationEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthenticationCategory_RegistrationEvent>, I>>(
    object: I,
  ): AuthenticationCategory_RegistrationEvent {
    const message = createBaseAuthenticationCategory_RegistrationEvent();
    message.authMethod = object.authMethod ?? 0;
    message.email = object.email ?? "";
    message.phone = object.phone ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    return message;
  },
};

function createBaseAuthenticationCategory_RegistrationFailureEvent(): AuthenticationCategory_RegistrationFailureEvent {
  return { authMethod: 0 };
}

export const AuthenticationCategory_RegistrationFailureEvent = {
  fromJSON(object: any): AuthenticationCategory_RegistrationFailureEvent {
    return { authMethod: isSet(object.authMethod) ? authenticationCategory_AuthMethodFromJSON(object.authMethod) : 0 };
  },

  toJSON(message: AuthenticationCategory_RegistrationFailureEvent): unknown {
    const obj: any = {};
    if (message.authMethod !== 0) {
      obj.authMethod = authenticationCategory_AuthMethodToJSON(message.authMethod);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthenticationCategory_RegistrationFailureEvent>, I>>(
    base?: I,
  ): AuthenticationCategory_RegistrationFailureEvent {
    return AuthenticationCategory_RegistrationFailureEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthenticationCategory_RegistrationFailureEvent>, I>>(
    object: I,
  ): AuthenticationCategory_RegistrationFailureEvent {
    const message = createBaseAuthenticationCategory_RegistrationFailureEvent();
    message.authMethod = object.authMethod ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
