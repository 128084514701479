/* eslint-disable */

export const protobufPackage = "od_protos.journey.data.categories";

export interface AssessmentCategory {
  event?: { $case: "flowStart"; flowStart: AssessmentCategory_AssessmentFlowStartEvent } | {
    $case: "flowCompleted";
    flowCompleted: AssessmentCategory_AssessmentFlowCompletedEvent;
  } | undefined;
}

export enum AssessmentCategory_AssessmentType {
  UNKNOWN_ASSESSMENT_TYPE = 0,
  ORVA = 1,
  SSVA = 2,
  HA = 3,
  VIRTUAL_INSPECTION = 4,
  UNRECOGNIZED = -1,
}

export function assessmentCategory_AssessmentTypeFromJSON(object: any): AssessmentCategory_AssessmentType {
  switch (object) {
    case 0:
    case "UNKNOWN_ASSESSMENT_TYPE":
      return AssessmentCategory_AssessmentType.UNKNOWN_ASSESSMENT_TYPE;
    case 1:
    case "ORVA":
      return AssessmentCategory_AssessmentType.ORVA;
    case 2:
    case "SSVA":
      return AssessmentCategory_AssessmentType.SSVA;
    case 3:
    case "HA":
      return AssessmentCategory_AssessmentType.HA;
    case 4:
    case "VIRTUAL_INSPECTION":
      return AssessmentCategory_AssessmentType.VIRTUAL_INSPECTION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AssessmentCategory_AssessmentType.UNRECOGNIZED;
  }
}

export function assessmentCategory_AssessmentTypeToJSON(object: AssessmentCategory_AssessmentType): string {
  switch (object) {
    case AssessmentCategory_AssessmentType.UNKNOWN_ASSESSMENT_TYPE:
      return "UNKNOWN_ASSESSMENT_TYPE";
    case AssessmentCategory_AssessmentType.ORVA:
      return "ORVA";
    case AssessmentCategory_AssessmentType.SSVA:
      return "SSVA";
    case AssessmentCategory_AssessmentType.HA:
      return "HA";
    case AssessmentCategory_AssessmentType.VIRTUAL_INSPECTION:
      return "VIRTUAL_INSPECTION";
    case AssessmentCategory_AssessmentType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface AssessmentCategory_AssessmentFlowStartEvent {
  leadId: string;
  offerUuid: string;
  assessmentType: AssessmentCategory_AssessmentType;
}

export interface AssessmentCategory_AssessmentFlowCompletedEvent {
  leadId: string;
  offerUuid: string;
  assessmentType: AssessmentCategory_AssessmentType;
}

function createBaseAssessmentCategory(): AssessmentCategory {
  return { event: undefined };
}

export const AssessmentCategory = {
  fromJSON(object: any): AssessmentCategory {
    return {
      event: isSet(object.flowStart)
        ? { $case: "flowStart", flowStart: AssessmentCategory_AssessmentFlowStartEvent.fromJSON(object.flowStart) }
        : isSet(object.flowCompleted)
        ? {
          $case: "flowCompleted",
          flowCompleted: AssessmentCategory_AssessmentFlowCompletedEvent.fromJSON(object.flowCompleted),
        }
        : undefined,
    };
  },

  toJSON(message: AssessmentCategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "flowStart") {
      obj.flowStart = AssessmentCategory_AssessmentFlowStartEvent.toJSON(message.event.flowStart);
    }
    if (message.event?.$case === "flowCompleted") {
      obj.flowCompleted = AssessmentCategory_AssessmentFlowCompletedEvent.toJSON(message.event.flowCompleted);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssessmentCategory>, I>>(base?: I): AssessmentCategory {
    return AssessmentCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssessmentCategory>, I>>(object: I): AssessmentCategory {
    const message = createBaseAssessmentCategory();
    if (
      object.event?.$case === "flowStart" && object.event?.flowStart !== undefined && object.event?.flowStart !== null
    ) {
      message.event = {
        $case: "flowStart",
        flowStart: AssessmentCategory_AssessmentFlowStartEvent.fromPartial(object.event.flowStart),
      };
    }
    if (
      object.event?.$case === "flowCompleted" &&
      object.event?.flowCompleted !== undefined &&
      object.event?.flowCompleted !== null
    ) {
      message.event = {
        $case: "flowCompleted",
        flowCompleted: AssessmentCategory_AssessmentFlowCompletedEvent.fromPartial(object.event.flowCompleted),
      };
    }
    return message;
  },
};

function createBaseAssessmentCategory_AssessmentFlowStartEvent(): AssessmentCategory_AssessmentFlowStartEvent {
  return { leadId: "", offerUuid: "", assessmentType: 0 };
}

export const AssessmentCategory_AssessmentFlowStartEvent = {
  fromJSON(object: any): AssessmentCategory_AssessmentFlowStartEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
      assessmentType: isSet(object.assessmentType)
        ? assessmentCategory_AssessmentTypeFromJSON(object.assessmentType)
        : 0,
    };
  },

  toJSON(message: AssessmentCategory_AssessmentFlowStartEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    if (message.assessmentType !== 0) {
      obj.assessmentType = assessmentCategory_AssessmentTypeToJSON(message.assessmentType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssessmentCategory_AssessmentFlowStartEvent>, I>>(
    base?: I,
  ): AssessmentCategory_AssessmentFlowStartEvent {
    return AssessmentCategory_AssessmentFlowStartEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssessmentCategory_AssessmentFlowStartEvent>, I>>(
    object: I,
  ): AssessmentCategory_AssessmentFlowStartEvent {
    const message = createBaseAssessmentCategory_AssessmentFlowStartEvent();
    message.leadId = object.leadId ?? "";
    message.offerUuid = object.offerUuid ?? "";
    message.assessmentType = object.assessmentType ?? 0;
    return message;
  },
};

function createBaseAssessmentCategory_AssessmentFlowCompletedEvent(): AssessmentCategory_AssessmentFlowCompletedEvent {
  return { leadId: "", offerUuid: "", assessmentType: 0 };
}

export const AssessmentCategory_AssessmentFlowCompletedEvent = {
  fromJSON(object: any): AssessmentCategory_AssessmentFlowCompletedEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
      assessmentType: isSet(object.assessmentType)
        ? assessmentCategory_AssessmentTypeFromJSON(object.assessmentType)
        : 0,
    };
  },

  toJSON(message: AssessmentCategory_AssessmentFlowCompletedEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    if (message.assessmentType !== 0) {
      obj.assessmentType = assessmentCategory_AssessmentTypeToJSON(message.assessmentType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssessmentCategory_AssessmentFlowCompletedEvent>, I>>(
    base?: I,
  ): AssessmentCategory_AssessmentFlowCompletedEvent {
    return AssessmentCategory_AssessmentFlowCompletedEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssessmentCategory_AssessmentFlowCompletedEvent>, I>>(
    object: I,
  ): AssessmentCategory_AssessmentFlowCompletedEvent {
    const message = createBaseAssessmentCategory_AssessmentFlowCompletedEvent();
    message.leadId = object.leadId ?? "";
    message.offerUuid = object.offerUuid ?? "";
    message.assessmentType = object.assessmentType ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
