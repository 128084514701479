/* eslint-disable */
import { Timestamp } from "../../../../google/protobuf/timestamp";

export const protobufPackage = "od_protos.journey.data.categories";

export interface SellEACategory {
  event?: { $case: "schedule"; schedule: SellEACategory_ScheduleEvent } | {
    $case: "complete";
    complete: SellEACategory_CompleteEvent;
  } | undefined;
}

export enum SellEACategory_EAType {
  UNKNOWN_EA_TYPE = 0,
  EXTERIOR_ASSESSMENT = 1,
  EXTERIOR_VIRTUAL_ASSESSMENT = 2,
  /** HOME_ASSESSMENT - exterior + interior of home */
  HOME_ASSESSMENT = 3,
  UNRECOGNIZED = -1,
}

export function sellEACategory_EATypeFromJSON(object: any): SellEACategory_EAType {
  switch (object) {
    case 0:
    case "UNKNOWN_EA_TYPE":
      return SellEACategory_EAType.UNKNOWN_EA_TYPE;
    case 1:
    case "EXTERIOR_ASSESSMENT":
      return SellEACategory_EAType.EXTERIOR_ASSESSMENT;
    case 2:
    case "EXTERIOR_VIRTUAL_ASSESSMENT":
      return SellEACategory_EAType.EXTERIOR_VIRTUAL_ASSESSMENT;
    case 3:
    case "HOME_ASSESSMENT":
      return SellEACategory_EAType.HOME_ASSESSMENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellEACategory_EAType.UNRECOGNIZED;
  }
}

export function sellEACategory_EATypeToJSON(object: SellEACategory_EAType): string {
  switch (object) {
    case SellEACategory_EAType.UNKNOWN_EA_TYPE:
      return "UNKNOWN_EA_TYPE";
    case SellEACategory_EAType.EXTERIOR_ASSESSMENT:
      return "EXTERIOR_ASSESSMENT";
    case SellEACategory_EAType.EXTERIOR_VIRTUAL_ASSESSMENT:
      return "EXTERIOR_VIRTUAL_ASSESSMENT";
    case SellEACategory_EAType.HOME_ASSESSMENT:
      return "HOME_ASSESSMENT";
    case SellEACategory_EAType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SellEACategory_ScheduleEvent {
  leadId: string;
  offerId: string;
  eaType: SellEACategory_EAType;
  offerUuid: string;
  scheduledDatetime?: Date | undefined;
}

export interface SellEACategory_CompleteEvent {
  leadId: string;
  offerId: string;
  eaType: SellEACategory_EAType;
  offerUuid: string;
}

function createBaseSellEACategory(): SellEACategory {
  return { event: undefined };
}

export const SellEACategory = {
  fromJSON(object: any): SellEACategory {
    return {
      event: isSet(object.schedule)
        ? { $case: "schedule", schedule: SellEACategory_ScheduleEvent.fromJSON(object.schedule) }
        : isSet(object.complete)
        ? { $case: "complete", complete: SellEACategory_CompleteEvent.fromJSON(object.complete) }
        : undefined,
    };
  },

  toJSON(message: SellEACategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "schedule") {
      obj.schedule = SellEACategory_ScheduleEvent.toJSON(message.event.schedule);
    }
    if (message.event?.$case === "complete") {
      obj.complete = SellEACategory_CompleteEvent.toJSON(message.event.complete);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellEACategory>, I>>(base?: I): SellEACategory {
    return SellEACategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellEACategory>, I>>(object: I): SellEACategory {
    const message = createBaseSellEACategory();
    if (object.event?.$case === "schedule" && object.event?.schedule !== undefined && object.event?.schedule !== null) {
      message.event = { $case: "schedule", schedule: SellEACategory_ScheduleEvent.fromPartial(object.event.schedule) };
    }
    if (object.event?.$case === "complete" && object.event?.complete !== undefined && object.event?.complete !== null) {
      message.event = { $case: "complete", complete: SellEACategory_CompleteEvent.fromPartial(object.event.complete) };
    }
    return message;
  },
};

function createBaseSellEACategory_ScheduleEvent(): SellEACategory_ScheduleEvent {
  return { leadId: "", offerId: "", eaType: 0, offerUuid: "", scheduledDatetime: undefined };
}

export const SellEACategory_ScheduleEvent = {
  fromJSON(object: any): SellEACategory_ScheduleEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      eaType: isSet(object.eaType) ? sellEACategory_EATypeFromJSON(object.eaType) : 0,
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
      scheduledDatetime: isSet(object.scheduledDatetime) ? fromJsonTimestamp(object.scheduledDatetime) : undefined,
    };
  },

  toJSON(message: SellEACategory_ScheduleEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerId !== "") {
      obj.offerId = message.offerId;
    }
    if (message.eaType !== 0) {
      obj.eaType = sellEACategory_EATypeToJSON(message.eaType);
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    if (message.scheduledDatetime !== undefined) {
      obj.scheduledDatetime = message.scheduledDatetime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellEACategory_ScheduleEvent>, I>>(base?: I): SellEACategory_ScheduleEvent {
    return SellEACategory_ScheduleEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellEACategory_ScheduleEvent>, I>>(object: I): SellEACategory_ScheduleEvent {
    const message = createBaseSellEACategory_ScheduleEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.eaType = object.eaType ?? 0;
    message.offerUuid = object.offerUuid ?? "";
    message.scheduledDatetime = object.scheduledDatetime ?? undefined;
    return message;
  },
};

function createBaseSellEACategory_CompleteEvent(): SellEACategory_CompleteEvent {
  return { leadId: "", offerId: "", eaType: 0, offerUuid: "" };
}

export const SellEACategory_CompleteEvent = {
  fromJSON(object: any): SellEACategory_CompleteEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      eaType: isSet(object.eaType) ? sellEACategory_EATypeFromJSON(object.eaType) : 0,
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
    };
  },

  toJSON(message: SellEACategory_CompleteEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.offerId !== "") {
      obj.offerId = message.offerId;
    }
    if (message.eaType !== 0) {
      obj.eaType = sellEACategory_EATypeToJSON(message.eaType);
    }
    if (message.offerUuid !== "") {
      obj.offerUuid = message.offerUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellEACategory_CompleteEvent>, I>>(base?: I): SellEACategory_CompleteEvent {
    return SellEACategory_CompleteEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellEACategory_CompleteEvent>, I>>(object: I): SellEACategory_CompleteEvent {
    const message = createBaseSellEACategory_CompleteEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.eaType = object.eaType ?? 0;
    message.offerUuid = object.offerUuid ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
