/* eslint-disable */
import { Timestamp } from "../../../google/protobuf/timestamp";
import { AgentLead } from "../../agents/data/agentlead";
import { Listing } from "./listing";

export const protobufPackage = "od_protos.listings.data";

export enum ListingPresentationType {
  UNKNOWN_PRESENTATION_TYPE = 0,
  IN_PERSON = 1,
  VIDEO_CALL = 2,
  PHONE = 3,
  EMAIL = 4,
  UNRECOGNIZED = -1,
}

export function listingPresentationTypeFromJSON(object: any): ListingPresentationType {
  switch (object) {
    case 0:
    case "UNKNOWN_PRESENTATION_TYPE":
      return ListingPresentationType.UNKNOWN_PRESENTATION_TYPE;
    case 1:
    case "IN_PERSON":
      return ListingPresentationType.IN_PERSON;
    case 2:
    case "VIDEO_CALL":
      return ListingPresentationType.VIDEO_CALL;
    case 3:
    case "PHONE":
      return ListingPresentationType.PHONE;
    case 4:
    case "EMAIL":
      return ListingPresentationType.EMAIL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ListingPresentationType.UNRECOGNIZED;
  }
}

export function listingPresentationTypeToJSON(object: ListingPresentationType): string {
  switch (object) {
    case ListingPresentationType.UNKNOWN_PRESENTATION_TYPE:
      return "UNKNOWN_PRESENTATION_TYPE";
    case ListingPresentationType.IN_PERSON:
      return "IN_PERSON";
    case ListingPresentationType.VIDEO_CALL:
      return "VIDEO_CALL";
    case ListingPresentationType.PHONE:
      return "PHONE";
    case ListingPresentationType.EMAIL:
      return "EMAIL";
    case ListingPresentationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum NoteType {
  UNKNOWN_NOTE = 0,
  AGENT_NOTE = 1,
  CEP_NOTE = 2,
  LEAD_PASS_BACK_NOTE = 3,
  UNRECOGNIZED = -1,
}

export function noteTypeFromJSON(object: any): NoteType {
  switch (object) {
    case 0:
    case "UNKNOWN_NOTE":
      return NoteType.UNKNOWN_NOTE;
    case 1:
    case "AGENT_NOTE":
      return NoteType.AGENT_NOTE;
    case 2:
    case "CEP_NOTE":
      return NoteType.CEP_NOTE;
    case 3:
    case "LEAD_PASS_BACK_NOTE":
      return NoteType.LEAD_PASS_BACK_NOTE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NoteType.UNRECOGNIZED;
  }
}

export function noteTypeToJSON(object: NoteType): string {
  switch (object) {
    case NoteType.UNKNOWN_NOTE:
      return "UNKNOWN_NOTE";
    case NoteType.AGENT_NOTE:
      return "AGENT_NOTE";
    case NoteType.CEP_NOTE:
      return "CEP_NOTE";
    case NoteType.LEAD_PASS_BACK_NOTE:
      return "LEAD_PASS_BACK_NOTE";
    case NoteType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum CustomerDeclineReason {
  UNKNOWN_DECLINE_REASON = 0,
  DECLINE_REASON_NOT_BUYING_OR_SELLING = 1,
  DECLINE_REASON_COMPETITOR = 2,
  DECLINE_REASON_NON_RESPONSIVE = 3,
  DECLINE_REASON_DIRECT_SALE_ONLY = 4,
  UNRECOGNIZED = -1,
}

export function customerDeclineReasonFromJSON(object: any): CustomerDeclineReason {
  switch (object) {
    case 0:
    case "UNKNOWN_DECLINE_REASON":
      return CustomerDeclineReason.UNKNOWN_DECLINE_REASON;
    case 1:
    case "DECLINE_REASON_NOT_BUYING_OR_SELLING":
      return CustomerDeclineReason.DECLINE_REASON_NOT_BUYING_OR_SELLING;
    case 2:
    case "DECLINE_REASON_COMPETITOR":
      return CustomerDeclineReason.DECLINE_REASON_COMPETITOR;
    case 3:
    case "DECLINE_REASON_NON_RESPONSIVE":
      return CustomerDeclineReason.DECLINE_REASON_NON_RESPONSIVE;
    case 4:
    case "DECLINE_REASON_DIRECT_SALE_ONLY":
      return CustomerDeclineReason.DECLINE_REASON_DIRECT_SALE_ONLY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CustomerDeclineReason.UNRECOGNIZED;
  }
}

export function customerDeclineReasonToJSON(object: CustomerDeclineReason): string {
  switch (object) {
    case CustomerDeclineReason.UNKNOWN_DECLINE_REASON:
      return "UNKNOWN_DECLINE_REASON";
    case CustomerDeclineReason.DECLINE_REASON_NOT_BUYING_OR_SELLING:
      return "DECLINE_REASON_NOT_BUYING_OR_SELLING";
    case CustomerDeclineReason.DECLINE_REASON_COMPETITOR:
      return "DECLINE_REASON_COMPETITOR";
    case CustomerDeclineReason.DECLINE_REASON_NON_RESPONSIVE:
      return "DECLINE_REASON_NON_RESPONSIVE";
    case CustomerDeclineReason.DECLINE_REASON_DIRECT_SALE_ONLY:
      return "DECLINE_REASON_DIRECT_SALE_ONLY";
    case CustomerDeclineReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Actor {
  UNKNOWN_ACTOR = 0,
  AGENT = 1,
  CEP = 2,
  SYSTEM = 3,
  UNRECOGNIZED = -1,
}

export function actorFromJSON(object: any): Actor {
  switch (object) {
    case 0:
    case "UNKNOWN_ACTOR":
      return Actor.UNKNOWN_ACTOR;
    case 1:
    case "AGENT":
      return Actor.AGENT;
    case 2:
    case "CEP":
      return Actor.CEP;
    case 3:
    case "SYSTEM":
      return Actor.SYSTEM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Actor.UNRECOGNIZED;
  }
}

export function actorToJSON(object: Actor): string {
  switch (object) {
    case Actor.UNKNOWN_ACTOR:
      return "UNKNOWN_ACTOR";
    case Actor.AGENT:
      return "AGENT";
    case Actor.CEP:
      return "CEP";
    case Actor.SYSTEM:
      return "SYSTEM";
    case Actor.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ExperiencePartnerSupportRequestType {
  UNKNOWN_SUPPORT_REQUEST_TYPE = 0,
  SUPPORT_REQUEST_TYPE_REPAIR_ASKS = 1,
  SUPPORT_REQUEST_TYPE_OFFER_EXPLORATION = 2,
  SUPPORT_REQUEST_TYPE_SELLER_CONTACT = 3,
  UNRECOGNIZED = -1,
}

export function experiencePartnerSupportRequestTypeFromJSON(object: any): ExperiencePartnerSupportRequestType {
  switch (object) {
    case 0:
    case "UNKNOWN_SUPPORT_REQUEST_TYPE":
      return ExperiencePartnerSupportRequestType.UNKNOWN_SUPPORT_REQUEST_TYPE;
    case 1:
    case "SUPPORT_REQUEST_TYPE_REPAIR_ASKS":
      return ExperiencePartnerSupportRequestType.SUPPORT_REQUEST_TYPE_REPAIR_ASKS;
    case 2:
    case "SUPPORT_REQUEST_TYPE_OFFER_EXPLORATION":
      return ExperiencePartnerSupportRequestType.SUPPORT_REQUEST_TYPE_OFFER_EXPLORATION;
    case 3:
    case "SUPPORT_REQUEST_TYPE_SELLER_CONTACT":
      return ExperiencePartnerSupportRequestType.SUPPORT_REQUEST_TYPE_SELLER_CONTACT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ExperiencePartnerSupportRequestType.UNRECOGNIZED;
  }
}

export function experiencePartnerSupportRequestTypeToJSON(object: ExperiencePartnerSupportRequestType): string {
  switch (object) {
    case ExperiencePartnerSupportRequestType.UNKNOWN_SUPPORT_REQUEST_TYPE:
      return "UNKNOWN_SUPPORT_REQUEST_TYPE";
    case ExperiencePartnerSupportRequestType.SUPPORT_REQUEST_TYPE_REPAIR_ASKS:
      return "SUPPORT_REQUEST_TYPE_REPAIR_ASKS";
    case ExperiencePartnerSupportRequestType.SUPPORT_REQUEST_TYPE_OFFER_EXPLORATION:
      return "SUPPORT_REQUEST_TYPE_OFFER_EXPLORATION";
    case ExperiencePartnerSupportRequestType.SUPPORT_REQUEST_TYPE_SELLER_CONTACT:
      return "SUPPORT_REQUEST_TYPE_SELLER_CONTACT";
    case ExperiencePartnerSupportRequestType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface KeyTimestampPair {
  key: string;
  timestamp?: Date | undefined;
}

export interface LwcProduct {
  id: string;
  state: LwcProduct_State;
  sellerLeadToken: string;
  listingId: string;
  assignedAgentId: string;
  agentNote: string;
  cepNote: string;
  leadPassBackNote: string;
  timestamps: KeyTimestampPair[];
  presentationType: ListingPresentationType;
}

/** Next ID: 21 */
export enum LwcProduct_State {
  UNKNOWN = 0,
  CREATED = 1,
  LISTING_INITIATED = 2,
  FIRST_CONTACT_ATTEMPTED = 4,
  LISTING_PRESENTATION_SCHEDULED = 5,
  LISTING_PRESENTATION_COMPLETE = 6,
  SELECTED_LISTING = 19,
  SELECTED_DIRECT_SALE = 20,
  /** NOT_ELIGIBLE - Terminal */
  NOT_ELIGIBLE = 16,
  SOLD_ON_MARKET = 13,
  SOLD_TO_OPENDOOR = 17,
  NO_SALE = 18,
  /** DNC_NOT_BUYING_SELLING - Did not convert (DNC) */
  DNC_NOT_BUYING_SELLING = 9,
  DNC_COMPETITOR = 10,
  DNC_NON_RESPONSIVE = 11,
  DNC_NOT_INTERESTED = 14,
  DNC_DIRECT_SALE_ONLY = 15,
  /**
   * AGENT_ASSIGNED - Deprecated
   *
   * @deprecated
   */
  AGENT_ASSIGNED = 3,
  /** @deprecated */
  DELETED = 12,
  /** @deprecated */
  CONVERTED_LISTING = 7,
  /** @deprecated */
  CONVERTED_DIRECT_SALE = 8,
  UNRECOGNIZED = -1,
}

export function lwcProduct_StateFromJSON(object: any): LwcProduct_State {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return LwcProduct_State.UNKNOWN;
    case 1:
    case "CREATED":
      return LwcProduct_State.CREATED;
    case 2:
    case "LISTING_INITIATED":
      return LwcProduct_State.LISTING_INITIATED;
    case 4:
    case "FIRST_CONTACT_ATTEMPTED":
      return LwcProduct_State.FIRST_CONTACT_ATTEMPTED;
    case 5:
    case "LISTING_PRESENTATION_SCHEDULED":
      return LwcProduct_State.LISTING_PRESENTATION_SCHEDULED;
    case 6:
    case "LISTING_PRESENTATION_COMPLETE":
      return LwcProduct_State.LISTING_PRESENTATION_COMPLETE;
    case 19:
    case "SELECTED_LISTING":
      return LwcProduct_State.SELECTED_LISTING;
    case 20:
    case "SELECTED_DIRECT_SALE":
      return LwcProduct_State.SELECTED_DIRECT_SALE;
    case 16:
    case "NOT_ELIGIBLE":
      return LwcProduct_State.NOT_ELIGIBLE;
    case 13:
    case "SOLD_ON_MARKET":
      return LwcProduct_State.SOLD_ON_MARKET;
    case 17:
    case "SOLD_TO_OPENDOOR":
      return LwcProduct_State.SOLD_TO_OPENDOOR;
    case 18:
    case "NO_SALE":
      return LwcProduct_State.NO_SALE;
    case 9:
    case "DNC_NOT_BUYING_SELLING":
      return LwcProduct_State.DNC_NOT_BUYING_SELLING;
    case 10:
    case "DNC_COMPETITOR":
      return LwcProduct_State.DNC_COMPETITOR;
    case 11:
    case "DNC_NON_RESPONSIVE":
      return LwcProduct_State.DNC_NON_RESPONSIVE;
    case 14:
    case "DNC_NOT_INTERESTED":
      return LwcProduct_State.DNC_NOT_INTERESTED;
    case 15:
    case "DNC_DIRECT_SALE_ONLY":
      return LwcProduct_State.DNC_DIRECT_SALE_ONLY;
    case 3:
    case "AGENT_ASSIGNED":
      return LwcProduct_State.AGENT_ASSIGNED;
    case 12:
    case "DELETED":
      return LwcProduct_State.DELETED;
    case 7:
    case "CONVERTED_LISTING":
      return LwcProduct_State.CONVERTED_LISTING;
    case 8:
    case "CONVERTED_DIRECT_SALE":
      return LwcProduct_State.CONVERTED_DIRECT_SALE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LwcProduct_State.UNRECOGNIZED;
  }
}

export function lwcProduct_StateToJSON(object: LwcProduct_State): string {
  switch (object) {
    case LwcProduct_State.UNKNOWN:
      return "UNKNOWN";
    case LwcProduct_State.CREATED:
      return "CREATED";
    case LwcProduct_State.LISTING_INITIATED:
      return "LISTING_INITIATED";
    case LwcProduct_State.FIRST_CONTACT_ATTEMPTED:
      return "FIRST_CONTACT_ATTEMPTED";
    case LwcProduct_State.LISTING_PRESENTATION_SCHEDULED:
      return "LISTING_PRESENTATION_SCHEDULED";
    case LwcProduct_State.LISTING_PRESENTATION_COMPLETE:
      return "LISTING_PRESENTATION_COMPLETE";
    case LwcProduct_State.SELECTED_LISTING:
      return "SELECTED_LISTING";
    case LwcProduct_State.SELECTED_DIRECT_SALE:
      return "SELECTED_DIRECT_SALE";
    case LwcProduct_State.NOT_ELIGIBLE:
      return "NOT_ELIGIBLE";
    case LwcProduct_State.SOLD_ON_MARKET:
      return "SOLD_ON_MARKET";
    case LwcProduct_State.SOLD_TO_OPENDOOR:
      return "SOLD_TO_OPENDOOR";
    case LwcProduct_State.NO_SALE:
      return "NO_SALE";
    case LwcProduct_State.DNC_NOT_BUYING_SELLING:
      return "DNC_NOT_BUYING_SELLING";
    case LwcProduct_State.DNC_COMPETITOR:
      return "DNC_COMPETITOR";
    case LwcProduct_State.DNC_NON_RESPONSIVE:
      return "DNC_NON_RESPONSIVE";
    case LwcProduct_State.DNC_NOT_INTERESTED:
      return "DNC_NOT_INTERESTED";
    case LwcProduct_State.DNC_DIRECT_SALE_ONLY:
      return "DNC_DIRECT_SALE_ONLY";
    case LwcProduct_State.AGENT_ASSIGNED:
      return "AGENT_ASSIGNED";
    case LwcProduct_State.DELETED:
      return "DELETED";
    case LwcProduct_State.CONVERTED_LISTING:
      return "CONVERTED_LISTING";
    case LwcProduct_State.CONVERTED_DIRECT_SALE:
      return "CONVERTED_DIRECT_SALE";
    case LwcProduct_State.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface LwcDetails {
  lwcProduct?: LwcProduct | undefined;
  listing?: Listing | undefined;
  agentLead?: AgentLead | undefined;
}

/** The LwcProductFilter is used to filter LwcProduct list results */
export interface LwcProductFilter {
  /**
   * Only LwcProducts which have a state found in the states list will
   * be returned. If no states filter is provided, all LwcProducts found
   * in an "active" states (i.e. not CREATED or DELETED) will be returned.
   */
  states: LwcProduct_State[];
}

export interface AssignedAgent {
  agentCustomerUuid: string;
}

export interface LwcProductView {
  id: string;
  intent: LwcProductView_Intent;
  sellDirectLeadToken: string;
  listingAgreementSignedAt?: Date | undefined;
  assignedAgent?:
    | AssignedAgent
    | undefined;
  /** Time when a listing has become active, i.e. listed */
  listingActiveAt?: Date | undefined;
  listingSoldAt?: Date | undefined;
}

/** Next ID: 5 */
export enum LwcProductView_Intent {
  /** UNSET - Enum value not set, DO NOT USE */
  UNSET = 0,
  /** PENDING - Lead is qualified for LwC but we have not yet collected their intent */
  PENDING = 1,
  /** LISTING_VIA_LWC - Lead has explicitly expressed intent to list via LwC */
  LISTING_VIA_LWC = 2,
  /** DIRECT_SALE_VIA_LWC - Lead has explicitly expressed intent to take the SD offer after listing via LwC */
  DIRECT_SALE_VIA_LWC = 3,
  /** NON_LWC - Lead is qualified for LwC but has declined it */
  NON_LWC = 4,
  UNRECOGNIZED = -1,
}

export function lwcProductView_IntentFromJSON(object: any): LwcProductView_Intent {
  switch (object) {
    case 0:
    case "UNSET":
      return LwcProductView_Intent.UNSET;
    case 1:
    case "PENDING":
      return LwcProductView_Intent.PENDING;
    case 2:
    case "LISTING_VIA_LWC":
      return LwcProductView_Intent.LISTING_VIA_LWC;
    case 3:
    case "DIRECT_SALE_VIA_LWC":
      return LwcProductView_Intent.DIRECT_SALE_VIA_LWC;
    case 4:
    case "NON_LWC":
      return LwcProductView_Intent.NON_LWC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LwcProductView_Intent.UNRECOGNIZED;
  }
}

export function lwcProductView_IntentToJSON(object: LwcProductView_Intent): string {
  switch (object) {
    case LwcProductView_Intent.UNSET:
      return "UNSET";
    case LwcProductView_Intent.PENDING:
      return "PENDING";
    case LwcProductView_Intent.LISTING_VIA_LWC:
      return "LISTING_VIA_LWC";
    case LwcProductView_Intent.DIRECT_SALE_VIA_LWC:
      return "DIRECT_SALE_VIA_LWC";
    case LwcProductView_Intent.NON_LWC:
      return "NON_LWC";
    case LwcProductView_Intent.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseKeyTimestampPair(): KeyTimestampPair {
  return { key: "", timestamp: undefined };
}

export const KeyTimestampPair = {
  fromJSON(object: any): KeyTimestampPair {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
    };
  },

  toJSON(message: KeyTimestampPair): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.timestamp !== undefined) {
      obj.timestamp = message.timestamp.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KeyTimestampPair>, I>>(base?: I): KeyTimestampPair {
    return KeyTimestampPair.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<KeyTimestampPair>, I>>(object: I): KeyTimestampPair {
    const message = createBaseKeyTimestampPair();
    message.key = object.key ?? "";
    message.timestamp = object.timestamp ?? undefined;
    return message;
  },
};

function createBaseLwcProduct(): LwcProduct {
  return {
    id: "",
    state: 0,
    sellerLeadToken: "",
    listingId: "",
    assignedAgentId: "",
    agentNote: "",
    cepNote: "",
    leadPassBackNote: "",
    timestamps: [],
    presentationType: 0,
  };
}

export const LwcProduct = {
  fromJSON(object: any): LwcProduct {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      state: isSet(object.state) ? lwcProduct_StateFromJSON(object.state) : 0,
      sellerLeadToken: isSet(object.sellerLeadToken) ? String(object.sellerLeadToken) : "",
      listingId: isSet(object.listingId) ? String(object.listingId) : "",
      assignedAgentId: isSet(object.assignedAgentId) ? String(object.assignedAgentId) : "",
      agentNote: isSet(object.agentNote) ? String(object.agentNote) : "",
      cepNote: isSet(object.cepNote) ? String(object.cepNote) : "",
      leadPassBackNote: isSet(object.leadPassBackNote) ? String(object.leadPassBackNote) : "",
      timestamps: Array.isArray(object?.timestamps)
        ? object.timestamps.map((e: any) => KeyTimestampPair.fromJSON(e))
        : [],
      presentationType: isSet(object.presentationType) ? listingPresentationTypeFromJSON(object.presentationType) : 0,
    };
  },

  toJSON(message: LwcProduct): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.state !== 0) {
      obj.state = lwcProduct_StateToJSON(message.state);
    }
    if (message.sellerLeadToken !== "") {
      obj.sellerLeadToken = message.sellerLeadToken;
    }
    if (message.listingId !== "") {
      obj.listingId = message.listingId;
    }
    if (message.assignedAgentId !== "") {
      obj.assignedAgentId = message.assignedAgentId;
    }
    if (message.agentNote !== "") {
      obj.agentNote = message.agentNote;
    }
    if (message.cepNote !== "") {
      obj.cepNote = message.cepNote;
    }
    if (message.leadPassBackNote !== "") {
      obj.leadPassBackNote = message.leadPassBackNote;
    }
    if (message.timestamps?.length) {
      obj.timestamps = message.timestamps.map((e) => KeyTimestampPair.toJSON(e));
    }
    if (message.presentationType !== 0) {
      obj.presentationType = listingPresentationTypeToJSON(message.presentationType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LwcProduct>, I>>(base?: I): LwcProduct {
    return LwcProduct.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LwcProduct>, I>>(object: I): LwcProduct {
    const message = createBaseLwcProduct();
    message.id = object.id ?? "";
    message.state = object.state ?? 0;
    message.sellerLeadToken = object.sellerLeadToken ?? "";
    message.listingId = object.listingId ?? "";
    message.assignedAgentId = object.assignedAgentId ?? "";
    message.agentNote = object.agentNote ?? "";
    message.cepNote = object.cepNote ?? "";
    message.leadPassBackNote = object.leadPassBackNote ?? "";
    message.timestamps = object.timestamps?.map((e) => KeyTimestampPair.fromPartial(e)) || [];
    message.presentationType = object.presentationType ?? 0;
    return message;
  },
};

function createBaseLwcDetails(): LwcDetails {
  return { lwcProduct: undefined, listing: undefined, agentLead: undefined };
}

export const LwcDetails = {
  fromJSON(object: any): LwcDetails {
    return {
      lwcProduct: isSet(object.lwcProduct) ? LwcProduct.fromJSON(object.lwcProduct) : undefined,
      listing: isSet(object.listing) ? Listing.fromJSON(object.listing) : undefined,
      agentLead: isSet(object.agentLead) ? AgentLead.fromJSON(object.agentLead) : undefined,
    };
  },

  toJSON(message: LwcDetails): unknown {
    const obj: any = {};
    if (message.lwcProduct !== undefined) {
      obj.lwcProduct = LwcProduct.toJSON(message.lwcProduct);
    }
    if (message.listing !== undefined) {
      obj.listing = Listing.toJSON(message.listing);
    }
    if (message.agentLead !== undefined) {
      obj.agentLead = AgentLead.toJSON(message.agentLead);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LwcDetails>, I>>(base?: I): LwcDetails {
    return LwcDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LwcDetails>, I>>(object: I): LwcDetails {
    const message = createBaseLwcDetails();
    message.lwcProduct = (object.lwcProduct !== undefined && object.lwcProduct !== null)
      ? LwcProduct.fromPartial(object.lwcProduct)
      : undefined;
    message.listing = (object.listing !== undefined && object.listing !== null)
      ? Listing.fromPartial(object.listing)
      : undefined;
    message.agentLead = (object.agentLead !== undefined && object.agentLead !== null)
      ? AgentLead.fromPartial(object.agentLead)
      : undefined;
    return message;
  },
};

function createBaseLwcProductFilter(): LwcProductFilter {
  return { states: [] };
}

export const LwcProductFilter = {
  fromJSON(object: any): LwcProductFilter {
    return { states: Array.isArray(object?.states) ? object.states.map((e: any) => lwcProduct_StateFromJSON(e)) : [] };
  },

  toJSON(message: LwcProductFilter): unknown {
    const obj: any = {};
    if (message.states?.length) {
      obj.states = message.states.map((e) => lwcProduct_StateToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LwcProductFilter>, I>>(base?: I): LwcProductFilter {
    return LwcProductFilter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LwcProductFilter>, I>>(object: I): LwcProductFilter {
    const message = createBaseLwcProductFilter();
    message.states = object.states?.map((e) => e) || [];
    return message;
  },
};

function createBaseAssignedAgent(): AssignedAgent {
  return { agentCustomerUuid: "" };
}

export const AssignedAgent = {
  fromJSON(object: any): AssignedAgent {
    return { agentCustomerUuid: isSet(object.agentCustomerUuid) ? String(object.agentCustomerUuid) : "" };
  },

  toJSON(message: AssignedAgent): unknown {
    const obj: any = {};
    if (message.agentCustomerUuid !== "") {
      obj.agentCustomerUuid = message.agentCustomerUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssignedAgent>, I>>(base?: I): AssignedAgent {
    return AssignedAgent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssignedAgent>, I>>(object: I): AssignedAgent {
    const message = createBaseAssignedAgent();
    message.agentCustomerUuid = object.agentCustomerUuid ?? "";
    return message;
  },
};

function createBaseLwcProductView(): LwcProductView {
  return {
    id: "",
    intent: 0,
    sellDirectLeadToken: "",
    listingAgreementSignedAt: undefined,
    assignedAgent: undefined,
    listingActiveAt: undefined,
    listingSoldAt: undefined,
  };
}

export const LwcProductView = {
  fromJSON(object: any): LwcProductView {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      intent: isSet(object.intent) ? lwcProductView_IntentFromJSON(object.intent) : 0,
      sellDirectLeadToken: isSet(object.sellDirectLeadToken) ? String(object.sellDirectLeadToken) : "",
      listingAgreementSignedAt: isSet(object.listingAgreementSignedAt)
        ? fromJsonTimestamp(object.listingAgreementSignedAt)
        : undefined,
      assignedAgent: isSet(object.assignedAgent) ? AssignedAgent.fromJSON(object.assignedAgent) : undefined,
      listingActiveAt: isSet(object.listingActiveAt) ? fromJsonTimestamp(object.listingActiveAt) : undefined,
      listingSoldAt: isSet(object.listingSoldAt) ? fromJsonTimestamp(object.listingSoldAt) : undefined,
    };
  },

  toJSON(message: LwcProductView): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.intent !== 0) {
      obj.intent = lwcProductView_IntentToJSON(message.intent);
    }
    if (message.sellDirectLeadToken !== "") {
      obj.sellDirectLeadToken = message.sellDirectLeadToken;
    }
    if (message.listingAgreementSignedAt !== undefined) {
      obj.listingAgreementSignedAt = message.listingAgreementSignedAt.toISOString();
    }
    if (message.assignedAgent !== undefined) {
      obj.assignedAgent = AssignedAgent.toJSON(message.assignedAgent);
    }
    if (message.listingActiveAt !== undefined) {
      obj.listingActiveAt = message.listingActiveAt.toISOString();
    }
    if (message.listingSoldAt !== undefined) {
      obj.listingSoldAt = message.listingSoldAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LwcProductView>, I>>(base?: I): LwcProductView {
    return LwcProductView.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LwcProductView>, I>>(object: I): LwcProductView {
    const message = createBaseLwcProductView();
    message.id = object.id ?? "";
    message.intent = object.intent ?? 0;
    message.sellDirectLeadToken = object.sellDirectLeadToken ?? "";
    message.listingAgreementSignedAt = object.listingAgreementSignedAt ?? undefined;
    message.assignedAgent = (object.assignedAgent !== undefined && object.assignedAgent !== null)
      ? AssignedAgent.fromPartial(object.assignedAgent)
      : undefined;
    message.listingActiveAt = object.listingActiveAt ?? undefined;
    message.listingSoldAt = object.listingSoldAt ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
