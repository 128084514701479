/* eslint-disable */
import { Duration } from "./measure";

export const protobufPackage = "od_protos.common";

/** Next ID: 3 */
export interface Money {
  /** defaults to USD */
  currencyCode: Money_AlphabeticCurrencyCode;
  /** value in lowest unit, e.g. this would be cents in USD */
  value: number;
}

/**
 * defaults to USD;
 * sourced on 2017-04-27 from https://www.iso.org/iso-4217-currency-codes.html
 */
export enum Money_AlphabeticCurrencyCode {
  USD = 0,
  AED = 1,
  AFN = 2,
  ALL = 3,
  AMD = 4,
  ANG = 5,
  AOA = 6,
  ARS = 7,
  AUD = 8,
  AWG = 9,
  AZN = 10,
  BAM = 11,
  BBD = 12,
  BDT = 13,
  BGN = 14,
  BHD = 15,
  BIF = 16,
  BMD = 17,
  BND = 18,
  BOB = 19,
  BOV = 20,
  BRL = 21,
  BSD = 22,
  BTN = 23,
  BWP = 24,
  BYN = 25,
  BZD = 26,
  CAD = 27,
  CDF = 28,
  CHE = 29,
  CHF = 30,
  CHW = 31,
  CLF = 32,
  CLP = 33,
  CNY = 34,
  COP = 35,
  COU = 36,
  CRC = 37,
  CUC = 38,
  CUP = 39,
  CVE = 40,
  CZK = 41,
  DJF = 42,
  DKK = 43,
  DOP = 44,
  DZD = 45,
  EGP = 46,
  ERN = 47,
  ETB = 48,
  EUR = 49,
  FJD = 50,
  FKP = 51,
  GBP = 52,
  GEL = 53,
  GHS = 54,
  GIP = 55,
  GMD = 56,
  GNF = 57,
  GTQ = 58,
  GYD = 59,
  HKD = 60,
  HNL = 61,
  HRK = 62,
  HTG = 63,
  HUF = 64,
  IDR = 65,
  ILS = 66,
  INR = 67,
  IQD = 68,
  IRR = 69,
  ISK = 70,
  JMD = 71,
  JOD = 72,
  JPY = 73,
  KES = 74,
  KGS = 75,
  KHR = 76,
  KMF = 77,
  KPW = 78,
  KRW = 79,
  KWD = 80,
  KYD = 81,
  KZT = 82,
  LAK = 83,
  LBP = 84,
  LKR = 85,
  LRD = 86,
  LSL = 87,
  LYD = 88,
  MAD = 89,
  MDL = 90,
  MGA = 91,
  MKD = 92,
  MMK = 93,
  MNT = 94,
  MOP = 95,
  MRO = 96,
  MUR = 97,
  MVR = 98,
  MWK = 99,
  MXN = 100,
  MXV = 101,
  MYR = 102,
  MZN = 103,
  NAD = 104,
  NGN = 105,
  NIO = 106,
  NOK = 107,
  NPR = 108,
  NZD = 109,
  OMR = 110,
  PAB = 111,
  PEN = 112,
  PGK = 113,
  PHP = 114,
  PKR = 115,
  PLN = 116,
  PYG = 117,
  QAR = 118,
  RON = 119,
  RSD = 120,
  RUB = 121,
  RWF = 122,
  SAR = 123,
  SBD = 124,
  SCR = 125,
  SDG = 126,
  SEK = 127,
  SGD = 128,
  SHP = 129,
  SLL = 130,
  SOS = 131,
  SRD = 132,
  SSP = 133,
  STD = 134,
  SVC = 135,
  SYP = 136,
  SZL = 137,
  THB = 138,
  TJS = 139,
  TMT = 140,
  TND = 141,
  TOP = 142,
  TRY = 143,
  TTD = 144,
  TWD = 145,
  TZS = 146,
  UAH = 147,
  UGX = 148,
  USN = 149,
  UYI = 150,
  UYU = 151,
  UZS = 152,
  VEF = 153,
  VND = 154,
  VUV = 155,
  WST = 156,
  XAF = 157,
  XAG = 158,
  XAU = 159,
  XBA = 160,
  XBB = 161,
  XBC = 162,
  XBD = 163,
  XCD = 164,
  XDR = 165,
  XOF = 166,
  XPD = 167,
  XPF = 168,
  XPT = 169,
  XSU = 170,
  XTS = 171,
  XUA = 172,
  XXX = 173,
  YER = 174,
  ZAR = 175,
  ZMW = 176,
  ZWL = 177,
  UNRECOGNIZED = -1,
}

export function money_AlphabeticCurrencyCodeFromJSON(object: any): Money_AlphabeticCurrencyCode {
  switch (object) {
    case 0:
    case "USD":
      return Money_AlphabeticCurrencyCode.USD;
    case 1:
    case "AED":
      return Money_AlphabeticCurrencyCode.AED;
    case 2:
    case "AFN":
      return Money_AlphabeticCurrencyCode.AFN;
    case 3:
    case "ALL":
      return Money_AlphabeticCurrencyCode.ALL;
    case 4:
    case "AMD":
      return Money_AlphabeticCurrencyCode.AMD;
    case 5:
    case "ANG":
      return Money_AlphabeticCurrencyCode.ANG;
    case 6:
    case "AOA":
      return Money_AlphabeticCurrencyCode.AOA;
    case 7:
    case "ARS":
      return Money_AlphabeticCurrencyCode.ARS;
    case 8:
    case "AUD":
      return Money_AlphabeticCurrencyCode.AUD;
    case 9:
    case "AWG":
      return Money_AlphabeticCurrencyCode.AWG;
    case 10:
    case "AZN":
      return Money_AlphabeticCurrencyCode.AZN;
    case 11:
    case "BAM":
      return Money_AlphabeticCurrencyCode.BAM;
    case 12:
    case "BBD":
      return Money_AlphabeticCurrencyCode.BBD;
    case 13:
    case "BDT":
      return Money_AlphabeticCurrencyCode.BDT;
    case 14:
    case "BGN":
      return Money_AlphabeticCurrencyCode.BGN;
    case 15:
    case "BHD":
      return Money_AlphabeticCurrencyCode.BHD;
    case 16:
    case "BIF":
      return Money_AlphabeticCurrencyCode.BIF;
    case 17:
    case "BMD":
      return Money_AlphabeticCurrencyCode.BMD;
    case 18:
    case "BND":
      return Money_AlphabeticCurrencyCode.BND;
    case 19:
    case "BOB":
      return Money_AlphabeticCurrencyCode.BOB;
    case 20:
    case "BOV":
      return Money_AlphabeticCurrencyCode.BOV;
    case 21:
    case "BRL":
      return Money_AlphabeticCurrencyCode.BRL;
    case 22:
    case "BSD":
      return Money_AlphabeticCurrencyCode.BSD;
    case 23:
    case "BTN":
      return Money_AlphabeticCurrencyCode.BTN;
    case 24:
    case "BWP":
      return Money_AlphabeticCurrencyCode.BWP;
    case 25:
    case "BYN":
      return Money_AlphabeticCurrencyCode.BYN;
    case 26:
    case "BZD":
      return Money_AlphabeticCurrencyCode.BZD;
    case 27:
    case "CAD":
      return Money_AlphabeticCurrencyCode.CAD;
    case 28:
    case "CDF":
      return Money_AlphabeticCurrencyCode.CDF;
    case 29:
    case "CHE":
      return Money_AlphabeticCurrencyCode.CHE;
    case 30:
    case "CHF":
      return Money_AlphabeticCurrencyCode.CHF;
    case 31:
    case "CHW":
      return Money_AlphabeticCurrencyCode.CHW;
    case 32:
    case "CLF":
      return Money_AlphabeticCurrencyCode.CLF;
    case 33:
    case "CLP":
      return Money_AlphabeticCurrencyCode.CLP;
    case 34:
    case "CNY":
      return Money_AlphabeticCurrencyCode.CNY;
    case 35:
    case "COP":
      return Money_AlphabeticCurrencyCode.COP;
    case 36:
    case "COU":
      return Money_AlphabeticCurrencyCode.COU;
    case 37:
    case "CRC":
      return Money_AlphabeticCurrencyCode.CRC;
    case 38:
    case "CUC":
      return Money_AlphabeticCurrencyCode.CUC;
    case 39:
    case "CUP":
      return Money_AlphabeticCurrencyCode.CUP;
    case 40:
    case "CVE":
      return Money_AlphabeticCurrencyCode.CVE;
    case 41:
    case "CZK":
      return Money_AlphabeticCurrencyCode.CZK;
    case 42:
    case "DJF":
      return Money_AlphabeticCurrencyCode.DJF;
    case 43:
    case "DKK":
      return Money_AlphabeticCurrencyCode.DKK;
    case 44:
    case "DOP":
      return Money_AlphabeticCurrencyCode.DOP;
    case 45:
    case "DZD":
      return Money_AlphabeticCurrencyCode.DZD;
    case 46:
    case "EGP":
      return Money_AlphabeticCurrencyCode.EGP;
    case 47:
    case "ERN":
      return Money_AlphabeticCurrencyCode.ERN;
    case 48:
    case "ETB":
      return Money_AlphabeticCurrencyCode.ETB;
    case 49:
    case "EUR":
      return Money_AlphabeticCurrencyCode.EUR;
    case 50:
    case "FJD":
      return Money_AlphabeticCurrencyCode.FJD;
    case 51:
    case "FKP":
      return Money_AlphabeticCurrencyCode.FKP;
    case 52:
    case "GBP":
      return Money_AlphabeticCurrencyCode.GBP;
    case 53:
    case "GEL":
      return Money_AlphabeticCurrencyCode.GEL;
    case 54:
    case "GHS":
      return Money_AlphabeticCurrencyCode.GHS;
    case 55:
    case "GIP":
      return Money_AlphabeticCurrencyCode.GIP;
    case 56:
    case "GMD":
      return Money_AlphabeticCurrencyCode.GMD;
    case 57:
    case "GNF":
      return Money_AlphabeticCurrencyCode.GNF;
    case 58:
    case "GTQ":
      return Money_AlphabeticCurrencyCode.GTQ;
    case 59:
    case "GYD":
      return Money_AlphabeticCurrencyCode.GYD;
    case 60:
    case "HKD":
      return Money_AlphabeticCurrencyCode.HKD;
    case 61:
    case "HNL":
      return Money_AlphabeticCurrencyCode.HNL;
    case 62:
    case "HRK":
      return Money_AlphabeticCurrencyCode.HRK;
    case 63:
    case "HTG":
      return Money_AlphabeticCurrencyCode.HTG;
    case 64:
    case "HUF":
      return Money_AlphabeticCurrencyCode.HUF;
    case 65:
    case "IDR":
      return Money_AlphabeticCurrencyCode.IDR;
    case 66:
    case "ILS":
      return Money_AlphabeticCurrencyCode.ILS;
    case 67:
    case "INR":
      return Money_AlphabeticCurrencyCode.INR;
    case 68:
    case "IQD":
      return Money_AlphabeticCurrencyCode.IQD;
    case 69:
    case "IRR":
      return Money_AlphabeticCurrencyCode.IRR;
    case 70:
    case "ISK":
      return Money_AlphabeticCurrencyCode.ISK;
    case 71:
    case "JMD":
      return Money_AlphabeticCurrencyCode.JMD;
    case 72:
    case "JOD":
      return Money_AlphabeticCurrencyCode.JOD;
    case 73:
    case "JPY":
      return Money_AlphabeticCurrencyCode.JPY;
    case 74:
    case "KES":
      return Money_AlphabeticCurrencyCode.KES;
    case 75:
    case "KGS":
      return Money_AlphabeticCurrencyCode.KGS;
    case 76:
    case "KHR":
      return Money_AlphabeticCurrencyCode.KHR;
    case 77:
    case "KMF":
      return Money_AlphabeticCurrencyCode.KMF;
    case 78:
    case "KPW":
      return Money_AlphabeticCurrencyCode.KPW;
    case 79:
    case "KRW":
      return Money_AlphabeticCurrencyCode.KRW;
    case 80:
    case "KWD":
      return Money_AlphabeticCurrencyCode.KWD;
    case 81:
    case "KYD":
      return Money_AlphabeticCurrencyCode.KYD;
    case 82:
    case "KZT":
      return Money_AlphabeticCurrencyCode.KZT;
    case 83:
    case "LAK":
      return Money_AlphabeticCurrencyCode.LAK;
    case 84:
    case "LBP":
      return Money_AlphabeticCurrencyCode.LBP;
    case 85:
    case "LKR":
      return Money_AlphabeticCurrencyCode.LKR;
    case 86:
    case "LRD":
      return Money_AlphabeticCurrencyCode.LRD;
    case 87:
    case "LSL":
      return Money_AlphabeticCurrencyCode.LSL;
    case 88:
    case "LYD":
      return Money_AlphabeticCurrencyCode.LYD;
    case 89:
    case "MAD":
      return Money_AlphabeticCurrencyCode.MAD;
    case 90:
    case "MDL":
      return Money_AlphabeticCurrencyCode.MDL;
    case 91:
    case "MGA":
      return Money_AlphabeticCurrencyCode.MGA;
    case 92:
    case "MKD":
      return Money_AlphabeticCurrencyCode.MKD;
    case 93:
    case "MMK":
      return Money_AlphabeticCurrencyCode.MMK;
    case 94:
    case "MNT":
      return Money_AlphabeticCurrencyCode.MNT;
    case 95:
    case "MOP":
      return Money_AlphabeticCurrencyCode.MOP;
    case 96:
    case "MRO":
      return Money_AlphabeticCurrencyCode.MRO;
    case 97:
    case "MUR":
      return Money_AlphabeticCurrencyCode.MUR;
    case 98:
    case "MVR":
      return Money_AlphabeticCurrencyCode.MVR;
    case 99:
    case "MWK":
      return Money_AlphabeticCurrencyCode.MWK;
    case 100:
    case "MXN":
      return Money_AlphabeticCurrencyCode.MXN;
    case 101:
    case "MXV":
      return Money_AlphabeticCurrencyCode.MXV;
    case 102:
    case "MYR":
      return Money_AlphabeticCurrencyCode.MYR;
    case 103:
    case "MZN":
      return Money_AlphabeticCurrencyCode.MZN;
    case 104:
    case "NAD":
      return Money_AlphabeticCurrencyCode.NAD;
    case 105:
    case "NGN":
      return Money_AlphabeticCurrencyCode.NGN;
    case 106:
    case "NIO":
      return Money_AlphabeticCurrencyCode.NIO;
    case 107:
    case "NOK":
      return Money_AlphabeticCurrencyCode.NOK;
    case 108:
    case "NPR":
      return Money_AlphabeticCurrencyCode.NPR;
    case 109:
    case "NZD":
      return Money_AlphabeticCurrencyCode.NZD;
    case 110:
    case "OMR":
      return Money_AlphabeticCurrencyCode.OMR;
    case 111:
    case "PAB":
      return Money_AlphabeticCurrencyCode.PAB;
    case 112:
    case "PEN":
      return Money_AlphabeticCurrencyCode.PEN;
    case 113:
    case "PGK":
      return Money_AlphabeticCurrencyCode.PGK;
    case 114:
    case "PHP":
      return Money_AlphabeticCurrencyCode.PHP;
    case 115:
    case "PKR":
      return Money_AlphabeticCurrencyCode.PKR;
    case 116:
    case "PLN":
      return Money_AlphabeticCurrencyCode.PLN;
    case 117:
    case "PYG":
      return Money_AlphabeticCurrencyCode.PYG;
    case 118:
    case "QAR":
      return Money_AlphabeticCurrencyCode.QAR;
    case 119:
    case "RON":
      return Money_AlphabeticCurrencyCode.RON;
    case 120:
    case "RSD":
      return Money_AlphabeticCurrencyCode.RSD;
    case 121:
    case "RUB":
      return Money_AlphabeticCurrencyCode.RUB;
    case 122:
    case "RWF":
      return Money_AlphabeticCurrencyCode.RWF;
    case 123:
    case "SAR":
      return Money_AlphabeticCurrencyCode.SAR;
    case 124:
    case "SBD":
      return Money_AlphabeticCurrencyCode.SBD;
    case 125:
    case "SCR":
      return Money_AlphabeticCurrencyCode.SCR;
    case 126:
    case "SDG":
      return Money_AlphabeticCurrencyCode.SDG;
    case 127:
    case "SEK":
      return Money_AlphabeticCurrencyCode.SEK;
    case 128:
    case "SGD":
      return Money_AlphabeticCurrencyCode.SGD;
    case 129:
    case "SHP":
      return Money_AlphabeticCurrencyCode.SHP;
    case 130:
    case "SLL":
      return Money_AlphabeticCurrencyCode.SLL;
    case 131:
    case "SOS":
      return Money_AlphabeticCurrencyCode.SOS;
    case 132:
    case "SRD":
      return Money_AlphabeticCurrencyCode.SRD;
    case 133:
    case "SSP":
      return Money_AlphabeticCurrencyCode.SSP;
    case 134:
    case "STD":
      return Money_AlphabeticCurrencyCode.STD;
    case 135:
    case "SVC":
      return Money_AlphabeticCurrencyCode.SVC;
    case 136:
    case "SYP":
      return Money_AlphabeticCurrencyCode.SYP;
    case 137:
    case "SZL":
      return Money_AlphabeticCurrencyCode.SZL;
    case 138:
    case "THB":
      return Money_AlphabeticCurrencyCode.THB;
    case 139:
    case "TJS":
      return Money_AlphabeticCurrencyCode.TJS;
    case 140:
    case "TMT":
      return Money_AlphabeticCurrencyCode.TMT;
    case 141:
    case "TND":
      return Money_AlphabeticCurrencyCode.TND;
    case 142:
    case "TOP":
      return Money_AlphabeticCurrencyCode.TOP;
    case 143:
    case "TRY":
      return Money_AlphabeticCurrencyCode.TRY;
    case 144:
    case "TTD":
      return Money_AlphabeticCurrencyCode.TTD;
    case 145:
    case "TWD":
      return Money_AlphabeticCurrencyCode.TWD;
    case 146:
    case "TZS":
      return Money_AlphabeticCurrencyCode.TZS;
    case 147:
    case "UAH":
      return Money_AlphabeticCurrencyCode.UAH;
    case 148:
    case "UGX":
      return Money_AlphabeticCurrencyCode.UGX;
    case 149:
    case "USN":
      return Money_AlphabeticCurrencyCode.USN;
    case 150:
    case "UYI":
      return Money_AlphabeticCurrencyCode.UYI;
    case 151:
    case "UYU":
      return Money_AlphabeticCurrencyCode.UYU;
    case 152:
    case "UZS":
      return Money_AlphabeticCurrencyCode.UZS;
    case 153:
    case "VEF":
      return Money_AlphabeticCurrencyCode.VEF;
    case 154:
    case "VND":
      return Money_AlphabeticCurrencyCode.VND;
    case 155:
    case "VUV":
      return Money_AlphabeticCurrencyCode.VUV;
    case 156:
    case "WST":
      return Money_AlphabeticCurrencyCode.WST;
    case 157:
    case "XAF":
      return Money_AlphabeticCurrencyCode.XAF;
    case 158:
    case "XAG":
      return Money_AlphabeticCurrencyCode.XAG;
    case 159:
    case "XAU":
      return Money_AlphabeticCurrencyCode.XAU;
    case 160:
    case "XBA":
      return Money_AlphabeticCurrencyCode.XBA;
    case 161:
    case "XBB":
      return Money_AlphabeticCurrencyCode.XBB;
    case 162:
    case "XBC":
      return Money_AlphabeticCurrencyCode.XBC;
    case 163:
    case "XBD":
      return Money_AlphabeticCurrencyCode.XBD;
    case 164:
    case "XCD":
      return Money_AlphabeticCurrencyCode.XCD;
    case 165:
    case "XDR":
      return Money_AlphabeticCurrencyCode.XDR;
    case 166:
    case "XOF":
      return Money_AlphabeticCurrencyCode.XOF;
    case 167:
    case "XPD":
      return Money_AlphabeticCurrencyCode.XPD;
    case 168:
    case "XPF":
      return Money_AlphabeticCurrencyCode.XPF;
    case 169:
    case "XPT":
      return Money_AlphabeticCurrencyCode.XPT;
    case 170:
    case "XSU":
      return Money_AlphabeticCurrencyCode.XSU;
    case 171:
    case "XTS":
      return Money_AlphabeticCurrencyCode.XTS;
    case 172:
    case "XUA":
      return Money_AlphabeticCurrencyCode.XUA;
    case 173:
    case "XXX":
      return Money_AlphabeticCurrencyCode.XXX;
    case 174:
    case "YER":
      return Money_AlphabeticCurrencyCode.YER;
    case 175:
    case "ZAR":
      return Money_AlphabeticCurrencyCode.ZAR;
    case 176:
    case "ZMW":
      return Money_AlphabeticCurrencyCode.ZMW;
    case 177:
    case "ZWL":
      return Money_AlphabeticCurrencyCode.ZWL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Money_AlphabeticCurrencyCode.UNRECOGNIZED;
  }
}

export function money_AlphabeticCurrencyCodeToJSON(object: Money_AlphabeticCurrencyCode): string {
  switch (object) {
    case Money_AlphabeticCurrencyCode.USD:
      return "USD";
    case Money_AlphabeticCurrencyCode.AED:
      return "AED";
    case Money_AlphabeticCurrencyCode.AFN:
      return "AFN";
    case Money_AlphabeticCurrencyCode.ALL:
      return "ALL";
    case Money_AlphabeticCurrencyCode.AMD:
      return "AMD";
    case Money_AlphabeticCurrencyCode.ANG:
      return "ANG";
    case Money_AlphabeticCurrencyCode.AOA:
      return "AOA";
    case Money_AlphabeticCurrencyCode.ARS:
      return "ARS";
    case Money_AlphabeticCurrencyCode.AUD:
      return "AUD";
    case Money_AlphabeticCurrencyCode.AWG:
      return "AWG";
    case Money_AlphabeticCurrencyCode.AZN:
      return "AZN";
    case Money_AlphabeticCurrencyCode.BAM:
      return "BAM";
    case Money_AlphabeticCurrencyCode.BBD:
      return "BBD";
    case Money_AlphabeticCurrencyCode.BDT:
      return "BDT";
    case Money_AlphabeticCurrencyCode.BGN:
      return "BGN";
    case Money_AlphabeticCurrencyCode.BHD:
      return "BHD";
    case Money_AlphabeticCurrencyCode.BIF:
      return "BIF";
    case Money_AlphabeticCurrencyCode.BMD:
      return "BMD";
    case Money_AlphabeticCurrencyCode.BND:
      return "BND";
    case Money_AlphabeticCurrencyCode.BOB:
      return "BOB";
    case Money_AlphabeticCurrencyCode.BOV:
      return "BOV";
    case Money_AlphabeticCurrencyCode.BRL:
      return "BRL";
    case Money_AlphabeticCurrencyCode.BSD:
      return "BSD";
    case Money_AlphabeticCurrencyCode.BTN:
      return "BTN";
    case Money_AlphabeticCurrencyCode.BWP:
      return "BWP";
    case Money_AlphabeticCurrencyCode.BYN:
      return "BYN";
    case Money_AlphabeticCurrencyCode.BZD:
      return "BZD";
    case Money_AlphabeticCurrencyCode.CAD:
      return "CAD";
    case Money_AlphabeticCurrencyCode.CDF:
      return "CDF";
    case Money_AlphabeticCurrencyCode.CHE:
      return "CHE";
    case Money_AlphabeticCurrencyCode.CHF:
      return "CHF";
    case Money_AlphabeticCurrencyCode.CHW:
      return "CHW";
    case Money_AlphabeticCurrencyCode.CLF:
      return "CLF";
    case Money_AlphabeticCurrencyCode.CLP:
      return "CLP";
    case Money_AlphabeticCurrencyCode.CNY:
      return "CNY";
    case Money_AlphabeticCurrencyCode.COP:
      return "COP";
    case Money_AlphabeticCurrencyCode.COU:
      return "COU";
    case Money_AlphabeticCurrencyCode.CRC:
      return "CRC";
    case Money_AlphabeticCurrencyCode.CUC:
      return "CUC";
    case Money_AlphabeticCurrencyCode.CUP:
      return "CUP";
    case Money_AlphabeticCurrencyCode.CVE:
      return "CVE";
    case Money_AlphabeticCurrencyCode.CZK:
      return "CZK";
    case Money_AlphabeticCurrencyCode.DJF:
      return "DJF";
    case Money_AlphabeticCurrencyCode.DKK:
      return "DKK";
    case Money_AlphabeticCurrencyCode.DOP:
      return "DOP";
    case Money_AlphabeticCurrencyCode.DZD:
      return "DZD";
    case Money_AlphabeticCurrencyCode.EGP:
      return "EGP";
    case Money_AlphabeticCurrencyCode.ERN:
      return "ERN";
    case Money_AlphabeticCurrencyCode.ETB:
      return "ETB";
    case Money_AlphabeticCurrencyCode.EUR:
      return "EUR";
    case Money_AlphabeticCurrencyCode.FJD:
      return "FJD";
    case Money_AlphabeticCurrencyCode.FKP:
      return "FKP";
    case Money_AlphabeticCurrencyCode.GBP:
      return "GBP";
    case Money_AlphabeticCurrencyCode.GEL:
      return "GEL";
    case Money_AlphabeticCurrencyCode.GHS:
      return "GHS";
    case Money_AlphabeticCurrencyCode.GIP:
      return "GIP";
    case Money_AlphabeticCurrencyCode.GMD:
      return "GMD";
    case Money_AlphabeticCurrencyCode.GNF:
      return "GNF";
    case Money_AlphabeticCurrencyCode.GTQ:
      return "GTQ";
    case Money_AlphabeticCurrencyCode.GYD:
      return "GYD";
    case Money_AlphabeticCurrencyCode.HKD:
      return "HKD";
    case Money_AlphabeticCurrencyCode.HNL:
      return "HNL";
    case Money_AlphabeticCurrencyCode.HRK:
      return "HRK";
    case Money_AlphabeticCurrencyCode.HTG:
      return "HTG";
    case Money_AlphabeticCurrencyCode.HUF:
      return "HUF";
    case Money_AlphabeticCurrencyCode.IDR:
      return "IDR";
    case Money_AlphabeticCurrencyCode.ILS:
      return "ILS";
    case Money_AlphabeticCurrencyCode.INR:
      return "INR";
    case Money_AlphabeticCurrencyCode.IQD:
      return "IQD";
    case Money_AlphabeticCurrencyCode.IRR:
      return "IRR";
    case Money_AlphabeticCurrencyCode.ISK:
      return "ISK";
    case Money_AlphabeticCurrencyCode.JMD:
      return "JMD";
    case Money_AlphabeticCurrencyCode.JOD:
      return "JOD";
    case Money_AlphabeticCurrencyCode.JPY:
      return "JPY";
    case Money_AlphabeticCurrencyCode.KES:
      return "KES";
    case Money_AlphabeticCurrencyCode.KGS:
      return "KGS";
    case Money_AlphabeticCurrencyCode.KHR:
      return "KHR";
    case Money_AlphabeticCurrencyCode.KMF:
      return "KMF";
    case Money_AlphabeticCurrencyCode.KPW:
      return "KPW";
    case Money_AlphabeticCurrencyCode.KRW:
      return "KRW";
    case Money_AlphabeticCurrencyCode.KWD:
      return "KWD";
    case Money_AlphabeticCurrencyCode.KYD:
      return "KYD";
    case Money_AlphabeticCurrencyCode.KZT:
      return "KZT";
    case Money_AlphabeticCurrencyCode.LAK:
      return "LAK";
    case Money_AlphabeticCurrencyCode.LBP:
      return "LBP";
    case Money_AlphabeticCurrencyCode.LKR:
      return "LKR";
    case Money_AlphabeticCurrencyCode.LRD:
      return "LRD";
    case Money_AlphabeticCurrencyCode.LSL:
      return "LSL";
    case Money_AlphabeticCurrencyCode.LYD:
      return "LYD";
    case Money_AlphabeticCurrencyCode.MAD:
      return "MAD";
    case Money_AlphabeticCurrencyCode.MDL:
      return "MDL";
    case Money_AlphabeticCurrencyCode.MGA:
      return "MGA";
    case Money_AlphabeticCurrencyCode.MKD:
      return "MKD";
    case Money_AlphabeticCurrencyCode.MMK:
      return "MMK";
    case Money_AlphabeticCurrencyCode.MNT:
      return "MNT";
    case Money_AlphabeticCurrencyCode.MOP:
      return "MOP";
    case Money_AlphabeticCurrencyCode.MRO:
      return "MRO";
    case Money_AlphabeticCurrencyCode.MUR:
      return "MUR";
    case Money_AlphabeticCurrencyCode.MVR:
      return "MVR";
    case Money_AlphabeticCurrencyCode.MWK:
      return "MWK";
    case Money_AlphabeticCurrencyCode.MXN:
      return "MXN";
    case Money_AlphabeticCurrencyCode.MXV:
      return "MXV";
    case Money_AlphabeticCurrencyCode.MYR:
      return "MYR";
    case Money_AlphabeticCurrencyCode.MZN:
      return "MZN";
    case Money_AlphabeticCurrencyCode.NAD:
      return "NAD";
    case Money_AlphabeticCurrencyCode.NGN:
      return "NGN";
    case Money_AlphabeticCurrencyCode.NIO:
      return "NIO";
    case Money_AlphabeticCurrencyCode.NOK:
      return "NOK";
    case Money_AlphabeticCurrencyCode.NPR:
      return "NPR";
    case Money_AlphabeticCurrencyCode.NZD:
      return "NZD";
    case Money_AlphabeticCurrencyCode.OMR:
      return "OMR";
    case Money_AlphabeticCurrencyCode.PAB:
      return "PAB";
    case Money_AlphabeticCurrencyCode.PEN:
      return "PEN";
    case Money_AlphabeticCurrencyCode.PGK:
      return "PGK";
    case Money_AlphabeticCurrencyCode.PHP:
      return "PHP";
    case Money_AlphabeticCurrencyCode.PKR:
      return "PKR";
    case Money_AlphabeticCurrencyCode.PLN:
      return "PLN";
    case Money_AlphabeticCurrencyCode.PYG:
      return "PYG";
    case Money_AlphabeticCurrencyCode.QAR:
      return "QAR";
    case Money_AlphabeticCurrencyCode.RON:
      return "RON";
    case Money_AlphabeticCurrencyCode.RSD:
      return "RSD";
    case Money_AlphabeticCurrencyCode.RUB:
      return "RUB";
    case Money_AlphabeticCurrencyCode.RWF:
      return "RWF";
    case Money_AlphabeticCurrencyCode.SAR:
      return "SAR";
    case Money_AlphabeticCurrencyCode.SBD:
      return "SBD";
    case Money_AlphabeticCurrencyCode.SCR:
      return "SCR";
    case Money_AlphabeticCurrencyCode.SDG:
      return "SDG";
    case Money_AlphabeticCurrencyCode.SEK:
      return "SEK";
    case Money_AlphabeticCurrencyCode.SGD:
      return "SGD";
    case Money_AlphabeticCurrencyCode.SHP:
      return "SHP";
    case Money_AlphabeticCurrencyCode.SLL:
      return "SLL";
    case Money_AlphabeticCurrencyCode.SOS:
      return "SOS";
    case Money_AlphabeticCurrencyCode.SRD:
      return "SRD";
    case Money_AlphabeticCurrencyCode.SSP:
      return "SSP";
    case Money_AlphabeticCurrencyCode.STD:
      return "STD";
    case Money_AlphabeticCurrencyCode.SVC:
      return "SVC";
    case Money_AlphabeticCurrencyCode.SYP:
      return "SYP";
    case Money_AlphabeticCurrencyCode.SZL:
      return "SZL";
    case Money_AlphabeticCurrencyCode.THB:
      return "THB";
    case Money_AlphabeticCurrencyCode.TJS:
      return "TJS";
    case Money_AlphabeticCurrencyCode.TMT:
      return "TMT";
    case Money_AlphabeticCurrencyCode.TND:
      return "TND";
    case Money_AlphabeticCurrencyCode.TOP:
      return "TOP";
    case Money_AlphabeticCurrencyCode.TRY:
      return "TRY";
    case Money_AlphabeticCurrencyCode.TTD:
      return "TTD";
    case Money_AlphabeticCurrencyCode.TWD:
      return "TWD";
    case Money_AlphabeticCurrencyCode.TZS:
      return "TZS";
    case Money_AlphabeticCurrencyCode.UAH:
      return "UAH";
    case Money_AlphabeticCurrencyCode.UGX:
      return "UGX";
    case Money_AlphabeticCurrencyCode.USN:
      return "USN";
    case Money_AlphabeticCurrencyCode.UYI:
      return "UYI";
    case Money_AlphabeticCurrencyCode.UYU:
      return "UYU";
    case Money_AlphabeticCurrencyCode.UZS:
      return "UZS";
    case Money_AlphabeticCurrencyCode.VEF:
      return "VEF";
    case Money_AlphabeticCurrencyCode.VND:
      return "VND";
    case Money_AlphabeticCurrencyCode.VUV:
      return "VUV";
    case Money_AlphabeticCurrencyCode.WST:
      return "WST";
    case Money_AlphabeticCurrencyCode.XAF:
      return "XAF";
    case Money_AlphabeticCurrencyCode.XAG:
      return "XAG";
    case Money_AlphabeticCurrencyCode.XAU:
      return "XAU";
    case Money_AlphabeticCurrencyCode.XBA:
      return "XBA";
    case Money_AlphabeticCurrencyCode.XBB:
      return "XBB";
    case Money_AlphabeticCurrencyCode.XBC:
      return "XBC";
    case Money_AlphabeticCurrencyCode.XBD:
      return "XBD";
    case Money_AlphabeticCurrencyCode.XCD:
      return "XCD";
    case Money_AlphabeticCurrencyCode.XDR:
      return "XDR";
    case Money_AlphabeticCurrencyCode.XOF:
      return "XOF";
    case Money_AlphabeticCurrencyCode.XPD:
      return "XPD";
    case Money_AlphabeticCurrencyCode.XPF:
      return "XPF";
    case Money_AlphabeticCurrencyCode.XPT:
      return "XPT";
    case Money_AlphabeticCurrencyCode.XSU:
      return "XSU";
    case Money_AlphabeticCurrencyCode.XTS:
      return "XTS";
    case Money_AlphabeticCurrencyCode.XUA:
      return "XUA";
    case Money_AlphabeticCurrencyCode.XXX:
      return "XXX";
    case Money_AlphabeticCurrencyCode.YER:
      return "YER";
    case Money_AlphabeticCurrencyCode.ZAR:
      return "ZAR";
    case Money_AlphabeticCurrencyCode.ZMW:
      return "ZMW";
    case Money_AlphabeticCurrencyCode.ZWL:
      return "ZWL";
    case Money_AlphabeticCurrencyCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** A one-time or recurring fee. */
export interface Fee {
  /** The amount of the fee, if known. */
  amount?:
    | Money
    | undefined;
  /**
   * The period/duration (reciprocal of frequency) in between recurring
   * payments of the fee. Absent if a one-time fee.
   */
  recurringPeriod?:
    | Duration
    | undefined;
  /** Description for this fee. */
  description: string;
}

function createBaseMoney(): Money {
  return { currencyCode: 0, value: 0 };
}

export const Money = {
  fromJSON(object: any): Money {
    return {
      currencyCode: isSet(object.currencyCode) ? money_AlphabeticCurrencyCodeFromJSON(object.currencyCode) : 0,
      value: isSet(object.value) ? Number(object.value) : 0,
    };
  },

  toJSON(message: Money): unknown {
    const obj: any = {};
    if (message.currencyCode !== 0) {
      obj.currencyCode = money_AlphabeticCurrencyCodeToJSON(message.currencyCode);
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Money>, I>>(base?: I): Money {
    return Money.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Money>, I>>(object: I): Money {
    const message = createBaseMoney();
    message.currencyCode = object.currencyCode ?? 0;
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseFee(): Fee {
  return { amount: undefined, recurringPeriod: undefined, description: "" };
}

export const Fee = {
  fromJSON(object: any): Fee {
    return {
      amount: isSet(object.amount) ? Money.fromJSON(object.amount) : undefined,
      recurringPeriod: isSet(object.recurringPeriod) ? Duration.fromJSON(object.recurringPeriod) : undefined,
      description: isSet(object.description) ? String(object.description) : "",
    };
  },

  toJSON(message: Fee): unknown {
    const obj: any = {};
    if (message.amount !== undefined) {
      obj.amount = Money.toJSON(message.amount);
    }
    if (message.recurringPeriod !== undefined) {
      obj.recurringPeriod = Duration.toJSON(message.recurringPeriod);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Fee>, I>>(base?: I): Fee {
    return Fee.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Fee>, I>>(object: I): Fee {
    const message = createBaseFee();
    message.amount = (object.amount !== undefined && object.amount !== null)
      ? Money.fromPartial(object.amount)
      : undefined;
    message.recurringPeriod = (object.recurringPeriod !== undefined && object.recurringPeriod !== null)
      ? Duration.fromPartial(object.recurringPeriod)
      : undefined;
    message.description = object.description ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
