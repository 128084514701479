/* eslint-disable */
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "od_protos.agents.data";

export interface AgentLead {
  id: string;
  state: AgentLead_State;
  assignedAgentId: string;
  assignedAgentCustomerId: string;
  agentAssignedAt?: Date | undefined;
  assignedBrokerageId: string;
  assignedBrokerageName: string;
  proposalExpiration?: Date | undefined;
}

export enum AgentLead_State {
  UNKNOWN = 0,
  /** @deprecated */
  SELECT_AGENT_CANDIDATE = 1,
  /** @deprecated */
  CANDIDATE_AGENTS_PROPOSED = 2,
  /** @deprecated */
  ASSIGNED = 3,
  COMPLETED = 4,
  /** @deprecated */
  ASSIGNMENT_NOT_POSSIBLE = 5,
  CREATED = 6,
  SELECTING_BROKERAGE = 7,
  BROKERAGE_PROPOSED = 8,
  BROKERAGE_ACCEPTED = 9,
  SELECTING_AGENT = 10,
  AGENTS_PROPOSED = 11,
  IN_PROGRESS = 12,
  DELETED = 13,
  UNSERVICEABLE = 14,
  MANUAL_ASSIGNMENT_REQUIRED = 15,
  UNRECOGNIZED = -1,
}

export function agentLead_StateFromJSON(object: any): AgentLead_State {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return AgentLead_State.UNKNOWN;
    case 1:
    case "SELECT_AGENT_CANDIDATE":
      return AgentLead_State.SELECT_AGENT_CANDIDATE;
    case 2:
    case "CANDIDATE_AGENTS_PROPOSED":
      return AgentLead_State.CANDIDATE_AGENTS_PROPOSED;
    case 3:
    case "ASSIGNED":
      return AgentLead_State.ASSIGNED;
    case 4:
    case "COMPLETED":
      return AgentLead_State.COMPLETED;
    case 5:
    case "ASSIGNMENT_NOT_POSSIBLE":
      return AgentLead_State.ASSIGNMENT_NOT_POSSIBLE;
    case 6:
    case "CREATED":
      return AgentLead_State.CREATED;
    case 7:
    case "SELECTING_BROKERAGE":
      return AgentLead_State.SELECTING_BROKERAGE;
    case 8:
    case "BROKERAGE_PROPOSED":
      return AgentLead_State.BROKERAGE_PROPOSED;
    case 9:
    case "BROKERAGE_ACCEPTED":
      return AgentLead_State.BROKERAGE_ACCEPTED;
    case 10:
    case "SELECTING_AGENT":
      return AgentLead_State.SELECTING_AGENT;
    case 11:
    case "AGENTS_PROPOSED":
      return AgentLead_State.AGENTS_PROPOSED;
    case 12:
    case "IN_PROGRESS":
      return AgentLead_State.IN_PROGRESS;
    case 13:
    case "DELETED":
      return AgentLead_State.DELETED;
    case 14:
    case "UNSERVICEABLE":
      return AgentLead_State.UNSERVICEABLE;
    case 15:
    case "MANUAL_ASSIGNMENT_REQUIRED":
      return AgentLead_State.MANUAL_ASSIGNMENT_REQUIRED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AgentLead_State.UNRECOGNIZED;
  }
}

export function agentLead_StateToJSON(object: AgentLead_State): string {
  switch (object) {
    case AgentLead_State.UNKNOWN:
      return "UNKNOWN";
    case AgentLead_State.SELECT_AGENT_CANDIDATE:
      return "SELECT_AGENT_CANDIDATE";
    case AgentLead_State.CANDIDATE_AGENTS_PROPOSED:
      return "CANDIDATE_AGENTS_PROPOSED";
    case AgentLead_State.ASSIGNED:
      return "ASSIGNED";
    case AgentLead_State.COMPLETED:
      return "COMPLETED";
    case AgentLead_State.ASSIGNMENT_NOT_POSSIBLE:
      return "ASSIGNMENT_NOT_POSSIBLE";
    case AgentLead_State.CREATED:
      return "CREATED";
    case AgentLead_State.SELECTING_BROKERAGE:
      return "SELECTING_BROKERAGE";
    case AgentLead_State.BROKERAGE_PROPOSED:
      return "BROKERAGE_PROPOSED";
    case AgentLead_State.BROKERAGE_ACCEPTED:
      return "BROKERAGE_ACCEPTED";
    case AgentLead_State.SELECTING_AGENT:
      return "SELECTING_AGENT";
    case AgentLead_State.AGENTS_PROPOSED:
      return "AGENTS_PROPOSED";
    case AgentLead_State.IN_PROGRESS:
      return "IN_PROGRESS";
    case AgentLead_State.DELETED:
      return "DELETED";
    case AgentLead_State.UNSERVICEABLE:
      return "UNSERVICEABLE";
    case AgentLead_State.MANUAL_ASSIGNMENT_REQUIRED:
      return "MANUAL_ASSIGNMENT_REQUIRED";
    case AgentLead_State.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseAgentLead(): AgentLead {
  return {
    id: "",
    state: 0,
    assignedAgentId: "",
    assignedAgentCustomerId: "",
    agentAssignedAt: undefined,
    assignedBrokerageId: "",
    assignedBrokerageName: "",
    proposalExpiration: undefined,
  };
}

export const AgentLead = {
  fromJSON(object: any): AgentLead {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      state: isSet(object.state) ? agentLead_StateFromJSON(object.state) : 0,
      assignedAgentId: isSet(object.assignedAgentId) ? String(object.assignedAgentId) : "",
      assignedAgentCustomerId: isSet(object.assignedAgentCustomerId) ? String(object.assignedAgentCustomerId) : "",
      agentAssignedAt: isSet(object.agentAssignedAt) ? fromJsonTimestamp(object.agentAssignedAt) : undefined,
      assignedBrokerageId: isSet(object.assignedBrokerageId) ? String(object.assignedBrokerageId) : "",
      assignedBrokerageName: isSet(object.assignedBrokerageName) ? String(object.assignedBrokerageName) : "",
      proposalExpiration: isSet(object.proposalExpiration) ? fromJsonTimestamp(object.proposalExpiration) : undefined,
    };
  },

  toJSON(message: AgentLead): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.state !== 0) {
      obj.state = agentLead_StateToJSON(message.state);
    }
    if (message.assignedAgentId !== "") {
      obj.assignedAgentId = message.assignedAgentId;
    }
    if (message.assignedAgentCustomerId !== "") {
      obj.assignedAgentCustomerId = message.assignedAgentCustomerId;
    }
    if (message.agentAssignedAt !== undefined) {
      obj.agentAssignedAt = message.agentAssignedAt.toISOString();
    }
    if (message.assignedBrokerageId !== "") {
      obj.assignedBrokerageId = message.assignedBrokerageId;
    }
    if (message.assignedBrokerageName !== "") {
      obj.assignedBrokerageName = message.assignedBrokerageName;
    }
    if (message.proposalExpiration !== undefined) {
      obj.proposalExpiration = message.proposalExpiration.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AgentLead>, I>>(base?: I): AgentLead {
    return AgentLead.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AgentLead>, I>>(object: I): AgentLead {
    const message = createBaseAgentLead();
    message.id = object.id ?? "";
    message.state = object.state ?? 0;
    message.assignedAgentId = object.assignedAgentId ?? "";
    message.assignedAgentCustomerId = object.assignedAgentCustomerId ?? "";
    message.agentAssignedAt = object.agentAssignedAt ?? undefined;
    message.assignedBrokerageId = object.assignedBrokerageId ?? "";
    message.assignedBrokerageName = object.assignedBrokerageName ?? "";
    message.proposalExpiration = object.proposalExpiration ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
