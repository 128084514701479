/* eslint-disable */

export const protobufPackage = "od_protos.journey.data.categories";

export interface MarketplacePropertyCategory {
  event?:
    | { $case: "detailPageView"; detailPageView: MarketplacePropertyCategory_DetailPageViewEvent }
    | { $case: "similarHomesClick"; similarHomesClick: MarketplacePropertyCategory_SimilarHomesClickEvent }
    | { $case: "tourBookingStart"; tourBookingStart: MarketplacePropertyCategory_TourBookingStartEvent }
    | { $case: "reaction"; reaction: MarketplacePropertyCategory_ReactionEvent }
    | { $case: "offerStart"; offerStart: MarketplacePropertyCategory_OfferStartEvent }
    | { $case: "share"; share: MarketplacePropertyCategory_ShareEvent }
    | undefined;
}

export enum MarketplacePropertyCategory_ReactionEventReaction {
  REACTION_UNSPECIFIED = 0,
  REACTION_ADD_LIKE = 1,
  REACTION_REMOVE_LIKE = 2,
  REACTION_ADD_DISLIKE = 3,
  REACTION_REMOVE_DISLIKE = 4,
  UNRECOGNIZED = -1,
}

export function marketplacePropertyCategory_ReactionEventReactionFromJSON(
  object: any,
): MarketplacePropertyCategory_ReactionEventReaction {
  switch (object) {
    case 0:
    case "REACTION_UNSPECIFIED":
      return MarketplacePropertyCategory_ReactionEventReaction.REACTION_UNSPECIFIED;
    case 1:
    case "REACTION_ADD_LIKE":
      return MarketplacePropertyCategory_ReactionEventReaction.REACTION_ADD_LIKE;
    case 2:
    case "REACTION_REMOVE_LIKE":
      return MarketplacePropertyCategory_ReactionEventReaction.REACTION_REMOVE_LIKE;
    case 3:
    case "REACTION_ADD_DISLIKE":
      return MarketplacePropertyCategory_ReactionEventReaction.REACTION_ADD_DISLIKE;
    case 4:
    case "REACTION_REMOVE_DISLIKE":
      return MarketplacePropertyCategory_ReactionEventReaction.REACTION_REMOVE_DISLIKE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MarketplacePropertyCategory_ReactionEventReaction.UNRECOGNIZED;
  }
}

export function marketplacePropertyCategory_ReactionEventReactionToJSON(
  object: MarketplacePropertyCategory_ReactionEventReaction,
): string {
  switch (object) {
    case MarketplacePropertyCategory_ReactionEventReaction.REACTION_UNSPECIFIED:
      return "REACTION_UNSPECIFIED";
    case MarketplacePropertyCategory_ReactionEventReaction.REACTION_ADD_LIKE:
      return "REACTION_ADD_LIKE";
    case MarketplacePropertyCategory_ReactionEventReaction.REACTION_REMOVE_LIKE:
      return "REACTION_REMOVE_LIKE";
    case MarketplacePropertyCategory_ReactionEventReaction.REACTION_ADD_DISLIKE:
      return "REACTION_ADD_DISLIKE";
    case MarketplacePropertyCategory_ReactionEventReaction.REACTION_REMOVE_DISLIKE:
      return "REACTION_REMOVE_DISLIKE";
    case MarketplacePropertyCategory_ReactionEventReaction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MarketplacePropertyCategory_DetailPageViewEvent {
  addressUuid: string;
}

export interface MarketplacePropertyCategory_SimilarHomesClickEvent {
  addressUuid: string;
  clickedAddressUuid: string;
}

export interface MarketplacePropertyCategory_TourBookingStartEvent {
  addressUuid: string;
  placement: string;
}

export interface MarketplacePropertyCategory_ReactionEvent {
  addressUuid: string;
  source: string;
  reaction: MarketplacePropertyCategory_ReactionEventReaction;
}

export interface MarketplacePropertyCategory_OfferStartEvent {
  addressUuid: string;
  source: string;
}

export interface MarketplacePropertyCategory_ShareEvent {
  addressUuid: string;
  source: string;
}

function createBaseMarketplacePropertyCategory(): MarketplacePropertyCategory {
  return { event: undefined };
}

export const MarketplacePropertyCategory = {
  fromJSON(object: any): MarketplacePropertyCategory {
    return {
      event: isSet(object.detailPageView)
        ? {
          $case: "detailPageView",
          detailPageView: MarketplacePropertyCategory_DetailPageViewEvent.fromJSON(object.detailPageView),
        }
        : isSet(object.similarHomesClick)
        ? {
          $case: "similarHomesClick",
          similarHomesClick: MarketplacePropertyCategory_SimilarHomesClickEvent.fromJSON(object.similarHomesClick),
        }
        : isSet(object.tourBookingStart)
        ? {
          $case: "tourBookingStart",
          tourBookingStart: MarketplacePropertyCategory_TourBookingStartEvent.fromJSON(object.tourBookingStart),
        }
        : isSet(object.reaction)
        ? { $case: "reaction", reaction: MarketplacePropertyCategory_ReactionEvent.fromJSON(object.reaction) }
        : isSet(object.offerStart)
        ? { $case: "offerStart", offerStart: MarketplacePropertyCategory_OfferStartEvent.fromJSON(object.offerStart) }
        : isSet(object.share)
        ? { $case: "share", share: MarketplacePropertyCategory_ShareEvent.fromJSON(object.share) }
        : undefined,
    };
  },

  toJSON(message: MarketplacePropertyCategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "detailPageView") {
      obj.detailPageView = MarketplacePropertyCategory_DetailPageViewEvent.toJSON(message.event.detailPageView);
    }
    if (message.event?.$case === "similarHomesClick") {
      obj.similarHomesClick = MarketplacePropertyCategory_SimilarHomesClickEvent.toJSON(
        message.event.similarHomesClick,
      );
    }
    if (message.event?.$case === "tourBookingStart") {
      obj.tourBookingStart = MarketplacePropertyCategory_TourBookingStartEvent.toJSON(message.event.tourBookingStart);
    }
    if (message.event?.$case === "reaction") {
      obj.reaction = MarketplacePropertyCategory_ReactionEvent.toJSON(message.event.reaction);
    }
    if (message.event?.$case === "offerStart") {
      obj.offerStart = MarketplacePropertyCategory_OfferStartEvent.toJSON(message.event.offerStart);
    }
    if (message.event?.$case === "share") {
      obj.share = MarketplacePropertyCategory_ShareEvent.toJSON(message.event.share);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketplacePropertyCategory>, I>>(base?: I): MarketplacePropertyCategory {
    return MarketplacePropertyCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarketplacePropertyCategory>, I>>(object: I): MarketplacePropertyCategory {
    const message = createBaseMarketplacePropertyCategory();
    if (
      object.event?.$case === "detailPageView" &&
      object.event?.detailPageView !== undefined &&
      object.event?.detailPageView !== null
    ) {
      message.event = {
        $case: "detailPageView",
        detailPageView: MarketplacePropertyCategory_DetailPageViewEvent.fromPartial(object.event.detailPageView),
      };
    }
    if (
      object.event?.$case === "similarHomesClick" &&
      object.event?.similarHomesClick !== undefined &&
      object.event?.similarHomesClick !== null
    ) {
      message.event = {
        $case: "similarHomesClick",
        similarHomesClick: MarketplacePropertyCategory_SimilarHomesClickEvent.fromPartial(
          object.event.similarHomesClick,
        ),
      };
    }
    if (
      object.event?.$case === "tourBookingStart" &&
      object.event?.tourBookingStart !== undefined &&
      object.event?.tourBookingStart !== null
    ) {
      message.event = {
        $case: "tourBookingStart",
        tourBookingStart: MarketplacePropertyCategory_TourBookingStartEvent.fromPartial(object.event.tourBookingStart),
      };
    }
    if (object.event?.$case === "reaction" && object.event?.reaction !== undefined && object.event?.reaction !== null) {
      message.event = {
        $case: "reaction",
        reaction: MarketplacePropertyCategory_ReactionEvent.fromPartial(object.event.reaction),
      };
    }
    if (
      object.event?.$case === "offerStart" &&
      object.event?.offerStart !== undefined &&
      object.event?.offerStart !== null
    ) {
      message.event = {
        $case: "offerStart",
        offerStart: MarketplacePropertyCategory_OfferStartEvent.fromPartial(object.event.offerStart),
      };
    }
    if (object.event?.$case === "share" && object.event?.share !== undefined && object.event?.share !== null) {
      message.event = { $case: "share", share: MarketplacePropertyCategory_ShareEvent.fromPartial(object.event.share) };
    }
    return message;
  },
};

function createBaseMarketplacePropertyCategory_DetailPageViewEvent(): MarketplacePropertyCategory_DetailPageViewEvent {
  return { addressUuid: "" };
}

export const MarketplacePropertyCategory_DetailPageViewEvent = {
  fromJSON(object: any): MarketplacePropertyCategory_DetailPageViewEvent {
    return { addressUuid: isSet(object.addressUuid) ? String(object.addressUuid) : "" };
  },

  toJSON(message: MarketplacePropertyCategory_DetailPageViewEvent): unknown {
    const obj: any = {};
    if (message.addressUuid !== "") {
      obj.addressUuid = message.addressUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketplacePropertyCategory_DetailPageViewEvent>, I>>(
    base?: I,
  ): MarketplacePropertyCategory_DetailPageViewEvent {
    return MarketplacePropertyCategory_DetailPageViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarketplacePropertyCategory_DetailPageViewEvent>, I>>(
    object: I,
  ): MarketplacePropertyCategory_DetailPageViewEvent {
    const message = createBaseMarketplacePropertyCategory_DetailPageViewEvent();
    message.addressUuid = object.addressUuid ?? "";
    return message;
  },
};

function createBaseMarketplacePropertyCategory_SimilarHomesClickEvent(): MarketplacePropertyCategory_SimilarHomesClickEvent {
  return { addressUuid: "", clickedAddressUuid: "" };
}

export const MarketplacePropertyCategory_SimilarHomesClickEvent = {
  fromJSON(object: any): MarketplacePropertyCategory_SimilarHomesClickEvent {
    return {
      addressUuid: isSet(object.addressUuid) ? String(object.addressUuid) : "",
      clickedAddressUuid: isSet(object.clickedAddressUuid) ? String(object.clickedAddressUuid) : "",
    };
  },

  toJSON(message: MarketplacePropertyCategory_SimilarHomesClickEvent): unknown {
    const obj: any = {};
    if (message.addressUuid !== "") {
      obj.addressUuid = message.addressUuid;
    }
    if (message.clickedAddressUuid !== "") {
      obj.clickedAddressUuid = message.clickedAddressUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketplacePropertyCategory_SimilarHomesClickEvent>, I>>(
    base?: I,
  ): MarketplacePropertyCategory_SimilarHomesClickEvent {
    return MarketplacePropertyCategory_SimilarHomesClickEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarketplacePropertyCategory_SimilarHomesClickEvent>, I>>(
    object: I,
  ): MarketplacePropertyCategory_SimilarHomesClickEvent {
    const message = createBaseMarketplacePropertyCategory_SimilarHomesClickEvent();
    message.addressUuid = object.addressUuid ?? "";
    message.clickedAddressUuid = object.clickedAddressUuid ?? "";
    return message;
  },
};

function createBaseMarketplacePropertyCategory_TourBookingStartEvent(): MarketplacePropertyCategory_TourBookingStartEvent {
  return { addressUuid: "", placement: "" };
}

export const MarketplacePropertyCategory_TourBookingStartEvent = {
  fromJSON(object: any): MarketplacePropertyCategory_TourBookingStartEvent {
    return {
      addressUuid: isSet(object.addressUuid) ? String(object.addressUuid) : "",
      placement: isSet(object.placement) ? String(object.placement) : "",
    };
  },

  toJSON(message: MarketplacePropertyCategory_TourBookingStartEvent): unknown {
    const obj: any = {};
    if (message.addressUuid !== "") {
      obj.addressUuid = message.addressUuid;
    }
    if (message.placement !== "") {
      obj.placement = message.placement;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketplacePropertyCategory_TourBookingStartEvent>, I>>(
    base?: I,
  ): MarketplacePropertyCategory_TourBookingStartEvent {
    return MarketplacePropertyCategory_TourBookingStartEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarketplacePropertyCategory_TourBookingStartEvent>, I>>(
    object: I,
  ): MarketplacePropertyCategory_TourBookingStartEvent {
    const message = createBaseMarketplacePropertyCategory_TourBookingStartEvent();
    message.addressUuid = object.addressUuid ?? "";
    message.placement = object.placement ?? "";
    return message;
  },
};

function createBaseMarketplacePropertyCategory_ReactionEvent(): MarketplacePropertyCategory_ReactionEvent {
  return { addressUuid: "", source: "", reaction: 0 };
}

export const MarketplacePropertyCategory_ReactionEvent = {
  fromJSON(object: any): MarketplacePropertyCategory_ReactionEvent {
    return {
      addressUuid: isSet(object.addressUuid) ? String(object.addressUuid) : "",
      source: isSet(object.source) ? String(object.source) : "",
      reaction: isSet(object.reaction) ? marketplacePropertyCategory_ReactionEventReactionFromJSON(object.reaction) : 0,
    };
  },

  toJSON(message: MarketplacePropertyCategory_ReactionEvent): unknown {
    const obj: any = {};
    if (message.addressUuid !== "") {
      obj.addressUuid = message.addressUuid;
    }
    if (message.source !== "") {
      obj.source = message.source;
    }
    if (message.reaction !== 0) {
      obj.reaction = marketplacePropertyCategory_ReactionEventReactionToJSON(message.reaction);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketplacePropertyCategory_ReactionEvent>, I>>(
    base?: I,
  ): MarketplacePropertyCategory_ReactionEvent {
    return MarketplacePropertyCategory_ReactionEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarketplacePropertyCategory_ReactionEvent>, I>>(
    object: I,
  ): MarketplacePropertyCategory_ReactionEvent {
    const message = createBaseMarketplacePropertyCategory_ReactionEvent();
    message.addressUuid = object.addressUuid ?? "";
    message.source = object.source ?? "";
    message.reaction = object.reaction ?? 0;
    return message;
  },
};

function createBaseMarketplacePropertyCategory_OfferStartEvent(): MarketplacePropertyCategory_OfferStartEvent {
  return { addressUuid: "", source: "" };
}

export const MarketplacePropertyCategory_OfferStartEvent = {
  fromJSON(object: any): MarketplacePropertyCategory_OfferStartEvent {
    return {
      addressUuid: isSet(object.addressUuid) ? String(object.addressUuid) : "",
      source: isSet(object.source) ? String(object.source) : "",
    };
  },

  toJSON(message: MarketplacePropertyCategory_OfferStartEvent): unknown {
    const obj: any = {};
    if (message.addressUuid !== "") {
      obj.addressUuid = message.addressUuid;
    }
    if (message.source !== "") {
      obj.source = message.source;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketplacePropertyCategory_OfferStartEvent>, I>>(
    base?: I,
  ): MarketplacePropertyCategory_OfferStartEvent {
    return MarketplacePropertyCategory_OfferStartEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarketplacePropertyCategory_OfferStartEvent>, I>>(
    object: I,
  ): MarketplacePropertyCategory_OfferStartEvent {
    const message = createBaseMarketplacePropertyCategory_OfferStartEvent();
    message.addressUuid = object.addressUuid ?? "";
    message.source = object.source ?? "";
    return message;
  },
};

function createBaseMarketplacePropertyCategory_ShareEvent(): MarketplacePropertyCategory_ShareEvent {
  return { addressUuid: "", source: "" };
}

export const MarketplacePropertyCategory_ShareEvent = {
  fromJSON(object: any): MarketplacePropertyCategory_ShareEvent {
    return {
      addressUuid: isSet(object.addressUuid) ? String(object.addressUuid) : "",
      source: isSet(object.source) ? String(object.source) : "",
    };
  },

  toJSON(message: MarketplacePropertyCategory_ShareEvent): unknown {
    const obj: any = {};
    if (message.addressUuid !== "") {
      obj.addressUuid = message.addressUuid;
    }
    if (message.source !== "") {
      obj.source = message.source;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketplacePropertyCategory_ShareEvent>, I>>(
    base?: I,
  ): MarketplacePropertyCategory_ShareEvent {
    return MarketplacePropertyCategory_ShareEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarketplacePropertyCategory_ShareEvent>, I>>(
    object: I,
  ): MarketplacePropertyCategory_ShareEvent {
    const message = createBaseMarketplacePropertyCategory_ShareEvent();
    message.addressUuid = object.addressUuid ?? "";
    message.source = object.source ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
