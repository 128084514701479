/* storybook-check-ignore */
import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useObservability } from 'helpers/observability';

/**
 * Listens for soft navigations (i.e. navigations that don't result in a full page refresh)
 *
 * - Reports the navigation to analytics
 */
export default function NavListener() {
  const { trackJourneyEvent } = useObservability();
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (url !== location.pathname + location.search) {
        trackJourneyEvent('platform', 'pageViewEvent', {});
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  return null;
}
