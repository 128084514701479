/* eslint-disable */

export const protobufPackage = "od_protos.journey.data.categories";

export interface SellExclusivesCategory {
  event?:
    | { $case: "rangeViewEvent"; rangeViewEvent: SellExclusivesCategory_RangeViewEvent }
    | {
      $case: "sellerPreferencesSelectionEvent";
      sellerPreferencesSelectionEvent: SellExclusivesCategory_SellerPreferenceSelectionEvent;
    }
    | { $case: "sentToMarketplaceEvent"; sentToMarketplaceEvent: SellExclusivesCategory_SentToMarketplaceEvent }
    | {
      $case: "withdrawnFromMarketplaceEvent";
      withdrawnFromMarketplaceEvent: SellExclusivesCategory_WithdrawnFromMarketplaceEvent;
    }
    | {
      $case: "receivedListingOfferEvent";
      receivedListingOfferEvent: SellExclusivesCategory_ReceivedListingOfferEvent;
    }
    | {
      $case: "acceptedListingOfferEvent";
      acceptedListingOfferEvent: SellExclusivesCategory_AcceptedListingOfferEvent;
    }
    | {
      $case: "photographyAppointmentEvent";
      photographyAppointmentEvent: SellExclusivesCategory_PhotographyAppointmentEvent;
    }
    | {
      $case: "programAgreementSignedEvent";
      programAgreementSignedEvent: SellExclusivesCategory_ProgramAgreementSignedEvent;
    }
    | { $case: "tourRequestAcceptedEvent"; tourRequestAcceptedEvent: SellExclusivesCategory_TourRequestAcceptedEvent }
    | { $case: "finalOfferCallEvent"; finalOfferCallEvent: SellExclusivesCategory_FinalOfferCallEvent }
    | { $case: "attributeToggleEvent"; attributeToggleEvent: SellExclusivesCategory_AttributeToggleEvent }
    | undefined;
}

export enum SellExclusivesCategory_ReferringApp {
  CAKE = 0,
  RECEPTION = 1,
  UNRECOGNIZED = -1,
}

export function sellExclusivesCategory_ReferringAppFromJSON(object: any): SellExclusivesCategory_ReferringApp {
  switch (object) {
    case 0:
    case "CAKE":
      return SellExclusivesCategory_ReferringApp.CAKE;
    case 1:
    case "RECEPTION":
      return SellExclusivesCategory_ReferringApp.RECEPTION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellExclusivesCategory_ReferringApp.UNRECOGNIZED;
  }
}

export function sellExclusivesCategory_ReferringAppToJSON(object: SellExclusivesCategory_ReferringApp): string {
  switch (object) {
    case SellExclusivesCategory_ReferringApp.CAKE:
      return "CAKE";
    case SellExclusivesCategory_ReferringApp.RECEPTION:
      return "RECEPTION";
    case SellExclusivesCategory_ReferringApp.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SellExclusivesCategory_SellerPreference {
  SELL_DIRECT = 0,
  EXCLUSIVES = 1,
  TRADITIONAL = 2,
  UNRECOGNIZED = -1,
}

export function sellExclusivesCategory_SellerPreferenceFromJSON(object: any): SellExclusivesCategory_SellerPreference {
  switch (object) {
    case 0:
    case "SELL_DIRECT":
      return SellExclusivesCategory_SellerPreference.SELL_DIRECT;
    case 1:
    case "EXCLUSIVES":
      return SellExclusivesCategory_SellerPreference.EXCLUSIVES;
    case 2:
    case "TRADITIONAL":
      return SellExclusivesCategory_SellerPreference.TRADITIONAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellExclusivesCategory_SellerPreference.UNRECOGNIZED;
  }
}

export function sellExclusivesCategory_SellerPreferenceToJSON(object: SellExclusivesCategory_SellerPreference): string {
  switch (object) {
    case SellExclusivesCategory_SellerPreference.SELL_DIRECT:
      return "SELL_DIRECT";
    case SellExclusivesCategory_SellerPreference.EXCLUSIVES:
      return "EXCLUSIVES";
    case SellExclusivesCategory_SellerPreference.TRADITIONAL:
      return "TRADITIONAL";
    case SellExclusivesCategory_SellerPreference.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SellExclusivesCategory_AttributeChangeSource {
  WEB = 0,
  UNRECOGNIZED = -1,
}

export function sellExclusivesCategory_AttributeChangeSourceFromJSON(
  object: any,
): SellExclusivesCategory_AttributeChangeSource {
  switch (object) {
    case 0:
    case "WEB":
      return SellExclusivesCategory_AttributeChangeSource.WEB;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellExclusivesCategory_AttributeChangeSource.UNRECOGNIZED;
  }
}

export function sellExclusivesCategory_AttributeChangeSourceToJSON(
  object: SellExclusivesCategory_AttributeChangeSource,
): string {
  switch (object) {
    case SellExclusivesCategory_AttributeChangeSource.WEB:
      return "WEB";
    case SellExclusivesCategory_AttributeChangeSource.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Track range views to detect when ballpark fails.
 * No longer used/needed bc we now use offer.listingEstimateFloorCents and offer.listingEstimateCeilingCents
 *
 * @deprecated
 */
export interface SellExclusivesCategory_RangeViewEvent {
  sellerLeadId: string;
  rangeLowerBound: number;
  rangeUpperBound: number;
}

/** Track seller preference selections in reception and cake */
export interface SellExclusivesCategory_SellerPreferenceSelectionEvent {
  sellerInputId: string;
  /** Which app the event is triggered from, i.e. "reception" or "cake" */
  referrer: SellExclusivesCategory_ReferringApp;
  sellerPreferences: SellExclusivesCategory_SellerPreference[];
}

/** Track when an Exclusive Offer is sent to marketplace */
export interface SellExclusivesCategory_SentToMarketplaceEvent {
  leadId: string;
}

/** Track when an Exclusive Offer is withdrawn from marketplace */
export interface SellExclusivesCategory_WithdrawnFromMarketplaceEvent {
  leadId: string;
}

/** Track when a seller with Exclusives receives an offer from a buyer */
export interface SellExclusivesCategory_ReceivedListingOfferEvent {
  listingOfferId: string;
  leadId: string;
}

/** Track when a seller with Exclusives accepts an offer from a buyer */
export interface SellExclusivesCategory_AcceptedListingOfferEvent {
  listingOfferId: string;
  leadId: string;
}

/** Track when a seller has their photography appointment for their home */
export interface SellExclusivesCategory_PhotographyAppointmentEvent {
  leadId: string;
}

/**
 * Track when a seller signs their program agreement
 * We only enter the date (and not the time), so we will assume the time to be EOD of the date
 */
export interface SellExclusivesCategory_ProgramAgreementSignedEvent {
  leadId: string;
}

/** Track when a seller accepts a tour request */
export interface SellExclusivesCategory_TourRequestAcceptedEvent {
  leadId: string;
  tourRequestId: string;
}

/** Track when a seller has their final offer call */
export interface SellExclusivesCategory_FinalOfferCallEvent {
  leadId: string;
}

/** Track when a lead either gains or loses a predefined attribute */
export interface SellExclusivesCategory_AttributeToggleEvent {
  leadId: string;
  attributeName: string;
  attributeValue: boolean;
  attributeChangeSource: SellExclusivesCategory_AttributeChangeSource;
}

function createBaseSellExclusivesCategory(): SellExclusivesCategory {
  return { event: undefined };
}

export const SellExclusivesCategory = {
  fromJSON(object: any): SellExclusivesCategory {
    return {
      event: isSet(object.rangeViewEvent)
        ? {
          $case: "rangeViewEvent",
          rangeViewEvent: SellExclusivesCategory_RangeViewEvent.fromJSON(object.rangeViewEvent),
        }
        : isSet(object.sellerPreferencesSelectionEvent)
        ? {
          $case: "sellerPreferencesSelectionEvent",
          sellerPreferencesSelectionEvent: SellExclusivesCategory_SellerPreferenceSelectionEvent.fromJSON(
            object.sellerPreferencesSelectionEvent,
          ),
        }
        : isSet(object.sentToMarketplaceEvent)
        ? {
          $case: "sentToMarketplaceEvent",
          sentToMarketplaceEvent: SellExclusivesCategory_SentToMarketplaceEvent.fromJSON(object.sentToMarketplaceEvent),
        }
        : isSet(object.withdrawnFromMarketplaceEvent)
        ? {
          $case: "withdrawnFromMarketplaceEvent",
          withdrawnFromMarketplaceEvent: SellExclusivesCategory_WithdrawnFromMarketplaceEvent.fromJSON(
            object.withdrawnFromMarketplaceEvent,
          ),
        }
        : isSet(object.receivedListingOfferEvent)
        ? {
          $case: "receivedListingOfferEvent",
          receivedListingOfferEvent: SellExclusivesCategory_ReceivedListingOfferEvent.fromJSON(
            object.receivedListingOfferEvent,
          ),
        }
        : isSet(object.acceptedListingOfferEvent)
        ? {
          $case: "acceptedListingOfferEvent",
          acceptedListingOfferEvent: SellExclusivesCategory_AcceptedListingOfferEvent.fromJSON(
            object.acceptedListingOfferEvent,
          ),
        }
        : isSet(object.photographyAppointmentEvent)
        ? {
          $case: "photographyAppointmentEvent",
          photographyAppointmentEvent: SellExclusivesCategory_PhotographyAppointmentEvent.fromJSON(
            object.photographyAppointmentEvent,
          ),
        }
        : isSet(object.programAgreementSignedEvent)
        ? {
          $case: "programAgreementSignedEvent",
          programAgreementSignedEvent: SellExclusivesCategory_ProgramAgreementSignedEvent.fromJSON(
            object.programAgreementSignedEvent,
          ),
        }
        : isSet(object.tourRequestAcceptedEvent)
        ? {
          $case: "tourRequestAcceptedEvent",
          tourRequestAcceptedEvent: SellExclusivesCategory_TourRequestAcceptedEvent.fromJSON(
            object.tourRequestAcceptedEvent,
          ),
        }
        : isSet(object.finalOfferCallEvent)
        ? {
          $case: "finalOfferCallEvent",
          finalOfferCallEvent: SellExclusivesCategory_FinalOfferCallEvent.fromJSON(object.finalOfferCallEvent),
        }
        : isSet(object.attributeToggleEvent)
        ? {
          $case: "attributeToggleEvent",
          attributeToggleEvent: SellExclusivesCategory_AttributeToggleEvent.fromJSON(object.attributeToggleEvent),
        }
        : undefined,
    };
  },

  toJSON(message: SellExclusivesCategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "rangeViewEvent") {
      obj.rangeViewEvent = SellExclusivesCategory_RangeViewEvent.toJSON(message.event.rangeViewEvent);
    }
    if (message.event?.$case === "sellerPreferencesSelectionEvent") {
      obj.sellerPreferencesSelectionEvent = SellExclusivesCategory_SellerPreferenceSelectionEvent.toJSON(
        message.event.sellerPreferencesSelectionEvent,
      );
    }
    if (message.event?.$case === "sentToMarketplaceEvent") {
      obj.sentToMarketplaceEvent = SellExclusivesCategory_SentToMarketplaceEvent.toJSON(
        message.event.sentToMarketplaceEvent,
      );
    }
    if (message.event?.$case === "withdrawnFromMarketplaceEvent") {
      obj.withdrawnFromMarketplaceEvent = SellExclusivesCategory_WithdrawnFromMarketplaceEvent.toJSON(
        message.event.withdrawnFromMarketplaceEvent,
      );
    }
    if (message.event?.$case === "receivedListingOfferEvent") {
      obj.receivedListingOfferEvent = SellExclusivesCategory_ReceivedListingOfferEvent.toJSON(
        message.event.receivedListingOfferEvent,
      );
    }
    if (message.event?.$case === "acceptedListingOfferEvent") {
      obj.acceptedListingOfferEvent = SellExclusivesCategory_AcceptedListingOfferEvent.toJSON(
        message.event.acceptedListingOfferEvent,
      );
    }
    if (message.event?.$case === "photographyAppointmentEvent") {
      obj.photographyAppointmentEvent = SellExclusivesCategory_PhotographyAppointmentEvent.toJSON(
        message.event.photographyAppointmentEvent,
      );
    }
    if (message.event?.$case === "programAgreementSignedEvent") {
      obj.programAgreementSignedEvent = SellExclusivesCategory_ProgramAgreementSignedEvent.toJSON(
        message.event.programAgreementSignedEvent,
      );
    }
    if (message.event?.$case === "tourRequestAcceptedEvent") {
      obj.tourRequestAcceptedEvent = SellExclusivesCategory_TourRequestAcceptedEvent.toJSON(
        message.event.tourRequestAcceptedEvent,
      );
    }
    if (message.event?.$case === "finalOfferCallEvent") {
      obj.finalOfferCallEvent = SellExclusivesCategory_FinalOfferCallEvent.toJSON(message.event.finalOfferCallEvent);
    }
    if (message.event?.$case === "attributeToggleEvent") {
      obj.attributeToggleEvent = SellExclusivesCategory_AttributeToggleEvent.toJSON(message.event.attributeToggleEvent);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellExclusivesCategory>, I>>(base?: I): SellExclusivesCategory {
    return SellExclusivesCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellExclusivesCategory>, I>>(object: I): SellExclusivesCategory {
    const message = createBaseSellExclusivesCategory();
    if (
      object.event?.$case === "rangeViewEvent" &&
      object.event?.rangeViewEvent !== undefined &&
      object.event?.rangeViewEvent !== null
    ) {
      message.event = {
        $case: "rangeViewEvent",
        rangeViewEvent: SellExclusivesCategory_RangeViewEvent.fromPartial(object.event.rangeViewEvent),
      };
    }
    if (
      object.event?.$case === "sellerPreferencesSelectionEvent" &&
      object.event?.sellerPreferencesSelectionEvent !== undefined &&
      object.event?.sellerPreferencesSelectionEvent !== null
    ) {
      message.event = {
        $case: "sellerPreferencesSelectionEvent",
        sellerPreferencesSelectionEvent: SellExclusivesCategory_SellerPreferenceSelectionEvent.fromPartial(
          object.event.sellerPreferencesSelectionEvent,
        ),
      };
    }
    if (
      object.event?.$case === "sentToMarketplaceEvent" &&
      object.event?.sentToMarketplaceEvent !== undefined &&
      object.event?.sentToMarketplaceEvent !== null
    ) {
      message.event = {
        $case: "sentToMarketplaceEvent",
        sentToMarketplaceEvent: SellExclusivesCategory_SentToMarketplaceEvent.fromPartial(
          object.event.sentToMarketplaceEvent,
        ),
      };
    }
    if (
      object.event?.$case === "withdrawnFromMarketplaceEvent" &&
      object.event?.withdrawnFromMarketplaceEvent !== undefined &&
      object.event?.withdrawnFromMarketplaceEvent !== null
    ) {
      message.event = {
        $case: "withdrawnFromMarketplaceEvent",
        withdrawnFromMarketplaceEvent: SellExclusivesCategory_WithdrawnFromMarketplaceEvent.fromPartial(
          object.event.withdrawnFromMarketplaceEvent,
        ),
      };
    }
    if (
      object.event?.$case === "receivedListingOfferEvent" &&
      object.event?.receivedListingOfferEvent !== undefined &&
      object.event?.receivedListingOfferEvent !== null
    ) {
      message.event = {
        $case: "receivedListingOfferEvent",
        receivedListingOfferEvent: SellExclusivesCategory_ReceivedListingOfferEvent.fromPartial(
          object.event.receivedListingOfferEvent,
        ),
      };
    }
    if (
      object.event?.$case === "acceptedListingOfferEvent" &&
      object.event?.acceptedListingOfferEvent !== undefined &&
      object.event?.acceptedListingOfferEvent !== null
    ) {
      message.event = {
        $case: "acceptedListingOfferEvent",
        acceptedListingOfferEvent: SellExclusivesCategory_AcceptedListingOfferEvent.fromPartial(
          object.event.acceptedListingOfferEvent,
        ),
      };
    }
    if (
      object.event?.$case === "photographyAppointmentEvent" &&
      object.event?.photographyAppointmentEvent !== undefined &&
      object.event?.photographyAppointmentEvent !== null
    ) {
      message.event = {
        $case: "photographyAppointmentEvent",
        photographyAppointmentEvent: SellExclusivesCategory_PhotographyAppointmentEvent.fromPartial(
          object.event.photographyAppointmentEvent,
        ),
      };
    }
    if (
      object.event?.$case === "programAgreementSignedEvent" &&
      object.event?.programAgreementSignedEvent !== undefined &&
      object.event?.programAgreementSignedEvent !== null
    ) {
      message.event = {
        $case: "programAgreementSignedEvent",
        programAgreementSignedEvent: SellExclusivesCategory_ProgramAgreementSignedEvent.fromPartial(
          object.event.programAgreementSignedEvent,
        ),
      };
    }
    if (
      object.event?.$case === "tourRequestAcceptedEvent" &&
      object.event?.tourRequestAcceptedEvent !== undefined &&
      object.event?.tourRequestAcceptedEvent !== null
    ) {
      message.event = {
        $case: "tourRequestAcceptedEvent",
        tourRequestAcceptedEvent: SellExclusivesCategory_TourRequestAcceptedEvent.fromPartial(
          object.event.tourRequestAcceptedEvent,
        ),
      };
    }
    if (
      object.event?.$case === "finalOfferCallEvent" &&
      object.event?.finalOfferCallEvent !== undefined &&
      object.event?.finalOfferCallEvent !== null
    ) {
      message.event = {
        $case: "finalOfferCallEvent",
        finalOfferCallEvent: SellExclusivesCategory_FinalOfferCallEvent.fromPartial(object.event.finalOfferCallEvent),
      };
    }
    if (
      object.event?.$case === "attributeToggleEvent" &&
      object.event?.attributeToggleEvent !== undefined &&
      object.event?.attributeToggleEvent !== null
    ) {
      message.event = {
        $case: "attributeToggleEvent",
        attributeToggleEvent: SellExclusivesCategory_AttributeToggleEvent.fromPartial(
          object.event.attributeToggleEvent,
        ),
      };
    }
    return message;
  },
};

function createBaseSellExclusivesCategory_RangeViewEvent(): SellExclusivesCategory_RangeViewEvent {
  return { sellerLeadId: "", rangeLowerBound: 0, rangeUpperBound: 0 };
}

export const SellExclusivesCategory_RangeViewEvent = {
  fromJSON(object: any): SellExclusivesCategory_RangeViewEvent {
    return {
      sellerLeadId: isSet(object.sellerLeadId) ? String(object.sellerLeadId) : "",
      rangeLowerBound: isSet(object.rangeLowerBound) ? Number(object.rangeLowerBound) : 0,
      rangeUpperBound: isSet(object.rangeUpperBound) ? Number(object.rangeUpperBound) : 0,
    };
  },

  toJSON(message: SellExclusivesCategory_RangeViewEvent): unknown {
    const obj: any = {};
    if (message.sellerLeadId !== "") {
      obj.sellerLeadId = message.sellerLeadId;
    }
    if (message.rangeLowerBound !== 0) {
      obj.rangeLowerBound = message.rangeLowerBound;
    }
    if (message.rangeUpperBound !== 0) {
      obj.rangeUpperBound = message.rangeUpperBound;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellExclusivesCategory_RangeViewEvent>, I>>(
    base?: I,
  ): SellExclusivesCategory_RangeViewEvent {
    return SellExclusivesCategory_RangeViewEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellExclusivesCategory_RangeViewEvent>, I>>(
    object: I,
  ): SellExclusivesCategory_RangeViewEvent {
    const message = createBaseSellExclusivesCategory_RangeViewEvent();
    message.sellerLeadId = object.sellerLeadId ?? "";
    message.rangeLowerBound = object.rangeLowerBound ?? 0;
    message.rangeUpperBound = object.rangeUpperBound ?? 0;
    return message;
  },
};

function createBaseSellExclusivesCategory_SellerPreferenceSelectionEvent(): SellExclusivesCategory_SellerPreferenceSelectionEvent {
  return { sellerInputId: "", referrer: 0, sellerPreferences: [] };
}

export const SellExclusivesCategory_SellerPreferenceSelectionEvent = {
  fromJSON(object: any): SellExclusivesCategory_SellerPreferenceSelectionEvent {
    return {
      sellerInputId: isSet(object.sellerInputId) ? String(object.sellerInputId) : "",
      referrer: isSet(object.referrer) ? sellExclusivesCategory_ReferringAppFromJSON(object.referrer) : 0,
      sellerPreferences: Array.isArray(object?.sellerPreferences)
        ? object.sellerPreferences.map((e: any) => sellExclusivesCategory_SellerPreferenceFromJSON(e))
        : [],
    };
  },

  toJSON(message: SellExclusivesCategory_SellerPreferenceSelectionEvent): unknown {
    const obj: any = {};
    if (message.sellerInputId !== "") {
      obj.sellerInputId = message.sellerInputId;
    }
    if (message.referrer !== 0) {
      obj.referrer = sellExclusivesCategory_ReferringAppToJSON(message.referrer);
    }
    if (message.sellerPreferences?.length) {
      obj.sellerPreferences = message.sellerPreferences.map((e) => sellExclusivesCategory_SellerPreferenceToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellExclusivesCategory_SellerPreferenceSelectionEvent>, I>>(
    base?: I,
  ): SellExclusivesCategory_SellerPreferenceSelectionEvent {
    return SellExclusivesCategory_SellerPreferenceSelectionEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellExclusivesCategory_SellerPreferenceSelectionEvent>, I>>(
    object: I,
  ): SellExclusivesCategory_SellerPreferenceSelectionEvent {
    const message = createBaseSellExclusivesCategory_SellerPreferenceSelectionEvent();
    message.sellerInputId = object.sellerInputId ?? "";
    message.referrer = object.referrer ?? 0;
    message.sellerPreferences = object.sellerPreferences?.map((e) => e) || [];
    return message;
  },
};

function createBaseSellExclusivesCategory_SentToMarketplaceEvent(): SellExclusivesCategory_SentToMarketplaceEvent {
  return { leadId: "" };
}

export const SellExclusivesCategory_SentToMarketplaceEvent = {
  fromJSON(object: any): SellExclusivesCategory_SentToMarketplaceEvent {
    return { leadId: isSet(object.leadId) ? String(object.leadId) : "" };
  },

  toJSON(message: SellExclusivesCategory_SentToMarketplaceEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellExclusivesCategory_SentToMarketplaceEvent>, I>>(
    base?: I,
  ): SellExclusivesCategory_SentToMarketplaceEvent {
    return SellExclusivesCategory_SentToMarketplaceEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellExclusivesCategory_SentToMarketplaceEvent>, I>>(
    object: I,
  ): SellExclusivesCategory_SentToMarketplaceEvent {
    const message = createBaseSellExclusivesCategory_SentToMarketplaceEvent();
    message.leadId = object.leadId ?? "";
    return message;
  },
};

function createBaseSellExclusivesCategory_WithdrawnFromMarketplaceEvent(): SellExclusivesCategory_WithdrawnFromMarketplaceEvent {
  return { leadId: "" };
}

export const SellExclusivesCategory_WithdrawnFromMarketplaceEvent = {
  fromJSON(object: any): SellExclusivesCategory_WithdrawnFromMarketplaceEvent {
    return { leadId: isSet(object.leadId) ? String(object.leadId) : "" };
  },

  toJSON(message: SellExclusivesCategory_WithdrawnFromMarketplaceEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellExclusivesCategory_WithdrawnFromMarketplaceEvent>, I>>(
    base?: I,
  ): SellExclusivesCategory_WithdrawnFromMarketplaceEvent {
    return SellExclusivesCategory_WithdrawnFromMarketplaceEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellExclusivesCategory_WithdrawnFromMarketplaceEvent>, I>>(
    object: I,
  ): SellExclusivesCategory_WithdrawnFromMarketplaceEvent {
    const message = createBaseSellExclusivesCategory_WithdrawnFromMarketplaceEvent();
    message.leadId = object.leadId ?? "";
    return message;
  },
};

function createBaseSellExclusivesCategory_ReceivedListingOfferEvent(): SellExclusivesCategory_ReceivedListingOfferEvent {
  return { listingOfferId: "", leadId: "" };
}

export const SellExclusivesCategory_ReceivedListingOfferEvent = {
  fromJSON(object: any): SellExclusivesCategory_ReceivedListingOfferEvent {
    return {
      listingOfferId: isSet(object.listingOfferId) ? String(object.listingOfferId) : "",
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
    };
  },

  toJSON(message: SellExclusivesCategory_ReceivedListingOfferEvent): unknown {
    const obj: any = {};
    if (message.listingOfferId !== "") {
      obj.listingOfferId = message.listingOfferId;
    }
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellExclusivesCategory_ReceivedListingOfferEvent>, I>>(
    base?: I,
  ): SellExclusivesCategory_ReceivedListingOfferEvent {
    return SellExclusivesCategory_ReceivedListingOfferEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellExclusivesCategory_ReceivedListingOfferEvent>, I>>(
    object: I,
  ): SellExclusivesCategory_ReceivedListingOfferEvent {
    const message = createBaseSellExclusivesCategory_ReceivedListingOfferEvent();
    message.listingOfferId = object.listingOfferId ?? "";
    message.leadId = object.leadId ?? "";
    return message;
  },
};

function createBaseSellExclusivesCategory_AcceptedListingOfferEvent(): SellExclusivesCategory_AcceptedListingOfferEvent {
  return { listingOfferId: "", leadId: "" };
}

export const SellExclusivesCategory_AcceptedListingOfferEvent = {
  fromJSON(object: any): SellExclusivesCategory_AcceptedListingOfferEvent {
    return {
      listingOfferId: isSet(object.listingOfferId) ? String(object.listingOfferId) : "",
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
    };
  },

  toJSON(message: SellExclusivesCategory_AcceptedListingOfferEvent): unknown {
    const obj: any = {};
    if (message.listingOfferId !== "") {
      obj.listingOfferId = message.listingOfferId;
    }
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellExclusivesCategory_AcceptedListingOfferEvent>, I>>(
    base?: I,
  ): SellExclusivesCategory_AcceptedListingOfferEvent {
    return SellExclusivesCategory_AcceptedListingOfferEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellExclusivesCategory_AcceptedListingOfferEvent>, I>>(
    object: I,
  ): SellExclusivesCategory_AcceptedListingOfferEvent {
    const message = createBaseSellExclusivesCategory_AcceptedListingOfferEvent();
    message.listingOfferId = object.listingOfferId ?? "";
    message.leadId = object.leadId ?? "";
    return message;
  },
};

function createBaseSellExclusivesCategory_PhotographyAppointmentEvent(): SellExclusivesCategory_PhotographyAppointmentEvent {
  return { leadId: "" };
}

export const SellExclusivesCategory_PhotographyAppointmentEvent = {
  fromJSON(object: any): SellExclusivesCategory_PhotographyAppointmentEvent {
    return { leadId: isSet(object.leadId) ? String(object.leadId) : "" };
  },

  toJSON(message: SellExclusivesCategory_PhotographyAppointmentEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellExclusivesCategory_PhotographyAppointmentEvent>, I>>(
    base?: I,
  ): SellExclusivesCategory_PhotographyAppointmentEvent {
    return SellExclusivesCategory_PhotographyAppointmentEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellExclusivesCategory_PhotographyAppointmentEvent>, I>>(
    object: I,
  ): SellExclusivesCategory_PhotographyAppointmentEvent {
    const message = createBaseSellExclusivesCategory_PhotographyAppointmentEvent();
    message.leadId = object.leadId ?? "";
    return message;
  },
};

function createBaseSellExclusivesCategory_ProgramAgreementSignedEvent(): SellExclusivesCategory_ProgramAgreementSignedEvent {
  return { leadId: "" };
}

export const SellExclusivesCategory_ProgramAgreementSignedEvent = {
  fromJSON(object: any): SellExclusivesCategory_ProgramAgreementSignedEvent {
    return { leadId: isSet(object.leadId) ? String(object.leadId) : "" };
  },

  toJSON(message: SellExclusivesCategory_ProgramAgreementSignedEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellExclusivesCategory_ProgramAgreementSignedEvent>, I>>(
    base?: I,
  ): SellExclusivesCategory_ProgramAgreementSignedEvent {
    return SellExclusivesCategory_ProgramAgreementSignedEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellExclusivesCategory_ProgramAgreementSignedEvent>, I>>(
    object: I,
  ): SellExclusivesCategory_ProgramAgreementSignedEvent {
    const message = createBaseSellExclusivesCategory_ProgramAgreementSignedEvent();
    message.leadId = object.leadId ?? "";
    return message;
  },
};

function createBaseSellExclusivesCategory_TourRequestAcceptedEvent(): SellExclusivesCategory_TourRequestAcceptedEvent {
  return { leadId: "", tourRequestId: "" };
}

export const SellExclusivesCategory_TourRequestAcceptedEvent = {
  fromJSON(object: any): SellExclusivesCategory_TourRequestAcceptedEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      tourRequestId: isSet(object.tourRequestId) ? String(object.tourRequestId) : "",
    };
  },

  toJSON(message: SellExclusivesCategory_TourRequestAcceptedEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.tourRequestId !== "") {
      obj.tourRequestId = message.tourRequestId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellExclusivesCategory_TourRequestAcceptedEvent>, I>>(
    base?: I,
  ): SellExclusivesCategory_TourRequestAcceptedEvent {
    return SellExclusivesCategory_TourRequestAcceptedEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellExclusivesCategory_TourRequestAcceptedEvent>, I>>(
    object: I,
  ): SellExclusivesCategory_TourRequestAcceptedEvent {
    const message = createBaseSellExclusivesCategory_TourRequestAcceptedEvent();
    message.leadId = object.leadId ?? "";
    message.tourRequestId = object.tourRequestId ?? "";
    return message;
  },
};

function createBaseSellExclusivesCategory_FinalOfferCallEvent(): SellExclusivesCategory_FinalOfferCallEvent {
  return { leadId: "" };
}

export const SellExclusivesCategory_FinalOfferCallEvent = {
  fromJSON(object: any): SellExclusivesCategory_FinalOfferCallEvent {
    return { leadId: isSet(object.leadId) ? String(object.leadId) : "" };
  },

  toJSON(message: SellExclusivesCategory_FinalOfferCallEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellExclusivesCategory_FinalOfferCallEvent>, I>>(
    base?: I,
  ): SellExclusivesCategory_FinalOfferCallEvent {
    return SellExclusivesCategory_FinalOfferCallEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellExclusivesCategory_FinalOfferCallEvent>, I>>(
    object: I,
  ): SellExclusivesCategory_FinalOfferCallEvent {
    const message = createBaseSellExclusivesCategory_FinalOfferCallEvent();
    message.leadId = object.leadId ?? "";
    return message;
  },
};

function createBaseSellExclusivesCategory_AttributeToggleEvent(): SellExclusivesCategory_AttributeToggleEvent {
  return { leadId: "", attributeName: "", attributeValue: false, attributeChangeSource: 0 };
}

export const SellExclusivesCategory_AttributeToggleEvent = {
  fromJSON(object: any): SellExclusivesCategory_AttributeToggleEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      attributeName: isSet(object.attributeName) ? String(object.attributeName) : "",
      attributeValue: isSet(object.attributeValue) ? Boolean(object.attributeValue) : false,
      attributeChangeSource: isSet(object.attributeChangeSource)
        ? sellExclusivesCategory_AttributeChangeSourceFromJSON(object.attributeChangeSource)
        : 0,
    };
  },

  toJSON(message: SellExclusivesCategory_AttributeToggleEvent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.attributeName !== "") {
      obj.attributeName = message.attributeName;
    }
    if (message.attributeValue === true) {
      obj.attributeValue = message.attributeValue;
    }
    if (message.attributeChangeSource !== 0) {
      obj.attributeChangeSource = sellExclusivesCategory_AttributeChangeSourceToJSON(message.attributeChangeSource);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellExclusivesCategory_AttributeToggleEvent>, I>>(
    base?: I,
  ): SellExclusivesCategory_AttributeToggleEvent {
    return SellExclusivesCategory_AttributeToggleEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellExclusivesCategory_AttributeToggleEvent>, I>>(
    object: I,
  ): SellExclusivesCategory_AttributeToggleEvent {
    const message = createBaseSellExclusivesCategory_AttributeToggleEvent();
    message.leadId = object.leadId ?? "";
    message.attributeName = object.attributeName ?? "";
    message.attributeValue = object.attributeValue ?? false;
    message.attributeChangeSource = object.attributeChangeSource ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
