/* eslint-disable */

export const protobufPackage = "od_protos.sell.common";

/**
 * The marketing channel the seller came in from.
 * Next ID: 4
 */
export enum MarketingChannel {
  UNKNOWN_CHANNEL = 0,
  /** DEFAULT - From the main website opendoor.com. */
  DEFAULT = 1,
  /** APP - From our mobile application. */
  APP = 2,
  /** HOME_VALUE - From our home value report emails. */
  HOME_VALUE = 3,
  UNRECOGNIZED = -1,
}

export function marketingChannelFromJSON(object: any): MarketingChannel {
  switch (object) {
    case 0:
    case "UNKNOWN_CHANNEL":
      return MarketingChannel.UNKNOWN_CHANNEL;
    case 1:
    case "DEFAULT":
      return MarketingChannel.DEFAULT;
    case 2:
    case "APP":
      return MarketingChannel.APP;
    case 3:
    case "HOME_VALUE":
      return MarketingChannel.HOME_VALUE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MarketingChannel.UNRECOGNIZED;
  }
}

export function marketingChannelToJSON(object: MarketingChannel): string {
  switch (object) {
    case MarketingChannel.UNKNOWN_CHANNEL:
      return "UNKNOWN_CHANNEL";
    case MarketingChannel.DEFAULT:
      return "DEFAULT";
    case MarketingChannel.APP:
      return "APP";
    case MarketingChannel.HOME_VALUE:
      return "HOME_VALUE";
    case MarketingChannel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * The timeline the seller has when looking to sell their home.
 * Next ID: 6
 */
export enum Timeline {
  UNKNOWN_TIMELINE = 0,
  ASAP = 1,
  /** WEEKS_2_4 - 2 - 4 weeks */
  WEEKS_2_4 = 2,
  /** WEEKS_4_6 - 4 - 6 weeks */
  WEEKS_4_6 = 3,
  /** WEEKS_6_PLUS - 6+ weeks */
  WEEKS_6_PLUS = 4,
  JUST_BROWSING = 5,
  UNRECOGNIZED = -1,
}

export function timelineFromJSON(object: any): Timeline {
  switch (object) {
    case 0:
    case "UNKNOWN_TIMELINE":
      return Timeline.UNKNOWN_TIMELINE;
    case 1:
    case "ASAP":
      return Timeline.ASAP;
    case 2:
    case "WEEKS_2_4":
      return Timeline.WEEKS_2_4;
    case 3:
    case "WEEKS_4_6":
      return Timeline.WEEKS_4_6;
    case 4:
    case "WEEKS_6_PLUS":
      return Timeline.WEEKS_6_PLUS;
    case 5:
    case "JUST_BROWSING":
      return Timeline.JUST_BROWSING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Timeline.UNRECOGNIZED;
  }
}

export function timelineToJSON(object: Timeline): string {
  switch (object) {
    case Timeline.UNKNOWN_TIMELINE:
      return "UNKNOWN_TIMELINE";
    case Timeline.ASAP:
      return "ASAP";
    case Timeline.WEEKS_2_4:
      return "WEEKS_2_4";
    case Timeline.WEEKS_4_6:
      return "WEEKS_4_6";
    case Timeline.WEEKS_6_PLUS:
      return "WEEKS_6_PLUS";
    case Timeline.JUST_BROWSING:
      return "JUST_BROWSING";
    case Timeline.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Partner home builders that refers sellers to us.
 * Next ID: 22
 */
export enum Partnership {
  UNKNOWN = 0,
  NONE = 1,
  LENNAR = 2,
  TOLL_BROTHERS = 3,
  MI_HOMES = 4,
  MERITAGE = 5,
  TAYLOR_MORRISON = 6,
  CHAFIN = 7,
  SMITHDOUGLAS = 8,
  WOODSIDE = 9,
  MATTAMY = 10,
  RYAN = 11,
  PARDEE = 12,
  PULTE = 13,
  CALATLANTIC = 14,
  FRONTDOOR = 15,
  GOODALL = 16,
  KB_HOME = 17,
  OTHER_BUILDER = 18,
  CENTURY_COMMUNITIES = 19,
  DAVID_WEEKLEY = 20,
  SHEA_HOMES_TRILOGY = 21,
  DR_HORTON = 22,
  UNRECOGNIZED = -1,
}

export function partnershipFromJSON(object: any): Partnership {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return Partnership.UNKNOWN;
    case 1:
    case "NONE":
      return Partnership.NONE;
    case 2:
    case "LENNAR":
      return Partnership.LENNAR;
    case 3:
    case "TOLL_BROTHERS":
      return Partnership.TOLL_BROTHERS;
    case 4:
    case "MI_HOMES":
      return Partnership.MI_HOMES;
    case 5:
    case "MERITAGE":
      return Partnership.MERITAGE;
    case 6:
    case "TAYLOR_MORRISON":
      return Partnership.TAYLOR_MORRISON;
    case 7:
    case "CHAFIN":
      return Partnership.CHAFIN;
    case 8:
    case "SMITHDOUGLAS":
      return Partnership.SMITHDOUGLAS;
    case 9:
    case "WOODSIDE":
      return Partnership.WOODSIDE;
    case 10:
    case "MATTAMY":
      return Partnership.MATTAMY;
    case 11:
    case "RYAN":
      return Partnership.RYAN;
    case 12:
    case "PARDEE":
      return Partnership.PARDEE;
    case 13:
    case "PULTE":
      return Partnership.PULTE;
    case 14:
    case "CALATLANTIC":
      return Partnership.CALATLANTIC;
    case 15:
    case "FRONTDOOR":
      return Partnership.FRONTDOOR;
    case 16:
    case "GOODALL":
      return Partnership.GOODALL;
    case 17:
    case "KB_HOME":
      return Partnership.KB_HOME;
    case 18:
    case "OTHER_BUILDER":
      return Partnership.OTHER_BUILDER;
    case 19:
    case "CENTURY_COMMUNITIES":
      return Partnership.CENTURY_COMMUNITIES;
    case 20:
    case "DAVID_WEEKLEY":
      return Partnership.DAVID_WEEKLEY;
    case 21:
    case "SHEA_HOMES_TRILOGY":
      return Partnership.SHEA_HOMES_TRILOGY;
    case 22:
    case "DR_HORTON":
      return Partnership.DR_HORTON;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Partnership.UNRECOGNIZED;
  }
}

export function partnershipToJSON(object: Partnership): string {
  switch (object) {
    case Partnership.UNKNOWN:
      return "UNKNOWN";
    case Partnership.NONE:
      return "NONE";
    case Partnership.LENNAR:
      return "LENNAR";
    case Partnership.TOLL_BROTHERS:
      return "TOLL_BROTHERS";
    case Partnership.MI_HOMES:
      return "MI_HOMES";
    case Partnership.MERITAGE:
      return "MERITAGE";
    case Partnership.TAYLOR_MORRISON:
      return "TAYLOR_MORRISON";
    case Partnership.CHAFIN:
      return "CHAFIN";
    case Partnership.SMITHDOUGLAS:
      return "SMITHDOUGLAS";
    case Partnership.WOODSIDE:
      return "WOODSIDE";
    case Partnership.MATTAMY:
      return "MATTAMY";
    case Partnership.RYAN:
      return "RYAN";
    case Partnership.PARDEE:
      return "PARDEE";
    case Partnership.PULTE:
      return "PULTE";
    case Partnership.CALATLANTIC:
      return "CALATLANTIC";
    case Partnership.FRONTDOOR:
      return "FRONTDOOR";
    case Partnership.GOODALL:
      return "GOODALL";
    case Partnership.KB_HOME:
      return "KB_HOME";
    case Partnership.OTHER_BUILDER:
      return "OTHER_BUILDER";
    case Partnership.CENTURY_COMMUNITIES:
      return "CENTURY_COMMUNITIES";
    case Partnership.DAVID_WEEKLEY:
      return "DAVID_WEEKLEY";
    case Partnership.SHEA_HOMES_TRILOGY:
      return "SHEA_HOMES_TRILOGY";
    case Partnership.DR_HORTON:
      return "DR_HORTON";
    case Partnership.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * A Product that we can offer to the seller.
 * Next ID: 11
 */
export enum Product {
  /** UNKNOWN_PRODUCT - Product with an unknown name. This should rarely happen */
  UNKNOWN_PRODUCT = 0,
  /** OPENDOOR_DIRECT - Sell directly to Opendoor */
  OPENDOOR_DIRECT = 1,
  /**
   * AGENT_REFERRAL - Refer to an agent to help them sell their home
   *
   * @deprecated
   */
  AGENT_REFERRAL = 2,
  /** REIT - Sell to a REIT (Real Estate Investment Trust) */
  REIT = 3,
  /** LIST_WITH_OPENDOOR - List your home with Opendoor */
  LIST_WITH_OPENDOOR = 4,
  /**
   * SELL_WITH_UPSIDE - Sell directly with upside
   *
   * @deprecated
   */
  SELL_WITH_UPSIDE = 5,
  /** BUY_WITH_OPENDOOR - Buy a home from Opendoor */
  BUY_WITH_OPENDOOR = 6,
  /** OPENDOOR_BACKED_OFFERS - Make a cash offer on any listed home, using Opendoor cash */
  OPENDOOR_BACKED_OFFERS = 7,
  /** SELL_WITH_EXCLUSIVES - Sell your home on Exclusives */
  SELL_WITH_EXCLUSIVES = 8,
  /** LIST_WITH_CERTAINTY - List your home and get an Opendoor cash offer as a fallback */
  LIST_WITH_CERTAINTY = 9,
  /** EXTERNAL_LISTING_AGENT - Buy a home through an external listing agent */
  EXTERNAL_LISTING_AGENT = 10,
  UNRECOGNIZED = -1,
}

export function productFromJSON(object: any): Product {
  switch (object) {
    case 0:
    case "UNKNOWN_PRODUCT":
      return Product.UNKNOWN_PRODUCT;
    case 1:
    case "OPENDOOR_DIRECT":
      return Product.OPENDOOR_DIRECT;
    case 2:
    case "AGENT_REFERRAL":
      return Product.AGENT_REFERRAL;
    case 3:
    case "REIT":
      return Product.REIT;
    case 4:
    case "LIST_WITH_OPENDOOR":
      return Product.LIST_WITH_OPENDOOR;
    case 5:
    case "SELL_WITH_UPSIDE":
      return Product.SELL_WITH_UPSIDE;
    case 6:
    case "BUY_WITH_OPENDOOR":
      return Product.BUY_WITH_OPENDOOR;
    case 7:
    case "OPENDOOR_BACKED_OFFERS":
      return Product.OPENDOOR_BACKED_OFFERS;
    case 8:
    case "SELL_WITH_EXCLUSIVES":
      return Product.SELL_WITH_EXCLUSIVES;
    case 9:
    case "LIST_WITH_CERTAINTY":
      return Product.LIST_WITH_CERTAINTY;
    case 10:
    case "EXTERNAL_LISTING_AGENT":
      return Product.EXTERNAL_LISTING_AGENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Product.UNRECOGNIZED;
  }
}

export function productToJSON(object: Product): string {
  switch (object) {
    case Product.UNKNOWN_PRODUCT:
      return "UNKNOWN_PRODUCT";
    case Product.OPENDOOR_DIRECT:
      return "OPENDOOR_DIRECT";
    case Product.AGENT_REFERRAL:
      return "AGENT_REFERRAL";
    case Product.REIT:
      return "REIT";
    case Product.LIST_WITH_OPENDOOR:
      return "LIST_WITH_OPENDOOR";
    case Product.SELL_WITH_UPSIDE:
      return "SELL_WITH_UPSIDE";
    case Product.BUY_WITH_OPENDOOR:
      return "BUY_WITH_OPENDOOR";
    case Product.OPENDOOR_BACKED_OFFERS:
      return "OPENDOOR_BACKED_OFFERS";
    case Product.SELL_WITH_EXCLUSIVES:
      return "SELL_WITH_EXCLUSIVES";
    case Product.LIST_WITH_CERTAINTY:
      return "LIST_WITH_CERTAINTY";
    case Product.EXTERNAL_LISTING_AGENT:
      return "EXTERNAL_LISTING_AGENT";
    case Product.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Denial reason if the customer was denied for a product.
 * Next ID: 75
 */
export enum DenialReason {
  /** NO_DENIAL - home is not denied */
  NO_DENIAL = 0,
  /** ADDRESS - home is not in a region serviced by Opendoor */
  ADDRESS = 1,
  /** AGE_RESTRICTED_COMMUNITY - home is in an age restricted community */
  AGE_RESTRICTED_COMMUNITY = 2,
  /** DWELLING_TYPE - home type is not within the set of types purchased by Opendoor */
  DWELLING_TYPE = 4,
  /** FLOOD_ZONE - home is in a flood zone. */
  FLOOD_ZONE = 5,
  /** GATED_COMMUNITY - home is in a gated community */
  GATED_COMMUNITY = 6,
  /** LOT_SQ_FT - home's lot sq ft is too large */
  LOT_SQ_FT = 7,
  /** POLYBUTYLENE_PIPING - home has polybutylene piping */
  POLYBUTYLENE_PIPING = 8,
  /** UNPERMITTED_ADDITION - home has unpermitted additions */
  UNPERMITTED_ADDITION = 9,
  /** VALUATION_TOO_HIGH - home's value is outside of buybox range */
  VALUATION_TOO_HIGH = 10,
  /** VALUATION_TOO_LOW - home's value is outside of buybox range */
  VALUATION_TOO_LOW = 11,
  /** LEASED_SOLAR_PANELS - home has leased solar panels */
  LEASED_SOLAR_PANELS = 12,
  /** FLOOD_HISTORY - home has a history of flooding */
  FLOOD_HISTORY = 13,
  /** SEPTIC - home has a septic system */
  SEPTIC = 14,
  /** WELL_WATER - home has a private well as the primary water source */
  WELL_WATER = 15,
  /** YEAR_BUILT - home was built too long ago */
  YEAR_BUILT = 16,
  /** ASBESTOS_SIDING - home has asbestos siding */
  ASBESTOS_SIDING = 17,
  /** OWNED_BY_OPENDOOR - Opendoor owns the home */
  OWNED_BY_OPENDOOR = 18,
  /** LISTED_BY_OPENDOOR - Opendoor has listed the home for sale */
  LISTED_BY_OPENDOOR = 19,
  /** IN_CONTRACT_WITH_OPENDOOR - home is in contract or in contingencies released with Opendoor */
  IN_CONTRACT_WITH_OPENDOOR = 20,
  /** OTHER_DENIAL - other unspecified reason */
  OTHER_DENIAL = 22,
  /** AGENT_RELATION - customer is an agent representative of the homeowner */
  AGENT_RELATION = 23,
  /** WORKING_WITH_HOMEBUILDER - customer is working with a homebuilder partner */
  WORKING_WITH_HOMEBUILDER = 24,
  /** BUYER_UNAVAILABLE - buyers are currently unavailable to make an offer on the home */
  BUYER_UNAVAILABLE = 25,
  /** OFFER_OUTLIER - home has been deemed an outlier at pre-offer time */
  OFFER_OUTLIER = 26,
  NOT_IN_AUTODENIAL_LISTING_EXP = 27,
  NOT_IN_SIDE_BY_SIDE_EXP = 28,
  /** PARTNERSHIP - seller is working with a partner, could be homebuilder or other */
  PARTNERSHIP = 29,
  /** TRADE_IN - seller is a trade-in customer */
  TRADE_IN = 30,
  /** FOUNDATION - home has known foundation issues */
  FOUNDATION = 31,
  /** NON_SERIOUS_SELLER - seller is not serious yet */
  NON_SERIOUS_SELLER = 32,
  /** FIRE_DAMAGE - home has previous or current fire damage */
  FIRE_DAMAGE = 33,
  /** LACK_OF_ADEQUATE_COMPS - home does not have adequate comps */
  LACK_OF_ADEQUATE_COMPS = 34,
  /** NON_BUYBOX_DWELLING_TYPE - home dwelling type is not in buybox */
  NON_BUYBOX_DWELLING_TYPE = 35,
  /** OTHER_HIGH_RISK - home has high risk factors */
  OTHER_HIGH_RISK = 36,
  /** TENANT_OCCUPIED - home is tenant occupied (has lease) */
  TENANT_OCCUPIED = 37,
  /** VALUATION_TOO_LOW_LISTED - valuation is significantly lower than list price */
  VALUATION_TOO_LOW_LISTED = 38,
  /** AGE_RESTRICTED_SEASONAL - seasonal age restriction for certain market */
  AGE_RESTRICTED_SEASONAL = 39,
  /** BEFORE_1960 - home was built before 1960 */
  BEFORE_1960 = 40,
  /** FEES_TOO_HIGH - fees on offer are too high */
  FEES_TOO_HIGH = 41,
  /** DUPLICATE_LEAD - duplicate lead on address */
  DUPLICATE_LEAD = 42,
  /** SELLER_TRIAGE - rejected seller triage documentation */
  SELLER_TRIAGE = 43,
  /** SHARED_ENTRANCE - home has a shared entrance with neighbors */
  SHARED_ENTRANCE = 44,
  /** SHARED_LOT - home has a shared lot with neighbors */
  SHARED_LOT = 45,
  /** PREPARING_OFFER - already an active offer request for this address, still preparing offer */
  PREPARING_OFFER = 46,
  /** ACTIVE_OFFER - already an active offer for this address */
  ACTIVE_OFFER = 47,
  /** PRIVATE_POOL - private pool */
  PRIVATE_POOL = 48,
  /** RESERVED_FOR_EXTERNAL_BROKER - lead will be passed to external brokerage */
  RESERVED_FOR_EXTERNAL_BROKER = 49,
  /** NOT_IN_LWOD_PILOT - seller is not in the lwod experiment */
  NOT_IN_LWOD_PILOT = 50,
  /** COVID_19 - pause all acq offers for COVID-19 */
  COVID_19 = 51,
  /**
   * NOT_IN_SELL_WITH_UPSIDE_PILOT - Not in the Sell w/ Upside experiment
   *
   * @deprecated
   */
  NOT_IN_SELL_WITH_UPSIDE_PILOT = 52,
  /** UNABLE_TO_VALUE - opendoor cannot value the home. */
  UNABLE_TO_VALUE = 53,
  /** DENIED_FOR_SELL_WITH_UPSIDE - they were also denied for sell_with_upside, so they're not eligible for this other product */
  DENIED_FOR_SELL_WITH_UPSIDE = 54,
  /** QUALIFIED_FOR_LWOD - they're qualified for list with opendoor, which is incompatible with this product */
  QUALIFIED_FOR_LWOD = 55,
  /** DENIED_FOR_OPENDOOR_DIRECT - they were also denied for opendoor_direct, so they're not eligible for this other product */
  DENIED_FOR_OPENDOOR_DIRECT = 56,
  /** QUALIFIED_FOR_OPENDOOR_DIRECT - they're qualified for opendoor direct, which is incompatible with this product */
  QUALIFIED_FOR_OPENDOOR_DIRECT = 57,
  /** GUARD_GATED_COMMUNITY - home is in a guard-gated community */
  GUARD_GATED_COMMUNITY = 58,
  /** LIVESTOCK - livestock on property */
  LIVESTOCK = 62,
  /** MOBILE_MANUFACTURED_HOME - mobile or manufactured home */
  MOBILE_MANUFACTURED_HOME = 63,
  /** ADDRESS_OVERRIDE - this address has been explicitly denied */
  ADDRESS_OVERRIDE = 59,
  /** SALE_TIMELINE - sale timeline is not compatible with the experience */
  SALE_TIMELINE = 65,
  /** EXPECTED_LGM - expected levered gross margin (LGM) does not meet experience criteria */
  EXPECTED_LGM = 66,
  /** UNIQUE_OWNERSHIP_STRUCTURE - tenants in common, co-op programs */
  UNIQUE_OWNERSHIP_STRUCTURE = 68,
  /** BELOW_MARKET_RATE_OWNERSHIP - San Francisco-specific program for low-middle income buyers */
  BELOW_MARKET_RATE_OWNERSHIP = 69,
  /** UNDERGROUND_OIL_TANK - NY/NJ specific - homes commonly have underground oil tanks that can be costly */
  UNDERGROUND_OIL_TANK = 70,
  /** MLS_LISTING_STATUS - For use with partner API, controls display of OD offers in partner's website UI */
  MLS_LISTING_STATUS = 71,
  /** CESSPOOL - Boston and NY/NJ only, for homes with a cesspool */
  CESSPOOL = 72,
  /** INELIGIBLE_HOA - home is in an ineligible HOA - requires rental history, SSN, etc. */
  INELIGIBLE_HOA = 73,
  /**
   * OFFER_IN_PROGRESS - Deprecated, used only for the ShouldDenyBasedOnFlipStatus web GRPC endpoint
   *
   * @deprecated
   */
  OFFER_IN_PROGRESS = 64,
  /**
   * OTHER_DENIAL_GENERAL - denial code used externally to present that we have some uncategorized
   * reason for denying a given address for a given product.
   */
  OTHER_DENIAL_GENERAL = 60,
  /**
   * OTHER_DENIAL_DETAIL - denial code used externally to present that we are missing a
   * particular piece of information necessary to value a home.
   * For backwards compatibility this denial_reason does not
   * have a more reasonable name like OTHER_DENIAL_VALUATION
   */
  OTHER_DENIAL_DETAIL = 61,
  /** DEPENDENT_RULESET - dependent ruleset evaluation is incompatible with this product */
  DEPENDENT_RULESET = 67,
  /** RISK_PROHIBITED_REGION - the home is in a region that Pricing has deemed too risky */
  RISK_PROHIBITED_REGION = 74,
  UNRECOGNIZED = -1,
}

export function denialReasonFromJSON(object: any): DenialReason {
  switch (object) {
    case 0:
    case "NO_DENIAL":
      return DenialReason.NO_DENIAL;
    case 1:
    case "ADDRESS":
      return DenialReason.ADDRESS;
    case 2:
    case "AGE_RESTRICTED_COMMUNITY":
      return DenialReason.AGE_RESTRICTED_COMMUNITY;
    case 4:
    case "DWELLING_TYPE":
      return DenialReason.DWELLING_TYPE;
    case 5:
    case "FLOOD_ZONE":
      return DenialReason.FLOOD_ZONE;
    case 6:
    case "GATED_COMMUNITY":
      return DenialReason.GATED_COMMUNITY;
    case 7:
    case "LOT_SQ_FT":
      return DenialReason.LOT_SQ_FT;
    case 8:
    case "POLYBUTYLENE_PIPING":
      return DenialReason.POLYBUTYLENE_PIPING;
    case 9:
    case "UNPERMITTED_ADDITION":
      return DenialReason.UNPERMITTED_ADDITION;
    case 10:
    case "VALUATION_TOO_HIGH":
      return DenialReason.VALUATION_TOO_HIGH;
    case 11:
    case "VALUATION_TOO_LOW":
      return DenialReason.VALUATION_TOO_LOW;
    case 12:
    case "LEASED_SOLAR_PANELS":
      return DenialReason.LEASED_SOLAR_PANELS;
    case 13:
    case "FLOOD_HISTORY":
      return DenialReason.FLOOD_HISTORY;
    case 14:
    case "SEPTIC":
      return DenialReason.SEPTIC;
    case 15:
    case "WELL_WATER":
      return DenialReason.WELL_WATER;
    case 16:
    case "YEAR_BUILT":
      return DenialReason.YEAR_BUILT;
    case 17:
    case "ASBESTOS_SIDING":
      return DenialReason.ASBESTOS_SIDING;
    case 18:
    case "OWNED_BY_OPENDOOR":
      return DenialReason.OWNED_BY_OPENDOOR;
    case 19:
    case "LISTED_BY_OPENDOOR":
      return DenialReason.LISTED_BY_OPENDOOR;
    case 20:
    case "IN_CONTRACT_WITH_OPENDOOR":
      return DenialReason.IN_CONTRACT_WITH_OPENDOOR;
    case 22:
    case "OTHER_DENIAL":
      return DenialReason.OTHER_DENIAL;
    case 23:
    case "AGENT_RELATION":
      return DenialReason.AGENT_RELATION;
    case 24:
    case "WORKING_WITH_HOMEBUILDER":
      return DenialReason.WORKING_WITH_HOMEBUILDER;
    case 25:
    case "BUYER_UNAVAILABLE":
      return DenialReason.BUYER_UNAVAILABLE;
    case 26:
    case "OFFER_OUTLIER":
      return DenialReason.OFFER_OUTLIER;
    case 27:
    case "NOT_IN_AUTODENIAL_LISTING_EXP":
      return DenialReason.NOT_IN_AUTODENIAL_LISTING_EXP;
    case 28:
    case "NOT_IN_SIDE_BY_SIDE_EXP":
      return DenialReason.NOT_IN_SIDE_BY_SIDE_EXP;
    case 29:
    case "PARTNERSHIP":
      return DenialReason.PARTNERSHIP;
    case 30:
    case "TRADE_IN":
      return DenialReason.TRADE_IN;
    case 31:
    case "FOUNDATION":
      return DenialReason.FOUNDATION;
    case 32:
    case "NON_SERIOUS_SELLER":
      return DenialReason.NON_SERIOUS_SELLER;
    case 33:
    case "FIRE_DAMAGE":
      return DenialReason.FIRE_DAMAGE;
    case 34:
    case "LACK_OF_ADEQUATE_COMPS":
      return DenialReason.LACK_OF_ADEQUATE_COMPS;
    case 35:
    case "NON_BUYBOX_DWELLING_TYPE":
      return DenialReason.NON_BUYBOX_DWELLING_TYPE;
    case 36:
    case "OTHER_HIGH_RISK":
      return DenialReason.OTHER_HIGH_RISK;
    case 37:
    case "TENANT_OCCUPIED":
      return DenialReason.TENANT_OCCUPIED;
    case 38:
    case "VALUATION_TOO_LOW_LISTED":
      return DenialReason.VALUATION_TOO_LOW_LISTED;
    case 39:
    case "AGE_RESTRICTED_SEASONAL":
      return DenialReason.AGE_RESTRICTED_SEASONAL;
    case 40:
    case "BEFORE_1960":
      return DenialReason.BEFORE_1960;
    case 41:
    case "FEES_TOO_HIGH":
      return DenialReason.FEES_TOO_HIGH;
    case 42:
    case "DUPLICATE_LEAD":
      return DenialReason.DUPLICATE_LEAD;
    case 43:
    case "SELLER_TRIAGE":
      return DenialReason.SELLER_TRIAGE;
    case 44:
    case "SHARED_ENTRANCE":
      return DenialReason.SHARED_ENTRANCE;
    case 45:
    case "SHARED_LOT":
      return DenialReason.SHARED_LOT;
    case 46:
    case "PREPARING_OFFER":
      return DenialReason.PREPARING_OFFER;
    case 47:
    case "ACTIVE_OFFER":
      return DenialReason.ACTIVE_OFFER;
    case 48:
    case "PRIVATE_POOL":
      return DenialReason.PRIVATE_POOL;
    case 49:
    case "RESERVED_FOR_EXTERNAL_BROKER":
      return DenialReason.RESERVED_FOR_EXTERNAL_BROKER;
    case 50:
    case "NOT_IN_LWOD_PILOT":
      return DenialReason.NOT_IN_LWOD_PILOT;
    case 51:
    case "COVID_19":
      return DenialReason.COVID_19;
    case 52:
    case "NOT_IN_SELL_WITH_UPSIDE_PILOT":
      return DenialReason.NOT_IN_SELL_WITH_UPSIDE_PILOT;
    case 53:
    case "UNABLE_TO_VALUE":
      return DenialReason.UNABLE_TO_VALUE;
    case 54:
    case "DENIED_FOR_SELL_WITH_UPSIDE":
      return DenialReason.DENIED_FOR_SELL_WITH_UPSIDE;
    case 55:
    case "QUALIFIED_FOR_LWOD":
      return DenialReason.QUALIFIED_FOR_LWOD;
    case 56:
    case "DENIED_FOR_OPENDOOR_DIRECT":
      return DenialReason.DENIED_FOR_OPENDOOR_DIRECT;
    case 57:
    case "QUALIFIED_FOR_OPENDOOR_DIRECT":
      return DenialReason.QUALIFIED_FOR_OPENDOOR_DIRECT;
    case 58:
    case "GUARD_GATED_COMMUNITY":
      return DenialReason.GUARD_GATED_COMMUNITY;
    case 62:
    case "LIVESTOCK":
      return DenialReason.LIVESTOCK;
    case 63:
    case "MOBILE_MANUFACTURED_HOME":
      return DenialReason.MOBILE_MANUFACTURED_HOME;
    case 59:
    case "ADDRESS_OVERRIDE":
      return DenialReason.ADDRESS_OVERRIDE;
    case 65:
    case "SALE_TIMELINE":
      return DenialReason.SALE_TIMELINE;
    case 66:
    case "EXPECTED_LGM":
      return DenialReason.EXPECTED_LGM;
    case 68:
    case "UNIQUE_OWNERSHIP_STRUCTURE":
      return DenialReason.UNIQUE_OWNERSHIP_STRUCTURE;
    case 69:
    case "BELOW_MARKET_RATE_OWNERSHIP":
      return DenialReason.BELOW_MARKET_RATE_OWNERSHIP;
    case 70:
    case "UNDERGROUND_OIL_TANK":
      return DenialReason.UNDERGROUND_OIL_TANK;
    case 71:
    case "MLS_LISTING_STATUS":
      return DenialReason.MLS_LISTING_STATUS;
    case 72:
    case "CESSPOOL":
      return DenialReason.CESSPOOL;
    case 73:
    case "INELIGIBLE_HOA":
      return DenialReason.INELIGIBLE_HOA;
    case 64:
    case "OFFER_IN_PROGRESS":
      return DenialReason.OFFER_IN_PROGRESS;
    case 60:
    case "OTHER_DENIAL_GENERAL":
      return DenialReason.OTHER_DENIAL_GENERAL;
    case 61:
    case "OTHER_DENIAL_DETAIL":
      return DenialReason.OTHER_DENIAL_DETAIL;
    case 67:
    case "DEPENDENT_RULESET":
      return DenialReason.DEPENDENT_RULESET;
    case 74:
    case "RISK_PROHIBITED_REGION":
      return DenialReason.RISK_PROHIBITED_REGION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DenialReason.UNRECOGNIZED;
  }
}

export function denialReasonToJSON(object: DenialReason): string {
  switch (object) {
    case DenialReason.NO_DENIAL:
      return "NO_DENIAL";
    case DenialReason.ADDRESS:
      return "ADDRESS";
    case DenialReason.AGE_RESTRICTED_COMMUNITY:
      return "AGE_RESTRICTED_COMMUNITY";
    case DenialReason.DWELLING_TYPE:
      return "DWELLING_TYPE";
    case DenialReason.FLOOD_ZONE:
      return "FLOOD_ZONE";
    case DenialReason.GATED_COMMUNITY:
      return "GATED_COMMUNITY";
    case DenialReason.LOT_SQ_FT:
      return "LOT_SQ_FT";
    case DenialReason.POLYBUTYLENE_PIPING:
      return "POLYBUTYLENE_PIPING";
    case DenialReason.UNPERMITTED_ADDITION:
      return "UNPERMITTED_ADDITION";
    case DenialReason.VALUATION_TOO_HIGH:
      return "VALUATION_TOO_HIGH";
    case DenialReason.VALUATION_TOO_LOW:
      return "VALUATION_TOO_LOW";
    case DenialReason.LEASED_SOLAR_PANELS:
      return "LEASED_SOLAR_PANELS";
    case DenialReason.FLOOD_HISTORY:
      return "FLOOD_HISTORY";
    case DenialReason.SEPTIC:
      return "SEPTIC";
    case DenialReason.WELL_WATER:
      return "WELL_WATER";
    case DenialReason.YEAR_BUILT:
      return "YEAR_BUILT";
    case DenialReason.ASBESTOS_SIDING:
      return "ASBESTOS_SIDING";
    case DenialReason.OWNED_BY_OPENDOOR:
      return "OWNED_BY_OPENDOOR";
    case DenialReason.LISTED_BY_OPENDOOR:
      return "LISTED_BY_OPENDOOR";
    case DenialReason.IN_CONTRACT_WITH_OPENDOOR:
      return "IN_CONTRACT_WITH_OPENDOOR";
    case DenialReason.OTHER_DENIAL:
      return "OTHER_DENIAL";
    case DenialReason.AGENT_RELATION:
      return "AGENT_RELATION";
    case DenialReason.WORKING_WITH_HOMEBUILDER:
      return "WORKING_WITH_HOMEBUILDER";
    case DenialReason.BUYER_UNAVAILABLE:
      return "BUYER_UNAVAILABLE";
    case DenialReason.OFFER_OUTLIER:
      return "OFFER_OUTLIER";
    case DenialReason.NOT_IN_AUTODENIAL_LISTING_EXP:
      return "NOT_IN_AUTODENIAL_LISTING_EXP";
    case DenialReason.NOT_IN_SIDE_BY_SIDE_EXP:
      return "NOT_IN_SIDE_BY_SIDE_EXP";
    case DenialReason.PARTNERSHIP:
      return "PARTNERSHIP";
    case DenialReason.TRADE_IN:
      return "TRADE_IN";
    case DenialReason.FOUNDATION:
      return "FOUNDATION";
    case DenialReason.NON_SERIOUS_SELLER:
      return "NON_SERIOUS_SELLER";
    case DenialReason.FIRE_DAMAGE:
      return "FIRE_DAMAGE";
    case DenialReason.LACK_OF_ADEQUATE_COMPS:
      return "LACK_OF_ADEQUATE_COMPS";
    case DenialReason.NON_BUYBOX_DWELLING_TYPE:
      return "NON_BUYBOX_DWELLING_TYPE";
    case DenialReason.OTHER_HIGH_RISK:
      return "OTHER_HIGH_RISK";
    case DenialReason.TENANT_OCCUPIED:
      return "TENANT_OCCUPIED";
    case DenialReason.VALUATION_TOO_LOW_LISTED:
      return "VALUATION_TOO_LOW_LISTED";
    case DenialReason.AGE_RESTRICTED_SEASONAL:
      return "AGE_RESTRICTED_SEASONAL";
    case DenialReason.BEFORE_1960:
      return "BEFORE_1960";
    case DenialReason.FEES_TOO_HIGH:
      return "FEES_TOO_HIGH";
    case DenialReason.DUPLICATE_LEAD:
      return "DUPLICATE_LEAD";
    case DenialReason.SELLER_TRIAGE:
      return "SELLER_TRIAGE";
    case DenialReason.SHARED_ENTRANCE:
      return "SHARED_ENTRANCE";
    case DenialReason.SHARED_LOT:
      return "SHARED_LOT";
    case DenialReason.PREPARING_OFFER:
      return "PREPARING_OFFER";
    case DenialReason.ACTIVE_OFFER:
      return "ACTIVE_OFFER";
    case DenialReason.PRIVATE_POOL:
      return "PRIVATE_POOL";
    case DenialReason.RESERVED_FOR_EXTERNAL_BROKER:
      return "RESERVED_FOR_EXTERNAL_BROKER";
    case DenialReason.NOT_IN_LWOD_PILOT:
      return "NOT_IN_LWOD_PILOT";
    case DenialReason.COVID_19:
      return "COVID_19";
    case DenialReason.NOT_IN_SELL_WITH_UPSIDE_PILOT:
      return "NOT_IN_SELL_WITH_UPSIDE_PILOT";
    case DenialReason.UNABLE_TO_VALUE:
      return "UNABLE_TO_VALUE";
    case DenialReason.DENIED_FOR_SELL_WITH_UPSIDE:
      return "DENIED_FOR_SELL_WITH_UPSIDE";
    case DenialReason.QUALIFIED_FOR_LWOD:
      return "QUALIFIED_FOR_LWOD";
    case DenialReason.DENIED_FOR_OPENDOOR_DIRECT:
      return "DENIED_FOR_OPENDOOR_DIRECT";
    case DenialReason.QUALIFIED_FOR_OPENDOOR_DIRECT:
      return "QUALIFIED_FOR_OPENDOOR_DIRECT";
    case DenialReason.GUARD_GATED_COMMUNITY:
      return "GUARD_GATED_COMMUNITY";
    case DenialReason.LIVESTOCK:
      return "LIVESTOCK";
    case DenialReason.MOBILE_MANUFACTURED_HOME:
      return "MOBILE_MANUFACTURED_HOME";
    case DenialReason.ADDRESS_OVERRIDE:
      return "ADDRESS_OVERRIDE";
    case DenialReason.SALE_TIMELINE:
      return "SALE_TIMELINE";
    case DenialReason.EXPECTED_LGM:
      return "EXPECTED_LGM";
    case DenialReason.UNIQUE_OWNERSHIP_STRUCTURE:
      return "UNIQUE_OWNERSHIP_STRUCTURE";
    case DenialReason.BELOW_MARKET_RATE_OWNERSHIP:
      return "BELOW_MARKET_RATE_OWNERSHIP";
    case DenialReason.UNDERGROUND_OIL_TANK:
      return "UNDERGROUND_OIL_TANK";
    case DenialReason.MLS_LISTING_STATUS:
      return "MLS_LISTING_STATUS";
    case DenialReason.CESSPOOL:
      return "CESSPOOL";
    case DenialReason.INELIGIBLE_HOA:
      return "INELIGIBLE_HOA";
    case DenialReason.OFFER_IN_PROGRESS:
      return "OFFER_IN_PROGRESS";
    case DenialReason.OTHER_DENIAL_GENERAL:
      return "OTHER_DENIAL_GENERAL";
    case DenialReason.OTHER_DENIAL_DETAIL:
      return "OTHER_DENIAL_DETAIL";
    case DenialReason.DEPENDENT_RULESET:
      return "DEPENDENT_RULESET";
    case DenialReason.RISK_PROHIBITED_REGION:
      return "RISK_PROHIBITED_REGION";
    case DenialReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Segments that classify types of sellers
 * Next ID: 5
 */
export enum Segment {
  HOMEOWNER = 0,
  WORKING_WITH_AGENT = 1,
  BUYER = 2,
  HOMEBUILDER = 3,
  STAYING_IN_MARKET = 4,
  UNRECOGNIZED = -1,
}

export function segmentFromJSON(object: any): Segment {
  switch (object) {
    case 0:
    case "HOMEOWNER":
      return Segment.HOMEOWNER;
    case 1:
    case "WORKING_WITH_AGENT":
      return Segment.WORKING_WITH_AGENT;
    case 2:
    case "BUYER":
      return Segment.BUYER;
    case 3:
    case "HOMEBUILDER":
      return Segment.HOMEBUILDER;
    case 4:
    case "STAYING_IN_MARKET":
      return Segment.STAYING_IN_MARKET;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Segment.UNRECOGNIZED;
  }
}

export function segmentToJSON(object: Segment): string {
  switch (object) {
    case Segment.HOMEOWNER:
      return "HOMEOWNER";
    case Segment.WORKING_WITH_AGENT:
      return "WORKING_WITH_AGENT";
    case Segment.BUYER:
      return "BUYER";
    case Segment.HOMEBUILDER:
      return "HOMEBUILDER";
    case Segment.STAYING_IN_MARKET:
      return "STAYING_IN_MARKET";
    case Segment.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * SelfServiceVirtualAssessmentExperimentGroup determines what experiment group the seller is in with regards to the Self Service
 * Virtual Assessment Experiment, where the seller has the option to upload a set of recordings of their home
 * in order to finalize their headline price. Depending on what group the seller falls into will determine
 * the user experience in terms of how they're presented with this option.
 * Next ID: 6
 */
export enum SelfServiceVirtualAssessmentExperimentGroup {
  /** NOT_IN_EXPERIMENT - Seller is not in the experiment. */
  NOT_IN_EXPERIMENT = 0,
  /**
   * BALANCED - Seller is presented with both uploading the self service and scheduling a virtual assessment call with no
   * bias towards either option.
   */
  BALANCED = 1,
  /**
   * ASSERTIVE - Seller is presented with a heavier bias towards the self service option, and must explicitly opt out
   * of that experience.
   */
  ASSERTIVE = 2,
  /** CONTROL - Seller is in the control group of the experiment */
  CONTROL = 3,
  /** AGENT_ASSERTIVE - Agent is presented the self-serve virtual assessment option with a heavier bias towards self-service. */
  AGENT_ASSERTIVE = 4,
  /** AGENT_CONTROL - Agent is in the control group of the experiment */
  AGENT_CONTROL = 5,
  UNRECOGNIZED = -1,
}

export function selfServiceVirtualAssessmentExperimentGroupFromJSON(
  object: any,
): SelfServiceVirtualAssessmentExperimentGroup {
  switch (object) {
    case 0:
    case "NOT_IN_EXPERIMENT":
      return SelfServiceVirtualAssessmentExperimentGroup.NOT_IN_EXPERIMENT;
    case 1:
    case "BALANCED":
      return SelfServiceVirtualAssessmentExperimentGroup.BALANCED;
    case 2:
    case "ASSERTIVE":
      return SelfServiceVirtualAssessmentExperimentGroup.ASSERTIVE;
    case 3:
    case "CONTROL":
      return SelfServiceVirtualAssessmentExperimentGroup.CONTROL;
    case 4:
    case "AGENT_ASSERTIVE":
      return SelfServiceVirtualAssessmentExperimentGroup.AGENT_ASSERTIVE;
    case 5:
    case "AGENT_CONTROL":
      return SelfServiceVirtualAssessmentExperimentGroup.AGENT_CONTROL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SelfServiceVirtualAssessmentExperimentGroup.UNRECOGNIZED;
  }
}

export function selfServiceVirtualAssessmentExperimentGroupToJSON(
  object: SelfServiceVirtualAssessmentExperimentGroup,
): string {
  switch (object) {
    case SelfServiceVirtualAssessmentExperimentGroup.NOT_IN_EXPERIMENT:
      return "NOT_IN_EXPERIMENT";
    case SelfServiceVirtualAssessmentExperimentGroup.BALANCED:
      return "BALANCED";
    case SelfServiceVirtualAssessmentExperimentGroup.ASSERTIVE:
      return "ASSERTIVE";
    case SelfServiceVirtualAssessmentExperimentGroup.CONTROL:
      return "CONTROL";
    case SelfServiceVirtualAssessmentExperimentGroup.AGENT_ASSERTIVE:
      return "AGENT_ASSERTIVE";
    case SelfServiceVirtualAssessmentExperimentGroup.AGENT_CONTROL:
      return "AGENT_CONTROL";
    case SelfServiceVirtualAssessmentExperimentGroup.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
