/* eslint-disable */

export const protobufPackage = "od_protos.journey.data";

/** Next ID: 14 */
export interface WebsiteSource {
  url: string;
  userAgent?:
    | string
    | undefined;
  /** ip is overwritten on HTTP requests with a website source. */
  ip?: string | undefined;
  referrer?:
    | string
    | undefined;
  /** the url.pathname passed to the underlying internal service */
  proxyPath?:
    | string
    | undefined;
  /** request.url.pathname */
  pathname?:
    | string
    | undefined;
  /** request.url.search ({ 'x': '5', 'y': '3', 'z': 'a,b' }) */
  queryMap: { [key: string]: string };
  /** request.url.host */
  host?:
    | string
    | undefined;
  /** where the event is coming from (mostly useful for debugging page view events) */
  eventOrigin: WebsiteSource_EventOrigin;
  /** the url.searchParams passed to the underlying internal service */
  proxyQueryMap: { [key: string]: string };
  screen?: WebsiteSource_Screen | undefined;
  language: string[];
}

export enum WebsiteSource_EventOrigin {
  UNSPECIFIED = 0,
  SERVER = 1,
  CLIENT = 2,
  UNRECOGNIZED = -1,
}

export function websiteSource_EventOriginFromJSON(object: any): WebsiteSource_EventOrigin {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return WebsiteSource_EventOrigin.UNSPECIFIED;
    case 1:
    case "SERVER":
      return WebsiteSource_EventOrigin.SERVER;
    case 2:
    case "CLIENT":
      return WebsiteSource_EventOrigin.CLIENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WebsiteSource_EventOrigin.UNRECOGNIZED;
  }
}

export function websiteSource_EventOriginToJSON(object: WebsiteSource_EventOrigin): string {
  switch (object) {
    case WebsiteSource_EventOrigin.UNSPECIFIED:
      return "UNSPECIFIED";
    case WebsiteSource_EventOrigin.SERVER:
      return "SERVER";
    case WebsiteSource_EventOrigin.CLIENT:
      return "CLIENT";
    case WebsiteSource_EventOrigin.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface WebsiteSource_Screen {
  density: number;
  width: number;
  height: number;
}

export interface WebsiteSource_QueryMapEntry {
  key: string;
  value: string;
}

export interface WebsiteSource_ProxyQueryMapEntry {
  key: string;
  value: string;
}

/** Next ID: 13 */
export interface EdgeSource {
  url: string;
  userAgent?: string | undefined;
  ip?: string | undefined;
  referrer?:
    | string
    | undefined;
  /** the url.pathname passed to the underlying internal service */
  proxyPath?:
    | string
    | undefined;
  /** request.url.pathname */
  pathname?:
    | string
    | undefined;
  /** request.url.search ({ 'x': '5', 'y': '3', 'z': 'a,b' }) */
  queryMap: { [key: string]: string };
  /** request.url.host */
  host?:
    | string
    | undefined;
  /** the url.searchParams passed to the underlying internal service */
  proxyQueryMap: { [key: string]: string };
  /** where the event is coming from (mostly useful for debugging page view events) */
  eventOrigin: EdgeSource_EventOrigin;
  market?: string | undefined;
  language: string[];
}

export enum EdgeSource_EventOrigin {
  SERVER = 0,
  UNRECOGNIZED = -1,
}

export function edgeSource_EventOriginFromJSON(object: any): EdgeSource_EventOrigin {
  switch (object) {
    case 0:
    case "SERVER":
      return EdgeSource_EventOrigin.SERVER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EdgeSource_EventOrigin.UNRECOGNIZED;
  }
}

export function edgeSource_EventOriginToJSON(object: EdgeSource_EventOrigin): string {
  switch (object) {
    case EdgeSource_EventOrigin.SERVER:
      return "SERVER";
    case EdgeSource_EventOrigin.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface EdgeSource_QueryMapEntry {
  key: string;
  value: string;
}

export interface EdgeSource_ProxyQueryMapEntry {
  key: string;
  value: string;
}

/** Next ID: 22 */
export interface MobileSource {
  userAgent?: string | undefined;
  ip?:
    | string
    | undefined;
  /** App info */
  appName: string;
  appBuild?: string | undefined;
  appVersion?: string | undefined;
  appNamespace?:
    | string
    | undefined;
  /** Device info */
  deviceId: string;
  deviceType?: string | undefined;
  deviceModel?: string | undefined;
  deviceManufacturer?: string | undefined;
  deviceName?: string | undefined;
  advertisingId?: string | undefined;
  adTrackingEnabled?: boolean | undefined;
  notificationPermissionStatus?:
    | string
    | undefined;
  /** Network info */
  carrier?:
    | string
    | undefined;
  /** OS info */
  osName?: string | undefined;
  osVersion?:
    | string
    | undefined;
  /**
   * Screen info
   * using context.screen.XXX to keep consistency with WebsiteSource's convention
   */
  screenDensity?:
    | number
    | undefined;
  /** In pixels */
  screenHeight?:
    | number
    | undefined;
  /** In pixels */
  screenWidth?:
    | number
    | undefined;
  /** Route info */
  focusRouteName: string;
  /** where the event is coming from (mostly useful for debugging page view events) */
  eventOrigin: MobileSource_EventOrigin;
}

export enum MobileSource_EventOrigin {
  APP = 0,
  UNRECOGNIZED = -1,
}

export function mobileSource_EventOriginFromJSON(object: any): MobileSource_EventOrigin {
  switch (object) {
    case 0:
    case "APP":
      return MobileSource_EventOrigin.APP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MobileSource_EventOrigin.UNRECOGNIZED;
  }
}

export function mobileSource_EventOriginToJSON(object: MobileSource_EventOrigin): string {
  switch (object) {
    case MobileSource_EventOrigin.APP:
      return "APP";
    case MobileSource_EventOrigin.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Next ID: 4 */
export interface BackendSource {
  name: string;
  /**
   * client's user_agent and IP if the event originates from FE and routed by a BE service
   * should be nil if the event originates from BE
   */
  clientUserAgent?: string | undefined;
  clientIp?: string | undefined;
}

/** Next ID: 2 */
export interface SmsSource {
  name: string;
}

/** Next ID: 2 */
export interface BackfillSource {
  eventOrigin: BackfillSource_EventOrigin;
}

export enum BackfillSource_EventOrigin {
  UNSPECIFIED = 0,
  SNOWFLAKE = 1,
  UNRECOGNIZED = -1,
}

export function backfillSource_EventOriginFromJSON(object: any): BackfillSource_EventOrigin {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return BackfillSource_EventOrigin.UNSPECIFIED;
    case 1:
    case "SNOWFLAKE":
      return BackfillSource_EventOrigin.SNOWFLAKE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BackfillSource_EventOrigin.UNRECOGNIZED;
  }
}

export function backfillSource_EventOriginToJSON(object: BackfillSource_EventOrigin): string {
  switch (object) {
    case BackfillSource_EventOrigin.UNSPECIFIED:
      return "UNSPECIFIED";
    case BackfillSource_EventOrigin.SNOWFLAKE:
      return "SNOWFLAKE";
    case BackfillSource_EventOrigin.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseWebsiteSource(): WebsiteSource {
  return {
    url: "",
    userAgent: undefined,
    ip: undefined,
    referrer: undefined,
    proxyPath: undefined,
    pathname: undefined,
    queryMap: {},
    host: undefined,
    eventOrigin: 0,
    proxyQueryMap: {},
    screen: undefined,
    language: [],
  };
}

export const WebsiteSource = {
  fromJSON(object: any): WebsiteSource {
    return {
      url: isSet(object.url) ? String(object.url) : "",
      userAgent: isSet(object.userAgent) ? String(object.userAgent) : undefined,
      ip: isSet(object.ip) ? String(object.ip) : undefined,
      referrer: isSet(object.referrer) ? String(object.referrer) : undefined,
      proxyPath: isSet(object.proxyPath) ? String(object.proxyPath) : undefined,
      pathname: isSet(object.pathname) ? String(object.pathname) : undefined,
      queryMap: isObject(object.queryMap)
        ? Object.entries(object.queryMap).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      host: isSet(object.host) ? String(object.host) : undefined,
      eventOrigin: isSet(object.eventOrigin) ? websiteSource_EventOriginFromJSON(object.eventOrigin) : 0,
      proxyQueryMap: isObject(object.proxyQueryMap)
        ? Object.entries(object.proxyQueryMap).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      screen: isSet(object.screen) ? WebsiteSource_Screen.fromJSON(object.screen) : undefined,
      language: Array.isArray(object?.language)
        ? object.language.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: WebsiteSource): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.userAgent !== undefined) {
      obj.userAgent = message.userAgent;
    }
    if (message.ip !== undefined) {
      obj.ip = message.ip;
    }
    if (message.referrer !== undefined) {
      obj.referrer = message.referrer;
    }
    if (message.proxyPath !== undefined) {
      obj.proxyPath = message.proxyPath;
    }
    if (message.pathname !== undefined) {
      obj.pathname = message.pathname;
    }
    if (message.queryMap) {
      const entries = Object.entries(message.queryMap);
      if (entries.length > 0) {
        obj.queryMap = {};
        entries.forEach(([k, v]) => {
          obj.queryMap[k] = v;
        });
      }
    }
    if (message.host !== undefined) {
      obj.host = message.host;
    }
    if (message.eventOrigin !== 0) {
      obj.eventOrigin = websiteSource_EventOriginToJSON(message.eventOrigin);
    }
    if (message.proxyQueryMap) {
      const entries = Object.entries(message.proxyQueryMap);
      if (entries.length > 0) {
        obj.proxyQueryMap = {};
        entries.forEach(([k, v]) => {
          obj.proxyQueryMap[k] = v;
        });
      }
    }
    if (message.screen !== undefined) {
      obj.screen = WebsiteSource_Screen.toJSON(message.screen);
    }
    if (message.language?.length) {
      obj.language = message.language;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebsiteSource>, I>>(base?: I): WebsiteSource {
    return WebsiteSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WebsiteSource>, I>>(object: I): WebsiteSource {
    const message = createBaseWebsiteSource();
    message.url = object.url ?? "";
    message.userAgent = object.userAgent ?? undefined;
    message.ip = object.ip ?? undefined;
    message.referrer = object.referrer ?? undefined;
    message.proxyPath = object.proxyPath ?? undefined;
    message.pathname = object.pathname ?? undefined;
    message.queryMap = Object.entries(object.queryMap ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.host = object.host ?? undefined;
    message.eventOrigin = object.eventOrigin ?? 0;
    message.proxyQueryMap = Object.entries(object.proxyQueryMap ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    message.screen = (object.screen !== undefined && object.screen !== null)
      ? WebsiteSource_Screen.fromPartial(object.screen)
      : undefined;
    message.language = object.language?.map((e) => e) || [];
    return message;
  },
};

function createBaseWebsiteSource_Screen(): WebsiteSource_Screen {
  return { density: 0, width: 0, height: 0 };
}

export const WebsiteSource_Screen = {
  fromJSON(object: any): WebsiteSource_Screen {
    return {
      density: isSet(object.density) ? Number(object.density) : 0,
      width: isSet(object.width) ? Number(object.width) : 0,
      height: isSet(object.height) ? Number(object.height) : 0,
    };
  },

  toJSON(message: WebsiteSource_Screen): unknown {
    const obj: any = {};
    if (message.density !== 0) {
      obj.density = Math.round(message.density);
    }
    if (message.width !== 0) {
      obj.width = Math.round(message.width);
    }
    if (message.height !== 0) {
      obj.height = Math.round(message.height);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebsiteSource_Screen>, I>>(base?: I): WebsiteSource_Screen {
    return WebsiteSource_Screen.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WebsiteSource_Screen>, I>>(object: I): WebsiteSource_Screen {
    const message = createBaseWebsiteSource_Screen();
    message.density = object.density ?? 0;
    message.width = object.width ?? 0;
    message.height = object.height ?? 0;
    return message;
  },
};

function createBaseWebsiteSource_QueryMapEntry(): WebsiteSource_QueryMapEntry {
  return { key: "", value: "" };
}

export const WebsiteSource_QueryMapEntry = {
  fromJSON(object: any): WebsiteSource_QueryMapEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: WebsiteSource_QueryMapEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebsiteSource_QueryMapEntry>, I>>(base?: I): WebsiteSource_QueryMapEntry {
    return WebsiteSource_QueryMapEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WebsiteSource_QueryMapEntry>, I>>(object: I): WebsiteSource_QueryMapEntry {
    const message = createBaseWebsiteSource_QueryMapEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseWebsiteSource_ProxyQueryMapEntry(): WebsiteSource_ProxyQueryMapEntry {
  return { key: "", value: "" };
}

export const WebsiteSource_ProxyQueryMapEntry = {
  fromJSON(object: any): WebsiteSource_ProxyQueryMapEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: WebsiteSource_ProxyQueryMapEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebsiteSource_ProxyQueryMapEntry>, I>>(
    base?: I,
  ): WebsiteSource_ProxyQueryMapEntry {
    return WebsiteSource_ProxyQueryMapEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WebsiteSource_ProxyQueryMapEntry>, I>>(
    object: I,
  ): WebsiteSource_ProxyQueryMapEntry {
    const message = createBaseWebsiteSource_ProxyQueryMapEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseEdgeSource(): EdgeSource {
  return {
    url: "",
    userAgent: undefined,
    ip: undefined,
    referrer: undefined,
    proxyPath: undefined,
    pathname: undefined,
    queryMap: {},
    host: undefined,
    proxyQueryMap: {},
    eventOrigin: 0,
    market: undefined,
    language: [],
  };
}

export const EdgeSource = {
  fromJSON(object: any): EdgeSource {
    return {
      url: isSet(object.url) ? String(object.url) : "",
      userAgent: isSet(object.userAgent) ? String(object.userAgent) : undefined,
      ip: isSet(object.ip) ? String(object.ip) : undefined,
      referrer: isSet(object.referrer) ? String(object.referrer) : undefined,
      proxyPath: isSet(object.proxyPath) ? String(object.proxyPath) : undefined,
      pathname: isSet(object.pathname) ? String(object.pathname) : undefined,
      queryMap: isObject(object.queryMap)
        ? Object.entries(object.queryMap).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      host: isSet(object.host) ? String(object.host) : undefined,
      proxyQueryMap: isObject(object.proxyQueryMap)
        ? Object.entries(object.proxyQueryMap).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      eventOrigin: isSet(object.eventOrigin) ? edgeSource_EventOriginFromJSON(object.eventOrigin) : 0,
      market: isSet(object.market) ? String(object.market) : undefined,
      language: Array.isArray(object?.language)
        ? object.language.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: EdgeSource): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.userAgent !== undefined) {
      obj.userAgent = message.userAgent;
    }
    if (message.ip !== undefined) {
      obj.ip = message.ip;
    }
    if (message.referrer !== undefined) {
      obj.referrer = message.referrer;
    }
    if (message.proxyPath !== undefined) {
      obj.proxyPath = message.proxyPath;
    }
    if (message.pathname !== undefined) {
      obj.pathname = message.pathname;
    }
    if (message.queryMap) {
      const entries = Object.entries(message.queryMap);
      if (entries.length > 0) {
        obj.queryMap = {};
        entries.forEach(([k, v]) => {
          obj.queryMap[k] = v;
        });
      }
    }
    if (message.host !== undefined) {
      obj.host = message.host;
    }
    if (message.proxyQueryMap) {
      const entries = Object.entries(message.proxyQueryMap);
      if (entries.length > 0) {
        obj.proxyQueryMap = {};
        entries.forEach(([k, v]) => {
          obj.proxyQueryMap[k] = v;
        });
      }
    }
    if (message.eventOrigin !== 0) {
      obj.eventOrigin = edgeSource_EventOriginToJSON(message.eventOrigin);
    }
    if (message.market !== undefined) {
      obj.market = message.market;
    }
    if (message.language?.length) {
      obj.language = message.language;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EdgeSource>, I>>(base?: I): EdgeSource {
    return EdgeSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EdgeSource>, I>>(object: I): EdgeSource {
    const message = createBaseEdgeSource();
    message.url = object.url ?? "";
    message.userAgent = object.userAgent ?? undefined;
    message.ip = object.ip ?? undefined;
    message.referrer = object.referrer ?? undefined;
    message.proxyPath = object.proxyPath ?? undefined;
    message.pathname = object.pathname ?? undefined;
    message.queryMap = Object.entries(object.queryMap ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.host = object.host ?? undefined;
    message.proxyQueryMap = Object.entries(object.proxyQueryMap ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    message.eventOrigin = object.eventOrigin ?? 0;
    message.market = object.market ?? undefined;
    message.language = object.language?.map((e) => e) || [];
    return message;
  },
};

function createBaseEdgeSource_QueryMapEntry(): EdgeSource_QueryMapEntry {
  return { key: "", value: "" };
}

export const EdgeSource_QueryMapEntry = {
  fromJSON(object: any): EdgeSource_QueryMapEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: EdgeSource_QueryMapEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EdgeSource_QueryMapEntry>, I>>(base?: I): EdgeSource_QueryMapEntry {
    return EdgeSource_QueryMapEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EdgeSource_QueryMapEntry>, I>>(object: I): EdgeSource_QueryMapEntry {
    const message = createBaseEdgeSource_QueryMapEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseEdgeSource_ProxyQueryMapEntry(): EdgeSource_ProxyQueryMapEntry {
  return { key: "", value: "" };
}

export const EdgeSource_ProxyQueryMapEntry = {
  fromJSON(object: any): EdgeSource_ProxyQueryMapEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: EdgeSource_ProxyQueryMapEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EdgeSource_ProxyQueryMapEntry>, I>>(base?: I): EdgeSource_ProxyQueryMapEntry {
    return EdgeSource_ProxyQueryMapEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EdgeSource_ProxyQueryMapEntry>, I>>(
    object: I,
  ): EdgeSource_ProxyQueryMapEntry {
    const message = createBaseEdgeSource_ProxyQueryMapEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseMobileSource(): MobileSource {
  return {
    userAgent: undefined,
    ip: undefined,
    appName: "",
    appBuild: undefined,
    appVersion: undefined,
    appNamespace: undefined,
    deviceId: "",
    deviceType: undefined,
    deviceModel: undefined,
    deviceManufacturer: undefined,
    deviceName: undefined,
    advertisingId: undefined,
    adTrackingEnabled: undefined,
    notificationPermissionStatus: undefined,
    carrier: undefined,
    osName: undefined,
    osVersion: undefined,
    screenDensity: undefined,
    screenHeight: undefined,
    screenWidth: undefined,
    focusRouteName: "",
    eventOrigin: 0,
  };
}

export const MobileSource = {
  fromJSON(object: any): MobileSource {
    return {
      userAgent: isSet(object.userAgent) ? String(object.userAgent) : undefined,
      ip: isSet(object.ip) ? String(object.ip) : undefined,
      appName: isSet(object.appName) ? String(object.appName) : "",
      appBuild: isSet(object.appBuild) ? String(object.appBuild) : undefined,
      appVersion: isSet(object.appVersion) ? String(object.appVersion) : undefined,
      appNamespace: isSet(object.appNamespace) ? String(object.appNamespace) : undefined,
      deviceId: isSet(object.deviceId) ? String(object.deviceId) : "",
      deviceType: isSet(object.deviceType) ? String(object.deviceType) : undefined,
      deviceModel: isSet(object.deviceModel) ? String(object.deviceModel) : undefined,
      deviceManufacturer: isSet(object.deviceManufacturer) ? String(object.deviceManufacturer) : undefined,
      deviceName: isSet(object.deviceName) ? String(object.deviceName) : undefined,
      advertisingId: isSet(object.advertisingId) ? String(object.advertisingId) : undefined,
      adTrackingEnabled: isSet(object.adTrackingEnabled) ? Boolean(object.adTrackingEnabled) : undefined,
      notificationPermissionStatus: isSet(object.notificationPermissionStatus)
        ? String(object.notificationPermissionStatus)
        : undefined,
      carrier: isSet(object.carrier) ? String(object.carrier) : undefined,
      osName: isSet(object.osName) ? String(object.osName) : undefined,
      osVersion: isSet(object.osVersion) ? String(object.osVersion) : undefined,
      screenDensity: isSet(object.screenDensity) ? Number(object.screenDensity) : undefined,
      screenHeight: isSet(object.screenHeight) ? Number(object.screenHeight) : undefined,
      screenWidth: isSet(object.screenWidth) ? Number(object.screenWidth) : undefined,
      focusRouteName: isSet(object.focusRouteName) ? String(object.focusRouteName) : "",
      eventOrigin: isSet(object.eventOrigin) ? mobileSource_EventOriginFromJSON(object.eventOrigin) : 0,
    };
  },

  toJSON(message: MobileSource): unknown {
    const obj: any = {};
    if (message.userAgent !== undefined) {
      obj.userAgent = message.userAgent;
    }
    if (message.ip !== undefined) {
      obj.ip = message.ip;
    }
    if (message.appName !== "") {
      obj.appName = message.appName;
    }
    if (message.appBuild !== undefined) {
      obj.appBuild = message.appBuild;
    }
    if (message.appVersion !== undefined) {
      obj.appVersion = message.appVersion;
    }
    if (message.appNamespace !== undefined) {
      obj.appNamespace = message.appNamespace;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.deviceType !== undefined) {
      obj.deviceType = message.deviceType;
    }
    if (message.deviceModel !== undefined) {
      obj.deviceModel = message.deviceModel;
    }
    if (message.deviceManufacturer !== undefined) {
      obj.deviceManufacturer = message.deviceManufacturer;
    }
    if (message.deviceName !== undefined) {
      obj.deviceName = message.deviceName;
    }
    if (message.advertisingId !== undefined) {
      obj.advertisingId = message.advertisingId;
    }
    if (message.adTrackingEnabled !== undefined) {
      obj.adTrackingEnabled = message.adTrackingEnabled;
    }
    if (message.notificationPermissionStatus !== undefined) {
      obj.notificationPermissionStatus = message.notificationPermissionStatus;
    }
    if (message.carrier !== undefined) {
      obj.carrier = message.carrier;
    }
    if (message.osName !== undefined) {
      obj.osName = message.osName;
    }
    if (message.osVersion !== undefined) {
      obj.osVersion = message.osVersion;
    }
    if (message.screenDensity !== undefined) {
      obj.screenDensity = message.screenDensity;
    }
    if (message.screenHeight !== undefined) {
      obj.screenHeight = message.screenHeight;
    }
    if (message.screenWidth !== undefined) {
      obj.screenWidth = message.screenWidth;
    }
    if (message.focusRouteName !== "") {
      obj.focusRouteName = message.focusRouteName;
    }
    if (message.eventOrigin !== 0) {
      obj.eventOrigin = mobileSource_EventOriginToJSON(message.eventOrigin);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MobileSource>, I>>(base?: I): MobileSource {
    return MobileSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MobileSource>, I>>(object: I): MobileSource {
    const message = createBaseMobileSource();
    message.userAgent = object.userAgent ?? undefined;
    message.ip = object.ip ?? undefined;
    message.appName = object.appName ?? "";
    message.appBuild = object.appBuild ?? undefined;
    message.appVersion = object.appVersion ?? undefined;
    message.appNamespace = object.appNamespace ?? undefined;
    message.deviceId = object.deviceId ?? "";
    message.deviceType = object.deviceType ?? undefined;
    message.deviceModel = object.deviceModel ?? undefined;
    message.deviceManufacturer = object.deviceManufacturer ?? undefined;
    message.deviceName = object.deviceName ?? undefined;
    message.advertisingId = object.advertisingId ?? undefined;
    message.adTrackingEnabled = object.adTrackingEnabled ?? undefined;
    message.notificationPermissionStatus = object.notificationPermissionStatus ?? undefined;
    message.carrier = object.carrier ?? undefined;
    message.osName = object.osName ?? undefined;
    message.osVersion = object.osVersion ?? undefined;
    message.screenDensity = object.screenDensity ?? undefined;
    message.screenHeight = object.screenHeight ?? undefined;
    message.screenWidth = object.screenWidth ?? undefined;
    message.focusRouteName = object.focusRouteName ?? "";
    message.eventOrigin = object.eventOrigin ?? 0;
    return message;
  },
};

function createBaseBackendSource(): BackendSource {
  return { name: "", clientUserAgent: undefined, clientIp: undefined };
}

export const BackendSource = {
  fromJSON(object: any): BackendSource {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      clientUserAgent: isSet(object.clientUserAgent) ? String(object.clientUserAgent) : undefined,
      clientIp: isSet(object.clientIp) ? String(object.clientIp) : undefined,
    };
  },

  toJSON(message: BackendSource): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.clientUserAgent !== undefined) {
      obj.clientUserAgent = message.clientUserAgent;
    }
    if (message.clientIp !== undefined) {
      obj.clientIp = message.clientIp;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BackendSource>, I>>(base?: I): BackendSource {
    return BackendSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BackendSource>, I>>(object: I): BackendSource {
    const message = createBaseBackendSource();
    message.name = object.name ?? "";
    message.clientUserAgent = object.clientUserAgent ?? undefined;
    message.clientIp = object.clientIp ?? undefined;
    return message;
  },
};

function createBaseSmsSource(): SmsSource {
  return { name: "" };
}

export const SmsSource = {
  fromJSON(object: any): SmsSource {
    return { name: isSet(object.name) ? String(object.name) : "" };
  },

  toJSON(message: SmsSource): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SmsSource>, I>>(base?: I): SmsSource {
    return SmsSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SmsSource>, I>>(object: I): SmsSource {
    const message = createBaseSmsSource();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseBackfillSource(): BackfillSource {
  return { eventOrigin: 0 };
}

export const BackfillSource = {
  fromJSON(object: any): BackfillSource {
    return { eventOrigin: isSet(object.eventOrigin) ? backfillSource_EventOriginFromJSON(object.eventOrigin) : 0 };
  },

  toJSON(message: BackfillSource): unknown {
    const obj: any = {};
    if (message.eventOrigin !== 0) {
      obj.eventOrigin = backfillSource_EventOriginToJSON(message.eventOrigin);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BackfillSource>, I>>(base?: I): BackfillSource {
    return BackfillSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BackfillSource>, I>>(object: I): BackfillSource {
    const message = createBaseBackfillSource();
    message.eventOrigin = object.eventOrigin ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
