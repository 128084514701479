/* eslint-disable */
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "od_protos.listings.data";

export interface Listing {
  id: string;
  state: Listing_State;
  addressId: string;
  marketId: string;
  customerId: string;
  agent?: Listing_Agent | undefined;
  source?: Listing_Source | undefined;
  listingPrice: number;
  contractPrice: number;
  soldPrice: number;
  grossCommission: number;
  timestamps: Listing_Timestamp[];
  brokerageId: string;
}

export enum Listing_State {
  UNKNOWN = 0,
  AGENT_ASSIGNMENT = 1,
  LEAD_TERMINATED = 2,
  OWNER_LIST_DECISION = 4,
  NOT_LISTED = 5,
  LISTING_AGREEMENT_SIGNED = 6,
  LISTING_ACTIVE = 7,
  LISTING_TERMINATED = 8,
  IN_CONTRACT = 9,
  SOLD = 10,
  UNRECOGNIZED = -1,
}

export function listing_StateFromJSON(object: any): Listing_State {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return Listing_State.UNKNOWN;
    case 1:
    case "AGENT_ASSIGNMENT":
      return Listing_State.AGENT_ASSIGNMENT;
    case 2:
    case "LEAD_TERMINATED":
      return Listing_State.LEAD_TERMINATED;
    case 4:
    case "OWNER_LIST_DECISION":
      return Listing_State.OWNER_LIST_DECISION;
    case 5:
    case "NOT_LISTED":
      return Listing_State.NOT_LISTED;
    case 6:
    case "LISTING_AGREEMENT_SIGNED":
      return Listing_State.LISTING_AGREEMENT_SIGNED;
    case 7:
    case "LISTING_ACTIVE":
      return Listing_State.LISTING_ACTIVE;
    case 8:
    case "LISTING_TERMINATED":
      return Listing_State.LISTING_TERMINATED;
    case 9:
    case "IN_CONTRACT":
      return Listing_State.IN_CONTRACT;
    case 10:
    case "SOLD":
      return Listing_State.SOLD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Listing_State.UNRECOGNIZED;
  }
}

export function listing_StateToJSON(object: Listing_State): string {
  switch (object) {
    case Listing_State.UNKNOWN:
      return "UNKNOWN";
    case Listing_State.AGENT_ASSIGNMENT:
      return "AGENT_ASSIGNMENT";
    case Listing_State.LEAD_TERMINATED:
      return "LEAD_TERMINATED";
    case Listing_State.OWNER_LIST_DECISION:
      return "OWNER_LIST_DECISION";
    case Listing_State.NOT_LISTED:
      return "NOT_LISTED";
    case Listing_State.LISTING_AGREEMENT_SIGNED:
      return "LISTING_AGREEMENT_SIGNED";
    case Listing_State.LISTING_ACTIVE:
      return "LISTING_ACTIVE";
    case Listing_State.LISTING_TERMINATED:
      return "LISTING_TERMINATED";
    case Listing_State.IN_CONTRACT:
      return "IN_CONTRACT";
    case Listing_State.SOLD:
      return "SOLD";
    case Listing_State.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Listing_Agent {
  leadId: string;
  id: string;
}

export interface Listing_Source {
  type: Listing_Source_Type;
  id: string;
}

export enum Listing_Source_Type {
  UNKNOWN = 0,
  LIST_WITH_CERTAINTY = 1,
  PARN = 2,
  UNRECOGNIZED = -1,
}

export function listing_Source_TypeFromJSON(object: any): Listing_Source_Type {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return Listing_Source_Type.UNKNOWN;
    case 1:
    case "LIST_WITH_CERTAINTY":
      return Listing_Source_Type.LIST_WITH_CERTAINTY;
    case 2:
    case "PARN":
      return Listing_Source_Type.PARN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Listing_Source_Type.UNRECOGNIZED;
  }
}

export function listing_Source_TypeToJSON(object: Listing_Source_Type): string {
  switch (object) {
    case Listing_Source_Type.UNKNOWN:
      return "UNKNOWN";
    case Listing_Source_Type.LIST_WITH_CERTAINTY:
      return "LIST_WITH_CERTAINTY";
    case Listing_Source_Type.PARN:
      return "PARN";
    case Listing_Source_Type.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Listing_Timestamp {
  state: Listing_State;
  timestamp?: Date | undefined;
}

function createBaseListing(): Listing {
  return {
    id: "",
    state: 0,
    addressId: "",
    marketId: "",
    customerId: "",
    agent: undefined,
    source: undefined,
    listingPrice: 0,
    contractPrice: 0,
    soldPrice: 0,
    grossCommission: 0,
    timestamps: [],
    brokerageId: "",
  };
}

export const Listing = {
  fromJSON(object: any): Listing {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      state: isSet(object.state) ? listing_StateFromJSON(object.state) : 0,
      addressId: isSet(object.addressId) ? String(object.addressId) : "",
      marketId: isSet(object.marketId) ? String(object.marketId) : "",
      customerId: isSet(object.customerId) ? String(object.customerId) : "",
      agent: isSet(object.agent) ? Listing_Agent.fromJSON(object.agent) : undefined,
      source: isSet(object.source) ? Listing_Source.fromJSON(object.source) : undefined,
      listingPrice: isSet(object.listingPrice) ? Number(object.listingPrice) : 0,
      contractPrice: isSet(object.contractPrice) ? Number(object.contractPrice) : 0,
      soldPrice: isSet(object.soldPrice) ? Number(object.soldPrice) : 0,
      grossCommission: isSet(object.grossCommission) ? Number(object.grossCommission) : 0,
      timestamps: Array.isArray(object?.timestamps)
        ? object.timestamps.map((e: any) => Listing_Timestamp.fromJSON(e))
        : [],
      brokerageId: isSet(object.brokerageId) ? String(object.brokerageId) : "",
    };
  },

  toJSON(message: Listing): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.state !== 0) {
      obj.state = listing_StateToJSON(message.state);
    }
    if (message.addressId !== "") {
      obj.addressId = message.addressId;
    }
    if (message.marketId !== "") {
      obj.marketId = message.marketId;
    }
    if (message.customerId !== "") {
      obj.customerId = message.customerId;
    }
    if (message.agent !== undefined) {
      obj.agent = Listing_Agent.toJSON(message.agent);
    }
    if (message.source !== undefined) {
      obj.source = Listing_Source.toJSON(message.source);
    }
    if (message.listingPrice !== 0) {
      obj.listingPrice = Math.round(message.listingPrice);
    }
    if (message.contractPrice !== 0) {
      obj.contractPrice = Math.round(message.contractPrice);
    }
    if (message.soldPrice !== 0) {
      obj.soldPrice = Math.round(message.soldPrice);
    }
    if (message.grossCommission !== 0) {
      obj.grossCommission = Math.round(message.grossCommission);
    }
    if (message.timestamps?.length) {
      obj.timestamps = message.timestamps.map((e) => Listing_Timestamp.toJSON(e));
    }
    if (message.brokerageId !== "") {
      obj.brokerageId = message.brokerageId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Listing>, I>>(base?: I): Listing {
    return Listing.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Listing>, I>>(object: I): Listing {
    const message = createBaseListing();
    message.id = object.id ?? "";
    message.state = object.state ?? 0;
    message.addressId = object.addressId ?? "";
    message.marketId = object.marketId ?? "";
    message.customerId = object.customerId ?? "";
    message.agent = (object.agent !== undefined && object.agent !== null)
      ? Listing_Agent.fromPartial(object.agent)
      : undefined;
    message.source = (object.source !== undefined && object.source !== null)
      ? Listing_Source.fromPartial(object.source)
      : undefined;
    message.listingPrice = object.listingPrice ?? 0;
    message.contractPrice = object.contractPrice ?? 0;
    message.soldPrice = object.soldPrice ?? 0;
    message.grossCommission = object.grossCommission ?? 0;
    message.timestamps = object.timestamps?.map((e) => Listing_Timestamp.fromPartial(e)) || [];
    message.brokerageId = object.brokerageId ?? "";
    return message;
  },
};

function createBaseListing_Agent(): Listing_Agent {
  return { leadId: "", id: "" };
}

export const Listing_Agent = {
  fromJSON(object: any): Listing_Agent {
    return { leadId: isSet(object.leadId) ? String(object.leadId) : "", id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: Listing_Agent): unknown {
    const obj: any = {};
    if (message.leadId !== "") {
      obj.leadId = message.leadId;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Listing_Agent>, I>>(base?: I): Listing_Agent {
    return Listing_Agent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Listing_Agent>, I>>(object: I): Listing_Agent {
    const message = createBaseListing_Agent();
    message.leadId = object.leadId ?? "";
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseListing_Source(): Listing_Source {
  return { type: 0, id: "" };
}

export const Listing_Source = {
  fromJSON(object: any): Listing_Source {
    return {
      type: isSet(object.type) ? listing_Source_TypeFromJSON(object.type) : 0,
      id: isSet(object.id) ? String(object.id) : "",
    };
  },

  toJSON(message: Listing_Source): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = listing_Source_TypeToJSON(message.type);
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Listing_Source>, I>>(base?: I): Listing_Source {
    return Listing_Source.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Listing_Source>, I>>(object: I): Listing_Source {
    const message = createBaseListing_Source();
    message.type = object.type ?? 0;
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseListing_Timestamp(): Listing_Timestamp {
  return { state: 0, timestamp: undefined };
}

export const Listing_Timestamp = {
  fromJSON(object: any): Listing_Timestamp {
    return {
      state: isSet(object.state) ? listing_StateFromJSON(object.state) : 0,
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
    };
  },

  toJSON(message: Listing_Timestamp): unknown {
    const obj: any = {};
    if (message.state !== 0) {
      obj.state = listing_StateToJSON(message.state);
    }
    if (message.timestamp !== undefined) {
      obj.timestamp = message.timestamp.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Listing_Timestamp>, I>>(base?: I): Listing_Timestamp {
    return Listing_Timestamp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Listing_Timestamp>, I>>(object: I): Listing_Timestamp {
    const message = createBaseListing_Timestamp();
    message.state = object.state ?? 0;
    message.timestamp = object.timestamp ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
