/* eslint-disable */
import { Actor, actorFromJSON, actorToJSON } from "../../../customer/v1/data/comm_preferences";

export const protobufPackage = "od_protos.journey.data.categories";

/** Next ID: 3 */
export interface CommunicationCategory {
  event?:
    | { $case: "smsReceived"; smsReceived: CommunicationCategory_SmsReceivedEvent }
    | { $case: "smsSent"; smsSent: CommunicationCategory_SmsSentEvent }
    | { $case: "subscriptionUpdated"; subscriptionUpdated: CommunicationCategory_SubscriptionUpdatedEvent }
    | undefined;
}

/** when a customer receives an sms from opendoor */
export interface CommunicationCategory_SmsReceivedEvent {
  fromPhoneNumber: string;
  toPhoneNumber: string;
  conversationId: string;
  messageSid: string;
  content: string;
}

/** when a customer sends an sms to opendoor */
export interface CommunicationCategory_SmsSentEvent {
  fromPhoneNumber: string;
  toPhoneNumber: string;
  conversationId: string;
  messageSid: string;
  phoneNumberCategory: string;
  content: string;
}

/** when a customer changes their subscriptions to communication channels */
export interface CommunicationCategory_SubscriptionUpdatedEvent {
  actor: Actor;
  /**
   * map of feed name to whether the customer is subscribed to that feed
   * format: medium.feed_name -> is_subscribed
   */
  subscriptions: { [key: string]: boolean };
}

export interface CommunicationCategory_SubscriptionUpdatedEvent_SubscriptionsEntry {
  key: string;
  value: boolean;
}

function createBaseCommunicationCategory(): CommunicationCategory {
  return { event: undefined };
}

export const CommunicationCategory = {
  fromJSON(object: any): CommunicationCategory {
    return {
      event: isSet(object.smsReceived)
        ? { $case: "smsReceived", smsReceived: CommunicationCategory_SmsReceivedEvent.fromJSON(object.smsReceived) }
        : isSet(object.smsSent)
        ? { $case: "smsSent", smsSent: CommunicationCategory_SmsSentEvent.fromJSON(object.smsSent) }
        : isSet(object.subscriptionUpdated)
        ? {
          $case: "subscriptionUpdated",
          subscriptionUpdated: CommunicationCategory_SubscriptionUpdatedEvent.fromJSON(object.subscriptionUpdated),
        }
        : undefined,
    };
  },

  toJSON(message: CommunicationCategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "smsReceived") {
      obj.smsReceived = CommunicationCategory_SmsReceivedEvent.toJSON(message.event.smsReceived);
    }
    if (message.event?.$case === "smsSent") {
      obj.smsSent = CommunicationCategory_SmsSentEvent.toJSON(message.event.smsSent);
    }
    if (message.event?.$case === "subscriptionUpdated") {
      obj.subscriptionUpdated = CommunicationCategory_SubscriptionUpdatedEvent.toJSON(
        message.event.subscriptionUpdated,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommunicationCategory>, I>>(base?: I): CommunicationCategory {
    return CommunicationCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommunicationCategory>, I>>(object: I): CommunicationCategory {
    const message = createBaseCommunicationCategory();
    if (
      object.event?.$case === "smsReceived" &&
      object.event?.smsReceived !== undefined &&
      object.event?.smsReceived !== null
    ) {
      message.event = {
        $case: "smsReceived",
        smsReceived: CommunicationCategory_SmsReceivedEvent.fromPartial(object.event.smsReceived),
      };
    }
    if (object.event?.$case === "smsSent" && object.event?.smsSent !== undefined && object.event?.smsSent !== null) {
      message.event = {
        $case: "smsSent",
        smsSent: CommunicationCategory_SmsSentEvent.fromPartial(object.event.smsSent),
      };
    }
    if (
      object.event?.$case === "subscriptionUpdated" &&
      object.event?.subscriptionUpdated !== undefined &&
      object.event?.subscriptionUpdated !== null
    ) {
      message.event = {
        $case: "subscriptionUpdated",
        subscriptionUpdated: CommunicationCategory_SubscriptionUpdatedEvent.fromPartial(
          object.event.subscriptionUpdated,
        ),
      };
    }
    return message;
  },
};

function createBaseCommunicationCategory_SmsReceivedEvent(): CommunicationCategory_SmsReceivedEvent {
  return { fromPhoneNumber: "", toPhoneNumber: "", conversationId: "", messageSid: "", content: "" };
}

export const CommunicationCategory_SmsReceivedEvent = {
  fromJSON(object: any): CommunicationCategory_SmsReceivedEvent {
    return {
      fromPhoneNumber: isSet(object.fromPhoneNumber) ? String(object.fromPhoneNumber) : "",
      toPhoneNumber: isSet(object.toPhoneNumber) ? String(object.toPhoneNumber) : "",
      conversationId: isSet(object.conversationId) ? String(object.conversationId) : "",
      messageSid: isSet(object.messageSid) ? String(object.messageSid) : "",
      content: isSet(object.content) ? String(object.content) : "",
    };
  },

  toJSON(message: CommunicationCategory_SmsReceivedEvent): unknown {
    const obj: any = {};
    if (message.fromPhoneNumber !== "") {
      obj.fromPhoneNumber = message.fromPhoneNumber;
    }
    if (message.toPhoneNumber !== "") {
      obj.toPhoneNumber = message.toPhoneNumber;
    }
    if (message.conversationId !== "") {
      obj.conversationId = message.conversationId;
    }
    if (message.messageSid !== "") {
      obj.messageSid = message.messageSid;
    }
    if (message.content !== "") {
      obj.content = message.content;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommunicationCategory_SmsReceivedEvent>, I>>(
    base?: I,
  ): CommunicationCategory_SmsReceivedEvent {
    return CommunicationCategory_SmsReceivedEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommunicationCategory_SmsReceivedEvent>, I>>(
    object: I,
  ): CommunicationCategory_SmsReceivedEvent {
    const message = createBaseCommunicationCategory_SmsReceivedEvent();
    message.fromPhoneNumber = object.fromPhoneNumber ?? "";
    message.toPhoneNumber = object.toPhoneNumber ?? "";
    message.conversationId = object.conversationId ?? "";
    message.messageSid = object.messageSid ?? "";
    message.content = object.content ?? "";
    return message;
  },
};

function createBaseCommunicationCategory_SmsSentEvent(): CommunicationCategory_SmsSentEvent {
  return {
    fromPhoneNumber: "",
    toPhoneNumber: "",
    conversationId: "",
    messageSid: "",
    phoneNumberCategory: "",
    content: "",
  };
}

export const CommunicationCategory_SmsSentEvent = {
  fromJSON(object: any): CommunicationCategory_SmsSentEvent {
    return {
      fromPhoneNumber: isSet(object.fromPhoneNumber) ? String(object.fromPhoneNumber) : "",
      toPhoneNumber: isSet(object.toPhoneNumber) ? String(object.toPhoneNumber) : "",
      conversationId: isSet(object.conversationId) ? String(object.conversationId) : "",
      messageSid: isSet(object.messageSid) ? String(object.messageSid) : "",
      phoneNumberCategory: isSet(object.phoneNumberCategory) ? String(object.phoneNumberCategory) : "",
      content: isSet(object.content) ? String(object.content) : "",
    };
  },

  toJSON(message: CommunicationCategory_SmsSentEvent): unknown {
    const obj: any = {};
    if (message.fromPhoneNumber !== "") {
      obj.fromPhoneNumber = message.fromPhoneNumber;
    }
    if (message.toPhoneNumber !== "") {
      obj.toPhoneNumber = message.toPhoneNumber;
    }
    if (message.conversationId !== "") {
      obj.conversationId = message.conversationId;
    }
    if (message.messageSid !== "") {
      obj.messageSid = message.messageSid;
    }
    if (message.phoneNumberCategory !== "") {
      obj.phoneNumberCategory = message.phoneNumberCategory;
    }
    if (message.content !== "") {
      obj.content = message.content;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommunicationCategory_SmsSentEvent>, I>>(
    base?: I,
  ): CommunicationCategory_SmsSentEvent {
    return CommunicationCategory_SmsSentEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommunicationCategory_SmsSentEvent>, I>>(
    object: I,
  ): CommunicationCategory_SmsSentEvent {
    const message = createBaseCommunicationCategory_SmsSentEvent();
    message.fromPhoneNumber = object.fromPhoneNumber ?? "";
    message.toPhoneNumber = object.toPhoneNumber ?? "";
    message.conversationId = object.conversationId ?? "";
    message.messageSid = object.messageSid ?? "";
    message.phoneNumberCategory = object.phoneNumberCategory ?? "";
    message.content = object.content ?? "";
    return message;
  },
};

function createBaseCommunicationCategory_SubscriptionUpdatedEvent(): CommunicationCategory_SubscriptionUpdatedEvent {
  return { actor: 0, subscriptions: {} };
}

export const CommunicationCategory_SubscriptionUpdatedEvent = {
  fromJSON(object: any): CommunicationCategory_SubscriptionUpdatedEvent {
    return {
      actor: isSet(object.actor) ? actorFromJSON(object.actor) : 0,
      subscriptions: isObject(object.subscriptions)
        ? Object.entries(object.subscriptions).reduce<{ [key: string]: boolean }>((acc, [key, value]) => {
          acc[key] = Boolean(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: CommunicationCategory_SubscriptionUpdatedEvent): unknown {
    const obj: any = {};
    if (message.actor !== 0) {
      obj.actor = actorToJSON(message.actor);
    }
    if (message.subscriptions) {
      const entries = Object.entries(message.subscriptions);
      if (entries.length > 0) {
        obj.subscriptions = {};
        entries.forEach(([k, v]) => {
          obj.subscriptions[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommunicationCategory_SubscriptionUpdatedEvent>, I>>(
    base?: I,
  ): CommunicationCategory_SubscriptionUpdatedEvent {
    return CommunicationCategory_SubscriptionUpdatedEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommunicationCategory_SubscriptionUpdatedEvent>, I>>(
    object: I,
  ): CommunicationCategory_SubscriptionUpdatedEvent {
    const message = createBaseCommunicationCategory_SubscriptionUpdatedEvent();
    message.actor = object.actor ?? 0;
    message.subscriptions = Object.entries(object.subscriptions ?? {}).reduce<{ [key: string]: boolean }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Boolean(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseCommunicationCategory_SubscriptionUpdatedEvent_SubscriptionsEntry(): CommunicationCategory_SubscriptionUpdatedEvent_SubscriptionsEntry {
  return { key: "", value: false };
}

export const CommunicationCategory_SubscriptionUpdatedEvent_SubscriptionsEntry = {
  fromJSON(object: any): CommunicationCategory_SubscriptionUpdatedEvent_SubscriptionsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Boolean(object.value) : false,
    };
  },

  toJSON(message: CommunicationCategory_SubscriptionUpdatedEvent_SubscriptionsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value === true) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommunicationCategory_SubscriptionUpdatedEvent_SubscriptionsEntry>, I>>(
    base?: I,
  ): CommunicationCategory_SubscriptionUpdatedEvent_SubscriptionsEntry {
    return CommunicationCategory_SubscriptionUpdatedEvent_SubscriptionsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommunicationCategory_SubscriptionUpdatedEvent_SubscriptionsEntry>, I>>(
    object: I,
  ): CommunicationCategory_SubscriptionUpdatedEvent_SubscriptionsEntry {
    const message = createBaseCommunicationCategory_SubscriptionUpdatedEvent_SubscriptionsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
