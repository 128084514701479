/* eslint-disable */
import { Timestamp } from "../../../../google/protobuf/timestamp";

export const protobufPackage = "od_protos.journey.data.categories";

export interface ChatCategory {
  event?: { $case: "interaction"; interaction: ChatCategory_InteractionEvent } | undefined;
}

export interface ChatCategory_InteractionEvent {
  interactionId: string;
  rawUserInput: string;
  phoneNumber: string;
  createdAt?: Date | undefined;
  interactionType: ChatCategory_InteractionEvent_InteractionType;
  source: ChatCategory_InteractionEvent_Source;
  category: string;
}

/** Next ID: 2 */
export enum ChatCategory_InteractionEvent_InteractionType {
  UNKNOWN_INTERACTION_TYPE = 0,
  SMS = 1,
  UNRECOGNIZED = -1,
}

export function chatCategory_InteractionEvent_InteractionTypeFromJSON(
  object: any,
): ChatCategory_InteractionEvent_InteractionType {
  switch (object) {
    case 0:
    case "UNKNOWN_INTERACTION_TYPE":
      return ChatCategory_InteractionEvent_InteractionType.UNKNOWN_INTERACTION_TYPE;
    case 1:
    case "SMS":
      return ChatCategory_InteractionEvent_InteractionType.SMS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ChatCategory_InteractionEvent_InteractionType.UNRECOGNIZED;
  }
}

export function chatCategory_InteractionEvent_InteractionTypeToJSON(
  object: ChatCategory_InteractionEvent_InteractionType,
): string {
  switch (object) {
    case ChatCategory_InteractionEvent_InteractionType.UNKNOWN_INTERACTION_TYPE:
      return "UNKNOWN_INTERACTION_TYPE";
    case ChatCategory_InteractionEvent_InteractionType.SMS:
      return "SMS";
    case ChatCategory_InteractionEvent_InteractionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Next ID: 4 */
export enum ChatCategory_InteractionEvent_Source {
  UNKNOWN_SOURCE = 0,
  CHAT_UI = 1,
  OPENDOOR_INITIATED = 2,
  CUSTOMER_INITIATED = 3,
  UNRECOGNIZED = -1,
}

export function chatCategory_InteractionEvent_SourceFromJSON(object: any): ChatCategory_InteractionEvent_Source {
  switch (object) {
    case 0:
    case "UNKNOWN_SOURCE":
      return ChatCategory_InteractionEvent_Source.UNKNOWN_SOURCE;
    case 1:
    case "CHAT_UI":
      return ChatCategory_InteractionEvent_Source.CHAT_UI;
    case 2:
    case "OPENDOOR_INITIATED":
      return ChatCategory_InteractionEvent_Source.OPENDOOR_INITIATED;
    case 3:
    case "CUSTOMER_INITIATED":
      return ChatCategory_InteractionEvent_Source.CUSTOMER_INITIATED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ChatCategory_InteractionEvent_Source.UNRECOGNIZED;
  }
}

export function chatCategory_InteractionEvent_SourceToJSON(object: ChatCategory_InteractionEvent_Source): string {
  switch (object) {
    case ChatCategory_InteractionEvent_Source.UNKNOWN_SOURCE:
      return "UNKNOWN_SOURCE";
    case ChatCategory_InteractionEvent_Source.CHAT_UI:
      return "CHAT_UI";
    case ChatCategory_InteractionEvent_Source.OPENDOOR_INITIATED:
      return "OPENDOOR_INITIATED";
    case ChatCategory_InteractionEvent_Source.CUSTOMER_INITIATED:
      return "CUSTOMER_INITIATED";
    case ChatCategory_InteractionEvent_Source.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseChatCategory(): ChatCategory {
  return { event: undefined };
}

export const ChatCategory = {
  fromJSON(object: any): ChatCategory {
    return {
      event: isSet(object.interaction)
        ? { $case: "interaction", interaction: ChatCategory_InteractionEvent.fromJSON(object.interaction) }
        : undefined,
    };
  },

  toJSON(message: ChatCategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "interaction") {
      obj.interaction = ChatCategory_InteractionEvent.toJSON(message.event.interaction);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatCategory>, I>>(base?: I): ChatCategory {
    return ChatCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatCategory>, I>>(object: I): ChatCategory {
    const message = createBaseChatCategory();
    if (
      object.event?.$case === "interaction" &&
      object.event?.interaction !== undefined &&
      object.event?.interaction !== null
    ) {
      message.event = {
        $case: "interaction",
        interaction: ChatCategory_InteractionEvent.fromPartial(object.event.interaction),
      };
    }
    return message;
  },
};

function createBaseChatCategory_InteractionEvent(): ChatCategory_InteractionEvent {
  return {
    interactionId: "",
    rawUserInput: "",
    phoneNumber: "",
    createdAt: undefined,
    interactionType: 0,
    source: 0,
    category: "",
  };
}

export const ChatCategory_InteractionEvent = {
  fromJSON(object: any): ChatCategory_InteractionEvent {
    return {
      interactionId: isSet(object.interactionId) ? String(object.interactionId) : "",
      rawUserInput: isSet(object.rawUserInput) ? String(object.rawUserInput) : "",
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      interactionType: isSet(object.interactionType)
        ? chatCategory_InteractionEvent_InteractionTypeFromJSON(object.interactionType)
        : 0,
      source: isSet(object.source) ? chatCategory_InteractionEvent_SourceFromJSON(object.source) : 0,
      category: isSet(object.category) ? String(object.category) : "",
    };
  },

  toJSON(message: ChatCategory_InteractionEvent): unknown {
    const obj: any = {};
    if (message.interactionId !== "") {
      obj.interactionId = message.interactionId;
    }
    if (message.rawUserInput !== "") {
      obj.rawUserInput = message.rawUserInput;
    }
    if (message.phoneNumber !== "") {
      obj.phoneNumber = message.phoneNumber;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.interactionType !== 0) {
      obj.interactionType = chatCategory_InteractionEvent_InteractionTypeToJSON(message.interactionType);
    }
    if (message.source !== 0) {
      obj.source = chatCategory_InteractionEvent_SourceToJSON(message.source);
    }
    if (message.category !== "") {
      obj.category = message.category;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatCategory_InteractionEvent>, I>>(base?: I): ChatCategory_InteractionEvent {
    return ChatCategory_InteractionEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatCategory_InteractionEvent>, I>>(
    object: I,
  ): ChatCategory_InteractionEvent {
    const message = createBaseChatCategory_InteractionEvent();
    message.interactionId = object.interactionId ?? "";
    message.rawUserInput = object.rawUserInput ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.interactionType = object.interactionType ?? 0;
    message.source = object.source ?? 0;
    message.category = object.category ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
