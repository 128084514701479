/* eslint-disable */
import { Timestamp } from "../../../../google/protobuf/timestamp";

export const protobufPackage = "od_protos.customer.v1.data";

export enum FeedType {
  FEED_TYPE_UNSPECIFIED = 0,
  MARKETING = 1,
  TRANSACTIONAL = 2,
  UNRECOGNIZED = -1,
}

export function feedTypeFromJSON(object: any): FeedType {
  switch (object) {
    case 0:
    case "FEED_TYPE_UNSPECIFIED":
      return FeedType.FEED_TYPE_UNSPECIFIED;
    case 1:
    case "MARKETING":
      return FeedType.MARKETING;
    case 2:
    case "TRANSACTIONAL":
      return FeedType.TRANSACTIONAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FeedType.UNRECOGNIZED;
  }
}

export function feedTypeToJSON(object: FeedType): string {
  switch (object) {
    case FeedType.FEED_TYPE_UNSPECIFIED:
      return "FEED_TYPE_UNSPECIFIED";
    case FeedType.MARKETING:
      return "MARKETING";
    case FeedType.TRANSACTIONAL:
      return "TRANSACTIONAL";
    case FeedType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Medium {
  MEDIUM_UNSPECIFIED = 0,
  EMAIL = 1,
  SMS = 2,
  CALL = 3,
  UNRECOGNIZED = -1,
}

export function mediumFromJSON(object: any): Medium {
  switch (object) {
    case 0:
    case "MEDIUM_UNSPECIFIED":
      return Medium.MEDIUM_UNSPECIFIED;
    case 1:
    case "EMAIL":
      return Medium.EMAIL;
    case 2:
    case "SMS":
      return Medium.SMS;
    case 3:
    case "CALL":
      return Medium.CALL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Medium.UNRECOGNIZED;
  }
}

export function mediumToJSON(object: Medium): string {
  switch (object) {
    case Medium.MEDIUM_UNSPECIFIED:
      return "MEDIUM_UNSPECIFIED";
    case Medium.EMAIL:
      return "EMAIL";
    case Medium.SMS:
      return "SMS";
    case Medium.CALL:
      return "CALL";
    case Medium.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SubscriptionStatus {
  SUBSCRIPTION_STATUS_UNSPECIFIED = 0,
  SUBSCRIBED = 1,
  UNSUBSCRIBED = 2,
  /** FORCE_UNSUBSCRIBED - This is when OD cannot resubscribe the customer (e.g. when the customer sends a STOP message) */
  FORCE_UNSUBSCRIBED = 3,
  UNRECOGNIZED = -1,
}

export function subscriptionStatusFromJSON(object: any): SubscriptionStatus {
  switch (object) {
    case 0:
    case "SUBSCRIPTION_STATUS_UNSPECIFIED":
      return SubscriptionStatus.SUBSCRIPTION_STATUS_UNSPECIFIED;
    case 1:
    case "SUBSCRIBED":
      return SubscriptionStatus.SUBSCRIBED;
    case 2:
    case "UNSUBSCRIBED":
      return SubscriptionStatus.UNSUBSCRIBED;
    case 3:
    case "FORCE_UNSUBSCRIBED":
      return SubscriptionStatus.FORCE_UNSUBSCRIBED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SubscriptionStatus.UNRECOGNIZED;
  }
}

export function subscriptionStatusToJSON(object: SubscriptionStatus): string {
  switch (object) {
    case SubscriptionStatus.SUBSCRIPTION_STATUS_UNSPECIFIED:
      return "SUBSCRIPTION_STATUS_UNSPECIFIED";
    case SubscriptionStatus.SUBSCRIBED:
      return "SUBSCRIBED";
    case SubscriptionStatus.UNSUBSCRIBED:
      return "UNSUBSCRIBED";
    case SubscriptionStatus.FORCE_UNSUBSCRIBED:
      return "FORCE_UNSUBSCRIBED";
    case SubscriptionStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Actor {
  ACTOR_TYPE_UNSPECIFIED = 0,
  CUSTOMER = 1,
  OPERATOR = 2,
  AUTOMATED = 3,
  UNRECOGNIZED = -1,
}

export function actorFromJSON(object: any): Actor {
  switch (object) {
    case 0:
    case "ACTOR_TYPE_UNSPECIFIED":
      return Actor.ACTOR_TYPE_UNSPECIFIED;
    case 1:
    case "CUSTOMER":
      return Actor.CUSTOMER;
    case 2:
    case "OPERATOR":
      return Actor.OPERATOR;
    case 3:
    case "AUTOMATED":
      return Actor.AUTOMATED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Actor.UNRECOGNIZED;
  }
}

export function actorToJSON(object: Actor): string {
  switch (object) {
    case Actor.ACTOR_TYPE_UNSPECIFIED:
      return "ACTOR_TYPE_UNSPECIFIED";
    case Actor.CUSTOMER:
      return "CUSTOMER";
    case Actor.OPERATOR:
      return "OPERATOR";
    case Actor.AUTOMATED:
      return "AUTOMATED";
    case Actor.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Feed {
  name: string;
  description: string;
  type: FeedType;
}

export interface Subscription {
  feed?: Feed | undefined;
  medium: Medium;
  status: SubscriptionStatus;
}

export interface CommPreference {
  emailOptedOut: boolean;
  smsOptedOut: boolean;
  callOptedOut: boolean;
  subscriptions: Subscription[];
  /**
   * The timestamp when the customer opted out of email communications
   * If email_opted_out is false, this field will be empty
   * TODO: remove this field once no longer needed by web's serializers that rely on it
   */
  emailOptedOutAt?: Date | undefined;
}

function createBaseFeed(): Feed {
  return { name: "", description: "", type: 0 };
}

export const Feed = {
  fromJSON(object: any): Feed {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      type: isSet(object.type) ? feedTypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: Feed): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.type !== 0) {
      obj.type = feedTypeToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Feed>, I>>(base?: I): Feed {
    return Feed.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Feed>, I>>(object: I): Feed {
    const message = createBaseFeed();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseSubscription(): Subscription {
  return { feed: undefined, medium: 0, status: 0 };
}

export const Subscription = {
  fromJSON(object: any): Subscription {
    return {
      feed: isSet(object.feed) ? Feed.fromJSON(object.feed) : undefined,
      medium: isSet(object.medium) ? mediumFromJSON(object.medium) : 0,
      status: isSet(object.status) ? subscriptionStatusFromJSON(object.status) : 0,
    };
  },

  toJSON(message: Subscription): unknown {
    const obj: any = {};
    if (message.feed !== undefined) {
      obj.feed = Feed.toJSON(message.feed);
    }
    if (message.medium !== 0) {
      obj.medium = mediumToJSON(message.medium);
    }
    if (message.status !== 0) {
      obj.status = subscriptionStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Subscription>, I>>(base?: I): Subscription {
    return Subscription.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Subscription>, I>>(object: I): Subscription {
    const message = createBaseSubscription();
    message.feed = (object.feed !== undefined && object.feed !== null) ? Feed.fromPartial(object.feed) : undefined;
    message.medium = object.medium ?? 0;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseCommPreference(): CommPreference {
  return {
    emailOptedOut: false,
    smsOptedOut: false,
    callOptedOut: false,
    subscriptions: [],
    emailOptedOutAt: undefined,
  };
}

export const CommPreference = {
  fromJSON(object: any): CommPreference {
    return {
      emailOptedOut: isSet(object.emailOptedOut) ? Boolean(object.emailOptedOut) : false,
      smsOptedOut: isSet(object.smsOptedOut) ? Boolean(object.smsOptedOut) : false,
      callOptedOut: isSet(object.callOptedOut) ? Boolean(object.callOptedOut) : false,
      subscriptions: Array.isArray(object?.subscriptions)
        ? object.subscriptions.map((e: any) => Subscription.fromJSON(e))
        : [],
      emailOptedOutAt: isSet(object.emailOptedOutAt) ? fromJsonTimestamp(object.emailOptedOutAt) : undefined,
    };
  },

  toJSON(message: CommPreference): unknown {
    const obj: any = {};
    if (message.emailOptedOut === true) {
      obj.emailOptedOut = message.emailOptedOut;
    }
    if (message.smsOptedOut === true) {
      obj.smsOptedOut = message.smsOptedOut;
    }
    if (message.callOptedOut === true) {
      obj.callOptedOut = message.callOptedOut;
    }
    if (message.subscriptions?.length) {
      obj.subscriptions = message.subscriptions.map((e) => Subscription.toJSON(e));
    }
    if (message.emailOptedOutAt !== undefined) {
      obj.emailOptedOutAt = message.emailOptedOutAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommPreference>, I>>(base?: I): CommPreference {
    return CommPreference.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommPreference>, I>>(object: I): CommPreference {
    const message = createBaseCommPreference();
    message.emailOptedOut = object.emailOptedOut ?? false;
    message.smsOptedOut = object.smsOptedOut ?? false;
    message.callOptedOut = object.callOptedOut ?? false;
    message.subscriptions = object.subscriptions?.map((e) => Subscription.fromPartial(e)) || [];
    message.emailOptedOutAt = object.emailOptedOutAt ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
