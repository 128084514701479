/* storybook-check-ignore */
import React, { ErrorInfo, ReactNode } from 'react';

import { useObservability } from '../helpers/observability';

type State = { hasError: false };
type Props = {
  observability: ReturnType<typeof useObservability>;
  errorLabel: string;
  children: ReactNode;
};

function ErrorBoundaryWithObservability({
  children,
  errorLabel,
}: {
  children?: ReactNode;
  errorLabel?: string;
}) {
  const observability = useObservability();
  return (
    <ErrorBoundary observability={observability} errorLabel={errorLabel ?? 'cosmos'}>
      {children}
    </ErrorBoundary>
  );
}

// We are only using this to assert that we log any unhandled exception that we receive.
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: unknown) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.observability.trackError(this.props.errorLabel, error, {
      componentStack: errorInfo.componentStack,
    });
    throw error;
  }

  render(): React.ReactNode {
    return this.props.children;
  }
}

export default ErrorBoundaryWithObservability;
