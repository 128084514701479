/* eslint-disable */

export const protobufPackage = "od_protos.common";

/** An amount of time. */
export interface Duration {
  value: number;
  unit: Duration_Unit;
}

export enum Duration_Unit {
  UNKWOWN = 0,
  SECOND = 1,
  MINUTE = 2,
  HOUR = 3,
  DAY = 4,
  WEEK = 5,
  MONTH = 6,
  YEAR = 7,
  UNRECOGNIZED = -1,
}

export function duration_UnitFromJSON(object: any): Duration_Unit {
  switch (object) {
    case 0:
    case "UNKWOWN":
      return Duration_Unit.UNKWOWN;
    case 1:
    case "SECOND":
      return Duration_Unit.SECOND;
    case 2:
    case "MINUTE":
      return Duration_Unit.MINUTE;
    case 3:
    case "HOUR":
      return Duration_Unit.HOUR;
    case 4:
    case "DAY":
      return Duration_Unit.DAY;
    case 5:
    case "WEEK":
      return Duration_Unit.WEEK;
    case 6:
    case "MONTH":
      return Duration_Unit.MONTH;
    case 7:
    case "YEAR":
      return Duration_Unit.YEAR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Duration_Unit.UNRECOGNIZED;
  }
}

export function duration_UnitToJSON(object: Duration_Unit): string {
  switch (object) {
    case Duration_Unit.UNKWOWN:
      return "UNKWOWN";
    case Duration_Unit.SECOND:
      return "SECOND";
    case Duration_Unit.MINUTE:
      return "MINUTE";
    case Duration_Unit.HOUR:
      return "HOUR";
    case Duration_Unit.DAY:
      return "DAY";
    case Duration_Unit.WEEK:
      return "WEEK";
    case Duration_Unit.MONTH:
      return "MONTH";
    case Duration_Unit.YEAR:
      return "YEAR";
    case Duration_Unit.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** A measure of a 2D space. */
export interface Area {
  value: number;
  unit: Area_Unit;
}

export enum Area_Unit {
  UNKNOWN = 0,
  SQUARE_METER = 1,
  SQUARE_INCH = 2,
  UNRECOGNIZED = -1,
}

export function area_UnitFromJSON(object: any): Area_Unit {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return Area_Unit.UNKNOWN;
    case 1:
    case "SQUARE_METER":
      return Area_Unit.SQUARE_METER;
    case 2:
    case "SQUARE_INCH":
      return Area_Unit.SQUARE_INCH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Area_Unit.UNRECOGNIZED;
  }
}

export function area_UnitToJSON(object: Area_Unit): string {
  switch (object) {
    case Area_Unit.UNKNOWN:
      return "UNKNOWN";
    case Area_Unit.SQUARE_METER:
      return "SQUARE_METER";
    case Area_Unit.SQUARE_INCH:
      return "SQUARE_INCH";
    case Area_Unit.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseDuration(): Duration {
  return { value: 0, unit: 0 };
}

export const Duration = {
  fromJSON(object: any): Duration {
    return {
      value: isSet(object.value) ? Number(object.value) : 0,
      unit: isSet(object.unit) ? duration_UnitFromJSON(object.unit) : 0,
    };
  },

  toJSON(message: Duration): unknown {
    const obj: any = {};
    if (message.value !== 0) {
      obj.value = message.value;
    }
    if (message.unit !== 0) {
      obj.unit = duration_UnitToJSON(message.unit);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Duration>, I>>(base?: I): Duration {
    return Duration.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Duration>, I>>(object: I): Duration {
    const message = createBaseDuration();
    message.value = object.value ?? 0;
    message.unit = object.unit ?? 0;
    return message;
  },
};

function createBaseArea(): Area {
  return { value: 0, unit: 0 };
}

export const Area = {
  fromJSON(object: any): Area {
    return {
      value: isSet(object.value) ? Number(object.value) : 0,
      unit: isSet(object.unit) ? area_UnitFromJSON(object.unit) : 0,
    };
  },

  toJSON(message: Area): unknown {
    const obj: any = {};
    if (message.value !== 0) {
      obj.value = message.value;
    }
    if (message.unit !== 0) {
      obj.unit = area_UnitToJSON(message.unit);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Area>, I>>(base?: I): Area {
    return Area.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Area>, I>>(object: I): Area {
    const message = createBaseArea();
    message.value = object.value ?? 0;
    message.unit = object.unit ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
